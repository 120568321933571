import React, { useCallback, useState } from "react";
import { useAuthStore } from "@/hooks/store";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared";
import { ContentBlockTemplateShape, ContentBlockType } from "@/models";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import {
    useFindContentBlockMethods,
    useModalQueryParams,
    useSelectedSimulation,
} from "@/hooks";
import { useTemplateMap } from "./useTemplateMap";
import TemplateMapCategoryDisplay from "./TemplateMapCategoryDisplay";
import { useQuery } from "@tanstack/react-query";
import { getContentBlockTemplates } from "./getContentBlockTemplates";

const typesWithoutParent = [
    ContentBlockType.Page,
    ContentBlockType.Navbar,
    ContentBlockType["Bottom Drawer"],
    ContentBlockType["Top Drawer"],
];

function AddFromTemplateModalContents() {
    const { queryParams, closeModal } = useModalQueryParams(
        "AddFromTemplateModal"
    );
    const { parentContentBlockId, weight, roundId, replacedContentBlockId } =
        queryParams;

    const { getContentBlock } = useFindContentBlockMethods();
    const replacedContentBlock = getContentBlock(replacedContentBlockId);
    const { selectedSimulation } = useSelectedSimulation();
    const { user } = useAuthStore();
    const { data } = useQuery({
        queryKey: ["content-block-templates", selectedSimulation?.id],
        queryFn: () => getContentBlockTemplates(selectedSimulation?.id),
    });

    const { query, setQuery, templateMap } = useTemplateMap(
        data?.templates,
        user
    );

    const emptyCategoryLabels = {
        "Your Templates": "You have not created any templates yet.",
        "Templates for this simulation":
            "There are no templates for this simulation.",
        "Templates Shared With You":
            "No templates have been shared with you yet.",
    };

    const [selectedTemplate, setSelectedTemplate] =
        useState<ContentBlockTemplateShape>();

    const save = useCallback(() => {
        const block = {
            ...selectedTemplate.content_block,
            parent_content_block_id: typesWithoutParent.includes(
                selectedTemplate.content_block.content_block_type
            )
                ? null
                : parentContentBlockId,
            weight: weight,
            round_id: roundId,
            position: "after",
        };
        if (!!replacedContentBlock) {
            SapienInertia.post(
                "creator.design.update.from-template",
                {
                    ...block,
                    position: "after",
                    ...{ theme: JSON.stringify(block.theme) },
                } as (typeof LaravelRequestBodyShapes)["creator.design.content-blocks.store"],
                {
                    contentBlockId: replacedContentBlock.id,
                },
                {
                    onSuccess: () => {
                        closeModal();
                    },
                }
            );
        } else {
            SapienInertia.post(
                "creator.design.store.from-template",
                {
                    ...block,
                    position: "after",
                    ...{ theme: JSON.stringify(block.theme) },
                } as (typeof LaravelRequestBodyShapes)["creator.design.content-blocks.store"],
                {
                    templateId: selectedTemplate.id,
                },
                {
                    onSuccess: () => {
                        closeModal();
                    },
                }
            );
        }
    }, [
        selectedTemplate,
        parentContentBlockId,
        weight,
        roundId,
        replacedContentBlock,
    ]);
    return (
        <div
            className="flex flex-col p-6"
            data-testid="add-from-template-modal"
        >
            <div className="flex flex-col p-2">
                <div className="relative mt-1 rounded-md shadow-sm px-4 py-2">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-7">
                        <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>
                    <input
                        placeholder="Search"
                        type="text"
                        className={
                            "pl-10 rounded-md w-full max-w-3xl block border-gray-300 bg-white/10 focus:outline-blue-600/50 focus:outline-offset-0  focus:text-white text-gray-400"
                        }
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <div className="max-h-[74vh] overflow-auto flex flex-col scrollbar-thumb-rounded-full scrollbar-thumb-gray-500 scrollbar-w-3 scrollbar">
                    {Object.keys(templateMap).map((category) => (
                        <TemplateMapCategoryDisplay
                            templates={templateMap[category]}
                            category={category}
                            selectedTemplate={selectedTemplate}
                            handleClickAdd={setSelectedTemplate}
                            emptyLabel={emptyCategoryLabels[category]}
                        />
                    ))}
                </div>
            </div>

            <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6">
                <div className="flex justify-center items-center">
                    <button
                        onClick={closeModal}
                        className="w-full inline-flex items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                    >
                        Cancel
                    </button>
                </div>
                <div className="flex justify-center items-center">
                    <button
                        onClick={() => {
                            save();
                        }}
                        className="w-full inline-flex items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default function AddFromTemplateModal() {
    const { isAtModalUrl, closeModal } = useModalQueryParams(
        "AddFromTemplateModal"
    );
    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => closeModal()}
            size="xl"
            backgroundColor="#111928"
            styles={{ marginTop: "-4.5vh" }}
        >
            {isAtModalUrl && <AddFromTemplateModalContents />}
        </ModalContainer>
    );
}

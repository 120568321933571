import React from "react";
import DropDownSelector from "./DropDownSelector";
import {
    ALargeSmall,
    ArrowUpZA,
    BoldIcon,
    ItalicIcon,
    ListIcon,
    ListOrdered,
    Redo,
    UnderlineIcon,
    Undo,
} from "lucide-react";
import AlignmentSelector from "./AlignmentSelector";
import { lineHeightOptions } from "@/modules/text-editor";
import { TypographyType } from "@/styles";
import { TypographyTypes, getTypographyStyle } from "@/styles/SimulationTheme";
import { Editor } from "@tiptap/core";
import RichTextMenuButton from "./RichTextMenuButton";
import ColorButton from "./ColorButton";

const textTypes = [
    {
        label: "H1",
        value: "FIGMA_HEADING_1",
    },
    {
        label: "H2",
        value: "FIGMA_HEADING_2",
    },
    {
        label: "H3",
        value: "FIGMA_HEADING_3",
    },
    {
        label: "H6",
        value: "HEADING_6",
    },
    {
        label: "P",
        value: "FIGMA_PARAGRAPH",
    },
    {
        label: "P Alt",
        value: "FIGMA_PARAGRAPH_ALT",
    },
    {
        label: "Subtitle",
        value: "FIGMA_SUBTITLE",
    },
    {
        label: "Subtitle Alt",
        value: "FIGMA_SUBTITLE_ALT",
    },
    {
        label: "Card Text",
        value: "TEXT_STACKED_CARD",
    },
];

const fonts = [
    {
        label: "Open Sans (default)",
        value: "Open Sans",
    },
    {
        label: "Inter",
        value: "Inter",
    },
    {
        label: "Arial",
        value: "Arial",
    },
    {
        label: "Courier New",
        value: "Courier New",
    },
    {
        label: "Georgia",
        value: "Georgia",
    },
    {
        label: "Helvetica",
        value: "Helvetica",
    },
    {
        label: "Impact",
        value: "Impact",
    },
    {
        label: "Times New Roman",
        value: "Times New Roman",
    },
];

const allowedFontSizes = [
    {
        label: "8",
        value: "8",
    },
    {
        label: "9",
        value: "9",
    },
    {
        label: "10",
        value: "10",
    },
    {
        label: "11",
        value: "11",
    },
    {
        label: "12",
        value: "12",
    },
    {
        label: "14",
        value: "14",
    },
    {
        label: "16",
        value: "16",
    },
    {
        label: "18",
        value: "18",
    },
    {
        label: "20",
        value: "20",
    },
    {
        label: "22",
        value: "22",
    },
    {
        label: "24",
        value: "24",
    },
    {
        label: "30",
        value: "30",
    },
    {
        label: "36",
        value: "36",
    },
    {
        label: "48",
        value: "48",
    },
    {
        label: "56",
        value: "56",
    },
    {
        label: "60",
        value: "60",
    },
    {
        label: "64",
        value: "64",
    },
    {
        label: "72",
        value: "72",
    },
    {
        label: "96",
        value: "96",
    },
];

type Props = { editor: Editor; showColorPicker?: boolean };

export default function Menu({ editor, showColorPicker }: Props) {
    const optionallySelectAll = (editor: Editor) => {
        const currentSelectionIsEmpty = editor.view.state.selection.empty;
        if (currentSelectionIsEmpty) {
            editor.chain().focus().selectAll().run();
        }
    };

    return (
        <div className="relative mt-2 flex flex-col items-center gap-2 rounded-md bg-white p-1 shadow-lg">
            <div className="flex w-full flex-row justify-around gap-1">
                <DropDownSelector
                    width={100}
                    items={textTypes}
                    selectedItem={
                        editor.getAttributes("typography")?.typographyType
                            ? editor.getAttributes("typography")?.typographyType
                            : "Font Size"
                    }
                    callback={(textType) => {
                        const value = textType as TypographyTypes;
                        optionallySelectAll(editor);
                        const typographyTypeKey = Object.keys(
                            TypographyType,
                        ).find((key) => TypographyType[key] === value);

                        const style = getTypographyStyle(value);

                        if (style?.fontSize) {
                            editor
                                .chain()
                                .focus()
                                .setFontSize(style.fontSize[0])
                                .run();
                        }

                        if (typographyTypeKey) {
                            editor
                                .chain()
                                .focus()
                                .setTypographyType(
                                    TypographyType[typographyTypeKey],
                                )
                                .run();
                        }
                    }}
                />
                <DropDownSelector
                    items={fonts}
                    selectedItem={
                        editor?.getAttributes("textStyle")?.fontFamily ||
                        "Open Sans"
                    }
                    callback={(font) => {
                        optionallySelectAll(editor);
                        editor.chain().focus().setFontFamily(font).run();
                    }}
                />
                <DropDownSelector
                    width={90}
                    label={<ALargeSmall className="h-5 w-5" />}
                    items={allowedFontSizes}
                    selectedItem={editor.getAttributes("textStyle")?.fontSize}
                    callback={(size) => {
                        optionallySelectAll(editor);
                        editor.chain().focus().setFontSize(size).run();
                    }}
                />
                <DropDownSelector
                    width={90}
                    label={<ArrowUpZA className="h-5 w-5" />}
                    items={lineHeightOptions.reduce((carry, option) => {
                        return [
                            ...carry,
                            {
                                label: option,
                                value: option,
                            },
                        ];
                    }, [])}
                    selectedItem={
                        lineHeightOptions.includes(
                            editor.getAttributes("textStyle")?.lineHeight,
                        )
                            ? editor.getAttributes("textStyle")?.lineHeight
                            : "N/A"
                    }
                    callback={(lineHeight) => {
                        optionallySelectAll(editor);
                        editor.chain().focus().setLineHeight(lineHeight).run();
                    }}
                />{" "}
            </div>

            <div className="flex w-full justify-around">
                <RichTextMenuButton
                    onClick={() => {
                        optionallySelectAll(editor);
                        editor.chain().focus().toggleBold().run();
                    }}
                    isActive={editor.isActive("bold")}
                >
                    <BoldIcon className="h-5 w-5" />
                </RichTextMenuButton>
                <RichTextMenuButton
                    onClick={() => {
                        optionallySelectAll(editor);
                        editor.chain().focus().toggleItalic().run();
                    }}
                    isActive={editor.isActive("italic")}
                >
                    <ItalicIcon className="h-5 w-5" />
                </RichTextMenuButton>
                <RichTextMenuButton
                    onClick={() => {
                        optionallySelectAll(editor);
                        editor.chain().focus().toggleUnderline().run();
                    }}
                    isActive={editor.isActive("underline")}
                >
                    <UnderlineIcon className="h-5 w-5" />
                </RichTextMenuButton>
                <RichTextMenuButton
                    onClick={() => {
                        optionallySelectAll(editor);
                        editor.chain().focus().toggleBulletList().run();
                    }}
                    isActive={editor.isActive("bulletList")}
                >
                    <ListIcon className="h-5 w-5" />
                </RichTextMenuButton>
                <RichTextMenuButton
                    onClick={() => {
                        optionallySelectAll(editor);
                        editor.chain().focus().toggleOrderedList().run();
                    }}
                    isActive={editor.isActive("orderedList")}
                >
                    {" "}
                    <ListOrdered className="h-4 w-4" />
                </RichTextMenuButton>

                <AlignmentSelector
                    alignment={
                        ["left", "center", "right", "justify"].find(
                            (alignment) =>
                                editor.isActive({ textAlign: alignment }),
                        ) || "left"
                    }
                    callback={(alignment) => {
                        optionallySelectAll(editor);
                        editor.chain().focus().setTextAlign(alignment).run();
                    }}
                />

                <ColorButton
                    color={editor.getAttributes("textStyle")?.color || "black"}
                    editor={editor}
                    optionallySelectAll={optionallySelectAll}
                />
                <RichTextMenuButton
                    onClick={() => editor.chain().focus().undo().run()}
                    isActive={false}
                >
                    <Undo className="h-5 w-5" />
                </RichTextMenuButton>
                <RichTextMenuButton
                    onClick={() => editor.chain().focus().redo().run()}
                    isActive={false}
                >
                    <Redo className="h-5 w-5" />
                </RichTextMenuButton>
            </div>
        </div>
    );
}

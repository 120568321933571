export const getIdOfChildBlockNearestClick = (
    event: React.MouseEvent,
): string | null => {
    const button = event.target as HTMLElement;

    if (!button.hasAttribute("data-add-to")) {
        console.log("no button  found");
        return null;
    }

    const parent = document.querySelector(
        `[data-content-block-id="${button.getAttribute("data-add-to")}"]`,
    ) as HTMLElement;
    if (!parent) {
        console.log("no parent block found");
        return null;
    }
    const clientX = event.clientX;
    const clientY = event.clientY;

    const result = clickHandler(parent, clientX, clientY);
    if (result !== null) {
        console.log("Clicked on:", result);
        return result;
    }
    return null;
};

const clickHandler = (
    element: HTMLElement,
    clientX: number,
    clientY: number,
): string | null => {
    // Recursive function to find the first element with children that have `data-content-block-id`
    const childrenWithId = Array.from(element.children).filter(
        (child) =>
            child instanceof HTMLElement &&
            child.hasAttribute("data-content-block-id"),
    ) as HTMLElement[];
    if (childrenWithId.length > 0) {
        // If children with the ID are found, handle the click at this level
        return processClick(
            childrenWithId,
            clientX,
            clientY,
            element.getBoundingClientRect(),
        );
    } else {
        // Recurse into each child that is an HTMLElement
        for (let child of Array.from(element.children)) {
            if (child instanceof HTMLElement) {
                const result = clickHandler(child, clientX, clientY);
                if (result !== null) {
                    return result;
                }
            }
        }
        return null;
    }
};

const processClick = (
    children: HTMLElement[],
    clientX: number,
    clientY: number,
    parentRect: DOMRect,
): string | null => {
    const isHorizontal = window
        .getComputedStyle(children[0].parentNode as Element)
        .display.includes("flex"); // Simplified horizontal check
    const clickPosition = isHorizontal
        ? clientX - parentRect.left
        : clientY - parentRect.top;

    let prevChild: HTMLElement | null = null;
    let nextChild: HTMLElement | null = null;

    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const childRect = child.getBoundingClientRect();
        const childStart = isHorizontal
            ? childRect.left - parentRect.left
            : childRect.top - parentRect.top;
        // const childEnd =
        //     childStart + (isHorizontal ? childRect.width : childRect.height);

        if (clickPosition < childStart) {
            nextChild = child;
            break;
        }
        prevChild = child;
    }

    if (prevChild) {
        console.log(
            "Click was between:",
            prevChild.getAttribute("data-content-block-id"),
            nextChild
                ? nextChild.getAttribute("data-content-block-id")
                : "and the end.",
        );
        return prevChild.getAttribute("data-content-block-id");
    } else if (
        children.length > 0 &&
        clickPosition <
            (isHorizontal
                ? children[0].getBoundingClientRect().left - parentRect.left
                : children[0].getBoundingClientRect().top - parentRect.top)
    ) {
        console.log("Click was left of the first child.");
    } else {
        console.log("Click was right of the last child.");
    }

    return null;
};

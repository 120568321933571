import { useSelectedSimulation } from "@/hooks";
import { sapienAxios } from "@/inertia-utils/hooks";
import { Color, ColorType } from "@/models";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import parseCSSColor from "parse-css-color";

type ColorTypeGroup = {
    name: string;
    leadColor: [number, number, number];
    colors: ColorType[];
    rawColors: string[];
};

const isValidCSSColor = (color: string): boolean => {
    const cssColorRegex =
        /^(?:#(?:[0-9a-fA-F]{3}){1,2}(?:[0-9a-fA-F]{2})?|rgb\((\s*\d{1,3}\s*,){2}\s*\d{1,3}\s*\)|rgba\((\s*\d{1,3}\s*,){3}\s*(0|0?\.\d+|1(\.0+)?)\)|hsl\(\s*\d{1,3}\s*,\s*\d{1,3}%\s*,\s*\d{1,3}%\s*\)|hsla\(\s*\d{1,3}\s*,\s*\d{1,3}%\s*,\s*\d{1,3}%\s*,\s*(0|0?\.\d+|1(\.0+)?)\))$/i;

    return cssColorRegex.test(color);
};
// Sorting
function blendRgbaWithWhite(color: ColorType) {
    const [originalRed, originalGreen, originalBlue, originalAlpha] =
        parseCSSColor(color.css_color).values;

    const a = originalAlpha / 255;
    const r = Math.floor(originalRed * a + 0xff * (1 - a));
    const g = Math.floor(originalGreen * a + 0xff * (1 - a));
    const b = Math.floor(originalBlue * a + 0xff * (1 - a));
    return {
        ...color,
        css_color: "#" + ((r << 16) | (g << 8) | b).toString(16),
    };
}

function rgbToHsl(
    r: number,
    g: number,
    b: number,
): { h: number; s: number; l: number } {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0,
        s = 0,
        l = (max + min) / 2;

    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360), // Convert hue to degrees
        s: Math.round(s * 100), // Convert saturation to percentage
        l: Math.round(l * 100), // Convert lightness to percentage
    };
}

function hexToHsl(hex: string): { h: number; s: number; l: number } {
    let r = parseInt(hex.slice(1, 3), 16) / 255;
    let g = parseInt(hex.slice(3, 5), 16) / 255;
    let b = parseInt(hex.slice(5, 7), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0,
        s = 0,
        l = (max + min) / 2;

    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return {
        h: Math.round(h * 360),
        s: Math.round(s * 100),
        l: Math.round(l * 100),
    };
}

function oneDimensionSorting(
    colors: (ColorType | App.Data.DesignColorData)[],
    dim: string,
) {
    return colors.sort((colorA, colorB) => {
        const hslA = hexToHsl(colorA.css_color);
        const hslB = hexToHsl(colorB.css_color);
        if (hslA[dim] < hslB[dim]) {
            return -1;
        } else if (hslA[dim] > hslB[dim]) {
            return 1;
        } else {
            return 0;
        }
    });
}

export const colorGroupNames = [
    "Blacks",
    "Whites",
    "Grays",
    "Reds",
    "Oranges",
    "Yellows",
    "Greens",
    "Cyans",
    "Blues",
    "Purples",
    "Magentas",
];

function getColorGroupName(h: number, s: number, l: number): string {
    // Adjust the lightness threshold to better distinguish dark colors
    if (l <= 5 && s <= 15) return "Blacks"; // Very dark and desaturated colors
    if (l >= 95) return "Whites"; // Very light colors
    if (s <= 20) return "Grays"; // Desaturated colors

    // Group by broader hue ranges for smoother transitions
    if (h < 15 || h >= 345) return "Reds";
    if (h < 45) return "Oranges";
    if (h < 75) return "Yellows";
    if (h < 165) return "Greens";
    if (h < 255) return "Blues";
    if (h < 285) return "Purples";
    if (h < 345) return "Magentas";
    return "Reds"; // Fallback
}

function sortWithClusters(
    colorsToSort: ColorType[],
): Record<string, ColorType[]> {
    if (!colorsToSort?.length) {
        return {};
    }

    const mappedColors = colorsToSort.map((color) => {
        if (!color.css_color) {
            return color;
        }

        const isRgba = color.css_color.includes("rgba");
        return isRgba ? blendRgbaWithWhite(color) : color;
    });

    const validColors = mappedColors.filter((color) =>
        isValidCSSColor(color.css_color),
    );

    const colorGroups: Record<string, ColorType[]> = validColors.reduce(
        (carry, color) => {
            color = {
                ...color,
                h: color.h || 0,
                s: color.s || 0,
                l: color.l || 0,
            };

            const groupName = getColorGroupName(color.h, color.s, color.l);
            if (!carry[groupName]) {
                carry[groupName] = [];
            }
            return {
                ...carry,
                [groupName]: [...carry[groupName], color],
            };
        },
        {},
    );

    return Object.keys(colorGroups).reduce((carry, key) => {
        const dim = ["white", "grey", "black"].includes(key) ? "l" : "s";
        return {
            ...carry,
            [key]: oneDimensionSorting(colorGroups[key], dim),
        };
    }, {});
}

async function fetchColors(simulationId: string) {
    const { data } = await sapienAxios.get<
        { colors: Color[]; gradients: App.Data.DesignColorGradientData[] },
        "creator.design.colors.show"
    >("creator.design.colors.show", {
        simulationId,
    });

    return data;
}
export function convertToHsl(color: string): {
    h: number;
    s: number;
    l: number;
} {
    const parsedColor = parseCSSColor(color);
    if (!parsedColor) {
        throw new Error(`Invalid color format: ${color}`);
    }
    const [r, g, b] = parsedColor.values;
    return rgbToHsl(r, g, b);
}

function ensureUniqueColorsByHSL(colors: ColorType[]): ColorType[] {
    const uniqueColors = new Set<string>();
    const uniqueColorObjects: ColorType[] = [];

    colors.forEach((color) => {
        const { h, s, l } = convertToHsl(color.css_color);
        const hslKey = `${h}-${s}-${l}`;
        if (!uniqueColors.has(hslKey)) {
            uniqueColors.add(hslKey);
            uniqueColorObjects.push({ ...color, h, s, l });
        }
    });

    return uniqueColorObjects;
}

export function useColors() {
    const { selectedSimulation } = useSelectedSimulation();

    const { data, isPending } = useQuery({
        queryKey: ["colors", selectedSimulation?.id],
        queryFn: async () => {
            if (!selectedSimulation?.id) {
                throw new Error("No simulation selected");
            }
            const { colors, gradients } = await fetchColors(
                selectedSimulation.id,
            );
            return { colors, gradients };
        },
        select: (data) => {
            return {
                colors: ensureUniqueColorsByHSL(data?.colors ?? []),
                gradients: data?.gradients ?? [],
            };
        },
        initialData: { colors: [], gradients: [] },
        enabled: !!selectedSimulation?.id, // Only run query if we have a simulation ID
    });

    const groupedColors = useMemo(() => {
        return sortWithClusters(data.colors);
    }, [data?.colors.length]);
    const ungroupedColors = useMemo(
        () =>
            Object.values(groupedColors).reduce<ColorType[]>(
                (carry, colorGroup) => [...carry, ...(colorGroup ?? [])],
                [],
            ),
        [groupedColors],
    );

    const orderedGradients = useMemo(() => {
        return data.gradients.sort((a, b) =>
            a.designColors[0].css_color.localeCompare(
                b.designColors[0].css_color,
            ),
        );
    }, [data.gradients, data.gradients?.length]);

    return {
        colors: data.colors ?? [],
        gradients: data.gradients ?? [],
        orderedGradients,
        groupedColors,
        ungroupedColors,
        isPending,
    };
}

export function useSetColors(colors: Record<string, string>) {
    useEffect(() => {
        if (!colors) return;

        Object.entries(colors).forEach(([key, value]) => {
            if (key && value) {
                document.documentElement.style.setProperty(`--${key}`, value);
            }
        });
    }, [colors]);
}

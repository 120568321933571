import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { SapienInertia } from "@/inertia-utils/hooks";
import { ModalContainer } from "@/modules/shared";
import {
    reducer,
    ActionTypes,
    initialState,
    timeSeriesTypes,
} from "./ChartWizardReducer";
import Steps from "../Steps";
import ChartWizardFooter from "../WizardFooter";
import ChartTypeSelector from "./ChartTypeSelector";
import ChartSeriesList from "./ChartSeriesList";
import ChartSeriesForm from "./ChartSeriesForm";
import { useModelVariableStore, useTimeHorizonStore } from "@/hooks/store";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { ContentBlockType, ModelVariableDataType } from "@/models";
import TimeHorizonForm from "../TimeHorizonForm";
import { defaultChartContainerTheme } from "@/styles";
import { TrashIcon } from "@heroicons/react/24/solid";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { useDefaultVariableValues } from "../useDefaultVariableValues";
import { useGetContentBlockWithModelDataSources } from "../useGetContentBlockWithModelDataSources";
import ChartPreview from "./ChartPreview";
import { getPreviewChartValues, variableValuesToChartSeries } from "./util";
import AdvancedOptions from "./AdvancedOptions";
import { useModalQueryParams } from "@/hooks";
// import { useChartState } from "./useChartState";

function ChartWizardContents() {
    const { queryParams, closeModal } = useModalQueryParams("ChartWizard");
    const { chartId, parentContentBlockId } = queryParams;

    const [state, dispatch] = useReducer(reducer, initialState);
    // const {state:testy} = useChartState()
    const { modelVariables } = useModelVariableStore();
    const { timeHorizons } = useTimeHorizonStore();
    const variables = Object.values(modelVariables).filter(
        (modelVariable) =>
            modelVariable.data_type === ModelVariableDataType.Number
    );

    const { editedIndex, editedItem, chartWizardSubmissionData } = state;

    const { series, chartType, timeHorizonIds } = chartWizardSubmissionData;

    const {
        data: { contentBlockWithModelDataSources },
    } = useGetContentBlockWithModelDataSources(chartId);

    const model_variable_ids = useMemo(() => {
        return [
            ...series.map((chartSeries) => chartSeries.modelVariableId),
            editedItem?.modelVariableId,
            ...contentBlockWithModelDataSources?.contentBlocks?.reduce(
                (acc, block) => {
                    if (!block.contentBlockModelDataSources?.length) {
                        return acc;
                    }

                    return [
                        ...acc,
                        ...block.contentBlockModelDataSources.map(
                            (source) => source.model_variable_id
                        ),
                    ];
                },
                []
            ),
        ];
    }, [chartId, editedItem, series, contentBlockWithModelDataSources]);

    const {
        isFetching,
        data: { values },
    } = useDefaultVariableValues<{ [index: string]: FormattedChartValue[] }>({
        model_variable_ids,
        timeHorizonIds,
        formatterCallback: (values) =>
            variableValuesToChartSeries(values, series, editedItem),
    });

    useEffect(() => {
        if (
            contentBlockWithModelDataSources?.id &&
            contentBlockWithModelDataSources?.contentBlocks?.length
        ) {
            dispatch({
                type: ActionTypes.SET_ALL_FROM_CONTENT_BLOCK,
                payload: contentBlockWithModelDataSources,
            });
        } else if (parentContentBlockId) {
            dispatch({
                type: ActionTypes.SET_ALL_FROM_CONTENT_BLOCK,
                payload: {
                    id: "",
                    contentBlocks: [],
                    content_block_type: ContentBlockType["Chart Container"],
                    parent_content_block_id: parentContentBlockId,
                    theme: defaultChartContainerTheme,
                },
            });
        }
    }, [chartId, parentContentBlockId, contentBlockWithModelDataSources]);

    const editedItemChartValues = useMemo(() => {
        if (!editedItem) return [] as FormattedChartValue[];
        return editedItem?.chart_data || values[editedItem?.modelVariableId];
    }, [editedItem, values]);

    const previewValues = useMemo(() => {
        return getPreviewChartValues(
            state?.chartWizardSubmissionData?.series,
            values,
            editedItem,
            editedIndex
        );
    }, [state, editedItem, values, editedIndex]);

    const isEditing =
        (!!editedItem && editedIndex !== -1) ||
        !chartWizardSubmissionData?.series?.length;

    const saveChart = useCallback(() => {
        if (chartWizardSubmissionData.id) {
            SapienInertia.put(
                "creator.design.chart.update",
                {
                    ...state.chartWizardSubmissionData,
                } as unknown as (typeof LaravelRequestBodyShapes)["creator.design.chart.update"],
                {
                    parentContentBlockId,
                    chart: chartWizardSubmissionData.id,
                }
            );
        } else {
            SapienInertia.post(
                "creator.design.chart.store",
                {
                    ...state.chartWizardSubmissionData,
                } as unknown as (typeof LaravelRequestBodyShapes)["creator.design.chart.store"],
                {
                    parentContentBlockId,
                }
            );
        }
    }, [state]);

    const rightButtonHandler = useCallback(() => {
        if (state.step === 3) {
            saveChart();
        } else {
            dispatch({
                type: ActionTypes.INCREMENT,
            });
        }
    }, [state]);

    if (!contentBlockWithModelDataSources)
        return (
            <div className="flex py-6 flex-col space-y-4">
                <div className="flex flex-col space-y-2">
                    <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-4 py-1">
                            <div className="h-4 bg-gray-400 rounded w-3/4"></div>
                            <div className="space-y-2">
                                <div className="h-4 bg-gray-400 rounded"></div>
                                <div className="h-4 bg-gray-400 rounded w-5/6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    return (
        <div className="py-6 text-white flex flex-col items-center space-y-4 mt-0" data-testid="chart-wizard">
            <div
                className={`px-6 w-full flex ${
                    !contentBlockWithModelDataSources?.id
                        ? "justify-between"
                        : "justify-end"
                }`}
            >
                {!contentBlockWithModelDataSources?.id && (
                    <Steps numberOfSteps={3} currentStep={state?.step} />
                )}
                {!!chartType && (
                    <span className="capitalize inline-flex items-center rounded-full bg-green-100/70 px-3 py-0.5 text-sm font-medium text-green-800">
                        {chartType}
                    </span>
                )}
            </div>
            {state.step === 1 && (
                <ChartTypeSelector
                    selectedChartType={chartType}
                    setChartType={(chartType) => {
                        dispatch({
                            type: ActionTypes.SET_CHART_TYPE,
                            payload: chartType,
                        });
                    }}
                />
            )}
            {state.step === 2 && (
                <div className="flex flex-col w-full items-start justify-center space-y-4 p-6 px-8 border-t border-[#374151]">
                    <label>
                        What kind of data would you like to use for your chart?
                    </label>
                    <span className="isolate inline-flex rounded-md shadow-sm">
                        <button
                            onClick={() => {
                                dispatch({
                                    type: ActionTypes.SET_DATA_SOURCE,
                                    payload: "model",
                                });
                            }}
                            type="button"
                            className={`relative inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-blue-700 hover:bg-blue-700 focus:z-10 ${
                                state.dataSource === "model"
                                    ? "bg-blue-600 text-white"
                                    : "bg-white text-gray-900 hover:text-white"
                            }}`}
                            data-testid="chart-source-model"
                        >
                            Values from the sim's model
                        </button>
                        <button
                            onClick={() => {
                                dispatch({
                                    type: ActionTypes.SET_DATA_SOURCE,
                                    payload: "manual",
                                });
                            }}
                            type="button"
                            className={`relative -ml-px inline-flex items-center rounded-r-md  px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-blue-700 hover:bg-blue-700 focus:z-10 ${
                                state.dataSource === "manual"
                                    ? "bg-blue-600 text-white"
                                    : "bg-white text-gray-900 hover:text-white"
                            }`}
                            data-testid="chart-source-manual"
                        >
                            Values you enter
                        </button>
                    </span>
                </div>
            )}
            {state.step === 3 && (
                <>
                    <div className="grid grid-cols-2 w-full gap-6 p-4 border-t border-[#374151]">
                        <div className=" w-full">
                            {isEditing && !!editedItem ? (
                                <>
                                    <ChartSeriesForm
                                        chartSeries={editedItem}
                                        modelVariables={variables}
                                        selectedModelVariable={variables.find(
                                            (variable) =>
                                                variable.id ===
                                                editedItem?.modelVariableId
                                        )}
                                        updateEditedSeries={(payload) => {
                                            dispatch({
                                                type: ActionTypes.UPDATE_EDITED_SERIES_AND_COMMIT_CHAGES,
                                                payload,
                                            });
                                        }}
                                        addSeries={() => {
                                            dispatch({
                                                type: ActionTypes.ADD_SERIES,
                                            });
                                        }}
                                        index={
                                            editedIndex !== -1 ? editedIndex : 0
                                        }
                                        dataSource={state.dataSource}
                                        timeHorizonIds={timeHorizonIds}
                                        chartValues={editedItemChartValues}
                                        commitEdits={() => {
                                            dispatch({
                                                type: ActionTypes.COMMIT_EDITS,
                                            });
                                        }}
                                    />
                                </>
                            ) : (
                                <ChartSeriesList
                                    chartType={chartType}
                                    chartSeries={
                                        state?.chartWizardSubmissionData?.series
                                    }
                                    addChartSeries={() => {
                                        dispatch({
                                            type: ActionTypes.ADD_SERIES,
                                        });
                                    }}
                                    editedIndex={editedIndex}
                                    setEditedIndex={(index) => {
                                        dispatch({
                                            type: ActionTypes.SELECT_SERIES_TO_EDIT,
                                            payload: index,
                                        });
                                    }}
                                />
                            )}{" "}
                            {!isEditing && (
                                <AdvancedOptions
                                    chartType={chartType}
                                    setSubmissionData={(submissionData) => {
                                        dispatch({
                                            type: ActionTypes.SET_ADVANCED_OPTIONS,
                                            payload: submissionData,
                                        });
                                    }}
                                    submissionData={
                                        state.chartWizardSubmissionData
                                    }
                                />
                            )}
                            {state.dataSource === "model" && !isEditing && (
                                <TimeHorizonForm
                                    timeHorizons={timeHorizons}
                                    selectedTimeHorizons={timeHorizons.filter(
                                        (th) => timeHorizonIds.includes(th.id)
                                    )}
                                    setTimeHorizons={(timeHorizonIds) => {
                                        dispatch({
                                            type: ActionTypes.SET_TIME_HORIZONS,
                                            payload: timeHorizonIds,
                                        });
                                    }}
                                    isSingleSelect={
                                        !timeSeriesTypes.includes(chartType)
                                    }
                                />
                            )}
                        </div>

                        <ChartPreview
                            chartType={chartType}
                            chartValues={previewValues}
                            dataSource={state.dataSource}
                            theme={state.chartWizardSubmissionData?.theme}
                            loading={isFetching}
                        />
                    </div>

                    {isEditing && (
                        <div className="border-t border-[#374151] grid md:grid-cols-2 gap-6 pt-6 px-6 w-full">
                            <div className="flex justify-center items-center">
                                <button
                                    onClick={() => {
                                        dispatch({
                                            type: ActionTypes.CANCEL_EDIT_SERIES,
                                        });
                                    }}
                                    className="w-full inline-flex space-x-2 items-center justify-center text-white hover:bg-[#1F2A37] focus:ring-2 focus:ring-blue-300 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center"
                                >
                                    <span>Discard Changes</span>
                                    <TrashIcon className="h-6 w-6" />
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}

            {(!isEditing || state.step < 3) && (
                <ChartWizardFooter
                    leftButtonHandler={() => {
                        if (state.step < 3) {
                            closeModal();
                        } else {
                            dispatch({
                                type: ActionTypes.DECREMENT,
                            });
                        }
                    }}
                    rightButtonHandler={rightButtonHandler}
                    isLastStep={state.step === 3}
                    isFirstStep={state.step === 1}
                    advanceDisabled={!chartType}
                />
            )}
        </div>
    );
}

export default function ChartWizard() {
    const { closeModal, isAtModalUrl } = useModalQueryParams("ChartWizard");

    return (
        <ModalContainer
            isModalOpen={isAtModalUrl}
            setIsModalOpen={() => closeModal()}
            size="xl"
            backgroundColor="#111928"
            modalId="chart-wizard"
        >
            {isAtModalUrl && <ChartWizardContents />}
        </ModalContainer>
    );
}

import React, { useMemo } from "react";

import { useColors } from "@/Pages/ELearning/shared-state";
import { ColorType } from "@/models";

type Props = {
    selectedColor: string;
    selectColor: (colorValue: string, color?: ColorType) => void;
    selectGradient: (gradient: App.Data.DesignColorGradientData) => void;
};

export default function ColorChooser({
    selectedColor,
    selectColor,
    selectGradient,
}: Props) {
    const { groupedColors, isPending, orderedGradients } = useColors();

    const colorGroupNames = [
        "Blacks",
        "Whites",
        "Grays",
        "Reds",
        "Oranges",
        "Yellows",
        "Greens",
        "Cyans",
        "Blues",
        "Purples",
        "Magentas",
    ];

    // Early return for loading state
    if (isPending) {
        return (
            <div className="flex flex-col gap-2 p-2">
                <div className="flex flex-col gap-2">
                    <p className="text-xs text-slate-500">Theme Colors</p>
                </div>
                <div className="grid w-full grid-cols-4 gap-[6px]">
                    {Array.from({ length: 16 }).map((_, index) => (
                        <div
                            key={index}
                            className="h-8 w-full rounded-md border border-gray-200"
                        ></div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-2 p-2">
            <div className="flex flex-col gap-2">
                <p className="text-xs text-slate-500">Theme Colors</p>
                <div className="grid w-full grid-cols-4 gap-[6px]">
                    {colorGroupNames.map((groupName) => {
                        const colors = groupedColors[groupName];
                        if (!colors?.length) return null;

                        return colors.map((color) => (
                            <button
                                data-testid={`color-${color.css_color}`}
                                key={color.id}
                                onClick={() =>
                                    selectColor(color.css_color, color)
                                }
                                className="h-8 w-full rounded-md border border-gray-200 transition-all duration-200
                                    hover:shadow-sm"
                                style={{
                                    backgroundColor: color.css_color,
                                }}
                            ></button>
                        ));
                    })}
                </div>
            </div>
            <div className="mt-2 flex flex-col gap-2">
                <p className="text-xs text-slate-500">Gradients</p>
                <div className="grid w-full grid-cols-4 gap-[6px]">
                    {orderedGradients.map((gradient) => (
                        <button
                            data-testid={`gradient-${gradient.designColors[0].css_color}-${gradient.designColors[gradient.designColors.length - 1].css_color}`}
                            key={gradient.id}
                            className={`duration-200F h-8 w-full rounded-md border border-gray-200 transition-all
                            hover:shadow-sm bg-gradient-to-${gradient.angle || "r"} from-[var(--from-color)]
                            to-[var(--to-color)] ${
                                gradient.designColors.length > 2
                                    ? "via-[var(--via-color)]"
                                    : ""
                            }`}
                            style={
                                {
                                    "--from-color":
                                        gradient.designColors[0].css_color,
                                    "--to-color":
                                        gradient.designColors[
                                            gradient.designColors.length - 1
                                        ].css_color,
                                    "--via-color":
                                        gradient.designColors.length > 2
                                            ? gradient.designColors[1].css_color
                                            : "",
                                } as React.CSSProperties
                            }
                            onClick={() => selectGradient(gradient)}
                        ></button>
                    ))}
                </div>
            </div>
        </div>
        //todo: gradients go here
    );
}

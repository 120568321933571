import { ModelVariable } from "../../models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// import {
//     gotEntities,
//     ModelBuilderSliceState,
//     resetEntities,
// } from "./sharedActions";
import { mapById } from "../util";

type modelVariableSliceState = {
    modelVariables: { [index: string]: ModelVariable };
}

const initialState: modelVariableSliceState = {
    modelVariables: {},
    // activeModelVariable: undefined,
};

const modelVariableSlice = createSlice({
    name: "ModelVariableStore",
    initialState,
    reducers: {
        gotVariables(state, { payload }: PayloadAction<ModelVariable[]>) {
            state.modelVariables = mapById(payload);
        },
        // setActiveModelVariable(
        //     state,
        //     { payload }: PayloadAction<ModelVariable>
        // ) {
        //     state.activeModelVariable = payload;
        // },
    },
    // extraReducers: {
    //     [gotEntities.toString()]: (
    //         state,
    //         { payload }: PayloadAction<Partial<ModelBuilderSliceState>>
    //     ) => {
    //         state.modelVariables = {
    //             // ...state.modelVariables,
    //             ...payload.modelVariables,
    //         };
    //     },
    //     [resetEntities.toString()]: (
    //         state,
    //         { payload }: PayloadAction<Partial<ModelBuilderSliceState>>
    //     ) => {
    //         state.modelVariables = {};
    //     },
    // },
});

export const modelVariableStoreReducer = modelVariableSlice.reducer;

export const { gotVariables } =
    modelVariableSlice.actions;

import { AppDispatch } from "./AppStore";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { ContentBlock, ContentBlockShape, Prompt } from "../models";
import { mapById } from "./util";
import { getPromptsFromContentBlocks } from "../util";

type ContentBlockSliceState = {
    contentBlocks: { [index: string]: ContentBlock | ContentBlockShape };
    prompts: { [index: string]: Prompt };
    nestedContentBlocks: { [index: string]: ContentBlock | ContentBlockShape };
    roundId: string;
    simulationTheme: { [index: string]: any };
    topLevelContentBlockLayers: ContentBlockShape[];
};

const initialState: ContentBlockSliceState = {
    contentBlocks: {},
    prompts: {},
    nestedContentBlocks: {},
    roundId: "",
    simulationTheme: {},
    topLevelContentBlockLayers: [],
};

const contentBlockDataSlice = createSlice({
    name: "ContentBlockStore",
    initialState,
    reducers: {
        contentBlocksQueried(
            state,
            { payload }: PayloadAction<ContentBlock[] | ContentBlockShape[]>
        ) {
            state.contentBlocks = mapById(payload);
        },
        gotContentBlock(state, { payload }: PayloadAction<ContentBlockShape>) {
            state.contentBlocks[payload.id] = payload;
        },
        promptsQueried(state, { payload }: PayloadAction<Prompt[]>) {
            state.prompts = mapById(payload);
        },
        gotNestedContentBlocks(
            state,
            { payload }: PayloadAction<ContentBlockShape[]>
        ) {
            state.nestedContentBlocks = mapById(payload);
        },
        setRoundId(state, { payload }: PayloadAction<string>) {
            state.roundId = payload;
        },
        // gotSimulationTheme(
        //     state,
        //     { payload }: PayloadAction<{ [index: string]: any }>
        // ) {
        //     state.simulationTheme = payload;
        // },
        // gotTopLevelContentBlockLayers(
        //     state,
        //     { payload }: PayloadAction<ContentBlockShape[]>
        // ) {
        //     state.topLevelContentBlockLayers = payload;
        // },
    },
});

export const contentBlockStoreReducer = contentBlockDataSlice.reducer;

const {
    contentBlocksQueried,
    gotContentBlock,
    promptsQueried,
    gotNestedContentBlocks,
    setRoundId,
    // gotSimulationTheme,
    // gotTopLevelContentBlockLayers,
} = contentBlockDataSlice.actions;

export {
    setRoundId,
    gotContentBlock,
    gotNestedContentBlocks,
    // gotTopLevelContentBlockLayers,
    // gotSimulationTheme,
};

export const gotContentBlocks = (
    contentBlocks: (ContentBlock | ContentBlockShape)[]
) => {
    return (dispatch: AppDispatch) => {
        if (Array.isArray(contentBlocks)) {
            // update all content blocks and prompts
            const prompts = getPromptsFromContentBlocks(contentBlocks);

            batch(() => {
                dispatch(contentBlocksQueried(contentBlocks));
                dispatch(promptsQueried(prompts as Prompt[]));
            });
        }
    };
};

import parse from "parse-css-color";

export const getRGBColor = (hex: string, type: string) => {
    let color = hex.replace(/#/g, "");
    // rgb values
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);

    return `--color-${type}: ${r}, ${g}, ${b};`;
};

export const isDark = (color: string) => {
    if (!color) return false;
    const values = parse(color)?.values;
    if (!values) {
        return false;
    }
    const [r, g, b] = values;
    // rgb values
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq <= 128;
};

/////////////////////////////////////////////////////////////////////
// Determine the accessible color of text
/////////////////////////////////////////////////////////////////////
export const getAccessibleColor = (hex: string) => {
    return isDark(hex) ? "#FFFFFF" : "#000000";
};

// tailwind.config.js
export function withOpacity(constiableName: string) {
    return ({ opacityValue }) => {
        if (opacityValue !== undefined) {
            return `rgba( const(${constiableName}), ${opacityValue})`;
        }
        return `rgb( const(${constiableName}))`;
    };
}

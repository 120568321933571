import { isEqual } from "lodash";
import { EditorContainerProps } from "../EditorContainer";
import {
    validateDesignLayerProps,
    ValidatedEditorContainerProps,
} from "./validateDesignLayerProps";
import {  WritableAtom, atom, useAtom } from "jotai";
import { useEffect } from "react";

const initialState = validateDesignLayerProps({});
const designLayerPropsAtom = atom<ValidatedEditorContainerProps>(initialState);
designLayerPropsAtom.debugLabel = "designLayerPropsAtom";

export const designLayerPropsAtoms = Object.keys(initialState).reduce(
    (carry, key) => {
        const createdAtom = atom(false);
        createdAtom.debugLabel = key;
        return { ...carry, [key]: createdAtom };
    },
    {}
) as {
    [key in keyof ValidatedEditorContainerProps]: WritableAtom<
        (typeof initialState)[key],
        [typeof initialState[key]],
        any
    >;
};

export const useDesignLayerProps = (
    props: Partial<EditorContainerProps> | undefined = undefined
) => {
    const usedAtoms = Object.keys(designLayerPropsAtoms).reduce(
        (carry, key) => {
            const atom = designLayerPropsAtoms[key];
            return {
                ...carry,
                [key]: useAtom<false | typeof atom>(atom),
            };
        },
        {}
    ) as {};

    const handleSetDesignLayerProps = (
        props: Partial<EditorContainerProps>
    ) => {
        const validatedProps = validateDesignLayerProps(props);

        for (const key in validatedProps) {
            const [oldValue, setter] = usedAtoms[key];
            if (!validatedProps[key]) continue;
            if (!oldValue || !isEqual(oldValue, validatedProps[key])) {
                setter(validatedProps[key]);
            }
        }
    };

    useEffect(() => {
        if (props) handleSetDesignLayerProps(props);
    }, [props]);

    return {
        designLayerProps: Object.keys(usedAtoms).reduce((carry, key) => {
            return { ...carry, [key]: usedAtoms[key][0] };
        }, {}) as ValidatedEditorContainerProps,
        setDesignLayerProps: (props) => {},
        designLayerPropsAtoms,
    };
};

import { defaultModelDataDisplayTheme } from "@/styles/themes/ModelDataDisplayTheme";
import { ContentBlock, ContentBlockType } from "../../models";
import { defaultCardTheme } from "../../styles";

// const image_url: string =
//     "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png";

export const DataDisplayCardLayout = new ContentBlock({
    title: "Data Display Card",
    content: "Data Display Card",
    content_block_type: ContentBlockType.Card,
    theme: {
        ...defaultCardTheme,
        ...{
            palette: "light",
            fillStyle: "outline",
            imagePlacement: "left",
            width: 0.25,
            backgroundColor: "#ffffff00",
        },
    },
    contentBlocks: [
        // {
        //     title: "",
        //     content: "",
        //     content_block_type: ContentBlockType.Box,
        //     theme: {
        //         height: ["156px", "166px", "176px", "196px"],
        //         backgroundImage: `url(${image_url})`,
        //         backgroundRepeat: "no-repeat",
        //     },
        // },
        {
            title: "",
            content: "",
            content_block_type: ContentBlockType.Box,
            theme: {
                display: "flex",
                alignItems: "center",
                justifyContent: "normal",
                pt: 5,
                pb: 5,
                pr: 5,
                pl: 5,
            },
            contentBlocks: [
                {
                    title: "Model Value Display",
                    content: "Model Value Display",
                    content_block_type: ContentBlockType["Model Value Display"],
                    theme: {
                        ...defaultModelDataDisplayTheme,
                    },
                },
            ],
        },
    ],
});

export const buildTopBarCard = () =>
    new ContentBlock({
        title: "Data Display Card",
        content: "Data Display Card",
        content_block_type: ContentBlockType.Card,
        theme: {
            ...defaultCardTheme,
            ...{
                palette: "light",
                fillStyle: "outline",
                imagePlacement: "left",
                width: 0.2,
            },
        },
        contentBlocks: [
            // {
            //     title: "",
            //     content: "",
            //     content_block_type: ContentBlockType.Box,
            //     theme: {
            //         height: ["156px", "166px", "176px", "196px"],
            //         backgroundImage: `url(${image_url})`,
            //         backgroundRepeat: "no-repeat",
            //     },
            // },
            {
                title: "",
                content: "",
                content_block_type: ContentBlockType.Box,
                theme: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "normal",
                    pt: 0,
                    pb: 0,
                    pr: 0,
                    pl: 0,
                },
                contentBlocks: [
                    {
                        title: "Model Value Display",
                        content: "Model Value Display",
                        content_block_type:
                            ContentBlockType["Model Value Display"],
                        theme: {
                            ...defaultModelDataDisplayTheme,
                        },
                    },
                ],
            },
        ],
    });

// export const TopBarCard = buildTopBarCard();

import React, { useCallback, useMemo } from "react";
import { useDroppable } from "@dnd-kit/core";
import {
    SortableContext,
    // rectSortingStrategy,
    useSortable,
} from "@dnd-kit/sortable";
// import { DraggableItem } from "./DraggableItem";
import { DragAndDropItem } from "./types";
import styled from "styled-components";
// import { motion } from "framer-motion";
// import { container } from "webpack";

const TimelineRow = styled.div`
    > * {
        border-right: 1px solid #d6d6d689;
        /* background: #00000011; */
        background-color: #fdfdfd;
    }
`;

function DroppableTimelineItem({
    id,
    width,
    children,
    hasBorder,
    index,
    isOptionContainer = false,
}: {
    id: string;
    width: number;
    children: JSX.Element;
    hasBorder: boolean;
    index: number;
    isOptionContainer?: boolean
}) {
    const { setNodeRef } = useDroppable({
        id,
        data: { id, canBeDropTarget: true, index, isOptionContainer },
    });

    return (
        <div
            id={id}
            ref={setNodeRef}
            className={`flex items-center min-h-16 ${hasBorder ? "border-l" : ""}`}
            style={{
                gridColumn: `span ${width}`,
            }}
        >
            {children}
        </div>
    );
}
const FullWidthChildren = styled.div<{ color?: string }>`
    > * {
        width: 100%;
        padding: 0 !important;
        border: ${({ color }) => (color ? `3px solid ${color}` : "")};
        padding: 0;
        border-radius: 0.375rem;
    }
`;
const TimeLineDroppable = ({
    id,
    children,
    isFirst,
    isLast,
    color,
    index,
}: {
    id: string;
    children: JSX.Element | JSX.Element[];
    isFirst: boolean;
    isLast: boolean;
    color: string;
    index: number;
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        // transform,
        // transition,
        // isDragging,
    } = useSortable({
        id,
        data: { id, canBeDropTarget: false, index },
    });
    return (
        <FullWidthChildren
            ref={setNodeRef}
            className={`w-full h-full flex items-center relative ${
                isFirst ? "rounded-l-md" : undefined
            } ${isLast ? "rounded-r-md" : undefined} ${color}`}
            {...attributes}
            {...listeners}
        >
            {children}
        </FullWidthChildren>
    );
};

const TimelineDropped = ({
    id,
    children,
    isFirst,
    isLast,
    color,
    parentContainerId,
    isOver,
}: {
    id: string;
    children: JSX.Element | JSX.Element[];
    isFirst: boolean;
    isLast: boolean;
    color: string;
    parentContainerId: string;
    isOver: boolean;
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        // transform,
        // transition,
        // isDragging,
    } = useSortable({
        id,
        data: {
            id,
            parentContainerId,
            canBeDropTarget: false,
            isDropped: true,
        },
    });
    return (
        <FullWidthChildren
            color={color}
            ref={setNodeRef}
            className={`w-full min-h-12 flex items-center overflow-hidden ${
                isFirst ? "rounded-l-md" : undefined
            } ${isLast ? "rounded-r-md" : undefined} ${color} ${
                isOver ? "cursor-not-allowed" : ""
            }`}
            {...attributes}
            {...listeners}
        >
            {children}
        </FullWidthChildren>
    );
};

type TimelineDropIndicatorProps = {
    isAllowedToDrop: boolean;
    idx: number;
    overIndex: number;
    overIndices: number[];
};

function TimelineDropIndicator({
    idx,
    overIndex,
    overIndices,
    isAllowedToDrop,
}: TimelineDropIndicatorProps) {
    const roundedClasses = useMemo(() => {
        if (overIndices.length === 0) return "";
        let classes = [];
        if (idx === overIndices[0]) {
            classes = [...classes, "rounded-l-md"];
        }

        if (idx === overIndices[overIndices.length - 1]) {
            classes = [...classes, "rounded-r-md"];
        }

        return classes.join(" ");
    }, [overIndex, overIndices]);

    if (overIndices.length === 0 || !overIndices.includes(idx)) return null;

    if (isAllowedToDrop) {
        return <div className={`h-6 w-full bg-green-200 ${roundedClasses}`} />;
    }

    return <div className={`h-6 w-full bg-red-200  ${roundedClasses}`} />;
}

export const TimelineContainer: React.FC<{
    id: string;
    activeId: string | null;
    name: string;
    isSelectionContainer: boolean;
    dragItemIds: string[];
    dragItems: DragAndDropItem[];
    orientation: "rows" | "columns";
    cssClasses: string;
    columns: number;
    overId: string;
    overIndex: number;
    isAllowedToDrop: boolean;
    overDroppedId: string;
    overIndices: number[];
    backgroundColor: string;
}> = ({
    id,
    dragItemIds,
    name,
    dragItems,
    orientation,
    isSelectionContainer,
    activeId,
    cssClasses,
    columns,
    overId,
    overIndex,
    isAllowedToDrop,
    overDroppedId,
    overIndices,
    backgroundColor,
}) => {
    const getItem = useCallback(
        (containerId: string, index: number): DragAndDropItem => {
            return dragItems?.find((dragItem) => {
                if (
                    dragItem.drag_and_drop_prompt_container_id ===
                        containerId &&
                    isSelectionContainer
                )
                    return (
                        dragItem.drag_and_drop_prompt_container_id ===
                            containerId &&
                        index == dragItem.selection_numerical_value
                    );
            });
        },
        [dragItems]
    );

    const selectionContainerStyle = {
        display: "grid",
        gridTemplateColumns: `span ${columns + 1}`,
        alignItems: "center",
        height: "",
    };

    const optionContainerStyle: React.CSSProperties = {
        background: "#f3f4f6",
    };

    //the number of rows we need to render for selection containers
    const rows = useMemo(() => {
        if (!isSelectionContainer) return 0;

        //get the max row
        const maxPosition = Math.max(
            ...dragItems
                .filter(
                    (item) =>
                        item.is_selected &&
                        item.drag_and_drop_prompt_container_id === id
                )
                .map(
                    (item) =>
                        Number(item.selection_numerical_value) +
                        Number(item.option_size)
                )
        );

        if (!dragItems || maxPosition < 1) return 1;

        return Math.ceil(maxPosition / columns) + 1;
    }, [dragItems, id, isSelectionContainer]);

    function numberIsBetween(a: number, b: number, c: number) {
        return a >= b && a <= c;
    }

    const emptyColumns = useMemo(() => {
        return [...Array(columns * rows)]
            .map((_, idx) => idx)
            .filter((idx) => {
                //fix this to better account for rows
                return dragItems.every((item) => {
                    return (
                        item.drag_and_drop_prompt_container_id !== id ||
                        !numberIsBetween(
                            idx,
                            Number(item.selection_numerical_value),
                            Number(item.selection_numerical_value) +
                                item.option_size -
                                1
                        )
                    );
                });
            });
    }, [dragItems, id, dragItemIds, rows, columns]);

    if (!dragItemIds) return <></>;

    return (
        <SortableContext
            id={id}
            items={dragItemIds || []}
            strategy={() => null}
        >
            <div
                style={{
                    ...(isSelectionContainer
                        ? selectionContainerStyle
                        : optionContainerStyle),
                    backgroundColor,
                }}
                className={`relative bg-gray-100 flex mb-4 ${cssClasses} `}
                id={id}
            >
                {isSelectionContainer ? (
                    <>
                        <div
                            className="grid w-full rounded-md overflow-hidden min-h-16 shadow-md"
                            style={{
                                gridTemplateColumns: `1fr ${columns}fr`,
                                alignItems: "center",
                            }}
                        >
                            <h5 className="flex items-center text-sm font-bold px-2 border-r border-white/50 h-full pr-2">
                                {name}
                            </h5>
                            <div className="flex flex-col min-h-16">
                                {[...Array(rows)].map((_, rowIdx) => (
                                    <TimelineRow
                                        key={`${id}_row_${rowIdx}`}
                                        className="grid min-h-16 rounded-r-md overflow-hidden"
                                        style={{
                                            gridTemplateColumns: `repeat(${columns}, 1fr)`,
                                        }}
                                    >
                                        {[...Array(columns)].map((_, idx) => {
                                            const item = getItem(
                                                id,
                                                columns * rowIdx + idx
                                            );
                                            if (!item) {
                                                if (
                                                    !emptyColumns.includes(
                                                        columns * rowIdx + idx
                                                    )
                                                )
                                                    return null;
                                                return (
                                                    <DroppableTimelineItem
                                                        id={`${id}_${
                                                            columns * rowIdx +
                                                            idx
                                                        }`}
                                                        width={1}
                                                        hasBorder={false}
                                                        key={`${id}_${
                                                            columns * rowIdx +
                                                            idx
                                                        }`}
                                                        index={
                                                            columns * rowIdx +
                                                            idx
                                                        }
                                                    >
                                                        <>
                                                            {id === overId && (
                                                                <TimelineDropIndicator
                                                                    overIndex={
                                                                        overIndex
                                                                    }
                                                                    overIndices={
                                                                        overIndices
                                                                    }
                                                                    idx={
                                                                        columns *
                                                                            rowIdx +
                                                                        idx
                                                                    }
                                                                    isAllowedToDrop={
                                                                        isAllowedToDrop
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    </DroppableTimelineItem>
                                                );
                                            }
                                            return (
                                                <DroppableTimelineItem
                                                    id={`${id}_${
                                                        columns * rowIdx + idx
                                                    }`}
                                                    width={item.option_size}
                                                    hasBorder={false}
                                                    key={`${id}_${
                                                        columns * rowIdx + idx
                                                    }`}
                                                    index={
                                                        columns * rowIdx + idx
                                                    }
                                                >
                                                    <TimelineDropped
                                                        parentContainerId={id}
                                                        key={idx}
                                                        id={item.option_id}
                                                        isFirst={
                                                            item.selection_numerical_value ===
                                                            idx
                                                        }
                                                        isLast={
                                                            item.selection_numerical_value +
                                                                item.option_size ===
                                                            idx + 1
                                                        }
                                                        color={item.color}
                                                        isOver={
                                                            item.option_id ===
                                                            overDroppedId
                                                        }
                                                    >
                                                        {item.child}
                                                    </TimelineDropped>
                                                </DroppableTimelineItem>
                                            );
                                        })}
                                    </TimelineRow>
                                ))}
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        className="grid w-full p-4 rounded-md min-h-16"
                        style={{
                            gridTemplateColumns: `1fr ${columns}fr`,
                            alignItems: "center",
                        }}
                    >
                        <h5 className="flex items-center text-sm font-bold px-2 border-r border-white/50 h-full pr-2">
                            {name}
                        </h5>
                        <DroppableTimelineItem
                            id={`${id}_0`}
                            width={1}
                            hasBorder={false}
                            index={0}
                            isOptionContainer={true}
                        >
                            <div className="flex gap-2 flex-wrap w-full">
                                {dragItemIds.map((id, idx) => {
                                    const item = dragItems.find(
                                        (item) => item.option_id === id
                                    );

                                    return (
                                        <div
                                            key={idx}
                                            className="inline-flex relative first:pr-2"
                                            style={{
                                                width: `${
                                                    (item.option_size /
                                                        columns) *
                                                    100
                                                }%`,
                                            }}
                                            data-option-id={item?.option_id}
                                        >
                                            {!!item && (
                                                <TimeLineDroppable
                                                    key={idx}
                                                    id={item.option_id}
                                                    isFirst={true}
                                                    isLast={true}
                                                    index={idx}
                                                    color={item.color}
                                                >
                                                    {item.child}
                                                </TimeLineDroppable>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </DroppableTimelineItem>
                    </div>
                )}
            </div>
        </SortableContext>
    );
};

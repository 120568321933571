import { sapienRoute } from "@/inertia-utils/hooks";
import { ContentBlockShape, ContentBlockType } from "@/models";
import axios from "axios";

type TableSaveBody = ContentBlockShape & {
    parent_content_block_id: string;
    round_id: string;
    weight: number;
    modelVariableIds: string[];
    timeHorizonIds: string[];
};

export async function saveTable(table: TableSaveBody) {
    let route = "";
    if (table.content_block_type === ContentBlockType.Leaderboard) {
        route = !!table.id
            ? sapienRoute("creator.design.leaderboards.update", {
                  leaderboard: table.id,
              })
            : sapienRoute("creator.design.leaderboards.store");
    } else {
        route = !!table.id
            ? sapienRoute("creator.design.tables.update", {
                  table: table.id,
              })
            : sapienRoute("creator.design.tables.store");
    }

    if (table.id) {
        const { data } = await axios.put<{ table: ContentBlockShape }>(
            route,
            table,
        );
        return data;
    }

    const { data } = await axios.post<{ table: ContentBlockShape }>(
        route,
        table,
    );
    return data;
}

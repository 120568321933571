import { BaseModel, IBaseModel } from "./BaseModel";
import { Prompt, PromptShape, PromptType } from "./Prompt";
import { ContentBlockType } from "./ContentBlockType";
import { ContentBlockModelDataSource } from "./ContentBlockModelDataSource";
import { ThemeObject } from "@/styles";
import { DisplayRule } from "./DisplayRule";
import { InterpolationPropType } from "victory";
import { Behaviors } from "./ContentBlockBehavior";
import { DynamicValidationRule } from "./DynamicValidationRule";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import { TypographyTypeValues } from "@/styles/SimulationTheme";

// type FlippedRecord<T> = {
//     [K in keyof T as `${string & T[K]}`]: K;
// };

// type ContentBlockTypeKeys = FlippedRecord<typeof ContentBlockType>;
// type AllowedContentBlockType = keyof ContentBlockTypeKeys;

export type RichText = {
    type: string;
    attrs?: {
        typographyType?: TypographyTypeValues;
        textAlign: string;
        lineHeight?: string;
        indent?: number;
        default?: any;
    };
    text?: string;
    content?: RichText[];
    marks?: any[];
};
export type ContentBlockShape = {
    id?: string;
    round_id?: string;
    content?: string;
    parent_content_block_id?: string;
    theme?: {
        tailwindClasses?: string[];
    } & any;
    contentBlocks?: ContentBlockShape[];
    prompt?: PromptShape;
    title?: string;
    weight?: number;
    content_block_type: ContentBlockType; //todo: refactor to allow AllowedContentBlockType

    // TO DO: change prop structure to metadata-style setup
    route?: string; // slug
    //Will be default image for all content blocks
    image_url?: string;
    video_url?: string;
    video_urls?: string[];
    // modelVariables: ModelVariable[];
    contentBlockModelDataSources?: ContentBlockModelDataSource[];
    directChildContentBlocks?: ContentBlock[];
    rich_text?: RichText;
    displayRule?: DisplayRule;
    behaviors?: Behaviors;

    //for posts
    promptType?: PromptType;
    dynamicValidationRules?: DynamicValidationRule[];
    chart_data?:
        | FormattedChartValue[]
        | { [index: string]: FormattedChartValue[] };
    table_data?: RichText[][];
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
    copied_from_id?: string | null;
    depth?: number;
};

export type ChartContentBlock = {
    formattedData: any;
} & ContentBlockShape;

export type LineSeriesTheme = {
    interpolation?: InterpolationPropType;
    stroke?: string;
    strokeWidth?: number;
    // labelProps can be used to styled the VictoryLabel
    labelProps?: {
        x: number;
        y: number;
    };
} & Partial<ThemeObject>;

export type AreaSeriesTheme = LineSeriesTheme & {
    fill: string;
};
// export type LineChartContentBlock = {
//     theme: LineSeriesTheme;
// } & ChartContentBlock;

// export type AreaChartContentBlock = {
//     theme: AreaSeriesTheme;
// } & ChartContentBlock;

export class ContentBlock
    extends BaseModel
    implements IBaseModel, ContentBlockShape
{
    constructor(data = null) {
        super();
        if (data) {
            this.setUp(data);
        }
    }

    setUp(data) {
        if (data) {
            for (let prop in data) {
                this[prop] = data[prop];
            }
        }
    }

    public static CLASSNAME: string = "ContentBlock";
    public static END_POINT: string = "/api/content-blocks/";

    round_id: string = "";
    content?: string = "";
    parent_content_block_id?: string = "";
    theme?: any = {};
    contentBlocks: ContentBlock[] = [];
    prompt?: Prompt;
    title: string = "";
    weight: number = 0;
    content_block_type: ContentBlockType; //todo: refactor to allow AllowedContentBlockType

    // TO DO: change prop structure to metadata-style setup
    route: string = ""; // slug
    //Will be default image for all content blocks
    image_url: string =
        "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/phpY7RD5C.png";
    video_urls: string[] = [];
    // modelVariables: ModelVariable[];
    contentBlockModelDataSources: ContentBlockModelDataSource[];
    directChildContentBlocks?: ContentBlock[];
    rich_text?: RichText;
    displayRule?: DisplayRule;
    behaviors?: Behaviors;
    dynamicValidationRules: DynamicValidationRule[];
    chart_data?:
        | FormattedChartValue[]
        | { [index: string]: FormattedChartValue[] };
    table_data?: RichText[][];
}

export type ColorMap = {
    color_id: string;
    opacity: number;
};

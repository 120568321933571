import { useEffect, useMemo, useState } from "react";
import { useContentBlockState } from "../../LessonDesign/useContentBlockState";

export function useTrackParentContentBlocks(
    minHeight = 200,
    scrollCallback?: (id: string) => void,
) {
    const { parentContentBlocks } = useContentBlockState();
    const [isRendered, setIsRendered] = useState(false);
    const [_, setRatio] = useState<{ [index: string]: number }>({});

    if ("function" !== typeof scrollCallback) return {};

    setTimeout(() => {
        setIsRendered(true);
    }, 1000);

    const selectors = useMemo(() => {
        return parentContentBlocks?.reduce((carry, block) => {
            return {
                ...carry,
                [block.id]: `[data-content-block-id="${block.id}"]`,
            };
        }, {});
    }, [parentContentBlocks]);

    useEffect(() => {
        if (!selectors) return;

        const resizeObservers = Object.keys(selectors).map((key) => {
            const el = document.querySelector(selectors[key]);
            if (!el) return;

            const resizeObserver = new ResizeObserver(() => {
                const height = el?.getBoundingClientRect().height || 0;
                if (height > minHeight) setIsRendered(true);
            });

            resizeObserver.observe(el);
            return resizeObserver;
        });

        return () => {
            resizeObservers.map((observer) => observer.disconnect());
        };
    }, [minHeight, selectors]);

    useEffect(() => {
        if (!isRendered || !selectors) {
            return;
        }

        const observers: [IntersectionObserver, HTMLElement][] = Object.keys(
            selectors,
        ).map((key) => {
            const el = document.querySelector(selectors[key]);
            if (!el) {
                console.log("not rendered", selectors[key]);
                return;
            }

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    setRatio((prev) => ({
                        ...prev,
                        [key]: entry.intersectionRatio,
                    }));
                    if (entry.isIntersecting) {
                        scrollCallback(key);
                        observer.unobserve(el);
                    }
                });
            });

            observer.observe(el);
            return [observer, el];
        });

        return () => {
            observers.map(([observer, el]) => observer.unobserve(el));
        };
    }, [isRendered, selectors]);

    return {};
}

import { SapienPageProps } from "@/inertia-utils/types";
import {
    Combobox,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/react";
import React, { useCallback, useState } from "react";
import { WithId } from "./WithId";
import Authenticated from "@/Layouts/Authenticated";
import Input from "@/components/Input";
import { useForm } from "@inertiajs/react";
import Label from "@/components/Label";
import ForumRichText from "./ForumRichText";
import { SapienInertia } from "@/inertia-utils/hooks";

type Props = {
    step: number;
    categorizables: Record<string, App.Data.Forum.CategorizableData[]>;
    category:
        | WithId<App.Data.Forum.CategoryData>
        | {
              title: string;
              rich_text: App.Data.RichTextData;
              updated_at: string;
              categorizable_type?: string;
              categorizable_id?: string;
          };
} & SapienPageProps;

export default function CreateCategory({
    auth,
    step,
    categorizables,
    category = {
        title: "",
        rich_text: {} as App.Data.RichTextData,
        updated_at: "",
        categorizable_type: null,
        categorizable_id: null,
    },
}: Props) {
    const { data, setData } = useForm(category);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItem, setSelectedItem] = useState<{
        type: string;
        id: string;
        name: string;
    } | null>(null);

    const allOptions = Object.entries(categorizables).flatMap(([type, items]) =>
        items.map((item) => ({ ...item, type })),
    );

    const filteredOptions =
        searchTerm === ""
            ? allOptions
            : allOptions.filter((item) =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase()),
              );

    const updateRichText = useCallback(
        (rich_text) => {
            setData("rich_text", rich_text as App.Data.RichTextData);
        },
        [category.updated_at],
    );

    return (
        <Authenticated>
            <div className="container mx-auto max-w-2xl">
                <h1 className="text-2xl font-bold">Create a category</h1>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        const existingCategory =
                            category as WithId<App.Data.Forum.CategoryData>;

                        if (!!existingCategory.id) {
                            SapienInertia.put(
                                "forum.categories.update",
                                {
                                    ...data,
                                    rich_text: JSON.stringify(data.rich_text),
                                },
                                { category: existingCategory.id },
                            );
                        } else {
                            SapienInertia.post("forum.categories.store", {
                                ...data,
                                rich_text: JSON.stringify(data.rich_text),
                            });
                        }
                    }}
                    className="mt-4 flex flex-col space-y-4 rounded-md bg-white p-6 shadow"
                >
                    <div>
                        <Label forInput="title" value="Title" />
                        <Input
                            required
                            name="title"
                            value={data.title}
                            handleChange={(e) => {
                                setData("title", e.target.value);
                            }}
                            placeHolder="Enter a title"
                        />
                    </div>
                    <div>
                        <Label forInput="title" value="Description" />
                        <ForumRichText
                            content={data.rich_text}
                            onUpdate={updateRichText}
                            editable={true}
                            parentObject={category}
                            hideButtons={true}
                        />
                    </div>
                    <div>
                        <Label
                            forInput="categorizable"
                            value="Attach to (Optional)"
                        />
                        <Combobox
                            value={selectedItem}
                            onChange={(item) => {
                                setSelectedItem(item);
                                setData((prev) => ({
                                    ...prev,
                                    categorizable_type: item?.type || null,
                                    categorizable_id: item?.id || null,
                                }));
                            }}
                        >
                            <div className="relative">
                                <ComboboxInput
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500
                                        focus:ring-indigo-500"
                                    onChange={(event) =>
                                        setSearchTerm(event.target.value)
                                    }
                                    displayValue={(item: typeof selectedItem) =>
                                        item?.name ?? ""
                                    }
                                    placeholder="Search for an item..."
                                />
                                <ComboboxOptions
                                    className="max-h-60 absolute z-10 mt-1 w-full overflow-auto overflow-y-auto rounded-md
                                        bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5
                                        focus:outline-none sm:text-sm"
                                >
                                    {Object.entries(categorizables).map(
                                        ([type, items]) => {
                                            const filteredItems = items.filter(
                                                (item) =>
                                                    item.name
                                                        .toLowerCase()
                                                        .includes(
                                                            searchTerm.toLowerCase(),
                                                        ),
                                            );

                                            if (filteredItems.length === 0)
                                                return null;

                                            return (
                                                <div key={type}>
                                                    <div className="bg-gray-100 px-4 py-2 text-xs font-semibold text-gray-600">
                                                        {type}
                                                    </div>
                                                    {filteredItems.map(
                                                        (item) => (
                                                            <ComboboxOption
                                                                key={`${type}-${item.id}`}
                                                                value={{
                                                                    ...item,
                                                                    type,
                                                                }}
                                                                className={({
                                                                    selected,
                                                                }) =>
                                                                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                                                        selected
                                                                            ? "bg-indigo-600 text-white"
                                                                            : "text-gray-900"
                                                                    }`
                                                                }
                                                            >
                                                                {({
                                                                    selected,
                                                                }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selected ? "font-medium" : "font-normal"}`}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </span>
                                                                        {selected && (
                                                                            <span
                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                                                    selected
                                                                                        ? "text-white"
                                                                                        : "text-indigo-600"
                                                                                }`}
                                                                            >
                                                                                ✓
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </ComboboxOption>
                                                        ),
                                                    )}
                                                </div>
                                            );
                                        },
                                    )}
                                    {filteredOptions.length === 0 && (
                                        <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                                            Nothing found.
                                        </div>
                                    )}
                                </ComboboxOptions>
                            </div>
                        </Combobox>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            className="rounded-md bg-red-100 px-4 py-2 text-gray-800"
                            onClick={() => {}}
                        >
                            Cancel
                        </button>
                        <button
                            className="rounded-md bg-sapien-blue px-4 py-2 text-white"
                            type="submit"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </Authenticated>
    );
}

import { sapienRoute } from "@/inertia-utils/hooks"
import axios from "axios";
import { ContentBlockSchemaType, contentBlockSchema } from "../shared-props/validateDesignLayerProps";

export async function updateContentBlockTitle(content_block_id: string, title: string) {

    const route = sapienRoute('creator.design.content-blocks.rename', { contentBlock: content_block_id });

    const {data} = await axios.put<{contentBlock: ContentBlockSchemaType}>(route, { title, content_block_id});

    return contentBlockSchema.parse(data.contentBlock);
}

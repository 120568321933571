import { ToastProps } from "@/components/toasts/StyledToast";
import { atom, useAtom } from "jotai";

const nofiticationAtom = atom<ToastProps | false>(false);
nofiticationAtom.debugLabel = "notification atom";
export function useNotificationStore() {
    const [notification, setNotification] = useAtom(nofiticationAtom);

    return {
        notification,
        setNotification,
    };
}

import { sapienAxios } from "@/inertia-utils/hooks";
import { useRef, useState, useEffect } from "react";

export const useImageIsText = ({ imageUrl }) => {
    const canvasRef = useRef(document.createElement("canvas")); // Create a canvas element to use in the hook
    const [isMostlyText, setIsMostlyText] = useState(false);

    useEffect(() => {
        const img = new Image();

        sapienAxios
            .get<string, "creator.design.image-proxy">(
                "creator.design.image-proxy",
                {
                    url: imageUrl,
                    base64: true,
                },
            )
            .then((res) => {
                img.src = res.data;

                img.onload = () => {
                    const canvas = canvasRef.current;
                    const ctx = canvas.getContext("2d");

                    canvas.width = img.width;
                    canvas.height = img.height;

                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    const imageData = ctx.getImageData(
                        0,
                        0,
                        img.width,
                        img.height,
                    );
                    const pixels = imageData.data;

                    // Analyzing pixel contrast
                    let highContrastPixels = 0;
                    const totalPixels = img.width * img.height;

                    for (let i = 0; i < pixels.length; i += 4) {
                        const r = pixels[i];
                        const g = pixels[i + 1];
                        const b = pixels[i + 2];

                        // Grayscale conversion (lightness formula)
                        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

                        // Check if the brightness is close to black or white (high contrast)
                        if (brightness < 50 || brightness > 205) {
                            highContrastPixels++;
                        }
                    }

                    // Determine if a large portion of the image has high contrast
                    const contrastRatio = highContrastPixels / totalPixels;
                    if (contrastRatio > 0.6) {
                        setIsMostlyText(true);
                    } else {
                        setIsMostlyText(false);
                    }
                };
            });
    }, [imageUrl]);

    return isMostlyText;
};

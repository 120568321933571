import React from "react";
import { Course } from "../../types";
import { useCourseViewedContentBlockPercentage } from "../viewed-content-block-tracking/useViewedContentBlockState";

type Props = {
    course: Course;
    lessonId: string
};

export default function LessonSideNavHeader({ course, lessonId}: Props) {
    const { percentage, completedCount } =
        useCourseViewedContentBlockPercentage(course, lessonId);
    return (
        <div className="sticky top-0 flex flex-col gap-6 bg-sapien-blue px-16 py-12 text-white shadow-sm">
            <h3>{course.courseMetadata.course_title}</h3>
            <div className="flex flex-col gap-2">
                <h4 className="w-full text-xs font-semibold uppercase text-white">
                    {completedCount} of {course.lessons.length} lessons
                    completed
                </h4>

                <div className="relative h-2 w-full overflow-hidden rounded-lg bg-gray-200">
                    <div
                        className="absolute h-full bg-green-500 transition-all duration-500"
                        style={{ width: `${percentage}%` }}
                    ></div>
                </div>
            </div>
        </div>
    );
}

import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape } from "@/models";
import { useMutation } from "@tanstack/react-query";
import { useContentBlockState } from "../useContentBlockState";
import { useIsAddingContentBlock } from "../content-block-editing/useIsAddingContentBlock";
import { atom, useAtom } from "jotai";

export const updatedContentBlockIdAtom = atom<string | false>(false);
updatedContentBlockIdAtom.debugLabel = "updatedContentBlockIdAtom";

// function getBlockWithNoParent(
//     block: ContentBlockShape,
//     contentBlocks: ContentBlockShape[],
// ) {
//     if (block.parent_content_block_id) {
//         const parentBlock = contentBlocks.find(
//             ({ id }) => id === block.parent_content_block_id,
//         );
//         return getBlockWithNoParent(parentBlock, contentBlocks);
//     }
//     return block;
// }

async function saveContentBlock(contentBlock: any) {
    if (contentBlock.id) {
        // const updatedBlock = getBlockWithNoParent(
        //     contentBlock,
        //     Object.values(storedBlocks),
        // );

        // setUpdatedContentBlockId(updatedBlock?.id);
        const { data } = await sapienAxios.put<
            {
                contentBlocks: ContentBlockShape[];
                savedContentBlock: ContentBlockShape;
            },
            "e-learning.api.update"
        >(
            "e-learning.api.update",
            {
                ...contentBlock,
                position: "after",
                theme: JSON.stringify(contentBlock.theme),
            },
            {
                contentBlock: contentBlock.id,
            },
        );
        const { savedContentBlock, contentBlocks } = data;
        return { savedContentBlock, contentBlocks };
    } else {
        const { data } = await sapienAxios.post<
            {
                contentBlocks: ContentBlockShape[];
                savedContentBlock: ContentBlockShape;
            },
            "e-learning.api.store"
        >("e-learning.api.store", {
            ...contentBlock,
            position: "after",
        });
        const { savedContentBlock, contentBlocks } = data;
        return { savedContentBlock, contentBlocks };
    }
}

export function useUpdateStyles() {
    // const { setContentBlock } = useContentBlockState();

    return useMutation({
        mutationFn: async (contentBlock: ContentBlockShape) => {
            // setContentBlock(contentBlock);
            saveContentBlock(contentBlock);
        },
        onError: (error) => {
            console.error("Error saving content block", error);
        },
    });
}

export function useSaveContentBlock() {
    const { setContentBlock } = useContentBlockState();
    const { setIsAddingBlock } = useIsAddingContentBlock();
    const [_, setUpdatedContentBlockId] = useAtom(updatedContentBlockIdAtom);
    const { contentBlocks: storedBlocks } = useContentBlockState();
    return useMutation({
        mutationFn: saveContentBlock,
        onSuccess: ({ contentBlocks, savedContentBlock }) => {
            setUpdatedContentBlockId(false);
            setIsAddingBlock(false);
            contentBlocks?.forEach((contentBlock) => {
                //only update components for blocks that are new
                //updates are handled optimistically
                if (!storedBlocks[contentBlock.id]) {
                    setContentBlock(contentBlock);
                }
            });

            if (
                storedBlocks[savedContentBlock.id] &&
                savedContentBlock?.contentBlocks?.length
            ) {
                setContentBlock(savedContentBlock);
            }
        },
        onError: (error) => {
            console.error("Error saving content block", error);
        },
    });
}

import { useEffect } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { Simulation, Team } from "@/models";
import { useAllSimulations } from "./useSimulationStore";
import { useAuthStore } from "./store";
import { usePage } from "@/inertia-utils/hooks";

export const useInitAuthFromInertiaProps = () =>
    {
        const { props } = usePage();
        const { auth, simulations, team, tenant } = props as SapienPageProps & {
            simulations: Simulation[];
            team: Team;
        };

        const { gotSimulations, simulations: _simulations } =
            useAllSimulations();
        const {
            signedIn,
            setTeam,
            setTenant,
            signedOut,
            user,
            isNotParticipant,
        } = useAuthStore();

        useEffect(() => {
            if (auth?.user && (!user || user.id !== auth.user.id)) {
                signedIn(auth);
            }
        }, [auth]);

        useEffect(() => {
            if (simulations) {
                if (Array.isArray(simulations)) {
                    gotSimulations(simulations);
                } else {
                    gotSimulations(Object.values(simulations));
                }
            }
        }, [simulations]);

        useEffect(() => {
            setTeam(team);
        }, [team]);

        useEffect(() => {
            setTenant(tenant);
        }, [tenant]);

        return {
            user,
            isNotParticipant,
            signedIn,
            signedOut,
        };
    };

import React from "react";
import { Send, CircleUserRound, Hexagon, RefreshCw } from "lucide-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ClaudeMessage, useClaudeChat } from "./useAI";

// const normalizeMarkdown = (message: string) =>
//     message.replace(/^\s*[-*]\s+/gm, "- ");

const FormattedMessage: React.FC<{ message: string }> = ({ message }) => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                h1: ({ node, ...props }) => (
                    <h1 className="mb-2 text-2xl font-bold" {...props} />
                ),
                h2: ({ node, ...props }) => (
                    <h2 className="mb-1 text-xl font-semibold" {...props} />
                ),
                code: ({ node, ...props }) => (
                    <code
                        className="rounded bg-slate-100 p-1 font-mono text-sm dark:bg-slate-700"
                        {...props}
                    />
                ),
                pre: ({ node, ...props }) => (
                    <pre
                        className="overflow-x-auto rounded-lg border border-slate-200 bg-slate-100 p-4 text-sm
                            dark:border-slate-600 dark:bg-slate-800"
                        {...props}
                    />
                ),
                ul: ({ node, ...props }) => (
                    <ul
                        className="list-inside list-disc space-y-1 pl-4"
                        {...props}
                    />
                ),
                ol: ({ node, ...props }) => (
                    <ol
                        className="list-inside list-decimal space-y-1 pl-4"
                        {...props}
                    />
                ),
                a: ({ node, ...props }) => (
                    <a
                        className="text-blue-600 hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                        {...props}
                    />
                ),
            }}
        >
            {message}
            {/* {normalizeMarkdown(message)} */}
        </ReactMarkdown>
    );
};

const MessageDisplay: React.FC<{ message: ClaudeMessage }> = ({ message }) => {
    return (
        <div
            className={`flex items-start space-x-3 ${
                message.role === "user" ? "justify-end" : "justify-start"
            }`}
        >
            {message.role === "assistant" && (
                <Hexagon className="h-8 w-8 flex-shrink-0 text-blue-500 dark:text-blue-400" />
            )}
            <div
                className={`max-w-[80%] rounded-xl px-4 py-2 ${
                    message.role === "user"
                        ? "bg-blue-500 text-white"
                        : "bg-slate-200 text-slate-800 dark:bg-slate-700 dark:text-slate-200"
                } ${
                    message.content === "..." && message.role === "assistant"
                        ? "animate-pulse tracking-widest"
                        : ""
                }`}
            >
                <FormattedMessage message={message.content} />
            </div>
            {message.role === "user" && (
                <CircleUserRound className="h-8 w-8 flex-shrink-0 text-slate-500 dark:text-slate-400" />
            )}
        </div>
    );
};

const ConversationDisplay: React.FC<{
    messages: ClaudeMessage[];
    loading: boolean;
}> = ({ messages, loading }) => {
    // const chatEndRef = useRef<null | HTMLDivElement>(null);

    // useEffect(() => {
    //     messages?.length > 0 &&
    //         chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // }, [messages]);

    return (
        <div className="flex-grow space-y-4 overflow-y-auto p-4">
            {messages.length === 0 ? (
                <div className="text-center italic text-slate-500 dark:text-slate-400">
                    <div>{`Start a conversation`}</div>
                </div>
            ) : (
                messages.map((msg, index) => (
                    <MessageDisplay key={index} message={msg} />
                ))
            )}
            {!!loading && (
                <MessageDisplay
                    key={"loading"}
                    message={
                        {
                            role: "assistant",
                            content: "...",
                        } as ClaudeMessage
                    }
                />
            )}
            {/* <div ref={chatEndRef} /> */}
        </div>
    );
};

export const ClaudeChat = () => {
    const { claudeMessages, message, setMessage, loading, handleSendMessage } =
        useClaudeChat();

    return (
        <div
            className={`flex flex-col rounded-lg border border-slate-200 bg-slate-50
            dark:border-slate-700 dark:bg-slate-800 ${
                claudeMessages?.length > 0 ? "h-96" : "h-40"
            }`}
        >
            <ConversationDisplay messages={claudeMessages} loading={loading} />

            <div className="flex items-center space-x-2 border-t border-slate-200 p-4 dark:border-slate-700">
                <input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !loading) {
                            handleSendMessage();
                        }
                    }}
                    placeholder="Message Athena"
                    className="flex-grow rounded-lg border border-slate-300 bg-white px-3 py-2 text-slate-800
                        focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-slate-600
                        dark:bg-slate-700 dark:text-slate-200"
                    disabled={loading}
                />
                <button
                    onClick={handleSendMessage}
                    disabled={loading || !message.trim()}
                    className="flex items-center justify-center rounded-lg bg-blue-500 p-2 text-white
                        transition-colors hover:bg-blue-600 disabled:opacity-50"
                >
                    {loading ? (
                        <RefreshCw className="h-5 w-5 animate-spin" />
                    ) : (
                        <Send className="h-5 w-5" />
                    )}
                </button>
            </div>
        </div>
    );
};

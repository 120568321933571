import React, { useEffect, useState } from "react";
import { ContentBlockType, Option, Prompt, PromptType } from "@/models";
import { H6Span, OverlineSpan } from "@/components";
import {
    useFindContentBlockMethods,
    getSortedIdArrayAndMapById,
} from "@/hooks";
import { useGetActiveContentBlock } from "@/hooks/activeContentBlock";
import { displayPromptType } from "@/util";

const shouldShowModelEffectsByOption = (promptType: PromptType) => {
    return (
        promptType === PromptType["Multiple Choice"] ||
        promptType === PromptType["Multiple Select"] ||
        promptType === PromptType["Dropdown List"]
    );
};

const getEffectValue = (i: number, j: number) => {
    return [-1, 1][j % 2] * [-1, 1][i % 2] * [1, 3, 2][j % 3];
};

export const ModelEditor = () => {
    const { data: activeContentBlock } = useGetActiveContentBlock();
    const { getContentBlock } = useFindContentBlockMethods();

    const [prompt, setPrompt] = useState<Prompt>();
    const [sortedOptionIds, setSortedOptionIds] = useState<string[]>();
    const [optionMap, setOptionMap] = useState<{
        [index: string]: Option;
    }>();

    useEffect(() => {
        if (
            activeContentBlock &&
            activeContentBlock.content_block_type === ContentBlockType.Question
        ) {
            let questionContentBlock = getContentBlock(activeContentBlock.id);
            setPrompt(questionContentBlock.prompt as Prompt);
        } else {
            setPrompt(undefined);
        }
    }, [activeContentBlock, getContentBlock]);

    useEffect(() => {
        if (prompt) {
            let sortedOptionIdsAndOptionMap =
                getSortedIdArrayAndMapById<Option>(
                    prompt.options !== undefined ? prompt.options : [],
                );
            setSortedOptionIds(sortedOptionIdsAndOptionMap.sortedIdArray);
            setOptionMap(sortedOptionIdsAndOptionMap.mapById);
        } else {
            setSortedOptionIds(undefined);
            setOptionMap(undefined);
        }
    }, [prompt]);

    return (
        <>
            <div className="px-4 py-2">
                <H6Span>{`Model`}</H6Span>
            </div>
            {activeContentBlock !== undefined &&
            activeContentBlock.content_block_type ===
                ContentBlockType.Question ? (
                <div>
                    {prompt !== undefined && (
                        <div className="px-4">
                            <div className="flex flex-col rounded-s bg-[rgba(255,255,255,.1)] p-2">
                                <div className="flex w-full flex-row justify-end pb-2">
                                    <OverlineSpan>
                                        {displayPromptType(prompt.prompt_type)}
                                    </OverlineSpan>
                                </div>
                                <div className="text-sm leading-none">
                                    {prompt.content}
                                </div>
                            </div>
                            {shouldShowModelEffectsByOption(
                                prompt.prompt_type,
                            ) &&
                                sortedOptionIds !== undefined &&
                                sortedOptionIds.length > 0 &&
                                optionMap !== undefined &&
                                Object.keys(optionMap).length > 0 && (
                                    <div className="mt-2">
                                        <div className="mb-2 font-bold">
                                            Variables
                                        </div>
                                        <div className="ml-1">
                                            {["Metric A", "Metric B"].map(
                                                (metric, i) => (
                                                    <div
                                                        className="mb-2 flex w-full flex-col justify-center"
                                                        key={metric}
                                                        style={{
                                                            borderTop:
                                                                "1px solid rgba(255, 255, 255, 0.5)",
                                                        }}
                                                    >
                                                        <div className="mb-0.5 ml-0.5">
                                                            {metric}
                                                        </div>
                                                        {sortedOptionIds.map(
                                                            (optionId, j) => (
                                                                <div
                                                                    className="mb-0.5 ml-0.5 flex flex-row items-start justify-between bg-[rgba(255,255,255,0.03)]"
                                                                    key={
                                                                        optionId
                                                                    }
                                                                >
                                                                    <div className="m-1 flex w-9/12 text-sm leading-none">
                                                                        {
                                                                            optionMap[
                                                                                optionId
                                                                            ]
                                                                                .content
                                                                        }
                                                                    </div>
                                                                    <div className="w-3/12 pr-1 text-right">
                                                                        {getEffectValue(
                                                                            i,
                                                                            j,
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}
                </div>
            ) : (
                <div className="px-4">
                    {!!activeContentBlock
                        ? "No model connections."
                        : "Select a component to view model connections."}
                </div>
            )}
        </>
    );
};

import { fireEvent, render, screen } from "@testing-library/react";
import { mode, setupTestStore, testBlocks } from "../../../../../tests";
import { renderEditorContainer } from "../../tests/renderEditorContainer";
import { ContentBlockType } from "@/models";
import { getDomain } from "@/modules/shared/charts/chart-util";

if (mode === "test") {
    const page = testBlocks.find((block) => block.content_block_type === ContentBlockType.Page);
    const sectionBlock = testBlocks.find(
        (block) => block.content_block_type === ContentBlockType.Section && block.parent_content_block_id === page?.id
    );
    describe("chart tests", async () => {
        it("adding a chart from AddContentBlockMenu opens modal", async () => {
            const storeRef = setupTestStore();

            const {findByTestId} = render(
                renderEditorContainer(testBlocks, {
                    locationInfo: {
                        query: {},
                        location: `localhost:3000?modalComponent=ChartWizard&currentPageId=${sectionBlock?.id}`,
                    },
                }),
                {
                    wrapper: storeRef.Wrapper,
                }
            );

            //find and click the button to open the add content block menu
            const addContentBlockButton =
                document.getElementById("left-menu-create");
            expect(addContentBlockButton).toBeTruthy();
            fireEvent.click(addContentBlockButton);

            //find and click the button to add a chart content block
            const addChartButton = await screen.findByText("", {
                selector: ".add-chart-button",
            });
            expect(addChartButton).toBeTruthy();
            fireEvent.click(addChartButton);

            //find the page content block and click it
            const page = await screen.findByText("", {
                selector: `[data-content-block-id="page-1"]`,
            });
            expect(page).toBeTruthy();
            fireEvent.click(page);

            // //clicking the page with the chart content block selected should open the chart modal
            const modal = await findByTestId("chart-wizard")
            expect(modal).toBeTruthy();
        });

        it("adding a chart from AddContentBlockModal opens modal", async () => {
            const boxBlock = {
                content_block_type: ContentBlockType.Box,
                id: "box-1",
                title: "Test Box",
                parent_content_block_id: "section-1",
            };

            const storeRef = setupTestStore();

            //create additional content blocks, in addition to the existing page, so we can test add button functionality
            render(
                renderEditorContainer(
                    [
                        {
                            content_block_type: ContentBlockType.Page,
                            id: "section-1",
                            title: "Test Section",
                            parent_content_block_id: "page-1",
                        },
                        boxBlock,
                    ],
                    {
                        locationInfo: {
                            query: {},
                            location: `localhost:3000?modalComponent=ChartWizard&currentPageId=${sectionBlock?.id}`,
                        },
                    }
                ),
                {
                    wrapper: storeRef.Wrapper,
                }
            );

            //select the box content block to bring up the add button
            const box = await screen.findByText("", {
                selector: `[data-content-block-id="box-1"]`,
            });
            expect(box).toBeTruthy();

            const getActiveContentBlock = await import(
                "@/hooks/activeContentBlock/getActiveContentBlock"
            );

            vi.spyOn(
                getActiveContentBlock,
                "getActiveContentBlock"
            ).mockResolvedValue(boxBlock);

            fireEvent.click(box);
            const outline = await screen.findByTestId("cb-outline");
            expect(outline).toBeTruthy();
            const addContentBlockButton = await screen.findByTestId(
                "add-sibling-button"
            );
            expect(addContentBlockButton).toBeTruthy();
            fireEvent.click(addContentBlockButton);

            //make sure the modal opens
            const modal = await screen.findByTestId("add-content-block-modal");
            expect(modal).toBeTruthy();

        });

        it('allows manual pie and donut charts to be built', async () => {

            const storeRef = setupTestStore();

            //create additional content blocks, in addition to the existing page, so we can test add button functionality
            const { findByText} = render(
                renderEditorContainer(
                    testBlocks,
                    {
                        locationInfo: {
                            query: {},
                            location: `localhost:3000?modalComponent=ChartWizard&parentContentBlockId=${sectionBlock?.id}`,
                        },
                    }
                ),
                {
                    wrapper: storeRef.Wrapper,
                }
            );

            //select the box content block to bring up the add button
            const box = await findByText("", {
                selector: `[data-content-block-id=${sectionBlock?.id}]`,
            });
            expect(box).toBeTruthy();

            const getActiveContentBlock = await import(
                "@/hooks/activeContentBlock/getActiveContentBlock"
            );

            vi.spyOn(
                getActiveContentBlock,
                "getActiveContentBlock"
            ).mockResolvedValue(sectionBlock);

            fireEvent.click(box);
            const outline = await screen.findByTestId("cb-outline");
            expect(outline).toBeTruthy();
            const addContentBlockButton = await screen.findByTestId(
                "add-sibling-button"
            );
            expect(addContentBlockButton).toBeTruthy();
            fireEvent.click(addContentBlockButton);
            const modal = await screen.findByTestId("add-content-block-modal");
            expect(modal).toBeTruthy();

            console.log("test test",modal.innerHTML)

            // const pieChartButton = await findByTestId("chart-type-selector-pie");

            //wizard-right-button
        });


        it("calculates the right number of nodes for a line chart", async () => {
            //chart data for a line chart with 2 series. Each series starts at x (timehorizon) 3 and ends at 11
            //we should render 9 nodes for each series
            const chartData={
                "f38f16dc-218e-4856-bc1b-a5b5a77f68ef": [
                    {
                        "x": 3,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    },
                    {
                        "x": 4,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    },
                    {
                        "x": 5,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    },
                    {
                        "x": 6,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    },
                    {
                        "x": 7,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    },
                    {
                        "x": 8,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    },
                    {
                        "x": 9,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    },
                    {
                        "x": 10,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    },
                    {
                        "x": 11,
                        "y": 1,
                        "label": "Exam Chair Count",
                        "theme": {
                            "stroke": "#00ABAB",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "1"
                    }
                ],

                "853c7e42-6b29-4a93-b3ab-b2aa6871e1c7": [
                    {
                        "x": 3,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    },
                    {
                        "x": 4,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    },
                    {
                        "x": 5,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    },
                    {
                        "x": 6,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    },
                    {
                        "x": 7,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    },
                    {
                        "x": 8,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    },
                    {
                        "x": 9,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    },
                    {
                        "x": 10,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    },
                    {
                        "x": 11,
                        "y": 2,
                        "label": "Practice Chair Count",
                        "theme": {
                            "stroke": "#009A44",
                            "labelProps": {
                                "x": 0,
                                "y": 0
                            },
                            "strokeWidth": 3,
                            "interpolation": "linear"
                        },
                        "chartType": "line",
                        "displayText": "2"
                    }
                ]
            }

            const xDomain = getDomain(chartData, "x");
            expect(xDomain).toEqual([0, 8]);

            //now let's shift the chart data so that the first x value is 0
            //we should get the same result for the x domain
            const standardTHChartData= Object.keys(chartData).reduce((carry, key) => {
                return {...carry, key: chartData[key].map((value) => ({...value, x: value.x - 3}))}
            }, {})

            const standardTHXDomain = getDomain(standardTHChartData, "x");
            expect(standardTHXDomain).toEqual([0, 8]);

        })

    });
}

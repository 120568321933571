import { BaseModelType } from "./BaseModel";
import { Option } from "./Option";
import { Prompt } from "./Prompt";
import { VariableRelationship } from "./VariableRelationship";
import { VariableValue } from "./VariableValue";

export enum ModelVariableType {
    Independent = "INDEPENDENT",
    "Selection Data" = "SELECTION_DATA",
    Iterative = "ITERATIVE",
    Aggregate = "AGGREGATE",
    Algebraic = "ALGEBRAIC",
    Conditional = "CONDITIONAL",
    Logical = "LOGICAL",
    Comparison = "COMPARISON",
    "Time Shift" = "TIME_SHIFT",
    // "Dot Product Value" = "DOT_PRODUCT_VALUE",
    // "Dot Product Score" = "DOT_PRODUCT_SCORE",
    // "Numerical Comparison Switch" = "NUMERICAL_COMPARISON_SWITCH",
    // "Order Proximity Score" = "ORDER_PROXIMITY_SCORE",
    // "Order Weighted Sum" = "ORDER_WEIGHTED_SUM",
    // "Numerical Range Categorization" = "NUMERICAL_RANGE_CATEGORIZATION",
}

export enum ModelVariableDataType {
    Number = "NUMBER",
    Boolean = "BOOLEAN",
    "Dictionary Entry" = "DICTIONARY_ENTRY", // not handled yet
    // combination?
}

export enum ModelVariableScope {
    User = "USER",
    Team = "TEAM",
    Cohort = "COHORT",
    Simulation = "SIMULATION",
}

export enum ModelVariableUnit {
    Percent = "PERCENT",
    Dollars = "DOLLARS",
    Custom = "CUSTOM",
    None = "NONE",
}

export enum CurrencyType {
    USD = "USD",
    INR = "INR",
}

export enum ModelVariableAggregateOperation {
    Average = "AVERAGE",
    Minimum = "MINIMUM",
    Maximum = "MAXIMUM",
    "Standard Deviation" = "STANDARD_DEVIATION",
    Count = "COUNT",
    "Count If" = "COUNT_IF",
    "Cumulative Sum" = "CUMULATIVE_SUM",
    "Cumulative Product" = "CUMULATIVE_PRODUCT",
}

export enum ModelVariableResampleFunction {
    sum = "sum", // target default (boolean: count of True)
    mean = "mean", // source default (boolean: proportion of True to False)
    last = "last",
    first = "first",
    mode = "mode",
    all = "all", // boolean (logical AND)
    any = "any", // boolean (logical OR)
    min = "min", // numeric
    max = "max", // numeric
}

export type ModelVariable = BaseModelType & {
    model_block_id: string;
    label: string;
    weight: number; // default 0
    variable_type: ModelVariableType;
    data_type: ModelVariableDataType;
    uses_time: boolean; // default false
    scope?: ModelVariableScope; // nullable
    aggregate_operation?: ModelVariableAggregateOperation; // nullable
    default_numerical_value: number; // default 0
    default_boolean_value: boolean; // default false
    default_dictionary_entry_id?: string; // nullable
    model_dictionary_id?: string; // nullable
    unit?: ModelVariableUnit; // nullable
    minimum?: number; // nullable
    maximum?: number; // nullable
    is_integer: boolean; // default false
    is_absolute_value: boolean; // default false
    expose_to_facilitator: boolean; // default false
    prompt_id?: string; // nullable
    option_id?: string; // nullable
    expose_to_designer: boolean; // default false
    is_key_metric: boolean; // default false
    resample_function?: string;
    description?: string;
    range_step?: number;

    variableValues?: VariableValue[];
    baseVariableValues?: VariableValue[];
    sourceVariables?: ModelVariable[];
    sourceVariableRelationships?: VariableRelationship[];
    targetVariables?: ModelVariable[];
    targetVariableRelationships?: VariableRelationship[];
    descendantVariables?: ModelVariable[];
    prompt?: Prompt;
    option?: Option;
};

// export class ClassModelVariable extends BaseModel implements IBaseModel {
//     constructor(data = null) {
//         super();
//         if (data) {
//             this.setUp(data);
//         }
//     }

//     setUp(data: any) {
//         if (data) {
//             for (let prop in data) {
//                 this[prop] = data[prop];
//             }
//         }
//     }

//     public static CLASSNAME: string = "ModelVariable";
// }

export type VariableDepthObject = {
    depth: number;
    label: string;
    variable_id: string;
};

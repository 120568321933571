import React from "react";

type HighlightProps = {
  text: string;
  highlight: string;

}

function HighlightText ({ text, highlight }: HighlightProps) {
  if (!highlight) return <>{text}</>;

  // Create a case-insensitive regex to find matches
  const regex = new RegExp(`(${highlight})`, "gi");

  // Split the text into parts based on the regex
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <strong key={index} className=" font-bold underline">
            {part}
          </strong>
        ) : (
          <React.Fragment key={index}>{part}</React.Fragment>
        )
      )}
    </>
  );
};

export default HighlightText;

import { isDark } from "@/tailwind-colors/util";
import { PipetteIcon } from "lucide-react";
import React, { useState } from "react";
import { EyeDropper } from "ya-react-eyedropper";

type EyeDropperProps = {
    onChange: (color: string) => void;
    currentColor: string;
};
export function EyeDropperComponent({
    onChange,
    currentColor,
}: EyeDropperProps) {
    const [on, setOn] = useState(false);
    return (
        <EyeDropper
            on={on}
            onPick={(color) => {
                setOn(false);
                onChange(color.hex);
            }}
            onPickCancel={() => {
                setOn(false);
            }}
        >
            <button
                className="h-10 w-10 rounded-full border border-gray-300 flex p-2 items-center justify-center"
                style={{
                    background: currentColor,
                }}
                onClick={() => {
                    setOn(true);
                }}
            >
                <PipetteIcon
                    className="h-4 w-4"
                    style={{
                        color: isDark(currentColor) ? "#fff" : "#000",
                    }}
                />
            </button>
        </EyeDropper>
    );
}

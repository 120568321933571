import React, { useCallback } from "react";
import { ChartSeries } from "./ChartWizardReducer";
import { ModelVariable } from "@/models";
import { FormattedChartValue } from "@/modules/shared/charts/SharedTypes";
import ManualChartValueEntry from "./ManualChartValueEntry";
import ModelDataChartEditor from "./ModelDataChartEditor";
import ManualPieOrDonutChartEntry from "./ManualPieOrDonutChartEntry";
import { CheckIcon } from "@heroicons/react/24/solid";
import { z } from "zod";

type Props = {
    chartSeries: ChartSeries;
    updateEditedSeries: (payload: {
        chartSeries: ChartSeries;
        index: number;
    }) => void;
    addSeries: () => void;
    modelVariables: ModelVariable[];
    selectedModelVariable: ModelVariable;
    index: number;
    dataSource: "model" | "manual";
    timeHorizonIds: string[];
    chartValues: FormattedChartValue[];
    commitEdits: () => void;
};

const validator = z.object({
    chartSeries: z.object({
        chartType: z.string(),
        label: z.string(),
        modelVariableId: z.string(),
        theme: z
            .object({
                fill: z.string(),
                stroke: z.string(),
            })
            .partial(),
        chart_data: z
            .array(
                z.object({
                    x: z.number(),
                    y: z.number().nullable(),
                    label: z.string(),
                    theme: z.object({
                        fill: z.string(),
                        stroke: z.string(),
                    }),
                })
            )
            .optional(),
    }),
    index: z.number(),
});

export default function ChartSeriesForm({
    chartSeries,
    selectedModelVariable,
    modelVariables,
    index,
    dataSource,
    updateEditedSeries,
    addSeries,
    chartValues,
    commitEdits,
}: Props) {
    const handleChange = useCallback(
        (series: ChartSeries) => {
            console.log("YO,", series, index)
            updateEditedSeries({
                chartSeries: series,
                index,
            });
        },
        [index]
    );
    return (
        <div className="flex flex-col space-y-4 w-full py-6 px-4">
            <div className="flex flex-col justify-between items-start w-full space-y-4">
                {dataSource === "model" ? (
                    <ModelDataChartEditor
                        chartSeries={chartSeries}
                        updateEditedSeries={handleChange}
                        modelVariables={modelVariables}
                        selectedModelVariable={selectedModelVariable}
                        editedIndex={index}
                    />
                ) : chartSeries.chartType === "pie" ||
                  chartSeries.chartType === "doughnut" ? (
                    <ManualPieOrDonutChartEntry
                        chartSeries={chartSeries}
                        updateEditedSeries={handleChange}
                        chartType={chartSeries.chartType}
                    />
                ) : (
                    <ManualChartValueEntry
                        chartSeries={chartSeries}
                        updateEditedSeries={handleChange}
                        editedIndex={index}
                    />
                )}

                <div className="flex justify-center items-center">
                    <button
                        disabled={!!validator.safeParse(chartSeries)?.success}
                        onClick={commitEdits}
                        className="w-full inline-flex space-x-2 items-center justify-center disabled:opacity-75 disabled:cursor-not-allowed text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center transition-all"
                    >
                        <span>Keep Changes</span>
                        <CheckIcon className="h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
    );
}

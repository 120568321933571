import { ContentBlockShape } from "@/models";
type UpdateActiveContentBlockByPropertyArgs<T extends keyof ContentBlockShape> =
    { property: T; value: ContentBlockShape[T] };

type UpdateActiveContentBlockArgs =
    | ContentBlockShape
    | UpdateActiveContentBlockByPropertyArgs<keyof ContentBlockShape>;

export function doUpdateActiveContentBlock(
    newData: UpdateActiveContentBlockArgs,
    currentData: ContentBlockShape,
    callBack: (newContentBlock: ContentBlockShape) => void
) {
    let updatedBlock: ContentBlockShape;

    if (newData.hasOwnProperty("property")) {
        const { property, value } =
            newData as UpdateActiveContentBlockByPropertyArgs<
                keyof ContentBlockShape
            >;
        updatedBlock = {
            ...currentData,
            [property]: value,
        };
    } else {
        updatedBlock = {
            ...currentData,
            ...newData,
        };
    }
    callBack(updatedBlock);
}

import { ContentBlock, ContentBlockShape, ContentBlockType } from "../models";
import { ContentBlockConfigObject } from "./ContentBlockConfigObject";
import { LayoutType } from "./LayoutType";
import { LayoutConfigObject } from "./LayoutConfigObject";
import { LayoutConfig } from "./LayoutConfigInterface";
import { ContentBlockConfig } from "./ContentBlockConfigInterface";

export const getContentBlockConfigByType = (
    contentBlockType: ContentBlockType | LayoutType,
): ContentBlockConfig | null => {
    return ContentBlockConfigObject[contentBlockType];
};

export const getLayoutConfigByType = (
    layoutType: LayoutType | ContentBlockType,
): LayoutConfig | undefined => {
    return LayoutConfigObject[layoutType];
};

export const getConfigByType = (type: LayoutType | ContentBlockType) => {
    return getLayoutConfigByType(type) || getContentBlockConfigByType(type);
};

export const getChildBlockConfigs = (
    contentBlockType: ContentBlockType,
    propertyName: "requiredChildBlocks" | "addableChildBlocks",
) => {
    let contentBlockConfig = ContentBlockConfigObject[contentBlockType];
    if (contentBlockConfig[propertyName]) {
        return contentBlockConfig[propertyName].map(
            (blockType) => ContentBlockConfigObject[blockType],
        );
    } else {
        return [];
    }
};

export const getCanAddChildren = (contentBlockType: ContentBlockType) => {
    let contentBlockConfig = ContentBlockConfigObject[contentBlockType];
    return (
        !contentBlockConfig.disallowAdd &&
        !!contentBlockConfig.onClickAdd &&
        "function" === typeof contentBlockConfig.onClickAdd
    );
};

// export const setThemedContentBlock = (
//     contentBlock: ContentBlock | ContentBlockShape,
// ) => {
//     // fill in theme with default if properties are missing
//     return {
//         ...contentBlock,
//         theme: {
//             // ...ContentBlockConfigObject[contentBlock.content_block_type]
//             //     .defaultTheme,
//             ...contentBlock.theme,
//         },
//     } as ContentBlock;
// };

export const getDefaultTheme = (contentBlockType: ContentBlockType) => {
    return ContentBlockConfigObject[contentBlockType].defaultTheme;
};

export const getShouldShowDeleteButton = (
    contentBlockType: ContentBlockType,
    parentType: ContentBlockType,
) => {
    if (
        contentBlockType === ContentBlockType.Button &&
        parentType === ContentBlockType.Form
    ) {
        return false;
    } else {
        return true;
    }
};

export const getIsCollectionType = (contentBlockType: ContentBlockType) => {
    return (
        !!ContentBlockConfigObject[contentBlockType] &&
        !!ContentBlockConfigObject[contentBlockType].isCollectionType
    );
};

export const createChildObject = (
    contentBlock: ContentBlock | ContentBlockShape,
) => {
    return [...contentBlock.contentBlocks].reduce((map, child) => {
        return {
            ...map,
            ...{
                [child.id]:
                    contentBlock.content_block_type === ContentBlockType.Tabs
                        ? child.title
                        : child.content,
            },
        };
    }, {});
};

import { ContentBlockShape, ContentBlockType } from "@/models";
import { cn } from "@/util";
import React, { FormEvent, useMemo } from "react";
import {
    selectionsFromEvent,
    useSelections,
    useSubmitSelections,
} from "../../shared-state/useSelections";
import LessonButton from "../LessonButton";
import { Check, XIcon } from "lucide-react";

type Props = {
    contentBlock: ContentBlockShape;
    children: React.ReactNode | React.ReactNode[];
    isInAdminContext: boolean;
};
// bg-[var(--custom-color-${contentBlock?.theme?.backgroundColorId})]
export default function LessonFormContentBlock({
    contentBlock,
    children,
    isInAdminContext,
}: Props) {
    const { isPending, save } = useSubmitSelections(isInAdminContext);

    const submit = async (e: FormEvent) => {
        const selections = selectionsFromEvent(e, contentBlock);
        save(selections);
    };

    const button = useMemo(() => {
        return contentBlock.contentBlocks.find(
            (child) => child.content_block_type === ContentBlockType.Button,
        );
    }, [contentBlock]);

    const buttonPosition = useMemo(() => {
        return (
            button?.theme?.tailwindClasses?.find((className: string) =>
                className.includes("self-"),
            ) || "self-start"
        );
    }, [button]);

    const prompt = useMemo(() => {
        return contentBlock.contentBlocks.find(
            (child) => child.content_block_type === ContentBlockType.Question,
        )?.prompt;
    }, [contentBlock]);

    const selections = useSelections(prompt?.id);

    return (
        <form
            onSubmit={submit}
            className={cn(
                "flex h-full w-full flex-col gap-4 bg-white",
                contentBlock.theme?.tailwindClasses,
            )}
            style={{
                backgroundColor:
                    contentBlock.theme?.backgroundColor || "#ffffff ",
            }}
        >
            {children}
            {!!button && (
                <div
                    data-button-alignment={buttonPosition}
                    className={`flex w-full flex-row items-center gap-4
                    data-[button-alignment="self-end"]:flex-row-reverse
                    data-[button-alignment="self-center"]:flex-col`}
                >
                    <LessonButton
                        contentBlock={button}
                        isInAdminContext={isInAdminContext}
                        isPending={isPending}
                    />
                    {!!selections?.length && (
                        <div
                            className={`flex flex-row items-center justify-between gap-4 rounded-full bg-white p-2
                            shadow ${!isPending ? "submission-success" : "submission-pending"}`}
                        >
                            {selections.every(
                                (selection) => selection.is_correct,
                            ) ? (
                                <Check className="h-6 w-6 text-green-500" />
                            ) : (
                                <XIcon className="h-6 w-6 text-red-500" />
                            )}
                            <span className="sr-only">
                                You answer was recorded.
                            </span>
                        </div>
                    )}
                </div>
            )}
        </form>
    );
}

import { useAtom } from "jotai";
import { ContentBlockShape } from "@/models";
import {
    activeContentBlockAtom,
    useGetActiveContentBlock,
} from "./useGetActiveContentBlock";
import { doUpdateActiveContentBlock } from "./doUpdateActiveContentBlock";

// type UpdateActiveContentBlockByPropertyArgs<T extends keyof ContentBlockShape> =
//     { property: T; value: ContentBlockShape[T] };

// export type UpdateActiveContentBlockArgs =
//     | ContentBlockShape
//     | UpdateActiveContentBlockByPropertyArgs<keyof ContentBlockShape>;

export function useUpdateActiveContentBlock() {
    const { data } = useGetActiveContentBlock();
    const [_, setActiveContentBlock] = useAtom(activeContentBlockAtom);

    const updateActiveContentBlock = <T extends keyof ContentBlockShape>(
        keyOrContentBlock: ContentBlockShape | T,
        value?: ContentBlockShape[T]
    ) => {
        if ("string" === typeof keyOrContentBlock) {
            doUpdateActiveContentBlock(
                { property: keyOrContentBlock, value },
                data,
                setActiveContentBlock
            );
        } else
            doUpdateActiveContentBlock(
                keyOrContentBlock,
                data,
                setActiveContentBlock
            );
    };

    return { updateActiveContentBlock };
}

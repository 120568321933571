import { useCallback, useState } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { sapienAxios } from "@/inertia-utils/hooks";
import { useSelectedSimulation } from "@/hooks";

// CLAUDE

export type ClaudeMessage = {
    role: "user" | "assistant";
    content: string;
};

type ClaudePayload = {
    messages: ClaudeMessage[];
    simulation_id: string;
};

type ClaudeResponse = {
    content: string;
};

const claudeMessagesAtom = atom<ClaudeMessage[]>([]);
// export const useClaudeMessages = () => {
//     const claudeMessages = useAtomValue(claudeMessagesAtom);
//     const setClaudeMessages = useSetAtom(claudeMessagesAtom);
//     return { claudeMessages, setClaudeMessages };
// };

export const useClaudeChat = () => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const claudeMessages = useAtomValue(claudeMessagesAtom);
    const setClaudeMessages = useSetAtom(claudeMessagesAtom);

    const { selectedSimulation } = useSelectedSimulation();

    const sendMessage = useCallback(
        async (message: string) => {
            const userMessage: ClaudeMessage = {
                role: "user",
                content: message,
            };

            const newMessages = [
                ...claudeMessages,
                { ...userMessage, content: `${userMessage.content}` },
            ];
            setClaudeMessages((prev) => [...prev, userMessage]);

            let newResponse: ClaudeMessage;
            try {
                const { data } = await sapienAxios.post<
                    ClaudeResponse,
                    "data-studio.claude.message"
                >(
                    "data-studio.claude.message",
                    {
                        messages: newMessages,
                        simulation_id: selectedSimulation?.id ?? "",
                    } as Partial<ClaudePayload>,
                    {},
                );

                newResponse = {
                    role: "assistant",
                    content: data.content,
                };
            } catch (error) {
                console.error("Error sending message:", error);
                newResponse = {
                    role: "assistant",
                    content: "I'm sorry, but I couldn't process your request.",
                };
            }

            setClaudeMessages((prev) => [...prev, newResponse]);
        },
        [claudeMessages, setClaudeMessages, selectedSimulation],
    );

    const handleSendMessage = async () => {
        if (!message.trim()) return;

        setLoading(true);
        const messageToSend = message;
        setMessage("");
        await sendMessage(messageToSend);
        setLoading(false);
    };

    return {
        claudeMessages,
        message,
        setMessage,
        loading,
        handleSendMessage,
    };
};

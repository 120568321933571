import { ContentBlockType } from "@/models";
import { defaultModalTheme, defaultSectionTheme } from "@/styles";

export const buildModal = () => {
    return {
        content_block_type: ContentBlockType.Modal,
        theme: JSON.stringify(defaultModalTheme),
        weight: 0,
        contentBlocks: [
            {
                content_block_type: ContentBlockType.Section,
                weight: 0,
                theme: defaultSectionTheme,
            },
        ],
    };
};

export const ModalLayout = buildModal();

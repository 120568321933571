import { ContentBlockShape, ContentBlockType, RichText } from "@/models";
import { Display, FlexDirection, defaultTabsTheme } from "@/styles";
import { FlexWrap, AlignItems, AlignContent, JustifyItems, JustifyContent } from "@/styles/ThemeObjects";
import { defaultRichTextTheme } from "@/styles/themes/RichTextTheme";

export const tabBox = (index: number, roundId?:string) => {
    const tab = {
        content_block_type: ContentBlockType.Box,
        title: `Tab ${index}`,
        content: `Tab ${index}`,
        weight: index,
        theme: {
            color: "#000000",
            borderColor: "#000000",
            borderWidth: 0,
            borderRadius: 0,
            borderStyle: "solid",
            // width: ["auto", "auto", "auto", "auto"],
            display: Display.flex,
            flexDirection: FlexDirection.row,
            flexWrap: FlexWrap.nowrap,
            alignItems: AlignItems.normal,
            alignContent: AlignContent.normal,
            justifyItems: JustifyItems.normal,
            justifyContent: JustifyContent.normal,
            backgroundColor: "#f1f5f9",
            px: 16,
            py: 16,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
        },
        contentBlocks: [
            {
                weight: 0,
                content_block_type: ContentBlockType["Rich Text"],
                theme: defaultRichTextTheme,
                rich_text: {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            attrs: {
                                textAlign: "left",
                                typographyType:"TEXT_STACKED_CARD",
                            },
                            content: [
                                {
                                    type: "text",
                                    text: `Tab ${index} content`,
                                },
                            ],
                        },
                    ],
                } as RichText,
            },
        ],
    };


    return tab as ContentBlockShape
};
function buildTabs(): ContentBlockShape {
    return {
        content_block_type: ContentBlockType.Tabs,
        theme: defaultTabsTheme,
        contentBlocks: [tabBox(1), tabBox(2)],
    };
}

export const TabsLayout = buildTabs();

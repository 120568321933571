import React, { FC, ReactNode, useEffect, useState } from "react";
import {
    ThemeObject,
    Display,
    AlignItems,
    AlignContent,
    JustifyItems,
    JustifyContent,
    FlexWrap,
    FlexDirection,
} from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalDiv } from "./Universal";
import { cn } from "@/util";

type StyledBoxProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId?: string;
};

// function testImage(url): Promise<boolean> {
//     console.log(url, typeof url);
//     if (!url || typeof url !== "string") return Promise.resolve(false);
//     return new Promise((resolve) => {
//         const imgElement = new Image();
//         imgElement.addEventListener("load", () => resolve(true));
//         imgElement.addEventListener("error", () => resolve(false));
//         imgElement.src = url;
//     });
// }

export const StyledBox: FC<StyledBoxProps> = ({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
    title,
}) => {
    const [numberOfRetriesRemaining, setNumberOfRetriesRemaining] = useState(5);
    const [src, setSrc] = useState(theme.backgroundImage);

    useEffect(() => {
        if (numberOfRetriesRemaining >= 0) {
            setSrc(() => theme.backgroundImage);
        } else {
            setSrc(
                () =>
                    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/images/fYVikfRxPBgvFoJjOLNqRIRjrHt4SOeEYLZHBQ7C.png",
            );
        }
    }, [numberOfRetriesRemaining]);

    useEffect(() => {
        setNumberOfRetriesRemaining(5);
        setSrc(() => theme?.backgroundImage);
    }, [theme?.backgroundImage]);

    // const retry = useCallback(() => {
    //     if (numberOfRetriesRemaining >= 0) {
    //         setSrc(() => "");
    //         setNumberOfRetriesRemaining((previousNumber) => {
    //             return previousNumber - 1;
    //         });
    //     }
    // }, [numberOfRetriesRemaining, theme?.backgroundImage]);

    const fixedTheme = { ...theme, backgroundImage: src };

    return (
        <>
            <StyledUniversalDiv
                crossOrigin="anonymous"
                {...fixedTheme}
                data-content-block-id={contentBlockId}
                data-content-block-type={title || "Box"}
                onClick={"function" === typeof onClick ? onClick : (evt) => {}}
                className={cn(theme?.tailwindClasses)}
            >
                {children}
                {!!theme.backgroundImage && (
                    <img
                        className="m-0 h-0 w-0 p-0 opacity-0"
                        src={src as string}
                        // onError={retry}
                    />
                )}
            </StyledUniversalDiv>
        </>
    );
};

export const defaultBoxTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: "#000000",
    borderWidth: 0,
    borderRadius: 0,
    borderStyle: "solid",
    pt: 3,
    pr: 3,
    pb: 3,
    pl: 3,
    mt: 3,
    mr: 3,
    mb: 3,
    ml: 3,
    // width: ["auto", "auto", "auto", "auto"],
    display: Display.flex,
    flexDirection: FlexDirection.row,
    flexWrap: FlexWrap.nowrap,
    alignItems: AlignItems.normal,
    alignContent: AlignContent.normal,
    justifyItems: JustifyItems.normal,
    justifyContent: JustifyContent.normal,
};

import React from "react";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import { ModelSuiteWrapper } from "@/Layouts/model-suite";
import { CenterPanelPageWrapper } from "../InteractiveModule/shared";
import { ModelSuiteTextNavLink } from "@/Layouts/model-suite/ModelSuiteNavLinks";

const DataStudioWrapperComponent = ({
    simulationTitle,
    simulationSlug,
    children,
}: {
    simulationTitle: string;
    simulationSlug: string;
    children: React.ReactNode;
}) => {
    const { props } = usePage();
    return (
        <ModelSuiteWrapper
            simulationTitle={simulationTitle}
            simulationSlug={simulationSlug}
            logoRoute={"model-dashboard.index"}
            subtitle={"Data Studio"}
            childrenNavCenter={
                <>
                    <ModelSuiteTextNavLink
                        href={sapienRoute("data-studio.index", {
                            simulationSlug: simulationSlug,
                        })}
                        active={isCurrent("data-studio.index", props?.route)}
                    >
                        Dashboard
                    </ModelSuiteTextNavLink>
                    <ModelSuiteTextNavLink
                        href={sapienRoute("data-studio.sets.index", {
                            simulationSlug: simulationSlug,
                        })}
                        active={isCurrent(
                            "data-studio.sets.index",
                            props?.route,
                            ["data-studio.sets.show"],
                        )}
                    >
                        Datasets
                    </ModelSuiteTextNavLink>
                    <ModelSuiteTextNavLink
                        href={sapienRoute("data-studio.questions.index", {
                            simulationSlug: simulationSlug,
                        })}
                        active={isCurrent(
                            "data-studio.questions.index",
                            props?.route,
                        )}
                    >
                        Questions
                    </ModelSuiteTextNavLink>
                    <ModelSuiteTextNavLink
                        href={sapienRoute("data-studio.variables.index", {
                            simulationSlug: simulationSlug,
                        })}
                        active={isCurrent(
                            "data-studio.variables.index",
                            props?.route,
                        )}
                    >
                        Variables
                    </ModelSuiteTextNavLink>
                </>
            }
            pageHeadTitle={"Data Studio"}
        >
            <CenterPanelPageWrapper maxWidthClass={""}>
                <div
                    className={
                        "flex flex-col text-slate-700 dark:text-slate-300"
                    }
                >
                    {children}
                </div>
            </CenterPanelPageWrapper>
        </ModelSuiteWrapper>
    );
};

export const DataStudioWrapper = React.memo(DataStudioWrapperComponent);

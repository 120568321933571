import React, { useState } from "react";
import { SimulationShape } from "@/models";
import { Course } from "../types";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Authenticated from "@/Layouts/Authenticated";
import { useColorThemes, ColorThemeType } from "./useColorThemes";
import { AdminPageSection } from "@/components/admin-components";
import { ColorThemeButton } from "./ColorThemeButton";
import {ColorPickerPopover} from "@/components/color-picker";
import { PlusIcon } from "lucide-react";

type Props = {
    simulation: SimulationShape | Course;
    simulations: (SimulationShape | Course)[];
};

const queryClient = new QueryClient();

function hasAllColors(
    simulation: SimulationShape | Course,
    theme: ColorThemeType,
) {
    return (
        theme.colors.every((color) =>
            simulation.designColors?.some(
                (designColor) => designColor.css_color === color,
            ),
        ) &&
        theme.colors.every((color) =>
            simulation.designColors?.some(
                (designColor) => designColor.css_color === color,
            ),
        )
    );
}

function ColorThemeComponent({ simulation, simulations }: Props) {
    const { themes, saveColors } = useColorThemes();
    const filteredSimulations = simulations.filter(
        (sim) => sim.id !== simulation.id,
    );

    const [customColor, setCustomColor] = useState("");

    return (
        <AdminPageSection className="min-h-screen">
            <div className="flex flex-col justify-center gap-4 bg-white p-4">
                <div>
                    <h1 className="text-2xl font-bold">
                        Choose a color theme for {simulation.title}
                    </h1>
                    <p>
                        Choose a theme to start with or create your own custom
                        theme. You'll be able to edit the theme later.
                    </p>
                </div>
                {!!simulation.designColors?.length && (
                    <div className="flex flex-col gap-2 rounded-md bg-gray-100 p-4">
                        <h2 className="text-lg font-bold">Current Colors</h2>
                        <div className="flex flex-col gap-2">
                            <h4 className="text-sm font-bold">
                                Add a custom color
                            </h4>
                            <div className="flex gap-2">
                                <ColorPickerPopover
                                    value={customColor}
                                    onChange={(color) => {
                                        setCustomColor(color);
                                    }}
                                    onGradientChange={(gradient) => {
                                        console.log(gradient);
                                    }}
                                />
                                {!!customColor && (
                                    <div className="flex flex-col gap-2">
                                        <button
                                            onClick={() => {
                                                setCustomColor("");
                                                saveColors(simulation.id, {
                                                    name: simulation.title,
                                                    colors: [
                                                        customColor,
                                                        ...simulation.designColors.map(
                                                            (color) =>
                                                                color.css_color,
                                                        ),
                                                    ],
                                                });
                                            }}
                                        >
                                            <PlusIcon className="h-4 w-4" />
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {simulation.designColors?.map((color) => (
                                    <div
                                        key={color.css_color}
                                        className="h-8 w-8 rounded-full border border-gray-300"
                                        style={{
                                            backgroundColor: color.css_color,
                                        }}
                                    ></div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex flex-col gap-2">
                    <h2 className="text-lg font-bold">Default Themes</h2>
                    <p>
                        Select a theme to apply to your simulation. You can choose the colors you'd like to add.
                    </p>
                    <div className="flex flex-wrap gap-2">
                        {themes
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((theme) => (
                                <ColorThemeButton
                                    key={theme.name}
                                    isSelected={hasAllColors(simulation, theme)}
                                    onSelect={() => {
                                        saveColors(simulation.id, theme);
                                    }}
                                    name={theme.name}
                                    colors={theme.colors}
                                    currentColors={simulation.designColors?.map(
                                        (color) => color.css_color,
                                    )}
                                />
                            ))}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <h2 className="text-lg font-bold">Custom Themes</h2>
                    <p>
                        You can create your own custom theme by selecting colors
                        from other simulations.
                    </p>
                    {filteredSimulations.length === 0 && (
                        <p>
                            No simulations have colors to choose from yet.
                            Choose a set from above to begin customizing.
                        </p>
                    )}
                    <div className="flex flex-wrap gap-2">
                        {simulations
                            .filter((sim) => sim.id !== simulation.id)
                            .map((sim) => (
                                <ColorThemeButton
                                    key={sim.title}
                                    isSelected={hasAllColors(simulation, {
                                        name: sim.title,
                                        colors: sim.designColors?.map(
                                            (color) => color.css_color,
                                        ),
                                    })}
                                    onSelect={(colors) => {
                                        saveColors(simulation.id, {
                                            name: simulation.title,
                                            colors,
                                        });
                                    }}
                                    name={sim.title}
                                    colors={sim.designColors?.map(
                                        (color) => color.css_color,
                                    )}
                                    currentColors={simulation.designColors?.map(
                                        (color) => color.css_color,
                                    )}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </AdminPageSection>
    );
}

export default function ColorTheme({ simulation, simulations }: Props) {
    return (
        <QueryClientProvider client={queryClient}>
            <Authenticated>
                <ColorThemeComponent
                    simulation={simulation}
                    simulations={simulations}
                />
            </Authenticated>
        </QueryClientProvider>
    );
}

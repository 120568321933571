import React, { memo } from "react";
import { Course, Lesson } from "../types";
import { SapienAdminPageProps } from "@/inertia-utils/types";
import LessonDisplay from "../LessonDisplay/LessonDisplay";
import AddContentBlockWidget from "./AddContentBlockWidget";
import "../lesson-design.css";
import {
    useContentBlockState,
    useSetupContentBlockState,
} from "./useContentBlockState";
import ContentBlockWidgets from "./content-block-editing/ContentBlockWidgets";
import { Link } from "@inertiajs/react";
import QueryClient from "../QueryClient/QueryClient";
import { useSelectedSimulation } from "@/hooks";
import Aside from "./content-block-editing/Aside";
import DesignModals from "./content-block-editing/DesignModals";
import { ChevronLeft, ChevronRight } from "lucide-react";
import LessonDesignHeader from "./LessonDesignHeader";
import { sapienRoute } from "@/inertia-utils/hooks";
import ApplicationLogo from "@/components/ApplicationLogo";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { createPortal } from "react-dom";
import LessonSettings from "./LessonSettings";
import Authenticated from "@/Layouts/Authenticated";

type Props = { course: Course; lesson: Lesson } & SapienAdminPageProps;
const LessonDesign = memo(
    function LessonDesign({ course, auth }: Props) {
        const { parentContentBlocks, lesson } = useContentBlockState();
        const hasContentBlocks = !!parentContentBlocks.length;
        const slot = useGetElementAsync("#aside-slot");

        if (!lesson) return null;
        return (
            <Authenticated>
                {/* <DevTools /> */}
                <div className="e-learning -mt-16 pb-60">
                    <LessonDesignHeader
                        lesson={lesson}
                        course={course}
                        auth={auth}
                    >
                        <div className="items-between flex w-full flex-1">
                            <div className="flex w-full flex-1 items-center gap-7">
                                <Link
                                    href={sapienRoute(
                                        "e-learning.courses.index",
                                    )}
                                    className="flex items-center underline"
                                    as="a"
                                >
                                    <ApplicationLogo className="block h-9 w-auto" />
                                </Link>
                                <Link
                                    href={sapienRoute(
                                        "e-learning.courses.edit",
                                        {
                                            course: course.id,
                                        },
                                    )}
                                    className="flex items-center underline"
                                    as="a"
                                >
                                    <ChevronLeft className="h-4 w-4" />
                                    <span>
                                        {course.courseMetadata?.course_title ||
                                            course.title}
                                    </span>
                                </Link>
                                <h3 className="flex items-center text-xl font-bold ">
                                    {lesson.title}
                                </h3>
                            </div>
                            <div className="flex items-center space-x-7">
                                {!!lesson.hasScoredQuestions && (
                                    <LessonSettings lesson={lesson} />
                                )}
                                <Link
                                    className="flex items-center underline"
                                    href={sapienRoute(
                                        "e-learning.preview.show",
                                        {
                                            lesson: lesson.id,
                                        },
                                    )}
                                >
                                    <span>Preview</span>
                                    <ChevronRight className="h-4 w-4" />
                                </Link>
                            </div>
                        </div>
                    </LessonDesignHeader>
                    <div id="aside-slot"></div>
                    <main
                        className="lesson-designer flex min-h-[calc(100vh-4rem)] w-full snap-y snap-proximity
                            flex-col items-center data-[has-blocks=true]:justify-start
                            data-[has-blocks=false]:justify-center"
                        data-has-blocks={hasContentBlocks}
                    >
                        <LessonDisplay
                            lesson={lesson}
                            lessonCount={0}
                            isInAdminContext={true}
                        />
                        <AddContentBlockWidget
                            roundId={lesson.id}
                            hasBlocks={hasContentBlocks}
                        />
                        <ContentBlockWidgets />
                    </main>
                </div>
                {/* Place Aside in portal so that the slot can be used for other dialogs */}
                {!!slot && createPortal(<Aside />, slot)}
                <DesignModals />
            </Authenticated>
        );
    },
    (oldProps, newProps) => {
        for (const key in oldProps) {
            if (!Object.is(oldProps[key], newProps[key])) {
                console.log("props changed in lesson design", key);
                return false;
            }
        }
        return Object.is(oldProps, newProps);
    },
);

const WrappedLessonDesign = (props: Props) => {
    const { lesson, course } = props;
    useSetupContentBlockState(lesson);
    const { gotSimulation } = useSelectedSimulation();
    gotSimulation(course);
    return <QueryClient>{<LessonDesign {...props} />}</QueryClient>;
};

export default WrappedLessonDesign;

import React from "react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { ModelSuiteTextNavLink } from "@/Layouts/model-suite/ModelSuiteNavLinks";

const ModelBuilderNavCenterComponent = ({
    simulationSlug,
    isInteractive,
}: {
    simulationSlug: string;
    isInteractive: boolean;
}) => {
    return (
        <>
            <ModelSuiteTextNavLink
                href={
                    !!simulationSlug &&
                    sapienRoute("model-builder.index", {
                        simulationSlug: simulationSlug,
                    })
                }
                active={!isInteractive}
            >
                Simulation
            </ModelSuiteTextNavLink>
            <ModelSuiteTextNavLink
                href={
                    !!simulationSlug &&
                    sapienRoute("model-builder.interactive", {
                        simulationSlug: simulationSlug,
                        type: "interactive",
                    })
                }
                active={!!isInteractive}
            >
                Interactive
            </ModelSuiteTextNavLink>
        </>
    );
};

export const ModelBuilderNavCenter = React.memo(ModelBuilderNavCenterComponent);

import React, { useState } from "react";
import { CheckIcon, MailIcon } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { sapienAxios } from "@/inertia-utils/hooks";
import { UserShape } from "@/models";

type Props = {
    user: UserShape;
    handleOnSuccess: () => void;
};

export const MagicLinkButton = ({ user, handleOnSuccess }: Props) => {
    const [linkSent, setLinkSent] = useState(false);
    const { mutateAsync, isPending } = useMutation({
        mutationFn: async (userEmail: string) => {
            const { data } = await sapienAxios.post<
                {
                    email: string;
                },
                "passwordless-login.admin.store"
            >("passwordless-login.admin.store", {
                email: userEmail,
            });
            return data;
        },
        onSuccess: (data) => {
            setLinkSent(true);
            handleOnSuccess();
        },
    });

    return (
        <div className="flex flex-col items-start gap-2">
            <button
                className="flex items-center rounded-md border border-slate-300 bg-transparent p-2 text-sm
                    text-slate-500 data-[is-pending=true]:animate-pulse hover:bg-slate-200
                    hover:text-sapien-blue"
                onClick={() => mutateAsync(user.email)}
                data-is-pending={isPending}
                disabled={isPending}
            >
                <MailIcon className="mr-2 h-4 w-4" />
                <span>Send Magic Link</span>
            </button>
            {!!linkSent && (
                <div className="flex items-center space-x-2 text-sm">
                    <CheckIcon className="h-4 w-4 text-green-500" />
                    <span>Email sent!</span>
                </div>
            )}
        </div>
    );
};

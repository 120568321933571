import React from "react";
import { StatisticsTable } from "./StatisticsTable";
import { DataStudioProps } from "./types";
import { SubsectionWrapper } from "./SharedComponents";

const OverviewDisplayComponent = ({
    data,
    timeHorizons,
    variables,
}: Partial<DataStudioProps>) => {
    return (
        <>
            <SubsectionWrapper title={"Properties"}>
                <div className="space-y-4">
                    {!!timeHorizons?.length && (
                        <div className="text-sm text-slate-500">
                            <div>{`TIMESPAN:`}</div>
                            <div>{`${timeHorizons[0]} to ${timeHorizons[timeHorizons.length - 1]}`}</div>
                            <div>{`steps: ${timeHorizons.length}`}</div>
                        </div>
                    )}
                    <div className="text-sm text-slate-500">
                        <div>{`VARIABLES:`}</div>
                        <div>{`${variables.map((variable) => ` ${variable}`)}`}</div>
                    </div>
                </div>
            </SubsectionWrapper>
            <SubsectionWrapper title={"Statistical Summary"}>
                <div className="space-y-8 pt-2">
                    <div className="w-full">
                        {data !== undefined && Object.keys(data).length > 1 && (
                            <StatisticsTable
                                data={data}
                                outerKeysAsRows={true}
                            />
                        )}
                    </div>
                    <div className="w-full">
                        {data !== undefined && Object.keys(data).length > 1 && (
                            <StatisticsTable data={data} />
                        )}
                    </div>
                </div>
            </SubsectionWrapper>
        </>
    );
};

export const OverviewDisplay = React.memo(OverviewDisplayComponent);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import { DataStudioWrapper } from "./DataStudioWrapper";
import {
    Cohort,
    ModelVariable,
    ModelVariableScope,
    ModelVariableType,
    ModelVariableUnit,
    SimulationShape,
} from "@/models";
import { sapienAxios } from "@/inertia-utils/hooks";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { defaultStatisticsObject, StatisticsObject } from "./types";
import { FilterMultiSelect } from "./SharedComponents";
import { StatisticsTable } from "./StatisticsTable";
import { groupBy, keyBy } from "lodash";
import { useSelectedSimulation } from "@/hooks";
import { atom, useAtomValue, useSetAtom } from "jotai";

interface FilterState {
    types: ModelVariableType[];
    scopes: ModelVariableScope[];
    cohorts: string[];
}

enum SummaryType {
    "By Variable" = "BY_VARIABLE",
    "By Time Index" = "BY_TIME",
}

type VariableAnalysisPayload = FilterState & {
    simulation_id: string;
    summary_type?: SummaryType;
    data_types?: string[];
    uses_time?: boolean[];
    units?: ModelVariableUnit[];
    model_blocks?: string[];
    search?: string;
};

type VariableAnalysisMetadata = {
    time_horizons: number[];
    model_variables: string[];
    unique_entities: number;
};

type VariableAnalysisResponse = {
    analysis_by_variable: { [model_variable_id: string]: StatisticsObject };
    analysis_by_time: {
        [time_index: number | string]: {
            [model_variable_id: string]: StatisticsObject;
        };
    };
    analysis_metadata: VariableAnalysisMetadata;
};

async function variableAnalysisFunction({
    body,
}: {
    body: Partial<VariableAnalysisPayload>;
}) {
    const { data } = await sapienAxios.post<
        VariableAnalysisResponse,
        "data-studio.api.variable-value-analysis"
    >(
        "data-studio.api.variable-value-analysis",
        body as Partial<VariableAnalysisPayload>,
        {},
    );

    return data;
}

const handleVariableAnalysis = (parameters: VariableAnalysisPayload) => {
    const { data } = useQuery({
        queryKey: ["variableAnalysis", parameters],
        queryFn: async () => {
            const data = await variableAnalysisFunction({
                body: parameters,
            });
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled: !!parameters && !!parameters.simulation_id,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

const useHandleVariableAnalysis = (parameters: VariableAnalysisPayload) => {
    const response = handleVariableAnalysis(parameters);
    return (
        response ??
        ({
            analysis_by_variable: {},
            analysis_by_time: {},
            analysis_metadata: {},
        } as VariableAnalysisResponse)
    );
};

const dataStudioModelVariablesAtom = atom<ModelVariable[]>(
    undefined as ModelVariable[],
);
export const useDataStudioModelVariables = () => {
    const dataStudioModelVariables = useAtomValue(dataStudioModelVariablesAtom);
    return dataStudioModelVariables;
};
const useSetDataStudioModelVariables = () => {
    const setDataStudioModelVariables = useSetAtom(
        dataStudioModelVariablesAtom,
    );
    return setDataStudioModelVariables;
};

const DataStudioVariablesDetail = ({
    simulationId,
    summaryType,
    filters,
    modelVariableMap,
}: {
    simulationId: string;
    summaryType: SummaryType;
    filters: FilterState;
    modelVariableMap: Record<string, ModelVariable>;
}) => {
    const variableAnalysisResponse = useHandleVariableAnalysis({
        simulation_id: simulationId,
        summary_type: summaryType,
        ...filters,
    });
    const { analysis_by_variable, analysis_by_time } = variableAnalysisResponse;

    const analysisByVariable: {
        [model_variable_label: string]: StatisticsObject;
    } = useMemo(() => {
        return analysis_by_variable !== undefined &&
            Object.keys(analysis_by_variable).length > 1 &&
            modelVariableMap !== undefined &&
            Object.keys(modelVariableMap)?.length > 1
            ? Object.keys(modelVariableMap)
                  ?.filter((key) => !!analysis_by_variable[key])
                  .reduce(
                      (map, key) => ({
                          ...map,
                          [modelVariableMap[key].label]:
                              analysis_by_variable[key],
                      }),
                      {},
                  )
            : {};
    }, [modelVariableMap, analysis_by_variable]);

    const analysisByTime: {
        [time_index: number]: {
            [model_variable_id: string]: StatisticsObject;
        };
    } = useMemo(() => {
        return analysis_by_time !== undefined &&
            Object.keys(analysis_by_time).length > 1 &&
            modelVariableMap !== undefined &&
            Object.keys(modelVariableMap)?.length > 1
            ? Object.keys(analysis_by_time)
                  ?.filter((key) => key !== "unknown")
                  .reduce(
                      (timeMap, key) => ({
                          ...timeMap,
                          [key]: Object.keys(modelVariableMap)
                              ?.filter(
                                  (modelVariableId) =>
                                      !!analysis_by_time[key][modelVariableId],
                              )
                              .reduce(
                                  (map, modelVariableId) => ({
                                      ...map,
                                      [modelVariableMap[modelVariableId].label]:
                                          analysis_by_time[key][
                                              modelVariableId
                                          ],
                                  }),
                                  {},
                              ),
                      }),
                      {},
                  )
            : {};
    }, [modelVariableMap, analysis_by_time]);

    return (
        <div className="space-y-8 pt-4">
            {summaryType == SummaryType["By Variable"] && (
                <div className="w-full space-y-4">
                    <div className="text-lg">Analysis by Variable</div>
                    {analysisByVariable !== undefined &&
                    Object.keys(analysisByVariable).length > 1 ? (
                        <div className="border-t border-slate-500 pt-4 dark:border-slate-500">
                            <StatisticsTable
                                data={analysisByVariable}
                                outerKeysAsRows={true}
                                isSimulationData={true}
                            />
                        </div>
                    ) : (
                        <div className="text-sm text-slate-600 dark:text-slate-400">
                            No data
                        </div>
                    )}
                </div>
            )}
            {summaryType == SummaryType["By Time Index"] && (
                <>
                    {analysisByVariable !== undefined &&
                        Object.keys(analysisByVariable).length > 1 && (
                            <div className="w-full space-y-4">
                                <div className="text-lg">
                                    Analysis by Variable (No Time Index)
                                </div>
                                <div className="border-t border-slate-500 pt-4 dark:border-slate-500">
                                    <StatisticsTable
                                        data={analysisByVariable}
                                        outerKeysAsRows={true}
                                        isSimulationData={true}
                                    />
                                </div>
                            </div>
                        )}
                    <div className="w-full space-y-4">
                        <div className="text-lg">Analysis by Time Index</div>
                        {analysisByTime !== undefined &&
                        Object.keys(analysisByTime).length > 1 ? (
                            <>
                                {Object.values(modelVariableMap)
                                    ?.filter(
                                        (modelVariable) =>
                                            !!analysisByTime[0][
                                                modelVariable.label
                                            ],
                                    )
                                    .map((modelVariable) => (
                                        <div
                                            key={modelVariable.id}
                                            className="space-y-2 border-t border-slate-500 pt-4 dark:border-slate-500"
                                        >
                                            <div className="font-semibold">{`${modelVariable.label}`}</div>
                                            <StatisticsTable
                                                data={Object.keys(
                                                    analysisByTime,
                                                ).reduce(
                                                    (map, timeHorizon) => ({
                                                        ...map,
                                                        [`${timeHorizon}`]:
                                                            analysisByTime[
                                                                timeHorizon
                                                            ][
                                                                modelVariable
                                                                    .label
                                                            ] ??
                                                            defaultStatisticsObject,
                                                    }),
                                                    {},
                                                )}
                                                formatKey={modelVariable.label}
                                                label={"Time Index"}
                                                isSimulationData={true}
                                            />
                                        </div>
                                    ))}
                                {/* {Object.keys(analysisByTime).map((key) => (
                                    <div key={key} className="space-y-2">
                                        <div className="text-sm">{`Time Index: ${Number(key)}`}</div>
                                        <StatisticsTable
                                            data={analysisByTime[key]}
                                            outerKeysAsRows={true}
                                            isSimulationData={true}
                                        />
                                    </div>
                                ))} */}
                            </>
                        ) : (
                            <div className="text-sm text-slate-600 dark:text-slate-400">
                                No data
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

const SELECT_MENU_LABELS: {
    [index in keyof FilterState]: string;
} = {
    types: "Variable Types",
    scopes: "Variable Scopes",
    cohorts: "Cohorts",
};

type Props = SapienPageProps & {
    simulation: SimulationShape & { is_course: boolean };
    modelVariables: ModelVariable[];
};

export default function DataStudioVariables({
    simulation,
    modelVariables,
}: Props) {
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    const setDataStudioModelVariables = useSetDataStudioModelVariables();

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
    }, [simulation]);

    useEffect(() => {
        setDataStudioModelVariables(modelVariables);
    }, [modelVariables]);

    const [filters, setFilters] = useState<FilterState>({
        types: [],
        scopes: [],
        cohorts: [],
    });

    const [summaryType, setSummaryType] = useState<SummaryType>(
        SummaryType["By Variable"],
    );

    const variableTypes: (keyof typeof ModelVariableType)[] = useMemo(() => {
        const variableTypeValues = (Object.keys(
            groupBy(modelVariables ?? [], "variable_type"),
        ) ?? []) as ModelVariableType[];

        return Object.keys(ModelVariableType).filter((key) =>
            variableTypeValues.includes(ModelVariableType[key]),
        ) as (keyof typeof ModelVariableType)[];
    }, [modelVariables]);

    const modelVariableMap: Record<string, ModelVariable> = useMemo(() => {
        return keyBy(modelVariables, "id") ?? {};
    }, [modelVariables]);

    const cohorts: Cohort[] = useMemo(() => {
        return simulation?.cohorts?.length > 0
            ? [...simulation.cohorts]
                  .filter((cohort) => cohort.selections_count > 0)
                  .reverse()
            : [];
    }, [simulation]);

    const itemsObject = useMemo<{
        [index in keyof FilterState]: {
            [index: string]: string;
        };
    }>(() => {
        return {
            types: variableTypes?.reduce(
                (map, key) => ({ ...map, [ModelVariableType[key]]: key }),
                {},
            ),
            scopes: Object.keys(ModelVariableScope).reduce(
                (map, key) => ({ ...map, [ModelVariableScope[key]]: key }),
                {},
            ),
            cohorts:
                cohorts?.reduce(
                    (map, cohort) => ({
                        ...map,
                        [cohort.id]: `${cohort.title} (teams: ${cohort.teams_count}, users: ${cohort.team_user_rounds_count})`,
                    }),
                    {},
                ) ?? {},
        };
    }, [variableTypes, cohorts]);

    const handleFilterChange = useCallback(
        (
            category: keyof FilterState,
            items: (string | ModelVariableType | ModelVariableScope)[],
        ) => {
            setFilters((prev) => {
                const newItems = Object.keys(itemsObject[category]).filter(
                    (item) => items.includes(item),
                );
                const updated = {
                    ...prev,
                    [category]: newItems,
                };
                return updated;
            });
        },
        [itemsObject],
    );

    const clearFilters = useCallback(() => {
        const cleared = {
            types: [],
            scopes: [],
            cohorts: [],
        };
        setFilters(cleared);
    }, []);

    return (
        <DataStudioWrapper
            simulationTitle={simulation?.title}
            simulationSlug={simulation?.slug}
        >
            <div className="flex w-full flex-col">
                <div className="mx-auto w-full max-w-7xl p-6">
                    <div className="space-y-4">
                        <div
                            className="flex flex-col justify-between space-x-0 space-y-2 sm:flex-row sm:items-center
                                sm:space-x-2 sm:space-y-0"
                        >
                            <h1 className="text-lg font-bold text-slate-700 dark:text-slate-300">
                                {"Variable Data"}
                            </h1>
                            <div className="flex items-center space-x-2 text-slate-600 dark:text-slate-400">
                                <div
                                    className="flex rounded-md border border-slate-200 hover:border-slate-300
                                        dark:border-slate-800 dark:hover:border-slate-700"
                                >
                                    <button
                                        onClick={clearFilters}
                                        className="rounded-md bg-slate-50/50 px-2 py-1.5 text-xs hover:bg-slate-100/25
                                            dark:bg-slate-800/20 dark:hover:bg-slate-800/25"
                                    >
                                        <div className="font-light tracking-wide">
                                            Clear All Filters
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-col space-y-4">
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3">
                                {!!itemsObject &&
                                    Object.keys(SELECT_MENU_LABELS)
                                        .filter((label) => label !== "scopes")
                                        .map((key) => (
                                            <div
                                                key={key}
                                                className="flex flex-col space-y-2"
                                            >
                                                <div className="text-slate-600 dark:text-slate-400">
                                                    {SELECT_MENU_LABELS[key]}
                                                </div>
                                                <FilterMultiSelect
                                                    items={Object.keys(
                                                        itemsObject[key],
                                                    )}
                                                    itemDisplayLabels={Object.values(
                                                        itemsObject[key],
                                                    )}
                                                    selectedItems={filters[key]}
                                                    handleSetItems={(
                                                        items: string[],
                                                    ) =>
                                                        handleFilterChange(
                                                            key as keyof FilterState,
                                                            items,
                                                        )
                                                    }
                                                    isDisabled={false}
                                                />
                                                <div className="text-sm text-orange-600 dark:text-orange-400">
                                                    {filters[key].length > 0 ? (
                                                        <div>{`${filters[
                                                            key
                                                        ].map(
                                                            (item) =>
                                                                ` ${itemsObject[key][item]}`,
                                                        )}`}</div>
                                                    ) : (
                                                        <div className="text-sm text-slate-500 dark:text-slate-500">{`No ${key} selected`}</div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                            </div>
                            <div className="flex items-center space-x-4">
                                <div className="text-slate-600 dark:text-slate-400">
                                    {`Summary Type: `}
                                </div>
                                {Object.keys(SummaryType).map((key) => (
                                    <div
                                        key={key}
                                        className="ml-4 flex items-center font-medium text-gray-900"
                                    >
                                        <label
                                            htmlFor={key}
                                            className="flex w-full items-center justify-between text-sm"
                                        >
                                            <span className="text-slate-600 dark:text-slate-400">
                                                <input
                                                    id={key}
                                                    type={"radio"}
                                                    checked={
                                                        SummaryType[key] ==
                                                        summaryType
                                                    }
                                                    className={`text-primary-600 focus:ring-primary-500 mr-2 h-4 w-4 border-slate-200
                                                        bg-slate-200 focus:ring-2 dark:border-slate-400 dark:bg-slate-400`}
                                                    onChange={() => {
                                                        setSummaryType(
                                                            SummaryType[key],
                                                        );
                                                    }}
                                                />
                                                {key}
                                            </span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <DataStudioVariablesDetail
                                simulationId={simulation?.id}
                                summaryType={summaryType}
                                filters={filters}
                                modelVariableMap={modelVariableMap}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </DataStudioWrapper>
    );
}

import { z } from "zod";
import { PromptType } from "@/models";

// export type PromptWithRoundData = Prompt & {
//     options: Option & { id: string };
//     rounds: { title: string; weight: number };
// };

export const modalSchema = z.discriminatedUnion("modalComponent", [
    z.object({
        modalComponent: z.literal("AddContentBlockModal"),
        contentBlockId: z.string(),
        position: z.union([z.literal("before"), z.literal("after")]),
    }),
    z.object({
        modalComponent: z.literal("AddFromTemplateModal"),
        roundId: z.string(),
        weight: z.number(),
        replacedContentBlockId: z.string().nullable().optional(),
        parentContentBlockId: z.string().nullable(),
    }),
    z.object({
        modalComponent: z.literal("BehaviorModal"),
        behaviorContentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("CardDeckModal"),
        parentContentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("CarouselModal"),
        parentContentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("ChartWizard"),
        parentContentBlockId: z.string(),
        chartId: z.string().nullable().optional(),
    }),
    z.object({
        modalComponent: z.literal("ChatGPTModal"),
    }),
    z.object({
        modalComponent: z.literal("ContentBlockModal"),
        modalContentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("ContentBlockTemplateModal"),
        contentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("DragAndDropQuestionModal"),
        contentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("DynamicContentHideRevealModal"),
    }),
    z.object({
        modalComponent: z.literal("DynamicContentModal"),
        contentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("DynamicValidationRuleModal"),
        contentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("ImageModal"),
        imageUrl: z.string().optional(),
        imageContentBlockId: z.string().optional(),
        backgroundImageContentBlockId: z.string().optional(),
    }),
    z.object({
        modalComponent: z.literal("ModelDataSourceModal"),
        contentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("MovePageModal"),
        pageId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("MoveParticipantModal"),
        parentContentBlockId: z.string(),
        participantId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("NotificationModal"),
        notificationModalContentBlockId: z.string(),
        afterSaveRoute: z.string(),
    }),
    z.object({
        modalComponent: z.literal("ParticipantLinkModal"),
        userId: z.string(),
        link: z.string(),
        user_name: z.string(),
    }),
    z.object({
        modalComponent: z.literal("PublishTemplateModal"),
        templateToPublishId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("QuestionModal"),
        contentBlockId: z.string(),
        promptType: z.nativeEnum(PromptType),
    }),
    z.object({
        modalComponent: z.literal("TableWizard"),
        parentContentBlockId: z.string(),
        tableId: z.string().nullable().optional(),
        type: z.enum(["TABLE", "LEADERBOARD"]).default("TABLE"),
    }),
    z.object({
        modalComponent: z.literal("TimelineQuestionModal"),
        contentBlockId: z.string(),
    }),
    z.object({
        modalComponent: z.literal("TopDrawerModal"),
    }),
    z.object({
        modalComponent: z.literal("VideoModal"),
        videoContentBlockId: z.string().optional(),
    }),
]);

export type ModalQueryStrings = z.infer<typeof modalSchema>;

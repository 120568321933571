import React, { FC, ReactNode, useMemo } from "react";
import styled from "styled-components";
import { ThemeObject } from "../../ThemeObjects";
import { ContentBlockEvents } from "../ContentBlockEvents";
import { ColorScheme, ContentBlockShape, ContentBlockType } from "@/models";
import { borderRadius, margin, padding } from "styled-system";
import { StyledUniversalDiv } from "../Universal";

type TableTheme = ThemeObject & {
    isBordered?: boolean;
    isStriped?: boolean;
    isSpreadsheet?: boolean;
    combineColumnsRowIndex?: number;
    combineCellsNumber?: number;
    // markColumn?: number;
};

function getPadding(styledStyledValue: number) {
    return `${2 ** styledStyledValue}px`;
}
export const Table = styled.table<Partial<TableTheme>>`
    width: 100%;
    border-spacing: 0;
    border: ${({ borderColor }) =>
        borderColor
            ? `1px solid ${borderColor.toString()}`
            : `1px solid  green`};
    overflow: hidden;
    /* margin: ${({ my, mx }) => `${my.toString()} ${mx.toString()}`}; */
    ${margin}
    ${borderRadius}
    tr {
        td,
        th {
            padding-left: ${({ px }) => getPadding(px as number)};
            padding-right: ${({ px }) => getPadding(px as number)};
            padding-top: ${({ py }) => getPadding(py as number)};
            padding-bottom: ${({ py }) => getPadding(py as number)};
        }
    }
    tr:not(.add-variable-row) {
        background-color: ${({ backgroundColor }) =>
            backgroundColor || "#F9FAFB"};
    }
    tr:nth-of-type(odd):not(.add-variable-row) {
        background-color: ${({ backgroundColor }) =>
            backgroundColor || "#F9FAFB"};
    }
    tbody {
        tr:nth-of-type(odd):not(.add-variable-row) {
            background-color: ${({ backgroundColor, isStriped }) => {
                if (isStriped) {
                    return reduceHexOpacity(backgroundColor || "#F9FAFB");
                }
                return backgroundColor || "#F9FAFB";
            }};
        }
    }
`;

export const TBody = styled.tbody`
    pointer-events: all;
`;

export const Cell = styled.td<Partial<TableTheme>>`
    color: ${
        (props) =>
            // theme.markColumn ?
            props.color || "#374151"
        // : "#374151"
    };
    border-bottom: ${(props) =>
        props.isBordered || props.isSpreadsheet
            ? `1px solid ${props.borderColor.toString() || "#E5E7EB"}`
            : "none"};
    &:not(:first-child) {
        border-left: ${(props) =>
            props.isSpreadsheet
                ? `1px solid ${props.borderColor.toString() || "#E5E7EB"}`
                : "none"};
    }
    text-align: left;
    ${padding}
`;

export const HeaderCell = styled.th<Partial<TableTheme>>`
    color: ${
        (props) =>
            // theme.markColumn ?
            props.color || "#374151"
        // : "#374151"
    };
    border-bottom: ${(props) =>
        props.isBordered || props.isSpreadsheet
            ? `1px solid ${props.borderColor.toString() || "#E5E7EB"}`
            : "none"};
    &:not(:first-child) {
        border-left: ${(props) =>
            props.isSpreadsheet
                ? `1px solid ${props.borderColor.toString() || "#E5E7EB"}`
                : "none"};
    }
    /* &:first-child { */
    text-align: left;
    /* } */
    background-color: ${({ backgroundColor }) => backgroundColor || "#E5E7EB"};
    ${padding}
`;

export const Row = styled.tr<Partial<TableTheme>>`
    /* &:first-child {
        ${Cell}:first-child {
            border-top-left-radius: 8px;
        }
        ${Cell}:last-child {
            border-top-right-radius: 8px;
        }
        ${HeaderCell}:first-child {
            border-top-left-radius: 8px;
        }
        ${HeaderCell}:last-child {
            border-top-right-radius: 8px;
        }
    }
    &:last-child {
        ${Cell}:first-child {
            border-bottom-left-radius: 8px;
        }
        ${Cell}:last-child {
            border-bottom-right-radius: 8px;
        }
        ${Cell} {
            border-bottom: none;
        }
    } */
`;

type StyledTableProps = ContentBlockEvents & {
    // tableData: TableData;
    theme: Partial<TableTheme>;
    children?: ReactNode;
    palette?: Partial<ColorScheme>;
    contentBlockId: string;
    tableConfig: ContentBlockShape;
};

export function reduceHexOpacity(color: string) {
    let opacityHex: string;
    if (color.length === 9) {
        opacityHex = color.slice(-2);
    } else if (color.length === 7) {
        opacityHex = "FF";
    } else {
        return color;
    }
    const newOpacity = Math.round(parseInt(opacityHex, 16) / 2).toString(16);
    return color.slice(0, 7) + newOpacity;
}

const StyledModelTable: FC<StyledTableProps> = ({
    theme,
    tableConfig,
    onClick,
    onHover,
    title,
}) => {
    const tableBodyBlock = useMemo(() => {
        if (tableConfig?.contentBlocks?.length) {
            return tableConfig.contentBlocks.find(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType["Table Body"]
            );
        }
    }, [theme, tableConfig]);

    const tableHeadBlock = useMemo(() => {
        if (tableConfig?.contentBlocks?.length) {
            return tableConfig.contentBlocks.find(
                (contentBlock) =>
                    contentBlock.content_block_type ===
                    ContentBlockType["Table Head"]
            );
        }
    }, [theme, tableConfig]);

    return (
        <StyledUniversalDiv
            data-content-block-id={tableConfig?.id}
            data-content-block-type={title || "Table"}
            className="max-w-full"
            width={theme.width || 1}
            onClick={
                "function" === typeof onClick
                    ? (evt) => {
                          console.log("clicked table");
                          onClick(evt);
                      }
                    : (evt) => {}
            }
        >
            <div className="overflow-x-auto max-w-[100vw] p-2 scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-slate-700 scrollbar-track-slate-300 scrollbar-rounded-full scrollbar-thin">
                <Table {...theme} width={"auto"}>
                    {!!tableHeadBlock && (
                        <thead>
                            {tableHeadBlock?.contentBlocks?.map((row, i) => (
                                <Row
                                    {...theme}
                                    key={row.id || `${i}_${tableHeadBlock.id}`}
                                >
                                    {row?.contentBlocks?.map(
                                        (contentBlock, j) => (
                                            <HeaderCell
                                                {...theme}
                                                key={
                                                    contentBlock.id ||
                                                    `${j}_${contentBlock.content}`
                                                }
                                            >
                                                {contentBlock.content}
                                            </HeaderCell>
                                        )
                                    )}
                                </Row>
                            ))}
                            {/* {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th
                                        style={{
                                            color:
                                                // theme.headerColor ||
                                                "#374151",
                                            textAlign: "left",
                                            borderBottom: theme.isBordered
                                                ? `1px solid ${
                                                      theme.backgroundColor ||
                                                      "#E5E7EB"
                                                  }`
                                                : "none",
                                            padding: "28px 30px",
                                        }}
                                        key={header.id}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef
                                                      .header,
                                                  header.getContext()
                                              )}
                                    </th>
                                ))}
                            </tr>
                        ))} */}
                        </thead>
                    )}

                    <TBody
                        onMouseMove={
                            "function" === typeof onHover
                                ? onHover
                                : (evt) => {}
                        }
                    >
                        {!tableBodyBlock?.contentBlocks?.length && (
                            <Row>
                                <Cell>
                                    Add some cells or connect model variables
                                </Cell>
                            </Row>
                        )}
                        {tableBodyBlock?.contentBlocks?.sort((a,b) => a.weight - b.weight).map((row, rowindex) => (
                            <Row key={row.id}>
                                {!row?.contentBlocks?.length && (
                                    <Cell>Add some cells</Cell>
                                )}
                                {row?.contentBlocks?.map((cell, index) => {
                                    return (
                                        <Cell
                                            colSpan={
                                                rowindex ===
                                                theme.combineColumnsRowIndex
                                                    ? theme.combineCellsNumber
                                                    : 1
                                            }
                                            {...theme}
                                            style={{
                                                fontWeight:
                                                    index === 0 ? 600 : "",
                                                height: "auto",
                                                // ...cell.getValue().styles,
                                            }}
                                            key={
                                                cell.id ||
                                                `${cell.parent_content_block_id}_${index}`
                                            }
                                        >
                                          {cell.content}
                                        </Cell>
                                    );
                                })}
                            </Row>
                        ))}
                    </TBody>
                </Table>
            </div>
        </StyledUniversalDiv>
    );
};

export const StyledTable: FC<StyledTableProps> = (props) => {
    if (props.tableConfig?.contentBlocks?.length) {
        return <StyledModelTable {...props} />;
    }

    if (!props.tableConfig?.table_data?.length) return <></>;
    const { contentBlockId, theme, onClick, children } = props;

    return (
        <StyledUniversalDiv
            data-content-block-id={contentBlockId}
            data-content-block-type={props.title || "Table"}
            className="max-w-full"
            width={theme.width || 1}
            onClick={
                "function" === typeof onClick
                    ? (evt) => {
                          console.log("clicked table");
                          onClick(evt);
                      }
                    : (evt) => {}
            }
        >
            <div className="overflow-x-auto max-w-[100vw] p-2 scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-slate-700 scrollbar-track-slate-300 scrollbar-rounded-full scrollbar">
                <Table
                    {...theme}
                    width={"auto"}
                    // onMouseMove={"function" === typeof onHover ? onHover : (evt) => {}}
                >
                    {children}
                </Table>
            </div>
        </StyledUniversalDiv>
    );
};

export const defaultTableTheme: Partial<TableTheme> = {
    color: "#4B5563",
    backgroundColor: "#E5E7EB",
    borderColor: "#E5E7EB",
    py: 3,
    px: 4,
    my: 2,
    mx: 0,
    // palette: "light",
    isBordered: true,
    isStriped: true,
    isSpreadsheet: false,
    borderRadius: 1,
    width: 1,
    // combineColumnsRowIndex: 2,
    // combineCellsNumber: 2,
};

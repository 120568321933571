import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    ContentBlock,
    ModelVariable,
    Prompt,
    PromptType,
    Round,
    TimeHorizon,
    User,
    Option,
    RoundShape,
    ContentBlockShape,
    PromptShape,
    ContentBlockTemplateShape,
    Tenant,
    TeamShape,
    UserShape,
} from "../models";
import { SapienPageProps } from "../inertia-utils/types";
import { ErrorBag, Errors } from "@inertiajs/core";

type Photo = {
    id: number;
    width: number;
    height: number;
    url: string;
    photographer: string;
    photographer_url: string;
    photographer_id: string;
    avg_color: string;
    src: {
        original: string;
        large2x: string;
        large: string;
        medium: string;
        small: string;
        portait: string;
        landscape: string;
        tiny: string;
    };
    liked: boolean;
    alt: string;
};


export type PromptWithRoundData = Prompt & {
    options: Option & { id: string };
    rounds: { title: string; weight: number };
};

export type ModalPageProps = {
    DynamicContentModal?: {
        timeHorizons: TimeHorizon[];
        modelVariables: ModelVariable[];
        contentBlock: ContentBlock;
        cancelRoute: string;
    };
    ParticipantLinkModal?: {
        link: string;
        user: User;
        cancelRoute: string;
    };
    QuestionModal?: {
        prompt: Prompt;
        allPrompts: PromptWithRoundData[];
        contentBlockId: string;
        promptType: PromptType;
        rounds: Round[];
        afterSaveRoute: string | null;
    };
    TimelineQuestionModal?: {
        prompt: PromptShape;
        contentBlockId: string;
        afterSaveRoute: string | null;
    };
    DragAndDropQuestionModal?: {
        prompt: PromptShape;
        contentBlockId: string;
        afterSaveRoute: string | null;
    };
    ContentBlockModal?: {
        modalContentBlockId: string;
        afterSaveRoute: string | null;
    };
    NotificationModal?: {
        notificationModalContentBlockId: string;
    };
    BehaviorModal?: {
        behaviorContentBlockId: string;
        afterSaveRoute: string | null;
    };
    MovePageModal?: {
        pageId: string;
        rounds: RoundShape[];
    };
    CardDeckModal?: {
        cardDeck: ContentBlockShape;
        cancelRoute: string;
    };
    ImageModal?: {
        imageUrl: string;
        imageContentBlock?: ContentBlockShape;
        backgroundImageContentBlock?: ContentBlockShape;
        apiResponse?: {
            photos: Photo[];
            nextPage: string;
            prevPage: string;
            totalResults: number;
            page: number;
        };
    };
    VideoModal?: {
        imageUrl: string;
        videoContentBlock: ContentBlockShape;
        apiResponse?: {
            photos: Photo[];

            nextPage: string;
            prevPage: string;
            totalResults: number;
            page: number;
        };
    };
    DynamicValidationRuleModal?: {
        contentBlock: ContentBlockShape;
        modelVariables: ModelVariable[];
        timeHorizons: TimeHorizon[];
    };
    ContentBlockTemplateModal?: {
        contentBlockId: string;
    };
    AddFromTemplateModal?: {
        parentContentBlockId: string | null;
        weight: number | null;
        templates: {
            user: ContentBlockTemplateShape[];
            simulation: ContentBlockTemplateShape[];
        };
        afterSaveRoute: string;
        roundId: string;
        replacedContentBlock?: ContentBlockShape;
        tenant?: Tenant;
    };
    PublishTemplateModal?: {
        templateToPublish?: ContentBlockTemplateShape;
    };
    AddContentBlockModal?: {
        contentBlockId: string;
        position: "before" | "after";
        templates: {
            user: ContentBlockTemplateShape[];
            simulation: ContentBlockTemplateShape[];
        };
        cancelRoute: string;
    };
    ChartWizard?: {
        parentContentBlockId: string;
        chart?: ContentBlockShape;
    };
    TableWizard?: {
        parentContentBlockId: string;
        table?: ContentBlockShape;
    };
    MoveParticipantModal?: {
        cancelRoute: string;
        teams: TeamShape[];
        participant: UserShape;
    };
    DynamicContentHideRevealModal?: {};
};

// Create an enum based on the keys of MyType
type ModalComponentEnum = keyof ModalPageProps;

// Create a mapped type to get the value types
type ModalComponentMap = {
  [K in ModalComponentEnum]: ModalPageProps[K];
};

export type ModalComponent = keyof ModalComponentMap;

type ModalComponentSliceState = {
    modalComponent: ModalComponent | null;
    modalPageProps: ModalPageProps | null;
    modalPageErrors: Errors & ErrorBag;
};

const initialState: ModalComponentSliceState = {
    modalComponent: null,
    modalPageProps: null,
    modalPageErrors: null,
};

export type ModalProps<T extends ModalComponent> = Partial<SapienPageProps> & {
    modalComponent?: ModalComponent | null;
    modalPageProps?: ModalPageProps | null;
    errors?: Errors & ErrorBag;
};

const modalComponentSlice = createSlice({
    name: "ModalComponentState",
    initialState,
    reducers: {
        setModalProps(
            state,
            {
                payload,
            }: PayloadAction<ModalProps<typeof payload.modalComponent> | null>
        ) {
            state.modalPageProps = payload.modalPageProps;
            state.modalComponent = payload.modalComponent;
            state.modalPageErrors = payload.errors;
        },
    },
});

export const modalComponentStoreReducer = modalComponentSlice.reducer;

export const { setModalProps } = modalComponentSlice.actions;

import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { DraggableAttributes } from "@dnd-kit/core";
type FunctionRenderer = (param: {
    listeners: SyntheticListenerMap;
    attributes: DraggableAttributes;
    isDragging: boolean;
}) => React.ReactNode;

export function SortableItem({
    id,
    children,
    disabled = false,
    dragHandleSelector,
}: {
    id: string;
    children: React.ReactNode | React.ReactNode[] | FunctionRenderer;
    disabled?: boolean;
    dragHandleSelector?: string;
}) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id, disabled });
    const style = {
        transform: isDragging
            ? CSS.Translate.toString({ ...transform })
            : CSS.Transform.toString({ ...transform }),
        transition,
    };

    if ("function" === typeof children) {
        return (
            <div ref={setNodeRef} style={style}>
                {children({ attributes, listeners, isDragging })}
            </div>
        );
    }

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {children}
        </div>
    );
}

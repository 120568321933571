import React from "react";
import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
} from "@headlessui/react";
import { CheckIcon } from "lucide-react";

const VariableMultiSelectComponent = ({
    variables,
    selectedVariables,
    handleSetVariables,
    isDisabled,
}: {
    variables: string[];
    selectedVariables: string[];
    handleSetVariables: (selectedVariables: string[]) => void;
    isDisabled: boolean;
}) => {
    return (
        <Listbox
            value={selectedVariables || []}
            onChange={(newVariables) => {
                handleSetVariables(newVariables);
            }}
            disabled={isDisabled}
            multiple
        >
            <div
                className="flex min-w-64 items-center rounded-md border border-slate-200
                    hover:border-slate-300 dark:border-slate-800 dark:hover:border-slate-700"
            >
                <ListboxButton
                    className="h-full w-full rounded-md bg-slate-50/50 px-3 py-1.5 hover:bg-slate-100/25
                        dark:bg-slate-800/20 dark:hover:bg-slate-800/25"
                >
                    <div className="font-light">Select Variables</div>
                </ListboxButton>
            </div>
            <ListboxOptions
                anchor="bottom start"
                className="scrollbar-thin scrollbar-track-slate-800 scrollbar-thumb-slate-700/75
                    scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                    scrollbar-corner-rounded-full [--anchor-gap:4px]"
            >
                {variables.map((variable) => (
                    <ListboxOption
                        key={variable}
                        value={variable}
                        className="group flex gap-3 bg-slate-900 p-2 text-sm text-white data-[focus]:bg-slate-700"
                        disabled={isDisabled}
                    >
                        <CheckIcon className="invisible size-5 text-blue-600 group-data-[selected]:visible" />
                        {variable}
                    </ListboxOption>
                ))}
            </ListboxOptions>
        </Listbox>
    );
};

export const VariableMultiSelect = React.memo(VariableMultiSelectComponent);

const SubsectionWrapperComponent = ({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) => {
    return (
        <div className="w-full pt-2">
            <div className="pb-4 text-base font-semibold">{title}</div>
            {children}
        </div>
    );
};

export const SubsectionWrapper = React.memo(SubsectionWrapperComponent);

export const FilterMultiSelect = ({
    items,
    itemDisplayLabels,
    selectedItems,
    handleSetItems,
    isDisabled,
}: {
    items: string[];
    itemDisplayLabels: string[];
    selectedItems: string[];
    handleSetItems: (selectedItems: string[]) => void;
    isDisabled: boolean;
}) => {
    return (
        <Listbox
            value={selectedItems || []}
            onChange={(newItems) => {
                handleSetItems(newItems);
            }}
            disabled={isDisabled}
            multiple
        >
            <div
                className="flex items-center rounded-md border border-slate-200 hover:border-slate-300
                    dark:border-slate-800 dark:hover:border-slate-700"
            >
                <ListboxButton
                    className="h-full w-full rounded-md bg-slate-50/50 px-3 py-1.5 hover:bg-slate-100/25
                        dark:bg-slate-800/20 dark:hover:bg-slate-800/25"
                >
                    <div className="font-light">Select</div>
                </ListboxButton>
            </div>
            <ListboxOptions
                anchor="bottom start"
                className="scrollbar-thin scrollbar-track-slate-800 scrollbar-thumb-slate-700/75
                    scrollbar-track-rounded-full scrollbar-thumb-rounded-full
                    scrollbar-corner-rounded-full [--anchor-gap:4px]"
            >
                {items.map((item, i) => (
                    <ListboxOption
                        key={item}
                        value={item}
                        className="group flex gap-3 bg-slate-950 p-2 text-sm text-white data-[focus]:bg-slate-800"
                        disabled={isDisabled}
                    >
                        <CheckIcon className="invisible size-5 text-blue-600 group-data-[selected]:visible" />
                        {itemDisplayLabels?.length > i
                            ? itemDisplayLabels[i]
                            : item}
                    </ListboxOption>
                ))}
            </ListboxOptions>
        </Listbox>
    );
};

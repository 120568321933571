import React from "react";
import { VictoryTooltipProps } from "victory";
import { abbrNum } from "./chart-util";
import { FormattedChartValue } from "./SharedTypes";

type ActivePoint = {
    chartType: "line" | "area";
    childName: string;
    continuous?: boolean;
    eventKey: number;
    label: string;
    theme: { stroke: string; strokeWidth: number };
    x: number;
    y: number;
    _voronoiX: number;
    _voronoiY: number;
    _x: number;
    _y: number;
    displayText?: string;
};
type SapienVictoryTooltipProps = Omit<
    VictoryTooltipProps,
    "text" | "activePoints" | "datum"
> & {
    text?: string[];
    activePoints?: ActivePoint[];
    displayText?: string;
    datum?: FormattedChartValue & {
        _voronoiX: number;
        _voronoiY: number;
        _x: number;
        _y: number;
    };
    color?: string;
    tooltipBackgroundColor?:string
};

export default function ResponsiveToolTip(props: SapienVictoryTooltipProps) {
    const { activePoints, x, height, text, tooltipBackgroundColor } = props;
    if (!activePoints || !activePoints?.length) return null;

    const longestText = text.reduce((acc, curr) => {
        if (curr.length * 20 > acc) return curr.length * 20;
        return acc;
    }, 0);

    const width = Math.min(longestText + 20, 250);
    //350 is the width of the chart. Why? Ask Formidable.
    function getXPosition(x: number, width: number, maxWidth = 350) {
        if (x - width < 0) return x + 15;
        if (x + width > maxWidth) return x - width + 45;

        return x;
    }

    const xPosition = getXPosition(x, width);

    const filteredActivePoints: ActivePoint[] = activePoints.reduce(
        (carry, point) => {
            if (
                carry.some(
                    (existingPoint) => existingPoint.label === point.label
                )
            )
                return carry;
            return [...carry, point];
        },
        []
    );

    return (
        <g style={{ pointerEvents: "none" }}>
            <foreignObject
                x={props.center.x - 10}
                y={50}
                height={height - 100}
                width={20}
                className="bg-gray-500/20"
            />
            <foreignObject x={xPosition} y={50} className="w-full h-96">
                <div
                    style={{
                        width,
                        fontFamily: "Inter",
                        backgroundColor: tooltipBackgroundColor || "#ffffffe6",
                    }}
                    className="flex flex-col w-auto flex-wrap p-2 items-center truncate max-w-[12rem] text-xs border rounded-sm shadow-sm"
                >
                    {filteredActivePoints.map((point, idx) => (
                        <div
                            key={idx}
                            className="whitespace-normal w-full text-xs truncate flex items-center space-x-1"
                        >
                            <div
                                className="w-2 h-2 rounded-full"
                                style={{
                                    backgroundColor:
                                        point.theme?.stroke || "black",
                                }}
                            ></div>
                            <p className=" text-xs">
                                {point.label}:{" "}
                                <span className="font-bold">
                                    {point?.displayText || abbrNum(point.y, 1)}
                                </span>
                            </p>
                        </div>
                    ))}
                </div>
            </foreignObject>
        </g>
    );
}

import { useFindContentBlockMethods } from "@/hooks";
import { ContentBlock, ContentBlockType } from "@/models";
import { useEffect, useState } from "react";
const cameraIcon =
    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/camera-icon.png";
const chartIcon =
    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/chart-icon.png";
const tableIcon =
    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/table-icon.png";
const linkIcon =
    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/link-icon.png";
const buttonIcon =
    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/button-icon.png";
const textIcon =
    "https://sapien-static-assets.nyc3.cdn.digitaloceanspaces.com/fallback/text-icon.png";

export function useWireframe() {
    const { getContentBlock } = useFindContentBlockMethods();

    const [wireframeElement, setWireframeElement] =
        useState<HTMLElement | null>(null);
    const [element, setElement] = useState<HTMLElement | null>(null);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (element && height && width) {
            const wireframe = createWireframe(
                element,
                element,
                getContentBlock as (id: string) => ContentBlock,
                width / element.clientWidth,
                true,
                width,
                height
            );
            if (!wireframeElement) {
                setWireframeElement(wireframe);
            }
        }
    }, [element, width, height, getContentBlock]);

    return { wireframeElement, setHeight, setWidth, setElement };
}

function setBackground(
    element: HTMLElement,
    url: string,
    width: number,
    height: number
) {
    element.style.backgroundImage = `url(${url})`;
    element.style.backgroundSize =
        Math.min(width, height) > 500 ? "50%" : "contain";
    element.style.backgroundRepeat = "no-repeat";
    element.style.backgroundPosition = "center";
}

function getContentBlockContent(
    contentBlock: ContentBlock,
    element: HTMLElement,
    width: number,
    height: number
) {
    switch (contentBlock.content_block_type) {
        case ContentBlockType["Rich Text"]:
            setBackground(element, textIcon, width, height);
            element.style.backgroundSize = "auto";
            element.style.backgroundRepeat = "no-repeat";
            element.style.backgroundPositionY = "5px";
            element.style.backgroundPositionX = "10px";
            element.style.minHeight = "15px";
            break;
        case ContentBlockType.Link:
            setBackground(element, linkIcon, width, height);
            break;
        case ContentBlockType.Button:
            setBackground(element, buttonIcon, width, height);
            break;
        case ContentBlockType.Chart:
            setBackground(element, chartIcon, width, height);
            break;

        case ContentBlockType.Image:
            setBackground(element, cameraIcon, width, height);
            break;
        case ContentBlockType.Table:
            setBackground(element, tableIcon, width, height);
            break;
        case ContentBlockType.Card:
            element.style.overflow = "hidden";
            element.classList.add("rounded-md");
            break;
    }

    return element;
}

const baseContentBlockTypes = [
    ContentBlockType["Rich Text"],
    ContentBlockType.Image,
    ContentBlockType.Table,
    ContentBlockType.Chart,
    ContentBlockType.Link,
    ContentBlockType.Button,
];

function createWireframe(
    element: HTMLElement,
    outermostElement: HTMLElement,
    getContentBlock: (id: string) => ContentBlock | undefined,
    scalingFactor = 1,
    isOutermost = false,
    width = 300,
    height = 300,
    depth = 0,
): HTMLElement {
    const div = document.createElement("div");

    const newHeight = element.clientWidth * scalingFactor;
    const styles = window.getComputedStyle(element);
    div.style.display = styles.display;
    div.style.flexDirection = styles.flexDirection;
    div.style.alignItems = styles.alignItems;
    div.style.justifyContent = styles.justifyContent;
    div.style.flexWrap = styles.flexWrap;
    let contentBlock: ContentBlock | undefined;

    if (element.hasAttribute("data-content-block-id")) {
        contentBlock = getContentBlock(
            element.getAttribute("data-content-block-id")
        );
    }

    if (contentBlock) {
        getContentBlockContent(contentBlock, div, width, height);
        div.setAttribute("data-source-content-block-id", contentBlock.id);
        div.setAttribute(
            "data-source-content-type",
            contentBlock.content_block_type
        );
    }
    if (isOutermost) {
        div.style.height = `${height}px`; // Scale height
        div.style.width = `${element.clientWidth * scalingFactor}px`; // Scale height
        div.style.overflow = "hidden"; // Hide any overflow
        div.style.position = "relative"; // Make sure children are positioned relative to this div
        div.classList.add("bg-gray-900");
        div.id = "wireframe";
        if(!div.style.padding) {
            div.style.padding = "1rem";
        }
        div.style.minHeight = "200px"
    } else {
        const left = parseInt(styles.marginLeft) * scalingFactor;
        const top = parseInt(styles.marginTop) * scalingFactor;
        const scaledHeight = element.clientHeight * scalingFactor;

        div.style.marginLeft = `${left * scalingFactor}px`;
        div.style.marginTop = `${top + 1}px`;
        div.style.width = newHeight - 2 + "px";
        div.style.height = `${scaledHeight}px`;
        div.style.borderRadius = element.style.borderRadius;
        div.classList.add("bg-white/10");
        div.style.borderRadius = element.style.borderRadius;
        // div.style.border = "1px solid #000";
        // div.style.padding = "10px";

        // ... rest of the code ...
    }

    if (element.style.backgroundImage && !div.style.backgroundImage) {
        div.style.backgroundImage = element.style.backgroundImage;
        div.style.backgroundPosition = element.style.backgroundPosition;
        div.style.backgroundRepeat = element.style.backgroundRepeat;
        div.style.backgroundSize = "cover";
        div.style.overflow = "hidden";
    }

    if (
        depth <= 8 &&
        newHeight > 5 &&
        (!contentBlock ||
            !baseContentBlockTypes.includes(contentBlock.content_block_type))
    ) {
        // div.classList.add("border-gray-100");
        // div.style.borderWidth = "1px";
        Array.from(element.children).forEach((child) => {
            if (child.id === "content-block-edit-popover") return;
            const childElement = createWireframe(
                child as HTMLElement,
                outermostElement,
                getContentBlock,
                scalingFactor,
                false,
                width,
                height,
                depth + 1
            );
            div.appendChild(childElement);
        });
    }

    return div;
}

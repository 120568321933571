import React from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import { useContentBlockState } from "@/Pages/ELearning/LessonDesign/useContentBlockState";
import { Switch } from "@/components/Switch";
import { useSaveContentBlock } from "@/Pages/ELearning/LessonDesign/content-block-management/useSaveContentBlock";
import { TableIcon } from "lucide-react";

export default function THSwitch({ tr }: { tr: ContentBlockShape }) {
    const { mutateAsync } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    return (
        <div
            className="flex items-center space-x-2 rounded-md px-2 py-1 transition-all
                hover:bg-slate-100"
        >
            <label className="flex items-center space-x-2">
                <TableIcon className="h-4 w-4" />
                <span>Header Row</span>
            </label>
            <Switch
                className="data-[state=checked]:bg-sapien-blue data-[state=unchecked]:bg-slate-200"
                checked={tr.contentBlocks.some(
                    (block) =>
                        block.content_block_type ===
                        ContentBlockType["Table Header Cell"],
                )}
                onCheckedChange={(checked) => {
                    const newTr = {
                        ...tr,
                        contentBlocks: tr.contentBlocks.map((td) => ({
                            ...td,
                            content_block_type: !checked
                                ? ContentBlockType["Table Data Cell"]
                                : ContentBlockType["Table Header Cell"],
                        })),
                    };
                    setContentBlock(newTr);
                    mutateAsync(newTr);
                }}
            />
        </div>
    );
}

import React, { useMemo, useState } from "react";
import {
    DndContext,
    closestCenter,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
    DragStartEvent,
    DragEndEvent,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";

type Props = {
    onSortEnd: (oldPosition: number, newPosition: number) => void;
    children: React.ReactNode[] | React.ReactNode[][];
    dragHandleSelector?: string;
    disabled: boolean;
    items?: { [index: string | number]: { id: string | number } };
};

export default function SortableWrapper({
    onSortEnd,
    children,
    dragHandleSelector,
    disabled = false,
    items,
}: Props) {
    const [childItems, setChildItems] = useState(
        [...children].reduce((acc, child, i) => ({ ...acc, [i]: child }), {})
    );

    const objectItems = useMemo(() => {
        return Object.values(items);
    }, [items]);

    const [activeId, setActiveId] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    if (objectItems?.length) {
        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCorners}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >
                <SortableContext
                    items={objectItems}
                    strategy={verticalListSortingStrategy}
                >
                    {children}
                </SortableContext>
                <DragOverlay>
                    {activeId ? (
                        <SortableItem key={activeId} id={activeId}>
                            <div className="bg-white/10 p-4 shadow-lg rounded-md z-50 -mt-16 -ml-2">
                                {children[activeId]}
                            </div>
                        </SortableItem>
                    ) : null}
                </DragOverlay>
            </DndContext>
        );
    } else {
        //should be a keyed map and we can use keys below in the SortableItem

        return (
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleChildItemDragEnd}
                onDragStart={handleDragStart}
            >
                <SortableContext
                    items={Object.keys(childItems)}
                    strategy={verticalListSortingStrategy}
                >
                    {Object.keys(childItems).map((key) => (
                        <SortableItem
                            key={key}
                            id={key}
                            dragHandleSelector={dragHandleSelector}
                            disabled={disabled}
                        >
                            {children[key]}
                        </SortableItem>
                    ))}
                </SortableContext>
                <DragOverlay>
                    {activeId ? (
                        <SortableItem key={activeId} id={activeId}>
                            {children[activeId]}
                        </SortableItem>
                    ) : null}
                </DragOverlay>
            </DndContext>
        );
    }

    function handleDragStart(event: DragStartEvent) {
        setActiveId(event.active.id);
    }

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = objectItems.findIndex(
                (item) => item.id === active.id
            );
            const newIndex = objectItems.findIndex(
                (item) => item.id === over.id
            );
            onSortEnd(oldIndex, newIndex);
            // setObjectItems((objectItems) => arrayMove(objectItems, oldIndex, newIndex));
        }
        setActiveId(null);
    }

    function handleChildItemDragEnd(event) {
        const { active, over } = event;
        if (active.id !== over.id) {
            setChildItems((items) => {
                const oldIndex = Object.keys(items).indexOf(active.id);
                const newIndex = Object.keys(items).indexOf(over.id);
                onSortEnd(oldIndex, newIndex);
                return arrayMove(Object.keys(items), oldIndex, newIndex);
            });
        }
    }
}

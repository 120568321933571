import React from "react";
import { ContentBlockShape, ContentBlockType } from "@/models";
import ColorSelector from "@/components/rich-text/ColorSelector";
import { useUpdateStyles } from "../../../content-block-management/useSaveContentBlock";
import { useContentBlockState } from "../../../useContentBlockState";
import ButtonGroup from "../../ButtonGroup";
import TableBorders, { TailwindThemeContentBlockShape } from "./TableBorders";
import { cnToArray } from "@/util";

function findTd(contentBlock: ContentBlockShape): ContentBlockShape {
    if (
        contentBlock.content_block_type === ContentBlockType["Table Data Cell"]
    ) {
        return contentBlock;
    }
    return contentBlock.contentBlocks?.map(findTd).find((td) => td);
}

function getRowNumbersToStyle(
    table: ContentBlockShape,
    rows: "even" | "odd",
): { hasTHEAD: boolean; indexes: number[] } {
    const hasTHEAD =
        table.contentBlocks?.[0]?.content_block_type ===
        ContentBlockType["Table Head"];

    const hasTHs =
        table.contentBlocks?.[0]?.contentBlocks[0]?.contentBlocks?.some(
            (block) =>
                block.content_block_type ===
                ContentBlockType["Table Header Cell"],
        );

    let start = hasTHs ? 1 : 0;

    if (hasTHEAD) start = 0;
    if (rows === "even") {
        return {
            hasTHEAD,
            indexes:
                table.contentBlocks?.[0]?.contentBlocks[0]?.contentBlocks
                    ?.map((_, i) => i + start)
                    .filter((i) => i % 2 === 0) || [],
        };
    }
    return {
        hasTHEAD,
        indexes:
            table.contentBlocks?.[0]?.contentBlocks[0]?.contentBlocks
                ?.map((_, i) => i + start)
                .filter((i) => i % 2 !== 0) || [],
    };
}

export function TableSpacingDropdown({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const table = contentBlock.contentBlocks?.find(
        (child) => child.content_block_type === ContentBlockType.Table,
    );

    const td = findTd(table);
    const { mutateAsync, isPending } = useUpdateStyles();
    const { setContentBlock } = useContentBlockState();

    return (
        <div
            className={`mt-4 flex flex-col gap-4 ${isPending && "cursor-wait"}`}
        >
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Cell Padding
                </label>
                <ButtonGroup
                    selectedValue={(td?.theme?.tailwindClasses as string[])
                        ?.filter((className) => className.includes("p-"))
                        .join(" ")}
                    values={[
                        { label: "XS", value: "p-1" },
                        { label: "S", value: "p-2" },
                        { label: "M", value: "p-4" },
                        { label: "L", value: "p-8" },
                        { label: "XL", value: "p-12" },
                    ]}
                    handleChange={(value) => {
                        const updatedTable = {
                            ...table,
                            contentBlocks: table.contentBlocks.map((child) => {
                                return {
                                    ...child,
                                    contentBlocks: child.contentBlocks.map(
                                        (tr) => {
                                            return {
                                                ...tr,
                                                contentBlocks:
                                                    tr.contentBlocks.map(
                                                        (td) => {
                                                            return {
                                                                ...td,
                                                                theme: {
                                                                    ...td.theme,
                                                                    tailwindClasses:
                                                                        [
                                                                            ...(
                                                                                td
                                                                                    .theme
                                                                                    ?.tailwindClasses ||
                                                                                []
                                                                            ).filter(
                                                                                (
                                                                                    className,
                                                                                ) =>
                                                                                    !className.includes(
                                                                                        "p-",
                                                                                    ),
                                                                            ),
                                                                            value,
                                                                        ],
                                                                },
                                                            };
                                                        },
                                                    ),
                                            };
                                        },
                                    ),
                                };
                            }),
                        };
                        setContentBlock(updatedTable);
                        mutateAsync(updatedTable);
                    }}
                />
            </div>
            <TableBorders
                table={table as TailwindThemeContentBlockShape}
                td={td as TailwindThemeContentBlockShape}
                handleChange={(updatedTable) => {
                    setContentBlock(updatedTable);
                    mutateAsync(updatedTable);
                }}
            />
            <div className="flex flex-col gap-2 pb-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Border Radius
                </label>
                <ButtonGroup
                    selectedValue={(table.theme?.tailwindClasses as string[])
                        ?.filter((className) => className.includes("rounded"))
                        .join(" ")}
                    values={[
                        { label: "None", value: "rounded-0" },
                        { label: "S", value: "rounded-sm" },
                        { label: "M", value: "rounded-md" },
                        { label: "L", value: "rounded-lg" },
                    ]}
                    handleChange={(value) => {
                        const updatedTable = {
                            ...table,
                            theme: {
                                ...table.theme,
                                tailwindClasses: cnToArray([
                                    ...table.theme?.tailwindClasses,
                                    value as string,
                                ]),
                            },
                        };
                        setContentBlock(updatedTable);
                        mutateAsync(updatedTable);
                    }}
                />
            </div>
        </div>
    );
}

export function TableColorStyling({
    contentBlock,
}: {
    contentBlock: ContentBlockShape;
}) {
    const table = contentBlock.contentBlocks?.find(
        (child) => child.content_block_type === ContentBlockType["Table"],
    );

    const { mutateAsync } = useUpdateStyles();
    const { setContentBlock } = useContentBlockState();

    return (
        <div className="mt-4 flex flex-col gap-4">
            <div className="flex flex-col gap-2 shadow-sm">
                <div className="flex items-center gap-2 py-2 shadow-sm">
                    <div className="flex h-9 w-9 items-center justify-center overflow-hidden rounded-full">
                        <ColorSelector
                            selectedColor={
                                table?.theme?.borderColor || "#eeeeee"
                            }
                            selectColor={(borderColor, color) => {
                                const updatedTable = {
                                    ...table,
                                    theme: {
                                        ...table.theme,
                                        borderColor,
                                        borderColorId: color.id,
                                    },
                                };
                                setContentBlock(updatedTable);
                                mutateAsync(updatedTable);
                            }}
                        />
                    </div>
                    <label htmlFor="accordionSpacing" className="text-xs">
                        Border Color
                    </label>
                </div>
            </div>
            <div className="flex flex-col gap-2 shadow-sm">
                <label htmlFor="accordionSpacing" className="text-xs">
                    Table Striping
                </label>
                <div className="flex flex-col space-y-2 p-4">
                    <div className="flex items-center gap-2 py-2 shadow-sm">
                        <div className="flex h-9 w-9 items-center justify-center overflow-hidden rounded-full">
                            <ColorSelector
                                selectedColor={
                                    table?.contentBlocks?.[0]?.contentBlocks[0]
                                        ?.theme?.backgroundColor || "white"
                                }
                                selectColor={(backgroundColor, color) => {
                                    const updatedTable = {
                                        ...table?.contentBlocks?.[0]
                                            ?.contentBlocks[0],
                                        theme: {
                                            ...table.theme,
                                            backgroundColor,
                                            backgroundColorId: color.id,
                                        },
                                    };
                                    setContentBlock(updatedTable);
                                    mutateAsync(updatedTable);
                                }}
                            />
                        </div>
                        <label htmlFor="accordionSpacing" className="text-xs">
                            Header Background
                        </label>
                    </div>

                    {table?.contentBlocks?.[0]?.contentBlocks[1] && (
                        <div className="flex items-center gap-2 py-2 shadow-sm">
                            <div className="flex h-9 w-9 items-center justify-center overflow-hidden rounded-full">
                                <ColorSelector
                                    selectedColor={
                                        table?.contentBlocks?.[0]
                                            ?.contentBlocks[1]?.theme
                                            ?.backgroundColor || "white"
                                    }
                                    selectColor={(backgroundColor, color) => {
                                        const { indexes, hasTHEAD } =
                                            getRowNumbersToStyle(table, "odd");

                                        const updatedTable = {
                                            ...table?.contentBlocks?.[0],
                                            contentBlocks:
                                                table?.contentBlocks?.[
                                                    hasTHEAD ? 1 : 0
                                                ]?.contentBlocks.map(
                                                    (tr, i) => {
                                                        if (
                                                            !indexes.includes(i)
                                                        )
                                                            return tr;
                                                        return {
                                                            ...tr,
                                                            theme: {
                                                                ...table.theme,
                                                                backgroundColor,
                                                                backgroundColorId:
                                                                    color.id,
                                                            },
                                                        };
                                                    },
                                                ),
                                        };
                                        setContentBlock(updatedTable);
                                        mutateAsync(updatedTable);
                                    }}
                                />
                            </div>
                            <label
                                htmlFor="accordionSpacing"
                                className="text-xs"
                            >
                                Odd Rows
                            </label>
                        </div>
                    )}
                    {!!table?.contentBlocks?.[0]?.contentBlocks[2] && (
                        <div className="flex items-center gap-2 py-2 shadow-sm">
                            <div className="flex h-9 w-9 items-center justify-center overflow-hidden rounded-full">
                                <ColorSelector
                                    selectedColor={
                                        table?.contentBlocks?.[0]
                                            ?.contentBlocks[2]?.theme
                                            ?.backgroundColor || "white"
                                    }
                                    selectColor={(backgroundColor, color) => {
                                        const { indexes, hasTHEAD } =
                                            getRowNumbersToStyle(table, "even");

                                        const updatedTable = {
                                            ...table?.contentBlocks?.[0],
                                            contentBlocks:
                                                table?.contentBlocks?.[
                                                    hasTHEAD ? 1 : 0
                                                ]?.contentBlocks.map(
                                                    (tr, i) => {
                                                        if (
                                                            !indexes.includes(i)
                                                        )
                                                            return tr;
                                                        return {
                                                            ...tr,
                                                            theme: {
                                                                ...table.theme,
                                                                backgroundColor,
                                                                backgroundColorId:
                                                                    color.id,
                                                            },
                                                        };
                                                    },
                                                ),
                                        };
                                        setContentBlock(updatedTable);
                                        mutateAsync(updatedTable);
                                    }}
                                />
                            </div>
                            <label
                                htmlFor="accordionSpacing"
                                className="text-xs"
                            >
                                Even Rows
                            </label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

import React, { useMemo, useState } from "react";
import { VictoryPie } from "victory";
import { defaultChartContainerTheme } from "@/styles";
import { FormattedChartValue, SharedChartProps } from "./SharedTypes";
import {
    abbrNum,
    getAverage,
    getDomain,
    getXOffset,
    getYOffset,
    reduceOpacity,
} from "./chart-util";
import Legend from "./Legend";

// function AxisLabel({ x, y, text }: { text?: string; x?: number; y?: number }) {
//     return (
//         <g fontSize={10}>
//             <foreignObject
//                 x={x - 25}
//                 y={y - 25}
//                 width={100}
//                 height={100}
//                 style={{ pointerEvents: "none" }}
//             >
//                 <div className="flex flex-wrap text-xs">{text}</div>
//             </foreignObject>
//         </g>
//     );
// }
// export type AreaOrLineSeriesTheme = {
//     interpolation?: InterpolationPropType;
//     stroke?: string;
//     strokeWidth?: number;
//     // labelProps can be used to styled the VictoryLabel
//     labelProps?: {
//         x: number;
//         y: number;
//     };
// } & Partial<ThemeObject>;

// export type ChartAxisTheme = {
//     tickValues?: number[];
//     dependentAxis?: boolean;
//     tickPrefix?: string;
//     tickSuffix?: string;
// } & Partial<ThemeObject>;

type PieOrDonutChartProps = SharedChartProps & {
    onClick?: (clickedId: string) => void;
    theme: typeof defaultChartContainerTheme;
    hideLegend?: boolean;
};
const LabelLine = (props: {
    cx: number;
    cy: number;
    midAngle: number;
    middleRadius: number;
    radius: number;
}) => {
    const { cx, cy, midAngle, middleRadius, radius } = props;
    const xStart = cx + getXOffset(radius, midAngle);
    const yStart = cy + getYOffset(radius, midAngle);

    const offSetMiddle = 2 * radius - middleRadius;
    const xMiddle = cx + getXOffset(offSetMiddle, midAngle);
    const yMiddle = cy + getYOffset(offSetMiddle, midAngle);

    const offSetEnd = 2.5 * radius - middleRadius;
    const xEnd = cx + getXOffset(offSetEnd, midAngle);

    return (
        <polyline
            style={{
                opacity: "0.3",
                fill: "none",
                stroke: "black",
                strokeWidth: "1px",
                strokeDasharray: "1",
            }}
            points={`${xStart},${yStart} ${xMiddle},${yMiddle} ${xEnd},${yMiddle}`}
        />
    );
};
function PieLabel(props: {
    text?: string;
    x?: number;
    y?: number;
    index?: number;
    datum?: any;
    innerRadius?: number;
    radius?: number;
    slice?: { startAngle: number; endAngle: number };
    nameKey?: string;
    valueKey?: string;
    cx?: number;
    cy?: number;
    data?: FormattedChartValue[];
}) {
    const {
        index,
        datum,
        innerRadius,
        radius,
        slice: { startAngle, endAngle },
        cx,
        cy,
        data,
    } = props;

    // calculation
    const middleRadius = getAverage([innerRadius, radius]);
    const midAngle = getAverage([endAngle, startAngle]);
    // const labelOffset = radius + middleRadius / 2.7;
    // const x = cx + getXOffset(labelOffset, midAngle);
    // const y = cy + getYOffset(labelOffset, midAngle);

    const labelOffset =
        innerRadius > 10
            ? 2.2 * radius - middleRadius
            : 2 * radius - middleRadius;
    const tempX = cx + getXOffset(labelOffset, midAngle);
    const showLines = radius - innerRadius > 0;

    const y = cy + getYOffset(labelOffset, midAngle) - 2;

    const textAnchor = cx < tempX ? "start" : "end";
    const x = cx < tempX ? tempX + 15 : tempX - 25;

    return (
        <g>
            <text x={x} y={y} textAnchor={textAnchor} fill={datum?.theme?.fill}>
                {data[index]?.displayText || abbrNum(datum.y, 0)}
            </text>
            {showLines && (
                <LabelLine
                    cx={cx}
                    cy={cy}
                    middleRadius={middleRadius}
                    radius={radius}
                    midAngle={midAngle}
                />
            )}
        </g>
    );
}

export function PieOrDonutChart({
    theme,
    formattedChartValues,
    hideLegend,
}: PieOrDonutChartProps) {
    const yDomain = useMemo(() => {
        return getDomain(formattedChartValues, "y");
    }, [formattedChartValues]);

    const height = useMemo(() => {
        if (theme?.height) {
            return theme?.height as number;
        }
        if (yDomain?.length === 2) {
            const minHeight = Math.min(
                Math.abs(yDomain[1] - yDomain[0]) * 6,
                300,
            );
            return Math.max(minHeight, 200);
        }
        return 300;
    }, [yDomain, theme]);

    const centerX = 200;
    const centerY = height / 2;

    const [filteredSeriesIndices, setFilteredSeriesIndices] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(-1);

    const values = useMemo(() => {
        return Object.keys(formattedChartValues)
            .filter((_, i) => !filteredSeriesIndices.includes(i))
            .reduce((acc, key, i) => {
                const series = formattedChartValues[key];
                if (!series?.length) return acc;
                if (hoveredIndex === i && series[0]?.theme) {
                    return [
                        ...acc,
                        {
                            ...series[0],
                            theme: {
                                ...series[0]?.theme,
                                stroke: reduceOpacity(
                                    series[0]?.theme?.fill,
                                    0.8,
                                ),
                                strokeWidth: 4,
                            },
                        },
                    ];
                }
                return [...acc, series[0]];
            }, [] as FormattedChartValue[]);
    }, [formattedChartValues, filteredSeriesIndices, hoveredIndex]);

    const colorScale = useMemo(() => {
        return values.map((v) => v.theme?.fill);
    }, [values]);

    const radius = useMemo(() => {
        const valueWithRadius = values.find(
            (value) => value.theme?.innerRadius,
        );
        if (valueWithRadius) return valueWithRadius.theme.innerRadius;
        if (values.some((value) => value.chartType === "doughnut")) {
            return values[0]?.theme?.radius || 55;
        }
        return 2;
    }, [values, formattedChartValues]);

    const padAngle = useMemo(() => {
        return values[0]?.theme?.padAngle || 2;
    }, [values, formattedChartValues]);

    const cornerRadius = useMemo(() => {
        return values[0]?.theme?.cornerRadius || 3;
    }, [values, formattedChartValues]);

    return (
        <div className="flex flex-col rounded-md border px-2 lg:items-center">
            <VictoryPie
                padAngle={padAngle}
                cornerRadius={cornerRadius}
                data={values}
                colorScale={colorScale}
                height={height}
                innerRadius={radius}
                animate={{
                    duration: 20,
                    onLoad: { duration: 1000 },
                }}
                style={{
                    data: {
                        fill: ({ datum }) => datum?.theme?.fill,
                        stroke: ({ datum }) => datum?.theme?.stroke,
                        strokeWidth: ({ datum }) =>
                            datum?.theme?.strokeWidth === 4 ? 4 : 0,
                    },
                }}
                labelComponent={
                    <PieLabel
                        innerRadius={radius}
                        cx={centerX}
                        cy={centerY}
                        radius={75}
                    />
                }
                standalone={true}
            />
            {!hideLegend && (
                <Legend
                    formattedChartValues={formattedChartValues}
                    filteredIndices={filteredSeriesIndices}
                    setFilteredSeriesIndices={setFilteredSeriesIndices}
                    setHoveredIndex={setHoveredIndex}
                    hoveredIndex={hoveredIndex}
                    color="fill"
                />
            )}
        </div>
    );
}

import React from "react";
import { ColorPickerPopover } from "../color-picker/ColorPickerPopover";
import { Editor } from "@tiptap/core";

export default function ColorButton({
    color,
    editor,
    optionallySelectAll,
}: {
    color: string;
    editor: Editor;
    optionallySelectAll: (editor: Editor) => void;
}) {
    const gradientAngleClass = editor
        .getAttributes("textStyle")
        ?.customClasses?.find((className) =>
            className.includes("gradient-to-"),
        );

    const value = !!gradientAngleClass
        ? `${editor.getAttributes("textStyle")?.color || "black"} ${gradientAngleClass}`
        : editor.getAttributes("textStyle")?.color || "black";
        
    return (
        <>
            <ColorPickerPopover
                value={value}
                onChange={(color) => {
                    optionallySelectAll(editor);
                    editor
                        .chain()
                        .focus()
                        .setCustomClasses([])
                        .setCustomStyles({})
                        .setColor(color)
                        .run();
                }}
                onGradientChange={(gradient) => {
                    editor.chain().focus().setColor("").run();
                    const customClasses = [
                        "text-transparent",
                        "bg-clip-text",
                        `from-[var(--from-color)]`,
                        `to-[var(--to-color)]`,
                        `bg-gradient-to-${gradient.angle}`,
                        `${
                            gradient.designColors.length > 2
                                ? `via-[var(--via-color)]`
                                : ""
                        }`,
                    ];

                    editor
                        .chain()
                        .focus()
                        .setCustomClasses(customClasses)
                        .run();
                    editor
                        .chain()
                        .focus()
                        .setCustomStyles({
                            "--from-color":
                                gradient.designColors?.[0]?.css_color,
                            "--to-color":
                                gradient.designColors?.[
                                    gradient.designColors.length - 1
                                ]?.css_color,
                            "--via-color":
                                gradient.designColors?.length > 2
                                    ? gradient.designColors?.[1]?.css_color
                                    : "",
                            color: "transparent",
                        })
                        .run();
                }}
                gradientColors={[
                    editor.getAttributes("textStyle")?.customStyles?.[
                        "--from-color"
                    ],
                    editor.getAttributes("textStyle")?.customStyles?.[
                        "--via-color"
                    ],
                    editor.getAttributes("textStyle")?.customStyles?.[
                        "--to-color"
                    ],
                ].filter(Boolean)}
            />
        </>
    );
}

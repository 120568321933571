import { useNotificationStore } from "@/hooks/useNotificationStore";
import { sapienAxios, SapienInertia } from "@/inertia-utils/hooks";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useMutation } from "@tanstack/react-query";

export type ColorThemeType = {
    name: string;
    colors: string[];
};

const themes: ColorThemeType[] = [
    {
        name: "Deloitte",
        colors: [
            "#FFFFFF", // White
            "#C4D600", // Pantone 382
            "#0097A9", // Pantone 7711
            "#012169", // Pantone 280
            "#75787B", // Pantone Cool Gray 9
            "#43B02A", // Pantone 361
            "#62B5E5", // Pantone 2915
            "#D0D0CE", // Pantone Cool Gray 2
            "#53565A", // Pantone Cool Gray 11
            "#046A38", // Pantone 349
            "#00A3E0", // Pantone 299
            "#BBBCBC", // Pantone Cool Gray 4
        ],
    },
    {
        name: "Fun and Professional",
        colors: [
            "#4ABDAC", // Mint
            "#FC4A1A", // Vermilion
            "#F7B733", // Sunshine
            "#DFDCE3", // Light Gray
        ],
    },
    {
        name: "Neon Tones and Sharp Contrast",
        colors: [
            "#0E0B16", // Black
            "#A239CA", // Magenta
            "#4717F6", // JEWEL
            "#E7DFDD", // STARK
        ],
    },
    {
        name: "Warm and Bold",
        colors: [
            "#000000", // BLACK
            "#062F4F", // INK
            "#813722", // POSY
            "#B82601", // EMBERS
        ],
    },
    {
        name: "Clean and Highlighted",
        colors: [
            "#CAEBF2", // SKY
            "#A9A9A9", // CARBON
            "#FF3B3F", // WATERMELON
            "#EFEFEF", // NEUTRAL
        ],
    },
    {
        name: "Warm Tones",
        colors: [
            "#D7CEC7", // GRAIN
            "#565656", // BLACKBOARD
            "#76323F", // OXBLOOD
            "#C09F80", // TAN
        ],
    },
    {
        name: "Sharp and Modern",
        colors: [
            "#3CC47C", // ELECTRIC
            "#1E39SA", // FOREST
            "#E9C893", // Light
            "#828081", // TIN
        ],
    },
    {
        name: "Bold and Punchy",
        colors: [
            "#E24E42", // PAPAYA
            "#E98000", // MUSTARD
            "#E86E80", // BLUSH
            "#008F95", // AQUA
        ],
    },
    {
        name: "Art-History Inspired",
        colors: [
            "#FFCE00", // UNFLOWERS
            "#0375B4", // STARRY NIGHT
            "#007849", // IRISES
            "#262228", // EVENING
        ],
    },
    {
        name: "Tints and Tones",
        colors: [
            "#94618E", // GRAPE
            "#49274A", // EGGPLANT
            "#F4DECB", // SAND
            "#F8EEE7", // SHELL
        ],
    },
    {
        name: "Splash of Color",
        colors: [
            "#4484CE", // CERULEAN
            "#D9D9D9", // ALUMINUM
            "#F9CF00", // LIGHT
            "#F19F4D", // TANGERINE
        ],
    },
    {
        name: "Elegant and Sophisticated",
        colors: [
            "#083C5D", // PRUSSIAN BLUE
            "#328CC1", // SKY BLUE
            "#D9B310", // GOLD LEAF
            "#1D2731", // IVORY BLACK
        ],
    },
    {
        name: "Summer Inspired",
        colors: [
            "#286DA8", // SUMMER SKY
            "#CD5360", // WARM PEACH
            "#B37D4E", // FIELD
            "#438496", // BREEZE
        ],
    },
    {
        name: "Professional and Modern",
        colors: [
            "#C2C1C0", // SCREEN
            "#0A1612", // STEEL
            "#1A2930", // DEMIN
            "#F7CE3E", // MARIGOLD
        ],
    },
];

export function useColorThemes() {
    const { setNotification } = useNotificationStore();
    const saveColors = (simulationId: string, theme: ColorThemeType) =>
        SapienInertia.post("creator.design.color-themes.store", {
            simulationId,
            colors: theme.colors,
        } as (typeof LaravelRequestBodyShapes)["creator.design.color-themes.store"]);

    const { mutateAsync: saveColor } = useMutation({
        mutationFn: async ({
            simulationId,
            color,
        }: {
            simulationId: string;
            color: string;
        }) => {
            const response = await sapienAxios.post(
                "creator.design.color-themes.add-color",
                {
                    simulationId,
                    color,
                } as (typeof LaravelRequestBodyShapes)["creator.design.color-themes.add-color"],
            );
            setNotification({
                text: "Color added to theme",
                backgroundColor: "green",
                autoClose: 5000,
            });
            return response.data;
        },
    });
        

    const { mutateAsync: saveGradient } = useMutation({
        mutationFn: async ({
            simulationId,
            colors,
            angle,
        }: {
            simulationId: string;
            colors: string[];
            angle: string;
        }) => {
            const response = await sapienAxios.post(
                "creator.design.color-themes.save-gradient",
                {
                    simulationId,
                colors,
                angle,
            }as (typeof LaravelRequestBodyShapes)["creator.design.color-themes.save-gradient"]);
            setNotification({
                text: "Gradient added to theme",
                backgroundColor: "green",
                autoClose: 5000,
            });
            return response.data;
        },
    });

    return { themes, saveColors, saveColor, saveGradient };
}

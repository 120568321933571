import { useEffect, useMemo, useState } from "react";
import { router } from "@inertiajs/react";
import { atom, useAtom } from "jotai";
import { Lesson } from "../../types";

export const transitionDirectionAtom = atom("up");

const waitForRender = async (query: string) => {
    let element: HTMLElement = await new Promise((resolve) => {
        let timeOut = 100;
        let timesRun = 0;
        function getElement() {
            const element = document.querySelector(query) as HTMLElement;
            if (element) {
                resolve(element);
            } else {
                timesRun += 1;
                timeOut = Math.min(timesRun * 100, 2000);

                // Set timeout isn't a must but it
                // decreases number of recursions
                setTimeout(() => {
                    requestAnimationFrame(getElement);
                }, timeOut);
            }
        }

        getElement();
    });

    return element;
};

export default (
    lesson: Lesson,
    { onEnter = "", onLeave = "" } = {
        onEnter: "slide-in",
        onLeave: "slide-out",
    },
) => {
    const [leaving, setLeaving] = useState(false);
    const [direction] = useAtom(transitionDirectionAtom);

    useEffect(() => {
        const removeStartListener = router.on("start", (e) => {

            if (e.detail.visit.method !== 'get') {
                return;
            }

            setLeaving(true);
        });
        const removeFinishListener = router.on("finish", async (page) => {
            console.log("ROUTER FINISH");


            // scrollContainer?.classList.remove("with-transition");

            const rendered = await waitForRender(
                `[data-content-block-id="${lesson.parentContentBlocks?.[0]?.id}"`,
            );
            if (!!rendered) {
                console.log("RENDERED", rendered);
            }

            setLeaving(() => false);

            const prevLink = await waitForRender(
                ".previous-lesson-link",
            );

            if (prevLink) {
                console.log(
                    "prev link",
                    prevLink,
                    prevLink.getBoundingClientRect().top,
                );

                window.scrollTo({
                    top: 60,
                    // behavior: "smooth",
                });
            }
        });
        return () => {
            removeFinishListener();
            removeStartListener();
        };
    }, [lesson]);

    const className = useMemo(() => (leaving ? onLeave : onEnter), [leaving]);

    return {
        className,
        leaving,
        direction,
    };
};

import React, { FC, ReactNode } from "react";
import { isCurrent, sapienRoute, usePage } from "@/inertia-utils/hooks";
import { SubNavLink } from "./SubNavLink";
import { Icon } from "react-icons-kit";
import { sliders } from "react-icons-kit/feather/sliders";

export const ModelDashboardTopNav: FC<{
    title: string;
    children?: ReactNode;
}> = ({ title, children }) => {
    const { props } = usePage();
    return (
        <div className="flex flex-row items-center space-x-8">
            {/* <div>
                <span className="text-xl font-semibold text-slate-900">
                    {title}
                </span> */}
            {/* {isCurrent("model-dashboard.index", props.route) ? (
                    <span className="text-xl font-semibold text-slate-900">
                        {title}
                    </span>
                ) : (
                    <Link href={sapienRoute("model-dashboard.index")}>
                        <div className="text-xl font-semibold text-slate-900 hover:underline">
                            {title}
                        </div>
                    </Link>
                )} */}
            {/* </div> */}
            <div className="flex w-full flex-wrap justify-between gap-2 text-sm font-semibold">
                <div className="flex gap-2">
                    <SubNavLink
                        href={sapienRoute("model-dashboard.index")}
                        active={isCurrent("model-dashboard.index", props.route)}
                    >
                        <span className={"mr-2"}>
                            <Icon
                                icon={sliders}
                                size={14}
                                style={{
                                    display: "flex",
                                }}
                            />
                        </span>
                        <span>Overview</span>
                    </SubNavLink>
                </div>
                {/* <div className="flex gap-2">
                    <SubNavLink
                        href={sapienRoute("data-studio.index")}
                        active={isCurrent("data-studio.index", props.route)}
                    >
                        <span className={"mr-2"}>
                            <SparklesIcon className="h-5 w-5" />
                        </span>
                        <span>Data Studio</span>
                        <span className={"ml-2 opacity-75"}>
                            <ArrowUpRightIcon className="h-3 w-3" />
                        </span>
                    </SubNavLink>
                </div> */}

                {/* <SubNavLink
                    href={sapienRoute("model-builder.structure.index", {
                        simulationSlug: slug,
                    })}
                    active={isCurrent(
                        "model-builder.structure.index",
                        props.route,
                    )}
                >
                    <span className={"mr-2"}>
                        <Icon
                            icon={sliders}
                            size={14}
                            style={{
                                display: "flex",
                            }}
                        />
                    </span>
                    <span>Structure</span>
                </SubNavLink> */}
                {/* <SubNavLink
                    href={sapienRoute("model-builder.wizard.index", {
                        simulationSlug: slug,
                    })}
                    active={isCurrent(
                        "model-builder.wizard.index",
                        props.route,
                    )}
                >
                    <span className={"mr-2"}>
                        <Icon
                            icon={box}
                            size={14}
                            style={{
                                display: "flex",
                            }}
                        />
                    </span>
                    <span>Wizard</span>
                </SubNavLink> */}
            </div>
        </div>
    );
};

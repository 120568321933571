import React, { useCallback, useEffect, useMemo } from "react";
import {
    ContentBlockShape,
    DependentOption,
    ModelVariable,
    TimeHorizon,
} from "@/models";
import { Option, DynamicValidationRule } from "@/models";
import { useFormatVariableValue } from "@/hooks";
import { ModelDataSelectionForm } from "@/components/admin-components";
import { useMutation } from "@tanstack/react-query";
import { saveDynamicValidationRule } from "./saveDynamicValidationRule";

type Props = {
    form: ContentBlockShape;
    modelVariables: ModelVariable[];
    timeHorizons: TimeHorizon[];
    closeModal: () => void;
};

type State = {
    selectedOptions: DependentOption[];
    validationRule:
        | (DynamicValidationRule & {
              rule_type: "sum";
              rule_position: "before" | "after";
              model_variable_id: string;
              model_variable_time_horizon_id: string;
          })
        | null;
    form: ContentBlockShape;
    rule_position: "before" | "after";
    sum: number;
};

const initialState: State = {
    selectedOptions: [],
    validationRule: null,
    form: null,
    rule_position: "before",
    sum: 0,
};

type Action =
    | { type: "HANDLE_OPTION_CHANGE"; option: Option }
    | {
          type: "SET_VALIDATION_RULE";
          rule: DynamicValidationRule & {
              rule_type: "sum";
              rule_position: "before" | "after";
              model_variable_id: string;
              model_variable_time_horizon_id: string;
          };
      }
    | { type: "SET_FORM"; form: ContentBlockShape }
    | { type: "SET_RULE_POSITION"; rule_position: "before" | "after" };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "HANDLE_OPTION_CHANGE":
            const isSelected = !!state.selectedOptions.find(
                (option) => option.id === action.option.id,
            );

            if (isSelected) {
                return {
                    ...state,
                    selectedOptions: state.selectedOptions.filter(
                        (option) => option.id !== action.option.id,
                    ),
                };
            }
            return {
                ...state,
                selectedOptions: [
                    ...state.selectedOptions,
                    {
                        ...action.option,
                        relation_type: "parent",
                        dynamic_validation_rule_id: state.validationRule.id,
                        dynamic_validation_rule_option_id: "",
                    },
                ],
            };
        case "SET_VALIDATION_RULE":
            return { ...state, validationRule: action.rule };
        case "SET_RULE_POSITION":
            return { ...state, rule_position: action.rule_position };
        case "SET_FORM":
            const validationRule = (action.form?.dynamicValidationRules?.find(
                (rule) => rule.rule_type === "sum",
            ) as DynamicValidationRule & {
                rule_type: "sum";
                rule_position: "before" | "after";
                model_variable_id: string;
                model_variable_time_horizon_id: string;
            }) || {
                rule_type: "sum",
                model_variable_id: null,
                model_variable_time_horizon_id: null,
                options: [],
                label: "",
                rule_position: "before",
            };
            return {
                ...state,
                form: action.form,
                validationRule,
                selectedOptions: validationRule.options,
            };
    }
};

type DynamicValidationFormError = {
    response: {
        data: {
            errors: {
                label: string;
                model_variable_id: string;
            };
        };
    };
};

function round(amount: number, decimals: number = 0) {
    return Number(
        Math.round(Number(`${amount}e${decimals}`)) + `e-${decimals}`,
    );
}

export default function DynamicValidationQuantityForm({
    form,
    modelVariables,
    timeHorizons,
    closeModal,
}: Props) {
    const formatVariableValue = useFormatVariableValue();

    const [{ validationRule, selectedOptions, rule_position }, dispatch] =
        React.useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: "SET_FORM", form });
    }, [form]);

    const {
        mutate,
        error,
    } = useMutation({
        mutationFn: saveDynamicValidationRule,
        onSuccess: ({ contentBlock }) => {
            // updateActiveContentBlock(contentBlock);
            // gotContentBlock(contentBlock);
            // queryClient.invalidateQueries({
            //     queryKey: ["contentBlockById"],
            // });
            closeModal();
        },
    });

    const formErrors = useMemo(() => {
        if (!error)
            return {
                response: {
                    data: {
                        errors: {
                            label: "",
                            model_variable_id: "",
                        },
                    },
                },
            };
        const { response } = error as unknown as DynamicValidationFormError;
        return { response };
    }, [error]);

    const handleSaveValidationRule = useCallback(() => {
        mutate({
            postBody: {
                ...validationRule,
                rule_position,
                formId: form.id,
                options: selectedOptions.map((option) => ({
                    ...option,
                    relation_type: "parent",
                    id: option.id,
                })),
            },
            dynamic_validation: validationRule?.id,
        });
    }, [
        validationRule,
        selectedOptions,
        selectedOptions?.length,
        form,
        rule_position,
    ]);

    const modelVariableText = useMemo(() => {
        if (!validationRule) return "";

        const modelVariable = modelVariables.find(
            (variable) =>
                variable.id ===
                (
                    validationRule as {
                        rule_type: "sum";
                        model_variable_id: string;
                    }
                ).model_variable_id,
        );

        if (!modelVariable || !modelVariable.baseVariableValues) return "";

        return formatVariableValue(
            modelVariable.unit,
            modelVariable.baseVariableValues[0].numerical_value,
            modelVariable.is_integer,
        );
    }, [validationRule, modelVariables, modelVariables?.length]);

    if (!validationRule) return <></>;

    return (
        <div className="flex flex-col space-y-4 p-4">
            <div className="flex flex-col space-y-8 border-b border-[#374151] pb-8 pt-6">
                <div
                    className={`flex flex-col gap-2 ${
                        !!formErrors.response.data.errors.model_variable_id
                            ? "*:text-pink-400"
                            : ""
                    }`}
                >
                    <label
                        className={`px-[16px] ${
                            !!formErrors.response.data.errors.model_variable_id
                                ? "*:border *:border-pink-400 "
                                : ""
                        }`}
                    >
                        Select a model variable to use as a quantity
                    </label>
                    <div className="-my-4">
                        <ModelDataSelectionForm
                            modelVariables={modelVariables}
                            selectedModelVariables={modelVariables.filter(
                                (modelVariable) =>
                                    modelVariable.id ===
                                    validationRule.model_variable_id,
                            )}
                            label={"Select Model Variable"}
                            setSelectedModelVariables={(modelVariables) => {
                                dispatch({
                                    type: "SET_VALIDATION_RULE",
                                    rule: {
                                        ...validationRule,
                                        rule_type: "sum",
                                        model_variable_id: modelVariables[0].id,
                                        rule_position,
                                        model_variable_time_horizon_id:
                                            validationRule.model_variable_time_horizon_id,
                                    },
                                });
                            }}
                            timeHorizons={timeHorizons}
                            setSelectedTimeHorizons={(selectedTimeHorizons) => {
                                if (validationRule.model_variable_id) {
                                    dispatch({
                                        type: "SET_VALIDATION_RULE",
                                        rule: {
                                            ...validationRule,
                                            model_variable_time_horizon_id:
                                                selectedTimeHorizons[0]?.id ||
                                                timeHorizons[0].id,
                                        },
                                    });
                                }
                            }}
                            selectedTimeHorizons={timeHorizons.filter(
                                (timeHorizon) =>
                                    timeHorizon.id ===
                                    validationRule.model_variable_time_horizon_id,
                            )}
                            isSingleModelVariableSelection={true}
                            isSingleTimeHorizonSelection={true}
                            hasError={
                                !!formErrors.response.data.errors
                                    .model_variable_id
                            }
                        />
                    </div>
                    {!!formErrors.response.data.errors.model_variable_id && (
                        <p className="px-[16px] text-sm text-pink-600">
                            Please select a model variable
                        </p>
                    )}
                </div>
                <div className="flex flex-col gap-2 px-[16px]">
                    <div>
                        <div>
                            <label>
                                Should the text before or after the quantity
                            </label>
                            <p className="mt-0 text-xs text-gray-400">
                                i.e. Budget Remaining: $50m vs $50m Budget
                                Remaining
                            </p>
                        </div>
                        <select
                            onChange={(e) => {
                                dispatch({
                                    type: "SET_RULE_POSITION",
                                    rule_position: e.target.value as
                                        | "before"
                                        | "after",
                                });
                            }}
                            className="block w-full max-w-xl rounded-md border-gray-300 bg-white/10 text-gray-200
                                focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm"
                        >
                            <option className="bg-[#29303e]" value="before">
                                Before
                            </option>
                            <option className="bg-[#29303e]" value="after">
                                After
                            </option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-col space-y-2 px-[16px]">
                    <label>Validation Text</label>{" "}
                    <div
                        className={`flex flex-col justify-center gap-2 ${
                            rule_position === "before"
                                ? "flex-row"
                                : "flex-row-reverse"
                        } ${
                            !!formErrors.response.data.errors.label
                                ? "text-pink-400"
                                : ""
                        }`}
                    >
                        {modelVariableText && <p>{modelVariableText}</p>}
                        <input
                            className={`block w-full rounded-md bg-white/10 text-gray-200 focus:text-white
                            focus:outline-offset-0 focus:outline-[#1d4ed8] sm:text-sm ${
                                !!formErrors.response.data.errors.label
                                    ? "border-pink-600"
                                    : "border-gray-300"
                            }`}
                            type="text"
                            value={validationRule.label}
                            onChange={(e) => {
                                dispatch({
                                    type: "SET_VALIDATION_RULE",
                                    rule: {
                                        ...validationRule,
                                        label: e.target.value,
                                    },
                                });
                            }}
                        />
                        {!!formErrors.response.data.errors.label && (
                            <p className="text-sm text-pink-600">
                                Please enter validation text
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <h6 className="p-2 text-lg">Select options</h6>
                <div className="flex flex-col space-y-2">
                    {form?.contentBlocks?.map((contentBlock) => {
                        return (
                            <div
                                key={contentBlock.id}
                                className="mb-2 flex flex-col space-y-2 border-t border-[#374151] p-2"
                            >
                                <h6 className="text-sm">
                                    {contentBlock.prompt.content}
                                </h6>
                                <div className="flex flex-col space-y-2 px-2 text-sm">
                                    {contentBlock.prompt.options.map(
                                        (option) => {
                                            return (
                                                <div
                                                    className="relative flex items-start"
                                                    key={option.id}
                                                >
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            id={option.id}
                                                            aria-describedby={
                                                                option.content
                                                            }
                                                            name={option.id}
                                                            checked={
                                                                !!selectedOptions.find(
                                                                    (
                                                                        selectedOption,
                                                                    ) =>
                                                                        selectedOption.id ===
                                                                        option.id,
                                                                )
                                                            }
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            onChange={() => {
                                                                dispatch({
                                                                    type: "HANDLE_OPTION_CHANGE",
                                                                    option: option as Option,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="ml-3 flex w-full justify-between text-sm leading-6">
                                                        <label
                                                            htmlFor={option.id}
                                                            className="font-medium text-white"
                                                        >
                                                            {option.content}
                                                        </label>
                                                        <p className="flex gap-2 text-gray-500">
                                                            <span>
                                                                Numeric value:
                                                            </span>
                                                            <span className="font-bold">
                                                                {round(
                                                                    option.numerical_value,
                                                                )}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="grid gap-6 border-t border-[#374151] pt-6 md:grid-cols-2">
                <div className="flex items-center justify-center">
                    <button
                        data-testid="close-dynamic-validation-rule-modal"
                        onClick={closeModal}
                        className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5
                            text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none
                            focus:ring-2 focus:ring-blue-300"
                    >
                        Cancel
                    </button>
                </div>
                <div className="flex items-center justify-center">
                    <button
                        data-testid="save-dynamic-validation-rule-modal"
                        onClick={handleSaveValidationRule}
                        className="inline-flex w-full items-center justify-center rounded-full bg-blue-700 px-5
                            py-2.5 text-center text-sm font-medium text-white transition-all
                            hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300
                            disabled:cursor-not-allowed disabled:opacity-75"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

import AddContentBlockButton from "./AddContentBlockButton";
import {
    FlipHorizontal,
    ImageIcon,
    Table,
    TypeIcon,
    VideoIcon,
} from "lucide-react";
import React from "react";
import { contentBlockFunctionMap } from "./content-block-management/content-block-functions/contentBlockFunctionMap";
import CreateQuestionButton from "./content-block-management/CreateQuestionButton";
import CreateDividerButton from "./content-block-management/CreateDividerButton";
import AccordionOrTabsCreator from "./content-block-management/AccordionOrTabsCreator";
import CreateChartButton from "./content-block-management/CreateChartButton";
import { useContentBlockState } from "./useContentBlockState";

type Props = {
    hasBlocks: boolean;
    roundId: string;
    index?: number;
};

export default function AddContentBlockWidget({
    hasBlocks,
    roundId,
    index,
}: Props) {
    const { parentContentBlocks } = useContentBlockState();
    const weight =
        "undefined" !== typeof index ? index : parentContentBlocks.length;
    return (
        <div className="flex max-w-6xl flex-col py-6">
            {!hasBlocks && (
                <div className="flex w-full items-center justify-center p-6">
                    <p className="text-2xl">
                        Add a content block to get started
                    </p>
                </div>
            )}
            <div className="flex space-x-2">
                {/* <div>
                    <button className="h-16 w-16">All blocks {weight}</button>
                </div> */}
                <div className="flex rounded-md border border-dashed border-slate-500 p-2">
                    <AddContentBlockButton
                        buildContentBlock={() =>
                            contentBlockFunctionMap.createTextBlock(
                                roundId,
                                weight,
                            )
                        }
                    >
                        <TypeIcon className="h-6 w-6" />
                        <p>Text</p>
                    </AddContentBlockButton>
                    <AddContentBlockButton
                        buildContentBlock={() =>
                            contentBlockFunctionMap.createImageBlock(
                                roundId,
                                weight,
                            )
                        }
                    >
                        <ImageIcon className="h-6 w-6" />
                        <p>Image</p>
                    </AddContentBlockButton>
                    <AddContentBlockButton
                        buildContentBlock={() =>
                            contentBlockFunctionMap.createVideoBlock(
                                roundId,
                                weight,
                            )
                        }
                    >
                        <VideoIcon className="h-6 w-6" />
                        <p>Video</p>
                    </AddContentBlockButton>
                    <CreateChartButton roundId={roundId} weight={weight} />
                    <AddContentBlockButton
                        buildContentBlock={() =>
                            contentBlockFunctionMap.createTableBlock(
                                roundId,
                                weight,
                            )
                        }
                    >
                        <Table className="h-6 w-6" />
                        <p>Table</p>
                    </AddContentBlockButton>
                    <CreateQuestionButton roundId={roundId} weight={weight} />
                    <AddContentBlockButton
                        buildContentBlock={() =>
                            contentBlockFunctionMap.createFlipCardBlock(
                                roundId,
                                weight,
                            )
                        }
                    >
                        <FlipHorizontal className="h-6 w-6" />
                        <p>Flash Cards</p>
                    </AddContentBlockButton>
                    <CreateDividerButton roundId={roundId} weight={weight} />
                    <AccordionOrTabsCreator roundId={roundId} weight={weight} />
                </div>
            </div>
        </div>
    );
}

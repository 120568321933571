import React, { useCallback, useState } from "react";
import { useEditedContentBlock } from "../../useEditedContentBlock";
import { AsideContents } from "../../Aside";
import { ContentBlockShape, ContentBlockType } from "@/models";
import {
    useChildContentBlocksOfType,
    useContentBlockState,
    useGetContentBlockWithChildren,
} from "../../../useContentBlockState";
import { useSaveContentBlock } from "../../../content-block-management/useSaveContentBlock";
import { createFlipCard } from "../../../content-block-management/content-block-functions/createFlipCard";
import { PlusIcon, Trash } from "lucide-react";
import DeleteContentBlockOverlay from "../../DeleteContentBlockOverlay";
import ImageModal from "@/Pages/Admin/editor/uploads/ImageModal";


function FlipCardSideEditor({
    flipCardSide,
    index,
}: {
    flipCardSide: ContentBlockShape;
    index: number;
}) {
    const [editedBlock, setEditedBlock] = useState(flipCardSide);

    // const variant: "text" | "image-full" | "image-top" =
    //     editedBlock.theme?.variant || "text";

    const side = index % 2 === 0 ? "Front" : "Back";

    const { mutateAsync, isPending } = useSaveContentBlock();
    const { setContentBlock } = useContentBlockState();

    const saveAndUpdate = useCallback(
        async (contentBlock: ContentBlockShape) => {
            setContentBlock(contentBlock);
            return mutateAsync(contentBlock);
        },
        [editedBlock.id],
    );

    // const updateRichText = useCallback(
    //     (richText: RichText | App.Data.RichTextData) => {
    //         const rich_text = richText as RichText;
    //         saveAndUpdate({
    //             ...editedBlock,
    //             rich_text,
    //         });
    //     },
    //     [editedBlock.id],
    // );

    return (
        <div className="relative flex flex-col gap-2">
            <div className="flex justify-between">
                <h3 className="font-bold">{side} of Card</h3>
            </div>

            <div
                key={editedBlock.id}
                className="mt-4 flex justify-between space-y-2"
            >
                <div className="flex items-center gap-2">
                    {editedBlock.theme?.variant !== "text" &&
                        !!editedBlock.theme?.backgroundImage && (
                            <button
                                className="rounded-md border border-sapien-blue px-2 py-1 text-sapien-blue"
                                onClick={() => {
                                    saveAndUpdate({
                                        ...editedBlock,
                                        theme: {
                                            ...editedBlock.theme,
                                            variant: "text",
                                        },
                                    });
                                }}
                            >
                                Remove Image
                            </button>
                        )}
                    <ImageModal
                        imageUrl={
                            editedBlock.theme?.variant !== "text"
                                ? editedBlock.theme?.backgroundImage
                                : ""
                        }
                        save={async (backgroundImage) => {
                            const { savedContentBlock } = await saveAndUpdate({
                                ...editedBlock,
                                theme: {
                                    ...editedBlock.theme,
                                    backgroundImage,
                                    variant: "image-full",
                                },
                            });
                            setEditedBlock(savedContentBlock);
                        }}
                        saving={isPending}
                    />
                </div>
            </div>

            {/* <div className="mt-4 flex items-start justify-start rounded-md border border-slate-200 p-6 bg-cover bg-center bg-no-repeat bg-no-repeat">
                <LessonRichText
                    contentBlock={editedBlock}
                    editable={true}
                    content={editedBlock.rich_text}
                    saveOverride={updateRichText}
                    delayMilliseconds={1000}
                />
            </div> */}
        </div>
    );
}

function FlipCardEditor({
    flipCard,
    index,
}: {
    flipCard: ContentBlockShape;
    index: number;
}) {
    const cardSides =
        useGetContentBlockWithChildren(flipCard.id)?.contentBlocks?.sort(
            (a, b) => a.weight - b.weight,
        ) || [];
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <div className="relative flex flex-col gap-4 px-4 py-6 odd:bg-slate-100 even:border">
            {!!isDeleting && (
                <DeleteContentBlockOverlay
                    contentBlock={flipCard}
                    cancelDelete={() => setIsDeleting(false)}
                />
            )}
            <h2 className="flex justify-between text-lg font-bold">
                Card {index + 1}
                <button onClick={() => setIsDeleting(true)}>
                    <span className="sr-only">Delete Card {index + 1}</span>
                    <Trash className="h-6 w-6" />
                </button>
            </h2>

            {cardSides.map((cb, index) => (
                <FlipCardSideEditor
                    key={cb.id}
                    flipCardSide={cb}
                    index={index}
                />
            ))}
        </div>
    );
}

export default function FlashcardSidebar() {
    const { editedContentBlock } = useEditedContentBlock();
    const flipCards = useChildContentBlocksOfType(
        editedContentBlock,
        ContentBlockType["Flip Card"],
    );

    const { mutate } = useSaveContentBlock();
    return (
        <AsideContents title="Flahscards">
            <div className="relative mt-2 flex flex-col pb-24">
                {flipCards?.map((cb, index) => (
                    <FlipCardEditor key={cb.id} flipCard={cb} index={index} />
                ))}
                <div className="fixed bottom-0 left-0 flex w-full max-w-[41.5rem] border-t bg-white p-4">
                    <button
                        className="flex w-full items-center justify-center gap-2 rounded-md border
                            border-sapien-blue bg-white px-4 py-1 text-sapien-blue transition-colors
                            hover:bg-sapien-blue hover:text-white"
                        onClick={() => {
                            const newCard = createFlipCard(
                                editedContentBlock.round_id,
                                editedContentBlock.contentBlocks.length,
                            );
                            console.log("new card", newCard);
                            mutate({
                                ...newCard,
                                parent_content_block_id: editedContentBlock.id,
                            });
                        }}
                    >
                        <span>Add Card</span>
                        <PlusIcon size={16} />
                    </button>
                </div>
            </div>
        </AsideContents>
    );
}

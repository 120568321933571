import { Auth } from "@/inertia-utils/types";
import { Team, Tenant, User, UserShape } from "@/models";
import { atom, useAtom } from "jotai";

const authAtom = atom<Auth | false>(false);
const teamAtom = atom<Team | false>(false);
const tenantAtom = atom<Tenant | false>(false);
const userAtom = atom<User | UserShape | false>((get) => {
    const auth = get(authAtom);
    return auth ? auth?.user : false;
});

const isNotParticipantAtom = atom((get) => {
    const user = get(userAtom);
    return (
        user &&
        user.roles &&
        user.roles?.some(
            (role) =>
                role.name !== "Participant" &&
                role.name !== "Unpublished Content Viewer",
        )
    );
});

const isSapienSuperAdminAtom = atom((get) => {
    const user = get(userAtom);
    return (
        user &&
        user.roles &&
        user.roles?.some((role) => role.name == "Sapien Super Admin")
    );
});

export const useAuthStore = (auth?: Auth) => {
    const [_auth, setAuth] = useAtom(authAtom);

    if (auth) {
        setAuth(auth);
    }

    const signedIn = (auth: Auth) => setAuth(auth);
    const signedOut = () => setAuth(false);

    const [_user] = useAtom(userAtom);
    const [team, setTeam] = useAtom(teamAtom);

    const [_tenant, setTenant] = useAtom(tenantAtom);
    const [isNotParticipant] = useAtom(isNotParticipantAtom);
    const [isSapienSuperAdmin] = useAtom(isSapienSuperAdminAtom);

    return {
        user: _user as User | UserShape | null,
        signedIn,
        signedOut,
        auth: _auth as Auth | null,
        setTeam,
        team: team as Team | null,
        tenant: _tenant as Tenant | null,
        setTenant,
        isNotParticipant,
        isSapienSuperAdmin,
    };
};

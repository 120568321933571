import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Head } from "@inertiajs/react";
import { useAuthStore } from "@/hooks/store";
import { usePage } from "@/inertia-utils/hooks";
import { UserNavDropdown } from "@/Layouts/UserNavDropdown";
import { ModelSuiteContainerProps, ModelSuiteTopNav } from "./ModelSuiteTopNav";
import {
    ModelSuiteColorModeButton,
    useModelSuiteTheme,
} from "./ModelSuiteColorModeButton";

const queryClient = new QueryClient();

const ModelSuiteWrapperComponent = ({
    pageHeadTitle,
    children,
    ...modelSuiteContainerProps
}: ModelSuiteContainerProps & {
    pageHeadTitle: string;
    children: React.ReactNode;
}) => {
    const { user, isSapienSuperAdmin, isNotParticipant } = useAuthStore();
    const { props } = usePage();

    const { colorModeClass, setColorModeClass } = useModelSuiteTheme();

    return (
        <QueryClientProvider client={queryClient}>
            <Head title={pageHeadTitle} />
            <div
                className={`model-suite-container ${colorModeClass} flex-flex-col relative h-[100vh]
                overflow-hidden transition-all dark:bg-slate-900`}
            >
                <ModelSuiteTopNav
                    {...modelSuiteContainerProps}
                    currentRoute={props.route}
                    showModelSuiteLinkGroup={isSapienSuperAdmin}
                    showSimWithSubtitle={!!isNotParticipant}
                    colorModeButton={
                        <span className="hidden md:flex">
                            <ModelSuiteColorModeButton
                                colorModeClass={colorModeClass}
                                setColorModeClass={setColorModeClass}
                            />
                        </span>
                    }
                    userNavDropdown={
                        !!user ? (
                            <span className="hidden md:flex">
                                <UserNavDropdown userName={user.name} />
                            </span>
                        ) : (
                            <></>
                        )
                    }
                />
                <div className="model-suite-page flex h-[calc(100vh-44px)]">
                    {children}
                </div>
            </div>
        </QueryClientProvider>
    );
};

export const ModelSuiteWrapper = React.memo(ModelSuiteWrapperComponent);

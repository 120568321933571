import React, { useEffect, useRef, useState } from "react";
import { ContentBlockShape, ContentBlockType, Simulation } from "@/models";
import {
    useRoundFrameProperties,
    useSelectedRoundId,
    useSelectedSimulation,
    useFindContentBlockMethods,
    useModalQueryParams,
    useGotContentBlock,
} from "@/hooks";
import { ContentBlockConfigObject } from "@/model-configs/ContentBlockConfigObject";
import { buildBottomDrawer } from "@/model-configs/content-block-layouts/BottomDrawerLayout";
import { SapienInertia } from "@/inertia-utils/hooks";
import { defaultInboxTheme } from "@/styles";
import { NestedContentBlockLayerDisplay } from "./NestedContentBlockLayerDisplay";
import { PlusIcon } from "@heroicons/react/24/outline";
import { designLayerPropsAtoms } from "../shared-props";
import { useAtom } from "jotai";
import SortableWrapper from "@/modules/sortable-list/SortableWrapper";
import { useReorderLayers } from "./useReorderLayers";
import { SortableItem } from "@/modules/sortable-list/SortableItem";
import {
    CreateContentBlockBody,
    createContentBlock,
} from "../content-block-buttons/create-content-block-button/createContentBlock";
import { useSelectActiveContentBlock } from "@/hooks/activeContentBlock";

const CategoryHeader = ({
    type,
    children,
}: {
    type: string;
    children?: React.ReactNode;
}) => (
    <div className="mt-2 w-full">
        <div className="flex items-center justify-between border-y-[0.5px] border-y-[rgba(255,255,255,0.15)] bg-[rgba(255,255,255,0.03)] px-2 py-1">
            <span className="text-sm font-medium uppercase tracking-wide text-gray-400">
                {type}
            </span>
            {children}
        </div>
    </div>
);

const createNavbar = async (
    selectedRoundId: string,
    selectedSimulation: Partial<Simulation>,
) => {
    const { contentBlock } = await createContentBlock({
        selectedSimulation,
        body: {
            title: `Navbar`,
            content_block_type: ContentBlockType.Navbar,
            content: selectedSimulation?.title
                ? selectedSimulation.title
                : `Navbar Content`,
            weight: 0,
            round_id: selectedRoundId,
            theme: JSON.stringify(
                ContentBlockConfigObject[ContentBlockType.Navbar].defaultTheme,
            ),
            position: "before",
        } as CreateContentBlockBody,
    });

    return contentBlock;
};

const createPage = (
    selectedRoundId: string,
    selectedSimulation: Partial<Simulation>,
) => {
    const page = {
        content_block_type: ContentBlockType.Page,
        weight: 0,
        round_id: selectedRoundId,
        theme: JSON.stringify(
            ContentBlockConfigObject[ContentBlockType.Page].defaultTheme,
        ),
        contentBlocks: [],
        position: "before",
    };

    SapienInertia.post("creator.design.store.page", page, {
        simulationSlug: selectedSimulation.slug,
        roundId: selectedRoundId,
    });
};

const createInbox = async (
    selectedRoundId: string,
    selectedSimulation: Partial<Simulation>,
) => {
    const body = {
        content_block_type: ContentBlockType.Inbox,
        theme: JSON.stringify(defaultInboxTheme),
        weight: 0,
        position: "before",
        round_id: selectedRoundId,
    } as CreateContentBlockBody;

    const inbox = await createContentBlock({
        selectedSimulation,
        body,
    });

    return inbox;
};

const createBottomDrawer = async (
    selectedRoundId: string,
    selectedSimulation: Partial<Simulation>,
) => {
    const bottomDrawer = buildBottomDrawer();

    const contentBlock = await createContentBlock({
        selectedSimulation,
        body: {
            ...bottomDrawer,
            position: "before",
            round_id: selectedRoundId,
        } as CreateContentBlockBody,
    });

    return contentBlock;
};

export const PanelLayers = (props: { currentPageId?: string }) => {
    const { openModal } = useModalQueryParams();
    const { currentPageId } = props;
    const [topLevelContentBlockLayers, setTopLevelContentBlockLayers] = useAtom(
        designLayerPropsAtoms.topLevelContentBlockLayers,
    );

    const { selectedRoundId } = useSelectedRoundId();
    const { hasNavbar, hasBottomDrawer, hasInbox, hasTopDrawer } =
        useRoundFrameProperties();
    const { selectedSimulation } = useSelectedSimulation();
    const { getContentBlockWithChildren } = useFindContentBlockMethods();

    // 'NAVBAR', 'PAGE', 'MODAL', 'INBOX', 'INBOX_MESSAGE', 'BOTTOM_DRAWER'
    const [navbarContentBlock, setNavbarContentBlock] =
        useState<ContentBlockShape>();
    const [inboxContentBlock, setInboxContentBlock] =
        useState<ContentBlockShape>();
    const [bottomDrawerContentBlock, setBottomDrawerContentBlock] =
        useState<ContentBlockShape>();
    const [topDrawerContentBlock, setTopDrawerContentBlock] =
        useState<ContentBlockShape>();
    const [pageContentBlocks, setPageContentBlocks] =
        useState<ContentBlockShape[]>();
    const [modalContentBlocks, setModalContentBlocks] =
        useState<ContentBlockShape[]>();
    const [inboxMessagesContentBlocks, setInboxMessagesContentBlocks] =
        useState<ContentBlockShape[]>();

    useEffect(() => {
        if (topLevelContentBlockLayers?.length) {
            let newNavbar;
            let newInbox;
            let newBottomDrawer;
            let newTopDrawer;
            let newPages = [];
            let newModals = [];
            let newInboxMessages = [];

            topLevelContentBlockLayers.forEach((contentBlock) => {
                switch (contentBlock.content_block_type) {
                    case ContentBlockType.Navbar:
                        newNavbar = contentBlock;
                        break;
                    case ContentBlockType.Inbox:
                        newInbox = contentBlock;
                        break;
                    case ContentBlockType["Bottom Drawer"]:
                        newBottomDrawer = contentBlock;
                        break;
                    case ContentBlockType["Top Drawer"]:
                        newTopDrawer = contentBlock;
                        break;
                    case ContentBlockType.Page:
                        newPages = [...newPages, contentBlock];
                        break;
                    case ContentBlockType.Modal:
                        newModals = [...newModals, contentBlock];
                        break;
                    case ContentBlockType["Inbox Message"]:
                        newInboxMessages = [...newInboxMessages, contentBlock];
                        break;
                }
            });

            setNavbarContentBlock(newNavbar);
            setInboxContentBlock(newInbox);
            setBottomDrawerContentBlock(newBottomDrawer);
            setPageContentBlocks(newPages);
            setModalContentBlocks(newModals);
            setInboxMessagesContentBlocks(newInboxMessages);
            setTopDrawerContentBlock(newTopDrawer);
        }
    }, [topLevelContentBlockLayers]);

    const mutation = useReorderLayers();
    const heightRef = useRef<HTMLDivElement>(null);
    const { gotContentBlock } = useGotContentBlock();
    const { setActiveContentBlock } = useSelectActiveContentBlock();
    // const { toggleLeftMenu } = useEditorMenuSections();

    return (
        <div className="panel-layers -mt-2" data-testid="panel-layers">
            <CategoryHeader type={"NAVBAR"}>
                {hasNavbar === false && (
                    <button
                        type="button"
                        className="inline-flex items-center rounded-full bg-gray-700 p-1 text-center text-sm font-medium text-gray-100 hover:bg-gray-600 focus:outline-none focus:ring-0"
                        onClick={async () => {
                            const contentBlock = await createNavbar(
                                selectedRoundId,
                                selectedSimulation,
                            );
                            gotContentBlock(contentBlock);
                            setActiveContentBlock(contentBlock.id);
                            setTopLevelContentBlockLayers([
                                ...topLevelContentBlockLayers,
                                contentBlock,
                            ]);
                        }}
                    >
                        <PlusIcon className="h-3 w-3" />
                    </button>
                )}
            </CategoryHeader>
            {!!navbarContentBlock && (
                <NestedContentBlockLayerDisplay
                    key={navbarContentBlock.id}
                    contentBlockId={navbarContentBlock.id}
                    contentBlockNested={
                        getContentBlockWithChildren(navbarContentBlock.id) ||
                        navbarContentBlock
                    }
                    ancestorId={navbarContentBlock.id}
                    nestingLevel={0}
                    currentPageId={currentPageId}
                />
            )}
            <CategoryHeader type={"TOP DRAWER"}>
                {hasTopDrawer === false && (
                    <button
                        data-testid="create-top-drawer"
                        type="button"
                        className="inline-flex items-center rounded-full bg-gray-700 p-1 text-center text-sm font-medium text-gray-100 hover:bg-gray-600 focus:outline-none focus:ring-0"
                        onClick={() => {
                            openModal({ modalComponent: "TopDrawerModal" });
                        }}
                    >
                        <PlusIcon className="h-3 w-3" />
                    </button>
                )}
            </CategoryHeader>
            {!!topDrawerContentBlock && (
                <NestedContentBlockLayerDisplay
                    key={topDrawerContentBlock.id}
                    contentBlockId={topDrawerContentBlock.id}
                    contentBlockNested={
                        getContentBlockWithChildren(topDrawerContentBlock.id) ||
                        topDrawerContentBlock
                    }
                    ancestorId={topDrawerContentBlock.id}
                    nestingLevel={0}
                    currentPageId={currentPageId}
                />
            )}
            <CategoryHeader type={"PAGES"}>
                <button
                    type="button"
                    className="inline-flex items-center rounded-full bg-gray-700 p-1 text-center text-sm font-medium text-gray-100 hover:bg-gray-600 focus:outline-none focus:ring-0"
                    onClick={() => {
                        createPage(selectedRoundId, selectedSimulation);
                    }}
                >
                    <PlusIcon className="h-3 w-3" />
                </button>
            </CategoryHeader>

            <div ref={heightRef}>
                {mutation.isPending && (
                    <div className="flex animate-pulse flex-col items-center justify-center space-y-2 p-4">
                        {pageContentBlocks?.map((contentBlock) => (
                            <div
                                key={contentBlock.id}
                                className="w-full rounded-md bg-gray-700 p-4"
                            ></div>
                        ))}
                    </div>
                )}
                {!mutation.isPending && !!pageContentBlocks?.length && (
                    <SortableWrapper
                        onSortEnd={async (oldIndex, newIndex) => {
                            mutation.mutateAsync({
                                page_id: pageContentBlocks[oldIndex]?.id,
                                destination_index: newIndex,
                            });
                        }}
                        disabled={mutation.isPending}
                        dragHandleSelector={".drag-handle"}
                        items={pageContentBlocks.reduce(
                            (carry, contentBlock) => ({
                                ...carry,
                                [contentBlock.weight]: contentBlock,
                            }),
                            {},
                        )}
                    >
                        {pageContentBlocks?.map((contentBlock, idx) => {
                            const nestedBlock =
                                getContentBlockWithChildren(contentBlock.id) ||
                                contentBlock;
                            return (
                                <SortableItem
                                    id={contentBlock.id}
                                    key={contentBlock.id}
                                >
                                    {({
                                        listeners,
                                        attributes,
                                        isDragging,
                                    }) => {
                                        return (
                                            <NestedContentBlockLayerDisplay
                                                key={contentBlock.id}
                                                contentBlockId={contentBlock.id}
                                                contentBlockNested={nestedBlock}
                                                ancestorId={contentBlock.id}
                                                nestingLevel={0}
                                                currentPageId={currentPageId}
                                                listeners={listeners}
                                                attributes={attributes}
                                                isDragging={isDragging}
                                            />
                                        );
                                    }}
                                </SortableItem>
                            );
                        })}
                    </SortableWrapper>
                )}
            </div>

            {!!modalContentBlocks?.length && <CategoryHeader type={"MODALS"} />}
            {!!modalContentBlocks?.length &&
                modalContentBlocks?.map((contentBlock) => (
                    <NestedContentBlockLayerDisplay
                        key={contentBlock.id}
                        contentBlockId={contentBlock.id}
                        contentBlockNested={
                            getContentBlockWithChildren(contentBlock.id) ||
                            contentBlock
                        }
                        ancestorId={contentBlock.id}
                        nestingLevel={0}
                        currentPageId={currentPageId}
                    />
                ))}
            <CategoryHeader type={"INBOX"}>
                {hasInbox === false && (
                    <button
                        type="button"
                        className="inline-flex items-center rounded-full bg-gray-700 p-1 text-center text-sm font-medium text-gray-100 hover:bg-gray-600 focus:outline-none focus:ring-0"
                        onClick={async () => {
                            const { contentBlock: block } = await createInbox(
                                selectedRoundId,
                                selectedSimulation,
                            );
                            gotContentBlock(block);
                            setTopLevelContentBlockLayers([
                                ...topLevelContentBlockLayers,
                                block,
                            ]);
                        }}
                    >
                        <PlusIcon className="h-3 w-3" />
                    </button>
                )}
            </CategoryHeader>
            {!!inboxContentBlock && (
                <NestedContentBlockLayerDisplay
                    key={inboxContentBlock.id}
                    contentBlockId={inboxContentBlock.id}
                    contentBlockNested={
                        getContentBlockWithChildren(inboxContentBlock.id) ||
                        inboxContentBlock
                    }
                    ancestorId={inboxContentBlock.id}
                    nestingLevel={0}
                    currentPageId={currentPageId}
                ><></>
                    {/* <div className="m-3">
                        <button
                            className="inline-flex text-xs items-center p-1 font-medium text-center text-gray-100 rounded-full bg-gray-700 hover:bg-gray-600 focus:ring-0 focus:outline-none"
                            onClick={() =>
                                createInboxMessage(
                                    selectedRoundId,
                                    selectedSimulation,
                                    inboxContentBlock
                                )
                            }
                        >
                            {" "}
                            <PlusIcon className="h-3 w-3" />
                            Add Message
                        </button>
                    </div> */}
                </NestedContentBlockLayerDisplay>
            )}
            {!!inboxMessagesContentBlocks?.length && (
                <CategoryHeader type={"INBOX MESSAGES"} />
            )}
            {!!inboxMessagesContentBlocks?.length &&
                inboxMessagesContentBlocks?.map((contentBlock) => (
                    <NestedContentBlockLayerDisplay
                        key={contentBlock.id}
                        contentBlockId={contentBlock.id}
                        contentBlockNested={
                            getContentBlockWithChildren(contentBlock.id) ||
                            contentBlock
                        }
                        ancestorId={contentBlock.id}
                        nestingLevel={0}
                        currentPageId={currentPageId}
                    />
                ))}
            <CategoryHeader type={"BOTTOM DRAWER"}>
                {hasBottomDrawer === false && (
                    <button
                        type="button"
                        className="inline-flex items-center rounded-full bg-gray-700 p-1 text-center text-sm font-medium text-gray-100 hover:bg-gray-600 focus:outline-none focus:ring-0"
                        onClick={async () => {
                            const { contentBlock } = await createBottomDrawer(
                                selectedRoundId,
                                selectedSimulation,
                            );
                            gotContentBlock(contentBlock);
                            setTopLevelContentBlockLayers([
                                ...topLevelContentBlockLayers,
                                contentBlock,
                            ]);
                        }}
                    >
                        <PlusIcon className="h-3 w-3" />
                    </button>
                )}
            </CategoryHeader>
            {!!bottomDrawerContentBlock && (
                <NestedContentBlockLayerDisplay
                    key={bottomDrawerContentBlock.id}
                    contentBlockId={bottomDrawerContentBlock.id}
                    contentBlockNested={
                        getContentBlockWithChildren(
                            bottomDrawerContentBlock.id,
                        ) || bottomDrawerContentBlock
                    }
                    ancestorId={bottomDrawerContentBlock.id}
                    nestingLevel={0}
                    currentPageId={currentPageId}
                />
            )}
        </div>
    );
};

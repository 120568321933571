import React, { useState } from "react";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";
import DirectionPicker from "../DirectionPicker";
import "./color-maker.css";
import { HashIcon, PlusIcon } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../Tabs";
import { isDark } from "@/tailwind-colors/util";
import { EyeDropperComponent } from "./EyeDropper";

export function ColorPicker({
    value,
    onChange,
    onGradientChange,
    isInGradientMode,
    initialGradient,
}: {
    value: string;
    onChange: (value: string) => void;
    onGradientChange: (value: App.Data.DesignColorGradientData) => void;
    isInGradientMode: boolean;
    initialGradient?: App.Data.DesignColorGradientData;
}) {
    const [gradient, setGradient] = useState(
        initialGradient || {
            id: null,
            name: "",
            angle: "r",
            simulationId: "",
            userId: "",
            designColors: [
                {
                    id: "",
                    css_color: "#ffffff",
                    name: "",
                    user_id: "",
                    simulation_id: "",
                },
                {
                    id: "",
                    css_color: "#000000",
                    name: "",
                    user_id: "",
                    simulation_id: "",
                },
            ],
        },
    );

    const handleGradientAngleChange = (angle: string) => {
        const newGradient = { ...gradient };
        newGradient.angle = angle;
        setGradient(newGradient);
        onGradientChange(newGradient);
    };

    const handleGradientColorChange = (color: string, colorIndex: number) => {
        const newGradient = { ...gradient };
        newGradient.designColors[colorIndex].css_color = color;
        setGradient(newGradient);
        onGradientChange(newGradient);
    };

    const handleColorChange = (color: string, colorIndex?: number) => {
        if (colorIndex !== undefined) {
            handleGradientColorChange(color, colorIndex);
        } else {
            onChange(color);
        }
    };

    return (
        <div className="color-maker">
            {!isInGradientMode && (
                <div className="flex gap-4">
                    <div
                        className={`flex w-full flex-col gap-2 ${isInGradientMode ? "gradient" : ""}`}
                    >
                        <HexAlphaColorPicker
                            className="w-full"
                            color={
                                !!isInGradientMode
                                    ? gradient.designColors[0].css_color
                                    : Array.isArray(value)
                                      ? value[0]
                                      : value
                            }
                            onChange={(color) => {
                                handleColorChange(
                                    color,
                                    !!isInGradientMode ? 0 : undefined,
                                );
                            }}
                            data-testid={`color-picker}`}
                        />
                        <div className="flex items-center gap-2 p-2">
                            <div
                                className="group relative"
                                data-is-gradient={isInGradientMode}
                            >
                                <HashIcon
                                    data-is-gradient={isInGradientMode}
                                    className="absolute left-2 top-1/2 h-4 w-4 text-gray-400 -translate-y-1/2"
                                />
                                <HexColorInput
                                    alpha={true}
                                    color={
                                        !!isInGradientMode
                                            ? gradient.designColors[0].css_color
                                            : Array.isArray(value)
                                              ? value[0]
                                              : value
                                    }
                                    onChange={(color) => {
                                        handleColorChange(color);
                                    }}
                                    type="text"
                                    className="rounded-md border border-gray-300 py-1 pl-6 pr-2 text-gray-400"
                                />
                            </div>
                            <EyeDropperComponent
                                currentColor={
                                    Array.isArray(value) ? value[0] : value
                                }
                                onChange={handleColorChange}
                            />
                        </div>
                    </div>
                </div>
            )}

            {!!isInGradientMode && (
                <GradientPicker
                    gradient={gradient}
                    onGradientChange={handleColorChange}
                    onAngleChange={handleGradientAngleChange}
                    onAddColor={() => {
                        const newGradient = {
                            ...gradient,
                            designColors: [
                                ...gradient.designColors,
                                {
                                    id: "",
                                    css_color: "#cccccc",
                                    name: "",
                                    user_id: "",
                                    simulation_id: "",
                                },
                            ],
                        };
                        onGradientChange(newGradient);
                        setGradient(newGradient);
                    }}
                />
            )}
        </div>
    );
}

function GradientPicker({
    gradient,
    onGradientChange,
    onAngleChange,
    onAddColor,
}: {
    gradient: App.Data.DesignColorGradientData;
    onGradientChange: (value: string, index: number) => void;
    onAngleChange: (value: string) => void;
    onAddColor: () => void;
}) {
    return (
        <div className="flex flex-col gap-4">
            <Tabs defaultValue={"0"}>
                <div className="flex">
                    <TabsList
                        className={
                            "flex w-full items-end justify-start gap-2 rounded-none px-0 pb-0 "
                        }
                    >
                        {gradient.designColors.map((color, index) => (
                            <TabsTrigger
                                key={index}
                                value={index.toString()}
                                className={`group flex w-1/3 gap-1 rounded-md px-2 py-1 transition-all
                                    data-[state=active]:bg-gray-200 hover:bg-gray-200`}
                            >
                                <div
                                    className="h-6 w-6 rounded-full border border-transparent
                                        data-[is-dark=false]:border-gray-600 data-[is-dark=true]:border-gray-300
                                        group-data-[state=active]:shadow-md"
                                    style={{
                                        backgroundColor: color.css_color,
                                    }}
                                    data-is-dark={isDark(color.css_color)}
                                />
                            </TabsTrigger>
                        ))}
                        {gradient.designColors.length < 3 && (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onAddColor();
                                }}
                                className="group flex w-1/3 items-center justify-center gap-1 rounded-md px-2 py-1
                                    transition-all data-[state=active]:bg-gray-200 hover:bg-gray-200"
                            >
                                <PlusIcon className="h-6 w-6" />
                            </button>
                        )}
                    </TabsList>
                </div>
                {gradient.designColors.map((color, index) => (
                    <TabsContent
                        key={index}
                        value={index.toString()}
                        className="pt-2"
                    >
                        <div className="gradient flex flex-col gap-2">
                            <HexAlphaColorPicker
                                color={color.css_color}
                                onChange={(color) => {
                                    onGradientChange(color, index);
                                }}
                                data-testid={`color-picker-${color.css_color}`}
                            />
                            <div className="flex items-center gap-2 p-2">
                                <div className="group relative">
                                    <HashIcon
                                        data-is-gradient={true}
                                        className="absolute top-1/2 h-4 w-4 text-gray-400 -translate-y-1/2
                                            data-[is-gradient=false]:left-4 data-[is-gradient=true]:left-2"
                                    />
                                    <HexColorInput
                                        alpha={true}
                                        color={color.css_color}
                                        onChange={(color) => {
                                            onGradientChange(color, index);
                                        }}
                                        type="text"
                                        className="rounded-md border border-gray-300 py-1 pl-6 pr-2 text-gray-400"
                                    />
                                </div>
                                <EyeDropperComponent
                                    currentColor={color.css_color}
                                    onChange={(color) => {
                                        onGradientChange(color, index);
                                    }}
                                />
                            </div>
                        </div>
                    </TabsContent>
                ))}
            </Tabs>
            <div className="flex gap-2">
                <div className="flex flex-col gap-2">
                    <DirectionPicker
                        onChange={(angle) => {
                            onAngleChange(angle);
                        }}
                        value={gradient.angle}
                    />
                </div>
                <div
                    className={`flex h-20 w-full gap-2 rounded-md border border-gray-300
                    bg-gradient-to-${gradient.angle || "r"} grow from-[var(--from-color)]
                    to-[var(--to-color)] ${
                        gradient.designColors.length > 2
                            ? "via-[var(--via-color)]"
                            : ""
                    }`}
                    style={
                        {
                            "--from-color": gradient.designColors[0].css_color,
                            "--to-color":
                                gradient.designColors[
                                    gradient.designColors.length - 1
                                ].css_color,
                            "--via-color":
                                gradient.designColors.length > 2
                                    ? gradient.designColors[1].css_color
                                    : "",
                        } as React.CSSProperties
                    }
                />
            </div>
        </div>
    );
}

import { usePage as inertiaUsePage } from "@inertiajs/react";
import { SapienPage } from "../types";
// import { Page } from "@inertiajs/core";
// import toast from "react-hot-toast";

// export function onSuccess(page: Page) {
//     const sapienPage = page as SapienPage;
//     const { props } = sapienPage;
//     if (props.toast) {
//         if (props.toast.type === "success") {
//             toast.success(props.toast.message);
//         } else {
//             toast(props.toast.message);
//         }
//     }
// }

// export function onError(errors: Errors) {
//     console.log("ERRORS", errors);
// }

export function usePage<T extends SapienPage>() {
    const page = inertiaUsePage() as T;

    //handle setting intial state here?
    return { ...page, props: page.props };
}

import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { isBackgroundDark } from "@/util/hasDarkBackground";
import React, { FC, ReactNode } from "react";
import { createPortal } from "react-dom";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components";

type ModalProps = {
    isTitleBlock?: boolean;
    // title: string;
    // content1: string;
    // content2?: string[];
    contentPosition?: "justify" | "center";
    iconBackgroundColor?: string;
    border?: string;
    backgroundColor?: string;
    boxShadow?: string;
    isModalOpen?: boolean;
    setIsModalOpen?: (isOpen: boolean) => void;
    actionsButton?: boolean;
    actionsButtonText?: string;
    actionsButtonTextColor?: string;
    buttonsGroup?: boolean;
    actionsButtonBackgroundColor?: string;
    buttonsGroupsPosition?: "fullWidth" | "right";
    leftGroupsButtonText?: string;
    leftGroupsButtonTextColor?: string;
    leftGroupsButtonBackgroundColor?: string;
    rightGroupsButtonText?: string;
    rightGroupsButtonBackgroundColor?: string;
    rightGroupsButtonTextColor?: string;
    buttonBorderRadius?: string;
    size?: "s" | "m" | "l" | "xl" | "xxl";
    icon?: JSX.Element;
    children?: ReactNode;
    styles?: { [index: string]: any };
    overlayStyes?: { [index: string]: any };
    classNames?: string;
    style?: {
        content?: any;
        overlay?: any;
    };
    modalId?: string;
    isInEditorContext?: boolean;
    wrapperSelector?: string;
};

const StyledModal = styled.div<ModalProps & { hasDarkBackground: boolean }>`
    overflow: visible;
    &.open {
        flex-direction: column;
        width: ${({ size }) => {
            switch (size) {
                case "s":
                    return "512px";
                case "m":
                    return "768px";
                case "l":
                    return "960px";
                case "xl":
                    return "1024px";
                case "xxl":
                    return "1280px";
            }
        }};

        border: ${({ border }) => border || "none"};
        box-shadow: ${({ boxShadow }) => boxShadow || "none"};
        border-radius: 8px;
        background-color: ${({ backgroundColor }) =>
            backgroundColor || "white"};

        .icon {
            padding: 0.25em;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${({ iconBackgroundColor }) =>
                iconBackgroundColor || "white"};
            flex-shrink: inherit;
            justify-self: flex-end;
            justify-content: flex-end;
            width: 100%;
        }
        z-index: 1000;
    }
    &:not(.open) {
        pointer-events: none;
        opacity: 0;
        height: 0;
        width: 0;
        > * {
            display: none;
        }
    }
    &::backdrop {
        background-color: rgba(0, 0, 0, 0.8);
    }
    padding: 0.5rem;
    > * {
        width: 100%;
    }
`;

export const ModalContainer: FC<ModalProps> = (props) => {
    const {
        isModalOpen,
        styles,
        children,
        icon,
        classNames,
        modalId,
        isInEditorContext,
        setIsModalOpen,
        wrapperSelector = "#modal-wrapper",
    } = props;

    const modalRef = React.useRef<HTMLDivElement>(null);

    const element = useGetElementAsync(wrapperSelector);

    if (!element) {
        return <></>;
    }

    const filteredStyles = Object.keys(styles || {})
        .filter((key) => key !== "left" && key !== "right" && key !== "top")
        .reduce((acc, key) => {
            acc[key] = styles[key];
            return acc;
        }, {});

    return createPortal(
        <div
            className={`modal-container data-[state=open]:animate-in data-[state=closed]:animate-out
            data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 inset-0 z-[10000]
            max-h-screen place-items-center overflow-y-auto bg-black/50 py-16
            backdrop-blur-sm @container scrollbar scrollbar-thumb-gray-500
            scrollbar-thumb-rounded-full scrollbar-w-3 data-[state=open]:fixed ${
                isModalOpen
                    ? "pointer-events-auto"
                    : "pointer-events-none opacity-0"
            }`}
            data-state={isModalOpen ? "open" : "closed"}
            style={{
                marginLeft: styles?.left,
                marginRight: styles?.right,
                marginTop: styles?.top,
            }}
        >
            <div
                className="fixed inset-0 z-10"
                role="button"
                tabIndex={0}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (setIsModalOpen) {
                        setIsModalOpen(!isModalOpen);
                    }
                }}
            ></div>
            <StyledModal
                ref={modalRef}
                className={`relative inset-auto mx-auto flex w-full max-w-[95%] flex-col shadow ${
                    isModalOpen
                        ? "open mt-0 opacity-100"
                        : "-mt-[200%] opacity-0"
                } z-100 flex
                flex-col transition-all duration-[500ms]`}
                hasDarkBackground={isBackgroundDark(
                    modalRef?.current as Element,
                )}
                {...props}
                modalId={modalId}
                style={filteredStyles}
                data-testid={modalId}
            >
                {(isModalOpen || isInEditorContext) && (
                    <div
                        style={{ margin: "0 auto", outline: 0 }}
                        className={classNames}
                    >
                        {/* <Suspense fallback={<Skeleton />}> */}
                        {!!icon && <div className="icon">{icon}</div>}
                        {children}
                        {/* </Suspense> */}
                    </div>
                )}
            </StyledModal>
        </div>,
        element,
    );
};

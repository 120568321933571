import React, { forwardRef } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragAndDropItem } from "./types";
import styled from "styled-components";

type Ref = HTMLDivElement;

interface Props {
    id: string;
    children: React.ReactNode;
    style: React.CSSProperties;
    width?: number;
}

const Item = forwardRef<Ref, Props & any>(
    ({ children, ...props }, ref) => {
        return (
            <div
                ref={ref}
                {...props}
                style={{ gridColumn: `span ${props?.width}` }}
            >
                {children}
            </div>
        );
    }
);

const StyledDragItem = styled(Item)<{ color: string }>`
    > div {
        width: 100%;
        padding: 0 !important;
        border-radius: 0.375rem;
        > * {
            margin: 0;
        }
    }
    .card {
        border: ${({ color }) => `3px solid ${color}`} !important;
    }
    * {
        cursor: pointer;
    }
`;

const itemStyle: React.CSSProperties = {
    // backgroundColor: "#FAF5FF",
    // width: "370px",
    // padding: "8px",
    // boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
    // borderRadius: "8px",
    width: "100%",
};

export const DraggableItem: React.FC<
    Pick<
        DragAndDropItem,
        "option_id" | "child" | "selection_numerical_value" | "color"
    > & { isBeingDragged: boolean }
> = ({
    option_id,
    child,
    selection_numerical_value,
    color,
    //because isDragging is always false, we use isBeingDragged instead
    isBeingDragged,
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        //For some reason, this is always false
        // isDragging,
    } = useSortable({
        id: option_id,
        data: { weight: selection_numerical_value },
    });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isBeingDragged ? 0.5 : 0.1,
    };

    return (
        <StyledDragItem
            id={option_id}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={{
                ...style,
                ...itemStyle,
            }}
            className={`relative rounded-md ${
                isBeingDragged ? "opacity-50" : "opacity-100"
            }`}
            data-option-id={option_id}
            color={color || "#ffffff"}
        >
            {child}
        </StyledDragItem>
    );
};

import { useState, useEffect } from "react";

let timerId: ReturnType<typeof setTimeout>;
export function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number,
): (...args: Parameters<T>) => void {
    return function (this: any, ...args: Parameters<T>) {
        // Specify 'this' type as 'any'
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(this, args); // 'this' context preserved
        }, delay);
    };
}
export function useDebounce<T>(value: T, delay: number): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

// export function useDebouncedCallback<T>(callback:() => void, delay: number) {
//     // State and setters for debounced value
//     useEffect(
//         () => {
//             // Update debounced value after delay4
//             const handler = setTimeout(() => {
//                 callback()
//             }, delay);
//             // Cancel the timeout if value changes (also on delay change or unmount)
//             // This is how we prevent debounced value from updating if value is changed ...
//             // .. within the delay period. Timeout gets cleared and restarted.
//             return () => {
//                 clearTimeout(handler);
//             };
//         },
//         [callback, delay] // Only re-call effect if value or delay changes
//     );
//     return callback;
// }

// export function useThrottle(cb: (...args) => any | void, delay: number) {
//     const options = { leading: true, trailing: false }; // add custom lodash options
//     const cbRef = useRef(cb);
//     // use mutable ref to make useCallback/throttle not depend on `cb` dep
//     useEffect(() => {
//         cbRef.current = cb;
//     });
//     return useCallback(
//         throttle((args) => cbRef.current(...args), delay, options),
//         [delay]
//     );
// }

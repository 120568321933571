import React, { useMemo, useState } from "react";
import {
    Participant,
    RoundShape,
    Simulation,
    SimulationShape,
    Team,
    TeamShape,
    TeamWithProgressData,
} from "@/models";
import { TeamDisplay } from "./TeamDisplay";
import MoveParticipantModal from "./MoveParticipantModal";
import { ParticipantDisplay } from "./ParticipantDisplay";
import { ParticipantRoundMenu, TeamRoundMenu } from "./RoundMenu";

export const TeamList = ({
    teams,
    rounds,
    simulation,
    showAdvanceControls,
    isCourse,
    expandedTeamMap,
    setExpandedTeamMap,
}: {
    teams: (TeamWithProgressData | Team)[];
    rounds: RoundShape[];
    simulation: (Simulation | SimulationShape) & {
        is_course: boolean;
    };
    showAdvanceControls: boolean;
    isCourse: boolean;
    expandedTeamMap: {
        [index: string]: boolean;
    };
    setExpandedTeamMap: (expandedTeamMap: { [index: string]: boolean }) => void;
}) => {
    const [participantToMove, setParticipantToMove] = useState<Participant>();

    const columnLabels = useMemo(() => {
        const advanceLabel = showAdvanceControls ? ["Advance"] : [];
        // Move label?
        return !isCourse
            ? ["Participant", "Email", "Link", "Round", ...advanceLabel]
            : [
                  "Participant",
                  "Email",
                  "Unpublished Access",
                  "Lesson",
                  ...advanceLabel,
              ];
    }, [isCourse, showAdvanceControls]);

    return (
        <>
            <div className="space-y-3">
                {!!teams &&
                    teams.map((team) => (
                        <TeamDisplay
                            key={team.id}
                            team={team as TeamWithProgressData}
                            rounds={rounds}
                            setParticipantToMove={setParticipantToMove}
                            showAdvanceControls={showAdvanceControls}
                            isExpanded={expandedTeamMap[team.id]}
                            setIsExpanded={(isExpanded) => {
                                setExpandedTeamMap({
                                    ...expandedTeamMap,
                                    [team.id]: isExpanded,
                                });
                            }}
                            roundMenu={
                                <TeamRoundMenu
                                    team={team as TeamShape}
                                    rounds={rounds}
                                    label={!isCourse ? "Round" : "Lesson"}
                                />
                            }
                            columnLabels={columnLabels}
                        >
                            <>
                                {!!expandedTeamMap[team.id] &&
                                    !!team.participants &&
                                    !!team.participants.length &&
                                    team.participants.map((participant) => (
                                        <ParticipantDisplay
                                            key={participant.id}
                                            participant={participant}
                                            cohortId={team.cohort_id}
                                            simulationSlug={simulation.slug}
                                            showAdvanceControls={
                                                showAdvanceControls
                                            }
                                            isCourse={isCourse}
                                            roundMenu={
                                                <ParticipantRoundMenu
                                                    participant={participant}
                                                    rounds={rounds}
                                                />
                                            }
                                        />
                                    ))}
                            </>
                        </TeamDisplay>
                    ))}
            </div>
            <MoveParticipantModal
                participant={participantToMove}
                teams={teams.filter(
                    (team) =>
                        team.id !== participantToMove?.pivot?.team_id &&
                        (team as TeamWithProgressData).team_user_rounds?.every(
                            (teamUserRound) =>
                                !teamUserRound?.teamSelections?.length,
                        ),
                ) as TeamShape[]}
                cancel={() => {
                    setParticipantToMove(undefined);
                }}
            />
        </>
    );
};

import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import React from "react";

type Props = {
    contentBlock: ContentBlockShape;
};

export default function Divider({ contentBlock }: Props) {
    // const variant = contentBlock?.theme?.variant || "numbered";

    return (
        <section
            data-content-block-id={contentBlock.id}
            className={`${cn(contentBlock?.theme?.tailwindClasses)} w-full`}
            style={{ backgroundColor: contentBlock?.theme?.backgroundColor }}
        >
            <div className="flex w-full max-w-6xl flex-row items-center justify-center">
                <div className="h-0.5 w-full bg-current"></div>
                <div
                    className="flex h-12 w-12 items-center justify-center rounded-full border border-current
                        bg-white p-6"
                >
                    <span className="text-lg font-bold">
                        {contentBlock.content}
                    </span>
                </div>
                <div className="h-0.5 w-full bg-current"></div>
            </div>
        </section>
    );
}

import React from "react";
import { ModelVariable, ModelVariableType, Round } from "@/models";
import { useModelVariables, useVariableRelationships } from "../state";
import { VariableLineageDisplay } from "./VariableLineageDisplay";
import {
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
} from "@radix-ui/react-accordion";

const displayVariable = (modelVariable: ModelVariable) => {
    return (
        <div
            className={"m-0.5 bg-[rgba(28,78,216,0.05)] p-2"}
            style={{
                fontWeight:
                    modelVariable.variable_type ===
                    ModelVariableType["Selection Data"]
                        ? "bold"
                        : "",
                border:
                    modelVariable.variable_type ===
                    ModelVariableType["Selection Data"]
                        ? "1px solid rgba(28,78,216,0.25)"
                        : "none",
            }}
        >
            {modelVariable.label}
        </div>
    );
};

export const ModelConnections = (props: { round: Round }) => {
    const { round } = props;

    const { relationshipsByTargetId } = useVariableRelationships();
    const { modelVariables } = useModelVariables();

    return (
        <div>
            <Accordion type="multiple">
                {round.prompts
                    .filter((prompt) => prompt.has_model_effects)
                    .map((prompt) => (
                        <AccordionItem key={prompt.id} value={prompt.id}>
                            <AccordionTrigger>
                                
                                    <div className="w-full overflow-hidden">
                                        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                            {prompt.content}
                                        </div>
                                    </div>
                            </AccordionTrigger>
                            <AccordionContent
                                style={{
                                    padding: "0px",
                                }}
                            >
                                <div style={{ overflowX: "hidden" }}>
                                    <div className="p-3">
                                        {!!modelVariables &&
                                            !prompt.connectionModelVariables
                                                ?.length &&
                                            prompt.selectionDataModelVariables?.map(
                                                (selectionDataVariable) => (
                                                    <div
                                                        key={
                                                            selectionDataVariable.id
                                                        }
                                                        className="my-2 flex flex-col justify-center text-xs"
                                                    >
                                                        {displayVariable(
                                                            selectionDataVariable,
                                                        )}
                                                    </div>
                                                ),
                                            )}
                                        {!!modelVariables &&
                                            prompt.connectionModelVariables?.map(
                                                (connectionModelVariable) => (
                                                    <div
                                                        key={
                                                            connectionModelVariable.id
                                                        }
                                                        className="my-4 overflow-x-auto text-xs"
                                                    >
                                                        <div className="flex flex-row items-center">
                                                            <div className="flex flex-col justify-center px-1">
                                                                {!!relationshipsByTargetId &&
                                                                    !!relationshipsByTargetId[
                                                                        connectionModelVariable
                                                                            .id
                                                                    ] &&
                                                                    relationshipsByTargetId[
                                                                        connectionModelVariable
                                                                            .id
                                                                    ].map(
                                                                        (
                                                                            sourceRelationship,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    sourceRelationship.id
                                                                                }
                                                                            >
                                                                                {!!modelVariables[
                                                                                    sourceRelationship
                                                                                        .source_variable_id
                                                                                ] &&
                                                                                    displayVariable(
                                                                                        modelVariables[
                                                                                            sourceRelationship
                                                                                                .source_variable_id
                                                                                        ],
                                                                                    )}
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </div>
                                                            <VariableLineageDisplay
                                                                modelVariable={
                                                                    connectionModelVariable
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    ))}
            </Accordion>
        </div>
    );
};

import { sapienAxios } from "@/inertia-utils/hooks";
// import { unescape } from "lodash";

export async function getCursor(iconRef: JSX.Element, maxWidth: number = 100) {
    if (!!iconRef) {
        const el = document.querySelector(".icon-wrapper");

        if (!el) return "inherit";
        const child = el.querySelector("svg") || el.querySelector("img");
        if (!child) return "inherit";

        const childType = child.tagName.toLowerCase();

        let cursor = "inherit";
        switch (childType) {
            case "svg":
                cursor = await handleSvg(child as SVGSVGElement).then(
                    (res) => res,
                );
                break;
            case "img":
                cursor = await handleImg(child as HTMLImageElement);
                break;
            default:
                cursor = "inherit";
        }
        return cursor;
    }
    return "inherit";
}

async function handleSvg(svgElement: SVGElement, maxWidth: number = 100) {
    const svgString = new XMLSerializer().serializeToString(svgElement);

    // Scale the SVG to the desired width
    const scaledSvg = scaleSvg(svgString, maxWidth);

    // Add a background to the SVG
    const svgWithBackground = addBackground(scaledSvg);

    const decoded = decodeURIComponent(encodeURIComponent(svgWithBackground));
    const base64 = btoa(decoded);
    const imgSource = `url('data:image/svg+xml;base64,${base64}') 32 32, auto`;
    return Promise.resolve(imgSource);
}

async function handleImg(
    imgElement: HTMLImageElement,
    maxSize: number = 110,
): Promise<string> {
    const { data: src } = await sapienAxios.get<
        string,
        "creator.design.image-proxy"
    >("creator.design.image-proxy", {
        url: imgElement.src,
        base64: true,
    });
    // Create a promise that resolves when the image is loaded and processed
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        // Ensure CORS policies are respected for external images
        img.crossOrigin = "Anonymous";

        img.src = src;

        img.onerror = (e) => {
            console.log("OH NO", e);
        };

        img.onload = () => {
            const imgWidth = img.width;
            const imgHeight = img.height;
            const scale = Math.min(maxSize / imgWidth, maxSize / imgHeight);
            const scaledWidth = imgWidth * scale;
            const scaledHeight = imgHeight * scale;

            // Set canvas size to the scaled image size
            canvas.width = scaledWidth;
            canvas.height = scaledHeight;

            // Draw the scaled image
            ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);
            const canvasWithBg =
                img.width > 200 ? canvas : drawRoundedImage(canvas, img, 50);

            const canvasWithPlusSign = addPlusSignToExistingImage(
                canvasWithBg,
                10,
                "#000",
                "white",
                2,
            );

            // Add custom graphics (circle with plus sign) here as needed...

            // Convert canvas to Data URL
            const cursorImageUrl = canvasWithPlusSign.toDataURL("image/png");

            // Resolve the promise with the Data URL
            resolve(`url('${cursorImageUrl}'), auto`);
        };
    });
}

function drawRoundedImage(
    canvas: HTMLCanvasElement,
    img: HTMLImageElement,
    borderRadius: number,
) {
    if (!canvas) {
        console.error("Canvas element not found");
        return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
        console.error("Unable to get canvas context");
        return;
    }

    // Set partially transparent background
    ctx.fillStyle = "rgba(0, 0, 0, 0.5)"; // Adjust the alpha for transparency
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Create a path with rounded corners
    ctx.beginPath();
    ctx.moveTo(borderRadius, 0);
    ctx.lineTo(canvas.width - borderRadius, 0);
    ctx.quadraticCurveTo(canvas.width, 0, canvas.width, borderRadius);
    ctx.lineTo(canvas.width, canvas.height - borderRadius);
    ctx.quadraticCurveTo(
        canvas.width,
        canvas.height,
        canvas.width - borderRadius,
        canvas.height,
    );
    ctx.lineTo(borderRadius, canvas.height);
    ctx.quadraticCurveTo(0, canvas.height, 0, canvas.height - borderRadius);
    ctx.lineTo(0, borderRadius);
    ctx.quadraticCurveTo(0, 0, borderRadius, 0);
    ctx.closePath();

    // Clip to the rounded rectangle path and draw the image
    ctx.clip();
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    return canvas;
}
function addPlusSignToExistingImage(
    canvas: HTMLCanvasElement,
    plusSignSize: number,
    plusColor: string = "black",
    backgroundColor: string = "white",
    padding: number = 5,
) {
    const ctx = canvas.getContext("2d");

    // Draw the original image
    // Calculate plus sign and background dimensions
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const totalSize = plusSignSize + 2 * padding;
    const halfSize = totalSize / 2;

    // Draw the background
    ctx.fillStyle = backgroundColor;
    ctx.beginPath();
    ctx.arc(centerX, centerY, totalSize, 0, 2 * Math.PI);
    ctx.fill();

    // Draw the plus sign
    ctx.fillStyle = plusColor;
    ctx.beginPath();
    ctx.rect(
        centerX - padding / 2,
        centerY - halfSize + padding,
        padding,
        plusSignSize,
    ); // Vertical line
    ctx.rect(
        centerX - halfSize + padding,
        centerY - padding / 2,
        plusSignSize,
        padding,
    ); // Horizontal line
    ctx.fill();

    return canvas;
}

function scaleSvg(svgString: string, newWidth: number) {
    // Parse the SVG string to a DOM element to easily manipulate attributes
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgString, "image/svg+xml");
    const svgElement = doc?.documentElement;
    if (!svgElement) return svgString;

    // Extract the current width, height, and viewBox
    const currentWidth = parseFloat(svgElement?.getAttribute("width"));
    const currentHeight = parseFloat(svgElement?.getAttribute("height"));

    // Calculate the new height to preserve the aspect ratio
    const aspectRatio = currentHeight / currentWidth;
    const newHeight = currentHeight * aspectRatio;

    // Update the SVG element with the new dimensions and viewBox
    svgElement?.setAttribute("width", newWidth.toString());
    svgElement?.setAttribute("height", newHeight.toString());

    // Serialize the updated SVG element back to a string
    const serializer = new XMLSerializer();
    const newSvgString = serializer?.serializeToString(svgElement);

    return newSvgString;
}

function addBackground(svgString: string) {
    // Parse the SVG string to a DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgString, "image/svg+xml");
    const svgElement = doc.documentElement;
    if (!svgElement) return svgString;

    let width, height;
    if (svgElement?.getAttribute("viewBox")) {
        [, , width, height] = svgElement
            .getAttribute("viewBox")
            .split(" ")
            .map(Number);
    } else {
        width = parseFloat(svgElement?.getAttribute("width"));
        height = parseFloat(svgElement?.getAttribute("height"));
    }

    // Calculate the center
    const centerX = width / 2;
    const centerY = height / 2;
    // Calculate the center and the ends of the plus symbol based on the provided size
    const plusSize = 30; // Adjust this to change the size of the plus symbol
    const circleRadius = 40; // Adjust this to change the size of the circle
    const plusHalfSize = plusSize / 2;
    const circle = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "circle",
    );
    circle.setAttribute("cx", centerX.toString());
    circle.setAttribute("cy", centerY.toString());
    circle.setAttribute("r", circleRadius.toString());
    circle.setAttribute("fill", "#09090972"); // Fill color of the circle

    // Create the plus symbol using paths or lines
    const plus = document.createElementNS("http://www.w3.org/2000/svg", "path");
    const dValue = `M ${centerX},${centerY - plusHalfSize} L ${centerX},${centerY + plusHalfSize} M ${centerX - plusHalfSize},${centerY} L ${centerX + plusHalfSize},${centerY}`;
    plus.setAttribute("d", dValue); // Draws a plus
    plus.setAttribute("stroke", "white"); // Color of the plus
    plus.setAttribute("stroke-width", "2");

    // Append the circle and plus to the SVG
    svgElement.appendChild(circle);
    svgElement.appendChild(plus);

    // Create a new rect element with the same dimensions as the SVG
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    rect.setAttribute("width", "175");
    rect.setAttribute("height", "195");
    rect.setAttribute("rx", "10"); // Adjust for desired corner rounding
    rect.setAttribute("ry", "10");
    rect.setAttribute("fill", "#1d2533"); // Or any color you wish

    // Prepend the rect to the SVG to ensure it's the background
    if (svgElement.firstChild) {
        svgElement.insertBefore(rect, svgElement.firstChild);
    } else {
        svgElement.appendChild(rect);
    }

    // Serialize the updated SVG element back to a string
    const serializer = new XMLSerializer();
    const newSvgString = serializer?.serializeToString(svgElement);

    return newSvgString;
}

import _ from "lodash";
import { BaseModel } from "../models";

export const unflatten = <T extends BaseModel>(
    parentIdPropertyName: string,
    childrenPropertyName: string,
    array: T[],
    parent?: T
): T[] => {
    let tree: T[] = [];

    if (typeof parent == "undefined") {
        parent = { id: "", [childrenPropertyName]: [] } as unknown as T;
    }

    array = [
        ...array.map(
            (b) =>
                ({
                    ...b,
                    [parentIdPropertyName]: b[parentIdPropertyName]
                        ? b[parentIdPropertyName]
                        : "",
                } as T)
        ),
    ];

    let children = _.filter(array, function (child) {
        return parent && parent.id
            ? child[parentIdPropertyName] === parent.id
            : true;
    });

    if (!_.isEmpty(children)) {
        if (parent.id === "") {
            tree = children;
        } else {
            parent[childrenPropertyName] = children;
        }
        _.each(children, function (child) {
            unflatten(parentIdPropertyName, childrenPropertyName, array, child);
        });
    }

    return tree;
};

export const createNestedTree = <T extends BaseModel>(
    // modelClass: typeof BaseModel,
    parentIdPropertyName: string,
    childrenPropertyName: string,
    flatArray: T[]
): T[] => {
    let unflattenedArray = unflatten(
        parentIdPropertyName,
        childrenPropertyName,
        flatArray
    );

    let filteredUnflattenedArray = unflattenedArray.filter(
        (item) => item[parentIdPropertyName] === ""
    );

    return filteredUnflattenedArray;
};

export const getSortedNestedObjects = <T extends BaseModel>(
    parentIdPropertyName: string,
    childrenPropertyName: string,
    flatArray: T[]
): { unflattenedArray: T[]; filteredUnflattenedArray: T[] } => {
    let sortedFlatArray = [...flatArray].sort((a, b) => a.weight - b.weight);

    let unflattenedArray = unflatten(
        parentIdPropertyName,
        childrenPropertyName,
        sortedFlatArray
    );

    let filteredUnflattenedArray = unflattenedArray.filter(
        (item) => item[parentIdPropertyName] === ""
    );

    return { unflattenedArray, filteredUnflattenedArray };
};

// function to get the top-level ancestor of a model in nested tree
// export const getAncestorModelId = <T extends BaseModel>(
//     model: T,
//     nestedModelObject: { [index: string]: T },
//     parentIdPropertyName: string
// ) => {
//     // check if model has parent
//     let directParentId = model ? model[parentIdPropertyName] : "";

//     if (directParentId) {
//         // if so, find the ancestor model id of the parent
//         let directParent =
//             nestedModelObject && nestedModelObject[directParentId];
//         return getAncestorModelId(
//             directParent,
//             nestedModelObject,
//             parentIdPropertyName
//         );
//     } else {
//         // if not, the ancestor is this model, so return its id
//         return model?.id;
//     }
// };

// function to get a flat list of all descendants of a nested model
// export const getDescendantModelArray = <T extends BaseModel>(
//     nestedModel: T,
//     childrenPropertyName: string
// ) => {
//     let directChildren: T[] = nestedModel
//         ? nestedModel[childrenPropertyName]
//         : [];

//     let descendantsOfChildren: T[] = [];

//     if (!directChildren) {
//         return [];
//     } else {
//         // recursively find and add all descendants of descendants
//         directChildren.forEach((childModel) => {
//             let descendantsOfChild = getDescendantModelArray(
//                 childModel,
//                 childrenPropertyName
//             );
//             descendantsOfChildren = [
//                 ...descendantsOfChildren,
//                 ...descendantsOfChild,
//             ];
//         });
//     }

//     return [...directChildren, ...descendantsOfChildren];
// };

// get ids of all ancestors of model in tree
export const getLineageIds = <T extends BaseModel>(
    modelId: string,
    modelObject: { [index: string]: T },
    parentIdPropertyName: string
) => {
    let lineageIds = [];
    // get model by id
    let model = modelObject && modelObject[modelId];
    // check if model has parent
    let directParentId = model ? model[parentIdPropertyName] : "";
    // if so, find the lineage of the parent
    if (directParentId) {
        let directParentLineage = getLineageIds(
            directParentId,
            modelObject,
            parentIdPropertyName
        );
        // add parent lineage and parent id to lineage id array
        lineageIds = [...directParentLineage, directParentId];
    }
    return lineageIds;
};

// const arrayMoveMutate = (array, from, to) => {
//     array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
// };

// const arrayMove = (array, from, to) => {
//     // console.log(array, from, to);
//     array = array.slice();
//     arrayMoveMutate(array, from, to);
//     return array;
// };

// export const arrayMoveSetWeights = (array: any[], from: number, to: number) => {
//     return arrayMove(array, from, to).map((item, index) => ({
//         ...item,
//         ...{ weight: index },
//     }));
// };

export const findNestedItem = <T>(
    nestedArray: T[],
    propertyToSearch: string,
    propertyToCompare: string,
    value: string | number
) => {
    let foundItem: T | undefined;
    nestedArray.forEach((item) => {
        if (item[propertyToCompare] === value) {
            foundItem = item;
        } else if (!foundItem && Array.isArray(item[propertyToSearch])) {
            foundItem = findNestedItem(
                item[propertyToSearch],
                propertyToSearch,
                propertyToCompare,
                value
            );
        }
    });

    return foundItem;
};

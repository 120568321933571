import React, { useState } from "react";
import { useForm } from "@inertiajs/react";
import { SapienInertia } from "@/inertia-utils/hooks";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { ModalContainer } from "@/modules/shared";
import { useRoles } from "@/components/user-management/useRoles";

type Props = {
    roles: { id: number; name: string }[];
    role?: { id: number; name: string };
    children: React.ReactNode;
};

export const UserCreationForm = ({  role, children }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { roles } = useRoles();


    const role_id =
        !!role && !!roles?.length
            ? roles.find((role) => role.name === role.name)?.id.toString()
            : "-1";
    const { data, setData } = useForm({
        name: "",
        email: "",
        role_id,
    });

    return (
        <>
            <button
                className="inline-flex items-center gap-2 rounded-md bg-indigo-600 px-4 py-2 text-sm
                    font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2
                    focus-visible:outline-indigo-600"
                onClick={() => setIsModalOpen(true)}
            >
                <UserPlusIcon className="h-6 w-6" />
                Create new user
            </button>
            <ModalContainer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                size="l"
            >
                <div className="rounded-md border border-slate-100/50 bg-white/75 p-3">
                    <div className="flex flex-col gap-4 ">
                        <div className="flex min-w-60 items-center space-x-3">
                            <UserPlusIcon className="h-6 w-6" />
                            <h2 className="text-nowrap text-base font-semibold leading-6 text-slate-900">
                                Create new user
                            </h2>
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                SapienInertia.post(
                                    "admin.users.store",
                                    data,
                                    {},
                                    {
                                        onSuccess: () => {
                                            setData({
                                                name: "",
                                                email: "",
                                                role_id: "-1",
                                            });
                                        },
                                    },
                                );
                            }}
                            className="flex w-full"
                        >
                            <div className="flex w-full flex-col gap-4">
                                <div>
                                    <label
                                        htmlFor="user-name"
                                        className="sr-only"
                                    >
                                        Name
                                    </label>
                                    <input
                                        name="user-name"
                                        id="user-name"
                                        className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-900
                                            ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                            focus:ring-inset focus:ring-indigo-600"
                                        placeholder={`Name`}
                                        value={data.name}
                                        onChange={(e) => {
                                            setData("name", e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="user-email"
                                        className="sr-only"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="user-email"
                                        id="user-email"
                                        className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-900
                                            ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                            focus:ring-inset focus:ring-indigo-600"
                                        placeholder={`Email`}
                                        value={data.email}
                                        onChange={(e) => {
                                            setData("email", e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="user-role"
                                        className="sr-only"
                                    >
                                        Role
                                    </label>
                                    <select
                                        id="user-role"
                                        name="user-role"
                                        className="block w-full rounded-md border-0 px-2 py-1.5 text-sm leading-6 text-slate-700
                                            ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2
                                            focus:ring-inset focus:ring-indigo-600"
                                        disabled={!!role}
                                        value={data.role_id}
                                        onChange={(e) => {
                                            setData("role_id", e.target.value);
                                        }}
                                    >
                                        {!!role ? (
                                            <option
                                                key={role.name}
                                                value={role.id}
                                            >
                                                {role.name}
                                            </option>
                                        ) : (
                                            <>
                                                <option>Role</option>
                                                {roles.map((role) => (
                                                    <option
                                                        key={role.name}
                                                        value={role.id}
                                                    >
                                                        {role.name}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                                <div className="flex justify-end gap-2">
                                    <button
                                        className="rounded-md bg-red-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                            hover:bg-red-700 focus-visible:outline focus-visible:outline-2
                                            focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setData({
                                                email: "",
                                                role_id: "-1",
                                                name: "",
                                            });
                                            setIsModalOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm
                                            hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2
                                            focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <span>Save</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {!!children && <div>{children}</div>}
                </div>
            </ModalContainer>
        </>
    );
};

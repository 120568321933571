import { useCallback } from "react";
import { CurrencyType, ModelVariableUnit } from "@/models";
import { variableValueFormatter } from "@/util";
import { useDataFile } from "./useDataStudio";
import { useFormatVariableValue } from "@/hooks";
import { useDataStudioModelVariables } from "./DataStudioVariables";

export const useFormatSandboxValue = (isSimulationData: boolean) => {
    const { dataFileMetadata } = useDataFile();
    const dataStudioModelVariables = useDataStudioModelVariables();
    const formatVariableValue = useFormatVariableValue();

    const formatSandboxValue = useCallback(
        (variableLabel: string, value: number) => {
            if (isSimulationData) {
                const modelVariable = dataStudioModelVariables?.find(
                    (modelVariable) => modelVariable.label == variableLabel,
                );
                return formatVariableValue(
                    modelVariable?.unit || ModelVariableUnit.None,
                    value,
                    !!modelVariable?.is_integer,
                );
            } else {
                return variableValueFormatter(
                    dataFileMetadata?.currency_type || CurrencyType.USD,
                    (!!dataFileMetadata?.variable_unit_map &&
                        dataFileMetadata?.variable_unit_map[variableLabel]) ||
                        ModelVariableUnit.None,
                    value,
                    !!dataFileMetadata?.variable_unit_map &&
                        dataFileMetadata?.variable_unit_map[variableLabel] ===
                            ModelVariableUnit.Dollars,
                );
            }
        },
        [
            dataFileMetadata,
            isSimulationData,
            formatVariableValue,
            dataStudioModelVariables,
        ],
    );

    return {
        formatSandboxValue,
        timeLabel: isSimulationData
            ? "Time Index"
            : dataFileMetadata?.time_label,
    };
};

import React, { useCallback, useRef, useState } from "react";
import {
    Popover,
    PopoverButton,
    PopoverPanel,
    useClose,
} from "@headlessui/react";
import { ColorPicker } from "./ColorPicker";
import ColorChooser from "./ColorChooser";
import ReactDOM from "react-dom";
import { useColorThemes } from "@/Pages/ELearning/color-themes/useColorThemes";
import { useSelectedSimulation } from "@/hooks";
import { BrushIcon } from "lucide-react";
import { isDark } from "@/tailwind-colors/util";

//get eyedropper working

export function ColorPickerPopover({
    value,
    onChange,
    onGradientChange,
    gradientColors,
}: {
    value: string;
    onChange: (value: string) => void;
    onGradientChange: (value: App.Data.DesignColorGradientData) => void;
    gradientColors?: string[];
}) {
    const { selectedSimulation } = useSelectedSimulation();

    const close = useClose();

    const initialGradient: App.Data.DesignColorGradientData = {
        id: null,
        name: "",
        angle: "r",
        simulationId: selectedSimulation?.id ?? "",
        userId: "",
        designColors:
            gradientColors?.length > 0
                ? gradientColors.map((color) => ({
                      id: "",
                      css_color: color,
                      name: "",
                      user_id: "",
                      simulation_id: selectedSimulation?.id ?? "",
                  }))
                : [
                      {
                          id: "",
                          css_color: value,
                          name: "",
                          user_id: "",
                          simulation_id: selectedSimulation?.id ?? "",
                      },
                      {
                          id: "",
                          css_color: value,
                          name: "",
                          user_id: "",
                          simulation_id: selectedSimulation?.id ?? "",
                      },
                  ],
    };

    const [gradient, setGradient] =
        useState<App.Data.DesignColorGradientData>(initialGradient);

    const handleGradientChange = useCallback(
        (gradient: App.Data.DesignColorGradientData) => {
            setGradient(gradient);
            onGradientChange(gradient);
        },
        [onGradientChange],
    );

    const handleChange = useCallback(
        (color: string, colorIndex?: 0 | 1) => {
            if (colorIndex !== undefined) {
                const newGradient = { ...gradient };
                newGradient.designColors[colorIndex].css_color = color;
                console.log("newGradient", newGradient);
                setGradient(() => newGradient);
            } else {
                onChange(color);
            }
        },
        [gradient, onGradientChange, onChange],
    );

    const [mode, setMode] = useState<"color" | "gradient" | "color-chooser">(
        "color-chooser",
    );

    const [isOpen, setIsOpen] = useState(false);

    const { saveColor, saveGradient } = useColorThemes();

    const hasViaColor = !!gradient.designColors[2]?.css_color;

    return (
        <div className="flex flex-col justify-center gap-2">
            <Popover>
                <PopoverButton
                    className="flex items-center"
                    onClick={() => setIsOpen(true)}
                >
                    <div
                        className={`flex ${value} h-8 w-8 items-center justify-center rounded-full border
                        border-gray-300 from-[var(--from-color)] to-[var(--to-color)] ${
                            hasViaColor ? "via-[var(--via-color)]" : ""
                        }`}
                        style={
                            {
                                backgroundColor: value,
                                "--from-color": gradientColors?.[0] || "",
                                "--to-color":
                                    gradientColors?.[gradientColors.length - 1] ||
                                    "",
                                "--via-color":
                                    gradientColors?.length > 2
                                        ? gradientColors?.[1] || ""
                                        : "",
                            } as React.CSSProperties
                        }
                        data-is-gradient={mode === "gradient"}
                    >
                        <BrushIcon
                            aria-label="Choose color"
                            className=" h-4 w-4"
                            style={{
                                color: isDark(gradientColors?.[1] || value)
                                    ? "white"
                                    : "black",
                            }}
                        />
                    </div>
                </PopoverButton>
                <PopoverPanel
                    static={isOpen}
                    className="data-[open=true]:animate-fade-in data-[open=false]:animate-fade-out absolute
                        z-[999999] mt-4 flex min-w-60 flex-col gap-2 rounded-lg border border-gray-300
                        bg-white pb-2 pt-0 shadow-md scrollbar scrollbar-thumb-gray-500
                        scrollbar-thumb-rounded-full scrollbar-w-2 data-[is-open=false]:hidden"
                    data-is-open={isOpen}
                    style={{
                        transitionBehavior: "allow-discrete",
                    }}
                >
                    <nav className="relative flex gap-2 p-2 pt-4">
                        <button
                            className="grow rounded-[0.225rem] p-2 text-center text-xs transition-all duration-200
                                data-[active=true]:bg-gray-100 data-[active=true]:font-semibold
                                hover:bg-gray-100"
                            data-active={mode === "color-chooser"}
                            onClick={() => setMode("color-chooser")}
                        >
                            Palette
                        </button>
                        <button
                            className="grow rounded-[0.225rem] p-2 text-center text-sm transition-all duration-200
                                data-[active=true]:bg-gray-100 data-[active=true]:font-semibold
                                hover:bg-gray-100"
                            data-active={mode === "color"}
                            onClick={() => setMode("color")}
                        >
                            Solid
                        </button>

                        <button
                            className="grow rounded-[0.225rem] p-2 text-center text-sm transition-all duration-200
                                data-[active=true]:bg-gray-100 data-[active=true]:font-semibold
                                hover:bg-gray-100"
                            data-active={mode === "gradient"}
                            onClick={() => setMode("gradient")}
                        >
                            Gradient
                        </button>
                    </nav>

                    {mode === "color-chooser" ? (
                        <div className="px-2 scrollbar scrollbar-thumb-gray-500
                        scrollbar-thumb-rounded-full scrollbar-w-2 max-h-96 overflow-y-auto">
                            <ColorChooser
                                selectedColor={value}
                                selectColor={(color) => {
                                    handleChange(color);
                                }}
                                selectGradient={handleGradientChange}
                            />
                        </div>
                    ) : (
                        <div
                            className="data-[is-gradient=true]:px-4 scrollbar scrollbar-thumb-gray-500
                        scrollbar-thumb-rounded-full scrollbar-w-2 max-h-[500px] overflow-auto"
                            data-is-gradient={mode === "gradient"}
                        >
                            <ColorPicker
                                value={value}
                                onChange={handleChange}
                                onGradientChange={handleGradientChange}
                                isInGradientMode={mode === "gradient"}
                                initialGradient={gradient}
                            />
                        </div>
                    )}
                    {ReactDOM.createPortal(
                        <button
                            className="overlay fixed inset-0 data-[is-open=false]:pointer-events-none
                                data-[is-open=true]:block data-[is-open=false]:hidden"
                            onClick={() => {
                                setIsOpen(false);
                                close();
                            }}
                            data-is-open={isOpen}
                        />,
                        document.body,
                    )}
                    {mode !== "color-chooser" && (
                        <div className="flex p-2">
                            <button
                                className="grow rounded-[0.225rem] bg-sapien-blue p-2 text-center text-xs font-semibold
                                    text-white transition-all duration-200"
                                onClick={() => {
                                    if (mode === "color") {
                                        console.log("value", value);
                                        saveColor({
                                            simulationId: selectedSimulation.id,
                                            color: value,
                                        });
                                    } else {
                                        saveGradient({
                                            simulationId: selectedSimulation.id,
                                            colors: gradient.designColors.map(
                                                (color) => color.css_color,
                                            ),
                                            angle: gradient.angle,
                                        });
                                    }
                                }}
                            >
                                Add to Theme
                            </button>
                        </div>
                    )}
                </PopoverPanel>
            </Popover>
        </div>
    );
}

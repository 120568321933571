import React from "react";
import { Submission, useSetupSelections } from "../../shared-state/useSelections";
import { Course, Lesson } from "../../types";
import { Auth } from "@/inertia-utils/types/SapienPage";
import { Participant } from "@/models";
import ParticipantLessonDisplay from "../../LessonDisplay/LessonDisplay";
import { useSetupContentBlockState } from "../../LessonDesign/useContentBlockState";
import { useViewedContentBlockState } from "../../LessonDisplay/viewed-content-block-tracking/useViewedContentBlockState";
import { sapienAxios } from "@/inertia-utils/hooks";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useMutation } from "@tanstack/react-query";

type Props = {
    course: Course;
    lesson: Lesson;
    teamUserRound: {
        id: string;
        viewedContentBlocks: {
            content_block_id: string;
        }[];
    };
    selections: Submission[];
    auth: Auth & {
        user?: Participant;
    };
};

export default function LessonDisplayWrapper({
    course,
    lesson,
    selections
}: Props) {
    useSetupContentBlockState(lesson);
    useSetupSelections(selections)

    const { mutate } = useMutation({
        mutationFn: async (content_block_ids: string[]) => {
            const response = await sapienAxios.post<
                any,
                "e-learning.participant.api.viewed-content-blocks.store"
            >("e-learning.participant.api.viewed-content-blocks.store", {
                content_block_ids,
            } as unknown as (typeof LaravelRequestBodyShapes)["e-learning.participant.api.viewed-content-blocks.store"]);
        },
    });

    const { viewContentBlock } = useViewedContentBlockState(lesson, mutate);

    return (
        <div className="flex min-h-screen w-full">

            <main
                className={`lesson-designer with-transition animation-target e-learning flex w-full flex-col
                    items-center`}
            >
                <div
                    className={
                        "animation-target-hide flex w-full flex-col items-center"
                    }
                >
                    <div className="scroll-target h-0 w-full"></div>
                    <ParticipantLessonDisplay
                        lesson={lesson}
                        lessonCount={course.lessons.length}
                        isInAdminContext={false}
                        scrollCallback={viewContentBlock}
                    />
                </div>
            </main>
        </div>
    );
}

import { useQuery } from "@tanstack/react-query";
import { VariableValue } from "@/models";
import { getDefaultVariableValues } from "./getDefaultVariableValues";

export type VariableValueWithLabel = VariableValue & {
    label: string;
};

export function useDefaultVariableValues<T>({
    model_variable_ids,
    timeHorizonIds,
    formatterCallback,
}: {
    model_variable_ids: string[];
    timeHorizonIds: string[];

    formatterCallback: (values: {
        [index: string]: VariableValueWithLabel[];
    }) => { values: T };
}) {
    return useQuery({
        queryKey: [
            "getDefaultVariableValues",
            model_variable_ids,
            timeHorizonIds,
        ],
        queryFn: () =>
            getDefaultVariableValues({
                model_variable_ids,
                timeHorizonIds,
            }),
        initialData: {
            values: {},
        },
        select: ({ values }): { values: T } => {
            return formatterCallback(values);
        },
        enabled: !!model_variable_ids?.length,
    });
}

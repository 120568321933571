import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Simulation } from "../models";
import { mapById } from "./util";

type SimulationSliceState = {
    simulations: { [index: string]: Simulation };
    simulation: Partial<Simulation> | undefined;
};

const initialState: SimulationSliceState = {
    simulations: {},
    simulation: undefined,
};

const simulationSlice = createSlice({
    name: "SimulationStore",
    initialState,
    reducers: {
        simulationsQueried(state, { payload }: PayloadAction<Simulation[]>) {
            state.simulations = mapById(payload);
        },
        gotSimulationById(
            state,
            { payload }: PayloadAction<Partial<Simulation>>
        ) {
            state.simulation = payload;
        },
    },
});

export const simulationStoreReducer = simulationSlice.reducer;

const { simulationsQueried } = simulationSlice.actions;

export { simulationsQueried };

import React, { useCallback, useEffect } from "react";
import {
    ContentBlock,
    Selection,
    ColorGroup,
    VariableValue,
    Team,
    User,
    UserShape,
    SimulationShape,
} from "@/models";
import { useAuthStore, useColorStore } from "@/hooks/store";
import { SapienParticipantPageProps } from "@/inertia-utils/types";
import {
    useParticipantStore,
    usePromptStore,
    useParticipantFormAndQuestionData,
    useGotSelections,
    useGotIdsForSavingSelections,
    useOthersSelections,
    useIsSubmitting,
} from "./redux-state/hooks";
import SimulationDisplay from "./SimulationDisplay";
import { useSelectedSimulation } from "@/hooks";
import { SapienInertia } from "@/inertia-utils/hooks";
import { WidthSideNav } from "./WithSideNav";
import { useWebSockets } from "@/hooks/websockets/useWebsockets";
import StyledToast, { ToastProps } from "@/components/toasts/StyledToast";
import { useNotificationStore } from "@/hooks/useNotificationStore";
import { Head } from "@inertiajs/react";
import { useSidebarState } from "@/hooks/store/useSidebarState";
import { useModalComponentStore } from "@/hooks/useModalComponentStore";
import { useReflectionJournalEntryStore } from "@/hooks/store/useReflectionJournalEntryStore";

type SimulationDisplayProps = {
    contentBlocks: ContentBlock[];
    simulation_id: string;
    team: Team;
    colorGroups: ColorGroup[];
    variableValues: VariableValue[];
    simulationTheme: any;
    simulationConfig: any;
    selections: Selection[];
    othersSelections: Selection[];
    simulationSlug: string;
    simulationName: string;
    unreadMessageIds: string[];
} & SapienParticipantPageProps;

export default function SimulationDisplayWrapper(
    props: SimulationDisplayProps,
) {
    const {
        contentBlocks,
        simulation_id,
        auth,
        colorGroups,
        variableValues,
        simulationTheme,
        simulationConfig,
        selections,
        team,
        simulationSlug,
        toasts,
        othersSelections,
        simulationName,
        modalComponent,
        modalPageProps,
        reflectionJournalEntries,
        submissionCountByContentBlockBehavior,
        decisionLogSelections,
        unreadMessageIds,
    } = props;

    const { setModalProps } = useModalComponentStore("ContentBlockModal");
    useEffect(() => {
        setModalProps(props);
        setIsSidebarOpen(false);
    }, [modalComponent, modalPageProps]);

    const { user } = useAuthStore();

    const reloadOnNotify = useCallback(
        (
            notification: {
                team: string;
                participant_id: string;
                toast?: ToastProps;
            },
            user: User | UserShape,
            only: string[] = [],
        ) => {
            const { participant_id, toast } = notification;
            if (participant_id !== user?.id) {
                if (toast) {
                    setNotification({
                        ...toast,
                        autoClose: toast.autoClose || 3000,
                    });
                }

                SapienInertia.reload({
                    preserveScroll: true,
                    preserveState: true,
                    only,
                    // only: [
                    //     "progress_messages",
                    //     "selections",
                    //     "participantAdvanceStatus",
                    //     "round"
                    // ],
                });
            }
        },
        [user],
    );

    const { setupPlayerSockets, setupTeamSockets } = useWebSockets();
    useEffect(() => {
        if (auth && auth.user && (!user || user.id !== auth.user.id)) {
            setupPlayerSockets(auth.user, {});
            setupTeamSockets(team.id, {
                "team.notify": (notification) =>
                    reloadOnNotify(
                        notification,
                        user,
                        Object.keys(props).filter(
                            (key) => !key.toLowerCase().includes("modal"),
                        ),
                    ),
                // "player.notify": reloadOnNotify,
            });
        }
    }, [user?.id, reloadOnNotify]);

    const {
        gotContentBlocks,
        contentBlocks: storeBlocks,
        gotVariableValues,
        gotTheme,
        gotSumbmissionCount,
        setUnreadMessageIds,
    } = useParticipantStore();

    const { setIsSidebarOpen } = useSidebarState();

    const { gotOthersSelections, gotDecisionLogSelections } =
        useOthersSelections();

    const { setPromptAndFormBlockDataFromContentBlocks } = usePromptStore();
    const { gotSelections } = useGotSelections();
    const { gotIdsForSavingSelections } = useGotIdsForSavingSelections();
    const { gotReflectionJournalEntries } = useReflectionJournalEntryStore();
    //hook handles comparing state to make sure we only update when the array contents have changed
    gotReflectionJournalEntries(reflectionJournalEntries);

    useEffect(() => {
        gotDecisionLogSelections(decisionLogSelections);
    }, [decisionLogSelections]);

    const { setDoneSubmitting } = useIsSubmitting();
    useEffect(() => {
        setDoneSubmitting();
        if (
            !storeBlocks ||
            !Object.keys(storeBlocks).length ||
            contentBlocks.length !== Object.keys(storeBlocks).length ||
            contentBlocks.some((contentBlock) => !storeBlocks[contentBlock.id])
        ) {
            gotContentBlocks(contentBlocks);
            setIsSidebarOpen(false);
        }
    }, [contentBlocks]);

    useEffect(() => {
        gotSumbmissionCount(submissionCountByContentBlockBehavior);
    }, [submissionCountByContentBlockBehavior]);

    const { setColorGroups } = useColorStore();

    useEffect(() => {
        setColorGroups(colorGroups);
    }, [colorGroups]);

    useEffect(() => {
        setPromptAndFormBlockDataFromContentBlocks(Object.values(storeBlocks));
    }, [storeBlocks]);

    useEffect(() => {
        gotSelections(selections);
    }, [selections, gotSelections]);

    useEffect(() => {
        gotOthersSelections(othersSelections);
    }, [othersSelections]);

    useEffect(() => {
        if (unreadMessageIds !== undefined)
            setUnreadMessageIds(unreadMessageIds);
    }, [unreadMessageIds]);

    useEffect(() => {
        gotIdsForSavingSelections(team.id, simulation_id);
    }, [team?.id, simulation_id]);

    const {} = useParticipantFormAndQuestionData();

    useEffect(() => {
        if (!!variableValues?.length) {
            gotVariableValues(variableValues);
        }
    }, [variableValues]);

    useEffect(() => {
        if (!!simulationTheme) {
            gotTheme(simulationTheme);
        }
    }, [simulationTheme]);

    const { gotSimulation } = useSelectedSimulation();

    useEffect(() => {
        gotSimulation({
            slug: simulationSlug,
            config: simulationConfig,
            is_course: false,
        } as SimulationShape & { is_course: boolean });
    }, [simulationSlug]);

    const { notification, setNotification } = useNotificationStore();
    useEffect(() => {
        if (toasts && toasts.length) {
            setNotification({
                autoClose: toasts[0].autoClose || 6000,
                text: toasts[0].message,
                isInline: true,
                textColor: "#4B5563",
                backgroundColor: "#F0FFF4",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08)",
            });
        }
    }, [toasts]);

    return (
        <>
            {!!notification && (
                <>
                    <StyledToast props={notification} />
                </>
            )}
            <WidthSideNav
                auth={auth}
                team={team}
                tenant={props.tenant}
                impersonating_user={props.impersonating_user}
            >
                <>
                    <Head title={simulationName} />

                    {storeBlocks && !!Object.keys(storeBlocks).length && (
                        <SimulationDisplay />
                    )}
                </>
            </WidthSideNav>
        </>
    );
}

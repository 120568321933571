import React, { useState } from "react";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/Select";
import { ContentBlockShape } from "@/models";
import { transformImageBlock } from "./transformImageBlock";
import { useSaveContentBlock } from "../../../content-block-management/useSaveContentBlock";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";
import { createPortal } from "react-dom";

const variants = [
    "text without image",
    "centered image without text",
    "text over centered image",
    "full width image without text",
    "full width image with text",
    "image on left of text",
    "image on right of text",
] as const;

export type ImageVariant = (typeof variants)[Exclude<
    keyof typeof variants,
    keyof Array<string>
>];
type Props = {
    width?: number;
    contentBlock: ContentBlockShape;
};

export default function ImageDropdown({ contentBlock }: Props) {
    const [variant, setVariant] = useState<ImageVariant>(
        (contentBlock.theme?.variant as ImageVariant) ||
            "centered image without text",
    );

    const { mutate, isPending } = useSaveContentBlock();

    const contentBlockElement = useGetElementAsync(
        `[data-content-block-id="${contentBlock.id}"]`,
    );
    return (
        <>
            {!!isPending &&
                !!contentBlockElement &&
                createPortal(
                    <div className="absolute inset-0 animate-pulse bg-white/50" />,
                    contentBlockElement,
                )}
            <Select
                onValueChange={(value) => {
                    setVariant(value as ImageVariant);
                    const updatedBlock = transformImageBlock(
                        contentBlock,
                        value as ImageVariant,
                    );
                    mutate(updatedBlock);
                }}
                disabled={isPending}
            >
                <SelectTrigger className="w-56 bg-white shadow-md">
                    <SelectValue placeholder={variant} />
                </SelectTrigger>
                <SelectContent className="cursor-pointer bg-white pl-0">
                    {variants.map((item) => (
                        <SelectItem
                            value={item}
                            key={item}
                            className="relative flex w-full flex-row"
                        >
                            <span>{item}</span>
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </>
    );
}

import { useCallback } from "react";
// import { ContentBlock, ContentBlockShape } from "../models";
// import { setThemedContentBlock } from "../model-configs";
import { useAppDispatch, useAppSelector } from "../redux-state";
import {
    // discardActiveContentBlock,
    // changeActiveContentBlock,
    setImageContentBlockId,
    setVideoContentBlockId,
    setBacgkgroundImageContentBlockId,
} from "../redux-state/contentEditorDataSlice";
// import { setAddSiblingBlockData } from "../redux-state/contentSelectorSlice";

// const requestDiscardConfirmation = () => {
//     let discardConfirmation = window.confirm("Discard all changes?");
//     return discardConfirmation;
// };

// const handleDiscard = (hasUnsavedChanges: boolean, discard: () => void) => {
//     if (
//         // if there are no unsaved changes
//         !hasUnsavedChanges
//     ) {
//         // discard active model
//         discard();
//         return true;
//     } else {
//         // otherwise, get confirmation to discard unsaved changes
//         if (requestDiscardConfirmation()) {
//             discard();
//             return true;
//         } else {
//             return false;
//         }
//     }
// };

// export const useHandleTransitionActiveContentBlock = () => {
//     const dispatch = useAppDispatch();

//     const _activeId = useAppSelector(
//         (state) => state.contentEditorStore.activeId
//     );

//     const _hasUnsavedChanges = useAppSelector(
//         (state) => state.contentEditorStore.hasUnsavedChanges
//     );

//     const handleTransitionActiveContentBlock = useCallback(
//         (contentBlock: ContentBlock | ContentBlockShape) => {
//             if (_activeId) {
//                 // handles (1) deselection of active model (without replacement)
//                 // or (2) switch from old to new active model.
//                 let isNewSelection = _activeId !== contentBlock.id;
//                 // check for unsaved changes before deselection/switch
//                 let isOldDiscarded = handleDiscard(_hasUnsavedChanges, () =>
//                     dispatch(discardActiveContentBlock())
//                 );
//                 // process complete in the case of deselection;
//                 // otherwise, if discard succeeded, proceed with setting new model
//                 if (isNewSelection && isOldDiscarded) {
//                     dispatch(
//                         changeActiveContentBlock(
//                             setThemedContentBlock(contentBlock)
//                         )
//                     );
//                 }
//             } else {
//                 dispatch(
//                     changeActiveContentBlock(
//                         setThemedContentBlock(contentBlock)
//                     )
//                 );
//             }

//             //get rid of data used to add siblings as well
//             dispatch(setAddSiblingBlockData(null));
//         },
//         [_activeId, _hasUnsavedChanges, dispatch]
//     );

//     return { activeId: _activeId, handleTransitionActiveContentBlock };
// };

// export const useDiscardActiveContentBlock = () => {
//     const dispatch = useAppDispatch();

//     const _discardActiveContentBlock = useCallback(
//         () => dispatch(discardActiveContentBlock()),
//         [dispatch]
//     );

//     return {
//         discardActiveContentBlock: _discardActiveContentBlock,
//     };
// };

// export const useHandleDiscardActiveContentBlock = () => {
//     const dispatch = useAppDispatch();

//     const _hasUnsavedChanges = useAppSelector(
//         (state) => state.contentEditorStore.hasUnsavedChanges
//     );

//     const handleDiscardActiveContentBlock = useCallback(() => {
//         return handleDiscard(_hasUnsavedChanges, () =>
//             dispatch(discardActiveContentBlock())
//         );
//     }, [dispatch, _hasUnsavedChanges]);

//     return {
//         hasUnsavedChanges: _hasUnsavedChanges,
//         handleDiscardActiveContentBlock,
//     };
// };

export const useEditorImageContentBlock = () => {
    const dispatch = useAppDispatch();

    const imageContentBlockId = useAppSelector(
        (state) => state.contentEditorStore.imageContentBlockId
    );

    const videoContentBlockId = useAppSelector(
        (state) => state.contentEditorStore.videoContentBlockId
    );

    const backgroundImageContentBlockId = useAppSelector(
        (state) => state.contentEditorStore.backgroundImageContentBlockId
    );

    const uploadedFileUrl = useAppSelector(
        (state) => state.contentEditorStore.uploadedFileUrl
    );

    const imageContentBlock = useAppSelector((state) =>
        state.contentEditorStore.imageContentBlockId &&
        state.contentBlockStore.contentBlocks[
            state.contentEditorStore.imageContentBlockId
        ]
            ? state.contentBlockStore.contentBlocks[
                  state.contentEditorStore.imageContentBlockId
              ]
            : null
    );

    const videoContentBlock = useAppSelector((state) =>
        state.contentEditorStore.videoContentBlockId &&
        state.contentBlockStore.contentBlocks[
            state.contentEditorStore.videoContentBlockId
        ]
            ? state.contentBlockStore.contentBlocks[
                  state.contentEditorStore.videoContentBlockId
              ]
            : null
    );

    const backgroundImageContentBlock = useAppSelector((state) =>
        state.contentEditorStore.backgroundImageContentBlockId &&
        state.contentBlockStore.contentBlocks[
            state.contentEditorStore.backgroundImageContentBlockId
        ]
            ? state.contentBlockStore.contentBlocks[
                  state.contentEditorStore.backgroundImageContentBlockId
              ]
            : null
    );

    const _setImageContentBlockId = useCallback(
        (imageContentBlockId: string) =>
            dispatch(setImageContentBlockId(imageContentBlockId)),
        [dispatch]
    );

    const _setVideoContentBlockId = useCallback(
        (imageContentBlockId: string) =>
            dispatch(setVideoContentBlockId(imageContentBlockId)),
        [dispatch]
    );

    const _setBackgroundImageContentBlockId = useCallback(
        (backgroundImageContentBlockId: string) =>
            dispatch(
                setBacgkgroundImageContentBlockId(backgroundImageContentBlockId)
            ),
        [dispatch]
    );

    return {
        imageContentBlockId,
        imageContentBlock,
        videoContentBlockId,
        videoContentBlock,
        backgroundImageContentBlockId,
        backgroundImageContentBlock,
        uploadedFileUrl,
        setImageContentBlockId: _setImageContentBlockId,
        setVideoContentBlockId: _setVideoContentBlockId,
        setBackgroundImageContentBlockId: _setBackgroundImageContentBlockId,
    };
};

import { useCallback } from "react";
import { ContentBlock, ContentBlockShape, ContentBlockType } from "@/models";
import { useAppDispatch, useAppSelector } from "@/redux-state";
import {
    gotContentBlock,
    gotContentBlocks,
    // gotTopLevelContentBlockLayers,
} from "@/redux-state/contentBlockDataSlice";

export const useFindContentBlockMethods = () => {
    const _contentBlocks: {
        [index: string]: ContentBlock | ContentBlockShape;
    } = useAppSelector((state) => state.contentBlockStore.contentBlocks);

    const _nestedContentBlocks = useAppSelector(
        (state) => state.contentBlockStore.nestedContentBlocks,
    );

    const rootContentBlocks = useAppSelector((state) =>
        Object.values(state.contentBlockStore.nestedContentBlocks).filter(
            (contentBlock) => !contentBlock.parent_content_block_id,
        ),
    );

    const getContentBlockWithChildren = useCallback(
        (contentBlockId: string) => {
            if (_nestedContentBlocks !== undefined) {
                return _nestedContentBlocks[contentBlockId];
            }
        },
        [_nestedContentBlocks],
    );

    const getContentBlock = useCallback(
        (contentBlockId: string) => {
            if (_contentBlocks !== undefined) {
                return _contentBlocks[contentBlockId];
            }
        },
        [_contentBlocks],
    );

    const getPageByRoute = useCallback(
        (route: string) => {
            return Object.values(_contentBlocks).find(
                (contentBlock) =>
                    contentBlock.content_block_type === ContentBlockType.Page &&
                    contentBlock.route.indexOf(route) !== -1,
            );
        },
        [_contentBlocks],
    );

    return {
        getContentBlockWithChildren,
        getContentBlock,
        getPageByRoute,
        rootContentBlocks,
    };
};

export const useGetContentBlocksByRoundId = () => {
    const dispatch = useAppDispatch();

    const _gotContentBlocks = useCallback(
        (contentBlocks: (ContentBlock | ContentBlockShape)[]) =>
            dispatch(gotContentBlocks(contentBlocks)),
        [dispatch],
    );

    return {
        gotContentBlocks: _gotContentBlocks,
    };
};

// export const useSimulationTheme = () => {
//     const _simulationTheme = useAppSelector(
//         (state) => state.contentBlockStore.simulationTheme,
//     );

//     return {
//         simulationTheme: _simulationTheme,
//     };
// };


// export const useGotTopLevelContentBlockLayers = () => {
//     const dispatch = useAppDispatch();

//     const _gotTopLevelContentBlockLayers = useCallback(
//         (topLevelContentBlockLayers) => {
//             dispatch(gotTopLevelContentBlockLayers(topLevelContentBlockLayers));
//         },
//         [dispatch],
//     );

//     return {
//         gotTopLevelContentBlockLayers: _gotTopLevelContentBlockLayers,
//     };
// };

export const useGotContentBlock = () => {
    const dispatch = useAppDispatch();

    const _gotContentBlock = useCallback(
        (contentBlock: ContentBlockShape) => {
            dispatch(gotContentBlock(contentBlock));
            contentBlock?.contentBlocks?.forEach((childContentBlock) => {
                _gotContentBlock(childContentBlock);
            });
        },
        [dispatch],
    );

    return {
        gotContentBlock: _gotContentBlock,
    };
};

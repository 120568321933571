import { ArrowUpIcon } from "lucide-react";
import React from "react";

const firstFourDirections = ["tl", "t", "tr", "l"];
const lastFourDirections = ["r", "bl", "b", "br"];
const directionMap = {
    tr: 45,
    t: 0,
    tl: -45,
    r: 90,
    l: -90,
    br: 135,
    b: 180,
    bl: -135,
};

type Props = {
    onChange: (direction: string) => void;
    value: string;
};

export default function DirectionPicker({ onChange, value }: Props) {
    return (
        <div
            className="grid h-20 w-20 grid-cols-3 grid-rows-3 flex-wrap overflow-hidden rounded-md
                border border-gray-300"
        >
            {firstFourDirections.map((direction, i) => (
                <button
                    className="flex h-full w-full items-center justify-center transition-colors duration-100
                        data-[is-active=true]:bg-gray-200 hover:bg-gray-200"
                    onClick={() => onChange(direction)}
                    key={direction}
                    data-is-active={value === direction}
                >
                    <ArrowUpIcon
                        className="h-4 w-4"
                        style={{
                            transform: `rotate(${directionMap[direction]}deg)`,
                        }}
                    />
                </button>
            ))}
            <div className="bg-red-transparent flex h-full w-full items-center justify-center" />

            {lastFourDirections.map((direction, i) => (
                <button
                    className="flex h-full w-full items-center justify-center transition-colors duration-100
                        data-[is-active=true]:bg-gray-200 hover:bg-gray-200"
                    onClick={() => onChange(direction)}
                    key={direction}
                    data-is-active={value === direction}
                >
                    <ArrowUpIcon
                        className="h-4 w-4"
                        style={{
                            transform: `rotate(${directionMap[direction]}deg)`,
                        }}
                    />
                </button>
            ))}
        </div>
    );
}

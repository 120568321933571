import React, { useRef } from "react";
import { ModelSuiteWrapper } from "@/Layouts/model-suite";
import { CenterPanelPageWrapper } from "../InteractiveModule/shared";
import { ModelBuilderMenu } from "./ModelBuilderMenu";
import { ModelBuilderNavCenter } from "./ModelBuilderNavCenter";

const ModelBuilderWrapperComponent = ({
    simulationTitle,
    simulationSlug,
    isInteractive,
    interactiveBlockId,
    interactiveBlockLabel,
    pageSection,
    setPageSection,
    children,
}: {
    simulationTitle: string;
    simulationSlug: string;
    isInteractive: boolean;
    interactiveBlockId: string;
    interactiveBlockLabel?: string;
    pageSection: string;
    setPageSection: (pageSection: string) => void;
    children: React.ReactNode;
}) => {
    const containerRef = useRef(null);

    return (
        <ModelSuiteWrapper
            simulationTitle={simulationTitle}
            simulationSlug={simulationSlug}
            logoRoute={"model-dashboard.index"}
            subtitle={"Model Builder"}
            childrenNavCenter={
                <>
                    <ModelBuilderNavCenter
                        simulationSlug={simulationSlug}
                        isInteractive={isInteractive}
                    />
                    {!!interactiveBlockId && (
                        <span
                            className="hidden items-center text-nowrap px-1 text-sm text-slate-500 sm:inline-flex
                                lg:px-2"
                        >
                            {interactiveBlockLabel || "block"}
                        </span>
                    )}
                </>
            }
            pageHeadTitle={"Model Builder"}
        >
            {(!isInteractive || !!interactiveBlockId) && (
                <ModelBuilderMenu
                    pageSection={pageSection}
                    setPageSection={setPageSection}
                    isInteractive={isInteractive}
                />
            )}
            <CenterPanelPageWrapper
                maxWidthClass={"max-w-none"}
                containerRef={containerRef}
            >
                <div className={"flex flex-col"}>{children}</div>
            </CenterPanelPageWrapper>
        </ModelSuiteWrapper>
    );
};

export const ModelBuilderWrapper = React.memo(ModelBuilderWrapperComponent);

import React, { useState } from "react";
import {
    ClipboardDocumentCheckIcon,
    ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { ModalContainer } from "@/modules/shared/";
import { useModalQueryParams } from "@/hooks/useModalQueryParams/useModalQueryParams";

export default function ParticipantLinkModal() {
    const {
        closeModal,
        queryParams,
        isAtModalUrl: isOpen,
    } = useModalQueryParams("ParticipantLinkModal");

    const { link } = queryParams;

    const [copiedText, setCopiedText] = useState("");

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(link)
            .then(() => {
                // If successful, update the isCopied state value
                setCopiedText(link);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <ModalContainer
            isModalOpen={isOpen}
            setIsModalOpen={() => closeModal()}
            size="s"
        >
            <div
                className="flex flex-col space-y-4 p-2"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <div className="flex flex-col space-y-2">
                    <div className="flex flex-col space-y-2 rounded border border-red-500 bg-red-100 p-4">
                        <h2 className="text-xl">
                            {/* Link created for {user.name} */}
                        </h2>
                        <h2 className="text-lg">
                            Click the link to copy it. You won't be able to view
                            it again.
                        </h2>
                    </div>
                    <div
                        className="flex items-center justify-between space-x-2 overflow-hidden"
                        onClick={() => handleCopyClick()}
                    >
                        {copiedText === link ? (
                            <ClipboardDocumentCheckIcon className="h-16 w-16 text-green-900" />
                        ) : (
                            <ClipboardDocumentIcon className="h-16 w-16" />
                        )}
                        <p className="truncate">{link}</p>
                    </div>
                </div>

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={closeModal}
                        className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4
                            py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700
                            focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                        Close
                    </button>
                </div>
            </div>
        </ModalContainer>
    );
}

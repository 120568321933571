import React, { useState } from "react";
import { ModalContainer } from "@/modules/shared";
import { CheckIcon, PlusIcon, XIcon } from "lucide-react";
import { isDark } from "@/tailwind-colors/util";
import { ColorPickerPopover } from "@/components/color-picker";

type Props = {
    isSelected: boolean;
    onSelect: (colors: string[]) => void;
    colors: string[];
    name: string;
    currentColors: string[];
};

function hasAllColors(colors: string[], pallette: string[]) {
    return colors.every((color) => pallette.includes(color));
}

function CustomColorInput({
    onSelect,
    allColors,
}: {
    onSelect: (color: string) => void;
    allColors: string[];
}) {
    const [color, setColor] = useState<string>("");

    return (
        <div className="flex gap-2">
            <ColorPickerPopover
                value={color}
                onChange={(value) => {
                    setColor(value);
                }}
                onGradientChange={() => {}}
            />
            {color && !allColors.includes(color) && (
                <div className="flex items-center gap-2">
                    <button
                        className="rounded-full bg-gray-200 p-2"
                        onClick={() => {
                            setColor("");
                            onSelect(color);
                        }}
                    >
                        <PlusIcon className="h-4 w-4" />
                    </button>
                </div>
            )}
        </div>
    );
}

export function ColorThemeButton({
    isSelected,
    onSelect,
    colors,
    name,
    currentColors,
}: Props) {
    const [isBeingSelected, setIsBeingSelected] = useState(false);
    const [customColors, setCustomColors] = useState<string[]>([]);
    const [palette, setPalette] = useState(colors);

    return (
        <>
            <button
                className="group flex flex-col items-start justify-between border border-black p-4
                    shadow-[2px_2px_0px_1px_#111827] transition-colors duration-300
                    data-[is-selected=true]:bg-black data-[is-selected=true]:text-white
                    hover:bg-gray-900 hover:text-white"
                data-is-selected={isSelected}
                onClick={() => {
                    setIsBeingSelected(true);
                }}
            >
                <span
                    className="text-sm font-bold "
                    data-is-selected={isSelected}
                >
                    {name}
                </span>

                <span className="flex items-center">
                    {colors.map((color, i) => (
                        <span
                            key={color}
                            className="-ml-1 h-4 w-4 rounded-full border bg-gray-200 transition-colors duration-300
                                group-hover:!border-white group-data-[is-selected=false]:border-black
                                group-data-[is-selected=true]:border-white"
                            style={{
                                backgroundColor: color,
                            }}
                        ></span>
                    ))}
                </span>
            </button>
            <ModalContainer
                isModalOpen={isBeingSelected}
                setIsModalOpen={setIsBeingSelected}
                size="s"
            >
                <div className="flex flex-col gap-4 p-4">
                    <div className="flex justify-between">
                        <h2 className="text-lg font-bold">
                            Build Your Color Palette
                        </h2>
                        <button
                            className="rounded-md bg-red-200 p-2"
                            onClick={() => setIsBeingSelected(false)}
                        >
                            <XIcon className="h-4 w-4" />
                        </button>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-lg font-bold">Custom Colors</h2>
                        <CustomColorInput
                            allColors={palette}
                            onSelect={(color) => {
                                setCustomColors([...customColors, color]);
                                setPalette([...palette, color]);
                            }}
                        />
                        <div className="flex flex-wrap gap-2">
                            {customColors.map((color) => (
                                <label key={color}>
                                    <input
                                        type="checkbox"
                                        key={color}
                                        className="hidden"
                                        style={{
                                            backgroundColor: color,
                                        }}
                                        checked={palette.includes(color)}
                                        onChange={() => {
                                            setPalette(
                                                palette.includes(color)
                                                    ? palette.filter(
                                                          (c) => c !== color,
                                                      )
                                                    : [...palette, color],
                                            );
                                        }}
                                    />
                                    <span
                                        className="group flex h-8 w-8 appearance-none items-center justify-center rounded-full
                                            border border-black bg-gray-200 transition-colors duration-300"
                                        style={{
                                            backgroundColor: color,
                                        }}
                                        data-is-dark-color={isDark(color)}
                                    >
                                        {palette.includes(color) && (
                                            <CheckIcon
                                                className="h-4 w-4 group-data-[is-dark-color=false]:text-black
                                                    group-data-[is-dark-color=true]:text-white"
                                            />
                                        )}
                                    </span>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-2">
                            <label className="flex items-center gap-2 text-lg font-bold">
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        if (
                                            hasAllColors(currentColors, palette)
                                        ) {
                                            setPalette(
                                                palette.filter(
                                                    (c) =>
                                                        !currentColors.includes(
                                                            c,
                                                        ),
                                                ),
                                            );
                                        } else {
                                            setPalette([
                                                ...palette,
                                                ...currentColors,
                                            ]);
                                        }
                                    }}
                                    checked={hasAllColors(
                                        currentColors,
                                        palette,
                                    )}
                                />
                                <span>Current Colors</span>
                            </label>
                            <div className="flex flex-wrap gap-2">
                                {currentColors.map((color) => (
                                    <label key={color}>
                                        <input
                                            type="checkbox"
                                            key={color}
                                            className="hidden"
                                            style={{
                                                backgroundColor: color,
                                            }}
                                            checked={palette.includes(color)}
                                            onChange={() => {
                                                setPalette(
                                                    palette.includes(color)
                                                        ? palette.filter(
                                                              (c) =>
                                                                  c !== color,
                                                          )
                                                        : [...palette, color],
                                                );
                                            }}
                                        />
                                        <span
                                            className="group flex h-8 w-8 appearance-none items-center justify-center rounded-full
                                                border border-black bg-gray-200 transition-colors duration-300"
                                            style={{
                                                backgroundColor: color,
                                            }}
                                            data-is-dark-color={isDark(color)}
                                        >
                                            {palette.includes(color) && (
                                                <CheckIcon
                                                    className="h-4 w-4 group-data-[is-dark-color=false]:text-black
                                                        group-data-[is-dark-color=true]:text-white"
                                                />
                                            )}
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <label className="flex items-center gap-2 text-lg font-bold">
                                <input
                                    type="checkbox"
                                    onChange={() => {
                                        if (hasAllColors(colors, palette)) {
                                            setPalette(
                                                palette.filter(
                                                    (c) => !colors.includes(c),
                                                ),
                                            );
                                        } else {
                                            setPalette([...palette, ...colors]);
                                        }
                                    }}
                                    checked={hasAllColors(colors, palette)}
                                />
                                <span>{name} Colors</span>
                            </label>

                            <div className="flex flex-wrap gap-2">
                                {colors.map((color) => (
                                    <label key={color}>
                                        <input
                                            type="checkbox"
                                            key={color}
                                            className="hidden"
                                            style={{
                                                backgroundColor: color,
                                            }}
                                            checked={palette.includes(color)}
                                            onChange={() => {
                                                setPalette(
                                                    palette.includes(color)
                                                        ? palette.filter(
                                                              (c) =>
                                                                  c !== color,
                                                          )
                                                        : [...palette, color],
                                                );
                                            }}
                                        />
                                        <span
                                            className="group flex h-8 w-8 appearance-none items-center justify-center rounded-full
                                                border border-black bg-gray-200 transition-colors duration-300"
                                            style={{
                                                backgroundColor: color,
                                            }}
                                            data-is-dark-color={isDark(color)}
                                        >
                                            {palette.includes(color) && (
                                                <CheckIcon
                                                    className="h-4 w-4 group-data-[is-dark-color=false]:text-black
                                                        group-data-[is-dark-color=true]:text-white"
                                                />
                                            )}
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 rounded-md bg-gray-200 p-4">
                            <h2 className="text-lg font-bold">
                                Selected Colors
                            </h2>
                            <div className="flex flex-wrap gap-2">
                                {palette.map((color) => (
                                    <div
                                        key={color + "_selected"}
                                        className="h-8 w-8 appearance-none rounded-full border border-black bg-gray-200
                                            transition-colors duration-300"
                                        style={{
                                            backgroundColor: color,
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end gap-2 p-2">
                        <button
                            className="rounded-md bg-red-200 p-2"
                            onClick={() => setIsBeingSelected(false)}
                        >
                            Cancel
                        </button>
                        <button
                            className="rounded-md bg-green-200 p-2"
                            onClick={() => {
                                onSelect(palette);
                                setIsBeingSelected(false);
                            }}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
}

import React, { useCallback, useEffect } from "react";
import { ContentBlockShape, Option, OptionShape } from "@/models";
import {
    PencilIcon,
    CheckIcon,
    XMarkIcon,
    PlusIcon,
} from "@heroicons/react/24/solid";
import {
    DependentOption,
    DynamicValidationRule,
} from "@/models/DynamicValidationRule";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
import {} from "react-hot-toast";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";

type Props = {
    form: ContentBlockShape;
    closeModal: () => void;
};

function buildOptionDependencyMap(form: ContentBlockShape) {
    return form.contentBlocks.reduce((carry, contentBlock) => {
        const promptOptionMap = contentBlock.prompt.options.reduce(
            (acc, option) => {
                const relaventRules = form?.dynamicValidationRules?.filter(
                    (rule) => {
                        return rule.options.some((_option) => {
                            return (
                                _option.id === option.id &&
                                _option.relation_type === "child"
                            );
                        });
                    },
                );

                const dependencies = relaventRules.reduce((acc, rule) => {
                    return [
                        ...acc,
                        ...rule.options
                            .filter(
                                (_option) => _option.relation_type === "parent",
                            )
                            .map((_option) => _option),
                    ];
                }, []);
                return {
                    ...acc,
                    [option.id]: dependencies,
                };
            },
            {},
        );
        return { ...carry, ...promptOptionMap };
    }, {});
}

function buildValidationRuleLabelsByOptionId(form: ContentBlockShape) {
    return form.dynamicValidationRules.reduce((carry, rule) => {
        const childOption = rule.options.find(
            (option) => option.relation_type === "child",
        );
        if (!childOption) return carry;
        return { ...carry, [childOption.id]: rule.label };
    }, {});
}

type State = {
    optionDependencyMap: { [index: string]: DependentOption[] };
    validationRuleLabelsByOptionId: { [index: string]: string };
    editedValidationRuleIds: string[];
    validationRules: DynamicValidationRule[];
    selectedOption: Option | OptionShape | null;
};

const initialState: State = {
    optionDependencyMap: {},
    validationRuleLabelsByOptionId: {},
    editedValidationRuleIds: [],
    validationRules: [],
    selectedOption: null,
};

type Action =
    | {
          type: "SET_OPTION_DEPENDENCY_MAP";
          optionDependencyMap: { [index: string]: DependentOption[] };
      }
    | {
          type: "SET_VALIDATION_RULE_LABELS_BY_OPTION_ID";
          validationRuleLabelsByOptionId: { [index: string]: string };
      }
    | {
          type: "SET_VALIDATION_RULES";
          validationRules: DynamicValidationRule[];
      }
    | {
          type: "SET_FORM";
          form: ContentBlockShape;
      }
    | { type: "SET_SELECTED_OPTION"; option: Option | OptionShape }
    | { type: "EDIT_VALIDATION_RULE_LABEL"; id: string };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_OPTION_DEPENDENCY_MAP":
            return {
                ...state,
                optionDependencyMap: action.optionDependencyMap,
            };
        case "SET_VALIDATION_RULE_LABELS_BY_OPTION_ID":
            return {
                ...state,
                validationRuleLabelsByOptionId:
                    action.validationRuleLabelsByOptionId,
            };
        case "SET_VALIDATION_RULES":
            return { ...state, validationRules: action.validationRules };
        case "SET_SELECTED_OPTION":
            return { ...state, selectedOption: action.option };
        case "EDIT_VALIDATION_RULE_LABEL":
            return {
                ...state,
                editedValidationRuleIds: [
                    ...state.editedValidationRuleIds,
                    action.id,
                ],
            };
        case "SET_FORM":
            return {
                ...state,
                editedValidationRuleIds: [],
                validationRules: action.form.dynamicValidationRules,
                optionDependencyMap: buildOptionDependencyMap(action.form),
                validationRuleLabelsByOptionId:
                    buildValidationRuleLabelsByOptionId(action.form),
            };
    }
};

export function DynamicValidationDependencyForm({ form }: Props) {
    const [
        {
            optionDependencyMap,
            validationRules,
            validationRuleLabelsByOptionId,
            selectedOption,
            editedValidationRuleIds,
        },
        dispatch,
    ] = React.useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({ type: "SET_FORM", form });
    }, [form]);

    const addDependency = useCallback(
        (
            parentOption: OptionShape,
            childOption: OptionShape & { relation_type: string },
        ) => {
            const oldValidationRule = validationRules.find((rule) => {
                return rule.options.some((option) => {
                    return (
                        option.id === childOption.id &&
                        option.relation_type === "child"
                    );
                });
            });

            console.log(
                "OLD RULE",
                oldValidationRule,
                childOption,
                parentOption,
            );

            const validationRule = oldValidationRule
                ? ({
                      ...oldValidationRule,
                      options: [
                          ...oldValidationRule.options,
                          { ...parentOption, relation_type: "parent" },
                      ],
                      label:
                          validationRuleLabelsByOptionId[childOption.id] ||
                          `${parentOption.content} -> ${childOption.content}`,
                      rule_type: "dependency",
                      formId: form.id,
                  } as DynamicValidationRule & { formId: string })
                : ({
                      options: [
                          { ...parentOption, relation_type: "parent" },
                          { ...childOption, relation_type: "child" },
                      ],
                      label:
                          validationRuleLabelsByOptionId[childOption.id] ||
                          `${parentOption.content} -> ${childOption.content}`,
                      rule_type: "dependency",
                      formId: form.id,
                  } as DynamicValidationRule & { formId: string });

            save(validationRule);
        },
        [
            form,
            validationRules,
            optionDependencyMap,
            validationRuleLabelsByOptionId,
        ],
    );

    const save = useCallback(
        (validationRule: DynamicValidationRule & { formId: string }) => {
            const childOption = validationRule.options.find(
                (option) => option.relation_type === "child",
            );

            if (validationRule.id) {
                SapienInertia.put(
                    "creator.design.dynamic-validation.update",
                    {
                        ...validationRule,
                        label:
                            validationRuleLabelsByOptionId[childOption.id] ||
                            validationRule.label,
                    } as (typeof LaravelRequestBodyShapes)["creator.design.dynamic-validation.update"],
                    {
                        dynamic_validation: validationRule.id,
                    },
                    {
                        preserveState: true,
                        preserveScroll: true,
                    },
                );
            } else {
                SapienInertia.post("creator.design.dynamic-validation.store", {
                    ...validationRule,
                    label:
                        validationRuleLabelsByOptionId[childOption.id] ||
                        validationRule.label,
                } as (typeof LaravelRequestBodyShapes)["creator.design.dynamic-validation.store"]);
            }
        },
        [
            validationRuleLabelsByOptionId,
            form,
            validationRules,
            optionDependencyMap,
        ],
    );

    return (
        <div className="flex flex-col p-4">
            <h6 className="p-2 text-lg">Questions</h6>
            <div className="flex flex-col space-y-2">
                {form?.contentBlocks?.map((contentBlock) => (
                    <div className="flex w-full flex-col overflow-hidden rounded-md border border-[#374151]">
                        <p className="border-b border-[#374151] p-4 font-bold">
                            {contentBlock.prompt.content}
                        </p>
                        {contentBlock.prompt.options.map((option) => (
                            <div className="grid grid-cols-2 bg-white/5">
                                <div
                                    className={`flex w-full flex-col justify-end space-y-2 border-b border-l border-[#374151]
                                    p-4 ${selectedOption?.id === option.id && "bg-[#1F2A37]"}`}
                                >
                                    <div className="flex flex-col divide-y-2 divide-[#374151]">
                                        {!!optionDependencyMap &&
                                            !!optionDependencyMap[option.id] &&
                                            optionDependencyMap[option.id].map(
                                                (
                                                    depedency: DependentOption,
                                                ) => (
                                                    <p className="flex w-full items-center space-x-2 p-2 ">
                                                        <button
                                                            className="rounded-full bg-white p-1 text-red-600 shadow hover:text-red-800"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                SapienInertia.delete(
                                                                    sapienRoute(
                                                                        "creator.design.dynamic-validation.destroy",
                                                                        {
                                                                            dynamic_validation:
                                                                                depedency.dynamic_validation_rule_option_id,
                                                                        },
                                                                    ),
                                                                );
                                                            }}
                                                        >
                                                            <XMarkIcon className="h-4 w-4" />
                                                        </button>
                                                        <span>
                                                            {depedency.content}
                                                        </span>
                                                    </p>
                                                ),
                                            )}
                                    </div>
                                    <button
                                        className="flex items-center justify-center space-x-2 rounded bg-white/5 px-2 py-1
                                            text-white/80 transition-all hover:bg-white/10 hover:text-white"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch({
                                                type: "SET_SELECTED_OPTION",
                                                option,
                                            });
                                        }}
                                    >
                                        <PlusIcon className="h-4 w-4 text-blue-600" />
                                        <span>Add Dependency</span>{" "}
                                    </button>
                                </div>
                                <div
                                    role="button"
                                    className={`flex w-full flex-col space-y-2 border-b border-l border-[#374151] p-4 ${
                                        selectedOption?.id === option.id &&
                                        "bg-[#1F2A37]"
                                    }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                            !selectedOption ||
                                            selectedOption.id === option.id
                                        )
                                            return;
                                        addDependency(option, {
                                            ...selectedOption,
                                            relation_type: "child",
                                        });
                                    }}
                                >
                                    <div>{option.content}</div>

                                    <div className="flex items-center space-x-2">
                                        <textarea
                                            disabled={
                                                !editedValidationRuleIds.includes(
                                                    option.id,
                                                )
                                            }
                                            value={
                                                validationRuleLabelsByOptionId[
                                                    option.id
                                                ]
                                            }
                                            placeholder="Enter label"
                                            onChange={(e) => {
                                                dispatch({
                                                    type: "SET_VALIDATION_RULE_LABELS_BY_OPTION_ID",
                                                    validationRuleLabelsByOptionId:
                                                        {
                                                            ...validationRuleLabelsByOptionId,
                                                            [option.id]:
                                                                e.target.value,
                                                        },
                                                });
                                            }}
                                            className="block w-full rounded-md border-0 border-gray-300 bg-white/10 px-3 py-1.5
                                                text-gray-200 focus:text-white focus:outline-offset-0 focus:outline-[#1d4ed8]
                                                sm:text-sm sm:leading-6"
                                        />
                                        {editedValidationRuleIds.includes(
                                            option.id,
                                        ) ? (
                                            <button
                                                className="rounded-full bg-white p-1 text-green-600 shadow hover:text-green-800"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const validationRule =
                                                        validationRules.find(
                                                            (rule) =>
                                                                rule.options.some(
                                                                    (_option) =>
                                                                        _option.id ===
                                                                            option.id &&
                                                                        _option.relation_type ===
                                                                            "child",
                                                                ),
                                                        );

                                                    if (validationRule) {
                                                        const childOption =
                                                            validationRule.options.find(
                                                                (option) =>
                                                                    option.relation_type ===
                                                                    "child",
                                                            );
                                                        if (childOption) {
                                                            save({
                                                                ...validationRule,
                                                                formId: form.id,
                                                                label: validationRuleLabelsByOptionId[
                                                                    option.id
                                                                ],
                                                            });
                                                        }
                                                    }
                                                }}
                                            >
                                                <CheckIcon className="h-4 w-4" />
                                            </button>
                                        ) : (
                                            <button
                                                className="rounded-full bg-white p-1 text-blue-600 shadow hover:text-blue-800"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const validationRule =
                                                        validationRules.find(
                                                            (rule) =>
                                                                rule.options.some(
                                                                    (_option) =>
                                                                        _option.id ===
                                                                            option.id &&
                                                                        _option.relation_type ===
                                                                            "child",
                                                                ),
                                                        );

                                                    if (validationRule) {
                                                        dispatch({
                                                            type: "EDIT_VALIDATION_RULE_LABEL",
                                                            id: option.id,
                                                        });
                                                    }
                                                }}
                                            >
                                                <PencilIcon className="h-4 w-4" />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="grid gap-6 border-t border-[#374151] pt-6 md:grid-cols-2">
                <div></div>
                <button
                    onClick={() => {
                        SapienInertia.reload();
                    }}
                    className="inline-flex w-full items-center justify-center rounded-full px-5 py-2.5
                        text-center text-sm font-medium text-white hover:bg-[#1F2A37] focus:outline-none
                        focus:ring-2 focus:ring-blue-300"
                >
                    Close
                </button>
            </div>
        </div>
    );
}

import React from "react";
import { Link } from "@inertiajs/react";

export function ModelSuiteTextNavLink({
    href,
    active,
    children,
}: {
    href: string;
    active: boolean;
    children: React.ReactNode;
}) {
    return (
        <div className="inline-flex items-center">
            <Link
                href={href}
                className={
                    active
                        ? `inline-flex items-center text-nowrap px-1 py-1 text-sm font-medium tracking-wide
                            text-indigo-500 transition duration-150 ease-in-out hover:text-indigo-500
                            focus:outline-none focus:ring-0 sm:px-2`
                        : `inline-flex items-center text-nowrap px-1 py-1 text-sm font-medium tracking-wide
                            text-slate-400 transition duration-150 ease-in-out hover:text-slate-300
                            focus:outline-none focus:ring-0 sm:px-2`
                }
            >
                {children}
            </Link>
        </div>
    );
}

export function ModelSuiteIconNavLink({
    href,
    title,
    active,
    children,
}: {
    href: string;
    title: string;
    active: boolean;
    children: React.ReactNode;
}) {
    return (
        <Link
            href={href}
            title={title}
            className={`rounded-full p-2 text-sm focus:outline-none focus:ring-0 ${
                !!active
                    ? "text-indigo-600 hover:text-indigo-500"
                    : "text-slate-300 hover:text-slate-100"
            }`}
        >
            {children}
        </Link>
    );
}

import { ContentBlockShape, ContentBlockType } from "../../models";
import {
    defaultHero3Theme,
    Display,
    FlexDirection,
} from "../../styles";

function buildHero3({
    imagePlacement,
    textPlacement,
    palette,
}): ContentBlockShape {
    return {
        title: "Hero",
        content: "Hero",
        content_block_type: ContentBlockType.Hero3,
        theme: {
            imagePlacement,
            textPlacement,
            display: "flex",
            flexDirection: "column",
            justifyContent: ["flex-start", "flex-end"],
            alignItems: "center",
            minHeight: "100vh",
            width: "100%",
            zIndex: 2,
            padding: [36, 52, 60, 64],
            ...defaultHero3Theme,
        },
        contentBlocks: [
            {
                content_block_type: ContentBlockType.Box,
                weight: 0,
                theme: {
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    maxWidth: ["100%", "96%", "88%", "844px"],
                },
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Box,
                        weight: 0,
                        theme: {
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            maxWidth: ["100%", "96%", "88%", "844px"],
                            test: "thisone",
                        },
                        contentBlocks: [
                            {
                                content_block_type: ContentBlockType.Typography,
                                weight: 0,
                                theme: {
                                    typographyType: "SUBTITLE",
                                    textAlign: "center",
                                    color: palette.textColor,
                                },
                                content: "Resilience: Season 1 Episode 1",
                            },
                            {
                                content_block_type: ContentBlockType.Typography,
                                weight: 1,
                                theme: {
                                    typographyType: "HEADING_3",
                                    textAlign: "center",
                                    color: palette.textColor,
                                    mt: 12,
                                },
                                content: " Welcome to Stiegler International",
                            },
                            {
                                content_block_type: ContentBlockType.Typography,
                                weight: 2,
                                theme: {
                                    typographyType:"PARAGRAPH_ALT",
                                    textAlign: "center",
                                    color: palette.subtitleColor,
                                    mt: 24,
                                },
                                content: `Stiegler International is a large multinational consumer
                                    goods business that began in the United States in 1959.
                                    Today, it sells products ranging from domestic appliances to
                                    over-ear headphones across the globe.`,
                            },
                        ],
                    },
                ],
            },
            {
                content_block_type: ContentBlockType.Box,
                weight: 1,
                theme: {
                    borderBottom: "1px solid white",
                    color: "white",
                    mt: 48,
                    pb: 16,
                    width: "100%",
                },
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Typography,
                        weight: 0,
                        theme: {
                            typographyType: "PARAGRAPH",
                            color: "white",
                        },
                    },
                ],
            },
            {
                content_block_type: ContentBlockType.Box,
                weight: 2,
                theme: {
                    display: Display.flex,
                    flexDirection: [
                        FlexDirection.column,
                        FlexDirection.column,
                        FlexDirection.column,
                        FlexDirection.row,
                    ],
                    justifyContent: "space-between",
                    gridColumnGap: [1, 2, 3, 4],
                    gridRowGap: [4, 3, 2, 1],
                    mt: 16,
                },
                contentBlocks: [
                    {
                        content_block_type: ContentBlockType.Box,
                        weight: 0,
                        theme: {
                            flexDirection: "column",
                        },
                        contentBlocks: [
                            {
                                content_block_type: ContentBlockType.Typography,
                                weight: 0,
                                theme: {
                                    typographyType: "SUBTITLE",
                                    textAlign: "left",
                                    color: palette.textColor,
                                    content: `Operating Income`,
                                },
                            },
                            {
                                content_block_type: ContentBlockType.Typography,
                                weight: 1,
                                theme: {
                                    typographyType:
                                        "PARAGRAPH_SMALL",
                                    textAlign: "left",
                                    color: palette.textColor,
                                    mt: "8px",
                                },
                                content: `Reduces the amount Stiegler is able to spend on other
                                priorities or return to shareholders in the form of
                                dividends.`,
                            },
                        ],
                    },
                    {
                        weight: 1,
                        content_block_type: ContentBlockType.Box,
                        theme: {
                            flexDirection: "column",
                        },
                        contentBlocks: [
                            {
                                weight: 0,
                                content_block_type: ContentBlockType.Typography,
                                theme: {
                                    typographyType: "SUBTITLE",
                                    textAlign: "left",
                                    color: palette.textColor,
                                    content: `Operating Income`,
                                },
                            },
                            {
                                weight: 1,
                                content_block_type: ContentBlockType.Typography,
                                theme: {
                                    typographyType:"PARAGRAPH_SMALL",
                                    textAlign: "left",
                                    color: palette.textColor,
                                    mt: "8px",
                                },
                                content: `Reduces the amount Stiegler is able to spend on other
                            priorities or return to shareholders in the form of
                            dividends.`,
                            },
                        ],
                    },
                    {
                        weight: 2,
                        content_block_type: ContentBlockType.Box,
                        theme: {
                            flexDirection: "column",
                        },
                        contentBlocks: [
                            {
                                weight: 0,
                                content_block_type: ContentBlockType.Typography,
                                theme: {
                                    typographyType: "SUBTITLE",
                                    textAlign: "left",
                                    color: palette.textColor,
                                    content: `Operating Income`,
                                },
                            },
                            {
                                weight: 1,
                                content_block_type: ContentBlockType.Typography,
                                theme: {
                                    typographyType:"PARAGRAPH_SMALL",
                                    textAlign: "left",
                                    color: palette.textColor,
                                    mt: "8px",
                                },
                                content: `Reduces the amount Stiegler is able to spend on other
                            priorities or return to shareholders in the form of
                            dividends.`,
                            },
                        ],
                    },
                ],
            },
        ],
    };
}

export const Hero3Layout: ContentBlockShape = buildHero3({
    imagePlacement: "left",
    textPlacement: "center",
    palette: {
        backgroundColor: "",
        textColor: "#FFF",
        borderColor: "",
        highlightColor: "",
        highlightBackgroundColor: "",
        subtitleColor: "#D1D5DB",
    },
});

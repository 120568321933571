import { sapienAxios } from "@/inertia-utils/hooks";
import { ContentBlockShape, Simulation } from "@/models";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";

export type ModalRequestBodyForExistingBlock = Omit<
    (typeof LaravelRequestBodyShapes)["creator.design.store.modal"],
    "childContentBlockType"
>;

export type ModalRequestBodyForNewBlock = Omit<
    (typeof LaravelRequestBodyShapes)["creator.design.store.modal"],
    "secondary_content_block_id"
>;

export type ModalRequestBody =
    | ModalRequestBodyForNewBlock
    | ModalRequestBodyForExistingBlock;

export async function saveModal({
    body,
    selectedSimulation,
}: {
    body: ModalRequestBody;
    selectedSimulation: Partial<Simulation>;
}) {
    const { data } = await sapienAxios.post<
        {
            contentBlocks: ContentBlockShape[];
        },
        "creator.design.store.modal"
    >("creator.design.store.modal", body, {
        simulationSlug: selectedSimulation?.slug,
        roundId: body.round_id,
    });

    return data?.contentBlocks;
}

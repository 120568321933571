import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { UserIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { SapienPageProps } from "@/inertia-utils/types";
import { DataStudioWrapper } from "./DataStudioWrapper";
import { Cohort, Prompt, PromptType, Round, SimulationShape } from "@/models";
import { sapienAxios } from "@/inertia-utils/hooks";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { StatisticsObject } from "./types";
import { displayPromptType } from "@/util";
import { groupBy } from "lodash";
import {
    DiscoveryBoxPlot,
    DiscoveryPieChart,
} from "../Discovery/shared/DiscoveryCharts";
import { FilterMultiSelect } from "./SharedComponents";

enum PromptScope {
    "Participant" = "Participant",
    "Team" = "Team",
}

interface FilterState {
    types: PromptType[];
    scopes: PromptScope[];
    rounds: string[];
    cohorts: string[];
}

type SandboxOption = {
    option_id: string;
    option_content: string;
    option_weight: number;
};

type SandboxPrompt = {
    prompt_id: string;
    prompt_content: string;
    prompt_weight: number;
    prompt_type: PromptType;
    prompt_scope: PromptScope;
    prompt_min: number;
    prompt_max: number;
};

type SandboxRound = {
    round_id: string;
    round_title: string;
    round_weight: number;
};

type SandboxRoundPromptOption = SandboxRound & SandboxPrompt & SandboxOption;

type SandboxCohortTeamUser = {
    cohort_id: string;
    cohort_title: string;
    cohort_created_at: string;

    team_id: string;
    team_name: string;
    team_number: number;

    user_id: string;
    user_name: string;

    user_team: string;
};

// type SandboxSelection = SandboxRoundPromptOption &
//     SandboxCohortTeamUser & {
//         selection_id: string;
//         selection_value_boolean: boolean;
//         selection_value_number: number;
//         selection_value_string: string;
//         selection_created_at: string;
//         selection_updated_at: string;

//         simulation_id: string;
//     };

type OptionAnalysis = Partial<StatisticsObject> & {
    percent?: number;
    mode?: number;
    position_counts?: number[];
};

type PromptAnalysis = {
    option_stats: {
        [option_id: string]: OptionAnalysis;
    };
    prompt_content: string;
    prompt_id: string;
    prompt_type: PromptType;
    submission_count: number;
    unprocessed_data: string[];
};

type SelectionAnalysis = {
    round_prompt_option_data: SandboxRoundPromptOption[];
    nested_rounds: (SandboxRound & {
        prompts: (SandboxPrompt & { options: SandboxOption[] })[];
    })[];
    cohort_team_user_data: SandboxCohortTeamUser[];
    analysis_by_prompt: { [prompt_id: string]: PromptAnalysis };
    cluster_analysis: {
        user_clusters: { [prompt_or_option_id: string]: StatisticsObject }[];
        team_clusters: { [prompt_or_option_id: string]: StatisticsObject }[];
    };
    selection_count: number;
    submission_count: number;
};

type SelectionAnalysisPayload = FilterState & {
    simulation_id: string;
    date_from?: string;
    date_to?: string;
    search?: string;
};

type SelectionAnalysisResponse = {
    selection_analysis: SelectionAnalysis;
    // selection_data: SandboxSelection[];
};

async function selectionAnalysisFunction({
    body,
}: {
    body: Partial<SelectionAnalysisPayload>;
}) {
    const { data } = await sapienAxios.post<
        SelectionAnalysisResponse,
        "data-studio.api.selection-analysis"
    >(
        "data-studio.api.selection-analysis",
        body as Partial<SelectionAnalysisPayload>,
        {},
    );

    return data;
}

const handleSelectionAnalysis = (parameters: SelectionAnalysisPayload) => {
    const { data } = useQuery({
        queryKey: ["selectionAnalysis", parameters],
        queryFn: async () => {
            const data = await selectionAnalysisFunction({
                body: parameters,
            });
            return data;
        },
        select: (data) => data,
        placeholderData: keepPreviousData,
        enabled: !!parameters && !!parameters.simulation_id,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

    return data;
};

const useHandleSelectionAnalysis = (parameters: SelectionAnalysisPayload) => {
    const response = handleSelectionAnalysis(parameters);
    return (
        response ??
        ({
            selection_analysis: {},
            // selection_data: [],
        } as SelectionAnalysisResponse)
    );
};

const tabKeys = ["Questions by Round", "Questions by Type", "Cluster Analysis"];

const TabContentWrapper = ({
    tabKey,
    children,
}: {
    tabKey: string;
    children: React.ReactNode;
}) => {
    return (
        <Tabs.Content key={tabKey} value={tabKey} style={{}}>
            <div className={"mx-auto my-0 w-full min-w-80 max-w-7xl"}>
                <div className="flex w-full flex-col space-y-8 px-6 py-8 2xl:px-0">
                    {/* <div className="text-lg font-bold text-slate-700 dark:text-slate-300">
                        {tabKey}
                    </div> */}
                    {children}
                </div>
            </div>
        </Tabs.Content>
    );
};

const QuestionDisplay = ({
    prompt,
    userCount,
    teamCount,
    promptAnalysis,
}: {
    prompt: SandboxPrompt & { options: SandboxOption[] };
    userCount: number;
    teamCount: number;
    promptAnalysis: PromptAnalysis;
}) => {
    return (
        <div key={prompt.prompt_id} className="mt-2 pt-2">
            <div>
                <div className="flex flex-row items-center justify-between pb-4">
                    <span className="flex items-center">
                        <span>
                            {prompt.prompt_scope === "Participant" ? (
                                <UserIcon className="h-5 w-5 text-gray-400" />
                            ) : (
                                <UserGroupIcon className="h-5 w-5 text-gray-400" />
                            )}
                        </span>
                        <span className="ml-2 rounded px-2.5 py-0.5 text-xs font-medium">
                            {`${promptAnalysis?.submission_count ?? 0} of ${
                                prompt.prompt_scope === "Participant"
                                    ? `${userCount} users`
                                    : `${teamCount} teams`
                            }`}
                        </span>
                    </span>
                    <div className="flex items-center">
                        <span className="text-sm uppercase text-gray-400">
                            {displayPromptType(prompt.prompt_type)}
                        </span>
                    </div>
                </div>
                <div className="pb-4 text-base font-semibold">
                    {prompt.prompt_content}
                </div>
                <div className="rounded-md dark:bg-slate-200/75">
                    {(prompt.prompt_type == PromptType["Multiple Choice"] ||
                        prompt.prompt_type == PromptType["Multiple Select"] ||
                        prompt.prompt_type == PromptType["Toggle Switch"] ||
                        prompt.prompt_type == PromptType["Dropdown List"]) && (
                        <DiscoveryPieChart
                            useColorScale={true}
                            data={prompt.options.reduce(
                                (map, option) => [
                                    ...map,
                                    {
                                        x: option.option_content,
                                        y:
                                            promptAnalysis?.option_stats?.[
                                                option.option_id
                                            ]?.count ?? 0,
                                        label: option.option_content,
                                        formattedValue:
                                            promptAnalysis?.option_stats?.[
                                                option.option_id
                                            ]?.count ?? 0,
                                    },
                                ],
                                [],
                            )}
                        />
                    )}
                    {(prompt.prompt_type == PromptType["Numerical Slider"] ||
                        prompt.prompt_type ==
                            PromptType["Numerical Input"]) && (
                        <DiscoveryBoxPlot
                            data={prompt.options.reduce(
                                (map, option) => [
                                    ...map,
                                    {
                                        x: " ",
                                        min:
                                            promptAnalysis?.option_stats?.[
                                                option.option_id
                                            ]?.min ?? 0,
                                        q1:
                                            promptAnalysis?.option_stats?.[
                                                option.option_id
                                            ]?.["25%"] ?? 0,
                                        median:
                                            promptAnalysis?.option_stats?.[
                                                option.option_id
                                            ]?.["50%"] ?? 0,
                                        q3:
                                            promptAnalysis?.option_stats?.[
                                                option.option_id
                                            ]?.["75%"] ?? 0,
                                        max:
                                            promptAnalysis?.option_stats?.[
                                                option.option_id
                                            ]?.max ?? 0,
                                    },
                                ],
                                [],
                            )}
                            height={100}
                            domain={
                                prompt.prompt_min < prompt.prompt_max
                                    ? {
                                          y: [
                                              prompt.prompt_min,
                                              prompt.prompt_max,
                                          ],
                                      }
                                    : undefined
                            }
                        />
                    )}
                    {(prompt.prompt_type == PromptType["Short Text"] ||
                        prompt.prompt_type == PromptType["Long Text"]) && (
                        <div className="space-y-1 rounded-md p-2 dark:bg-slate-800/95">
                            {promptAnalysis?.unprocessed_data?.map(
                                (text, i) => <div key={i}>{text}</div>,
                            )}
                        </div>
                    )}
                    {(prompt.prompt_type == PromptType["Rank Order"] ||
                        prompt.prompt_type == PromptType["Drag and Drop"] ||
                        prompt.prompt_type == PromptType.Timeline) && (
                        <div className="space-y-1 rounded-md p-2 dark:bg-slate-800/95">
                            {prompt?.options?.map((option) => (
                                <div key={option.option_id} className="">
                                    {`${option.option_content}`}
                                    <pre>
                                        {JSON.stringify(
                                            promptAnalysis?.option_stats?.[
                                                option.option_id
                                            ] ?? {},
                                        )}
                                    </pre>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const DataStudioQuestionsDetail = ({
    simulationId,
    filters,
}: {
    simulationId: string;
    filters: FilterState;
}) => {
    const selectionAnalysisResponse = useHandleSelectionAnalysis({
        simulation_id: simulationId,
        ...filters,
    });
    const { selection_analysis } = selectionAnalysisResponse;

    const [activeTab, setActiveTab] = useState(tabKeys[0]);
    const [displayRoundMap, setDisplayRoundMap] = useState<{
        [roundId: string]: boolean;
    }>(
        selection_analysis?.nested_rounds?.reduce(
            (map, round) => ({
                ...map,
                [round.round_id]: false,
            }),
            {},
        ),
    );

    useEffect(() => {
        const newMap = selection_analysis?.nested_rounds?.reduce(
            (map, round) => ({
                ...map,
                [round.round_id]:
                    !!displayRoundMap &&
                    displayRoundMap[round.round_id] !== undefined
                        ? displayRoundMap[round.round_id]
                        : true,
            }),
            {},
        );
        setDisplayRoundMap(newMap);
    }, [selection_analysis]);

    const userCount = useMemo(() => {
        return selection_analysis?.cohort_team_user_data?.length ?? 0;
    }, [selection_analysis]);

    const teamCount = useMemo(() => {
        return (
            Object.keys(
                groupBy(
                    selection_analysis?.cohort_team_user_data ?? [],
                    "team_id",
                ),
            )?.length ?? 0
        );
    }, [selection_analysis]);

    return (
        <div className="space-y-4">
            <div>{`Submissions: ${selection_analysis?.submission_count ?? 0}`}</div>
            <div className="flex w-full justify-center">
                <Tabs.Root
                    onValueChange={setActiveTab}
                    value={activeTab}
                    className="w-full"
                >
                    <Tabs.List className="flex w-full justify-center border-b border-slate-300/75 dark:border-slate-800">
                        {tabKeys.map((tabKey, i) => (
                            <Tabs.Trigger
                                key={tabKey}
                                value={tabKey}
                                className={`text-slate-700 data-[state="active"]:border-b
                                data-[state="active"]:border-indigo-600 data-[state="active"]:text-slate-900
                                dark:text-slate-400 dark:data-[state="active"]:border-indigo-600
                                dark:data-[state="active"]:text-slate-100`}
                            >
                                <div
                                    className="px-1 py-2 text-xs font-light tracking-wide hover:bg-slate-300/25 sm:px-2
                                        sm:text-sm md:px-4 md:text-base dark:hover:bg-slate-800/25"
                                >
                                    {tabKey}
                                </div>
                            </Tabs.Trigger>
                        ))}
                    </Tabs.List>
                    {Object.keys(selection_analysis)?.length > 0 ? (
                        <>
                            <TabContentWrapper tabKey={tabKeys[0]}>
                                <div className="space-y-4">
                                    {selection_analysis?.nested_rounds?.map(
                                        (round) => (
                                            <div
                                                key={round.round_id}
                                                className="rounded-md border border-slate-600/50 p-4 text-slate-900 dark:text-slate-200"
                                            >
                                                <div
                                                    className="-m-4 flex cursor-pointer items-center justify-between rounded-md bg-slate-50 p-4
                                                        text-sm font-semibold hover:bg-slate-200 dark:bg-slate-800/25
                                                        dark:hover:bg-slate-800/50"
                                                    onClick={() =>
                                                        setDisplayRoundMap({
                                                            ...(displayRoundMap ??
                                                                {}),
                                                            [round.round_id]:
                                                                !!displayRoundMap
                                                                    ? !displayRoundMap[
                                                                          round
                                                                              .round_id
                                                                      ]
                                                                    : true,
                                                        })
                                                    }
                                                >
                                                    {round.round_title}
                                                </div>
                                                {!!displayRoundMap &&
                                                    !!displayRoundMap[
                                                        round.round_id
                                                    ] && (
                                                        <div className="flex flex-col space-y-2 divide-y divide-slate-600/50 py-2 text-xs">
                                                            {round.prompts.map(
                                                                (prompt) => (
                                                                    <QuestionDisplay
                                                                        key={
                                                                            prompt.prompt_id
                                                                        }
                                                                        prompt={
                                                                            prompt
                                                                        }
                                                                        userCount={
                                                                            userCount
                                                                        }
                                                                        teamCount={
                                                                            teamCount
                                                                        }
                                                                        promptAnalysis={
                                                                            selection_analysis
                                                                                ?.analysis_by_prompt?.[
                                                                                prompt
                                                                                    .prompt_id
                                                                            ]
                                                                        }
                                                                    />
                                                                ),
                                                            )}
                                                        </div>
                                                    )}
                                            </div>
                                        ),
                                    )}
                                </div>
                            </TabContentWrapper>
                            <TabContentWrapper tabKey={tabKeys[1]}>
                                <></>
                            </TabContentWrapper>
                            <TabContentWrapper tabKey={tabKeys[2]}>
                                <>
                                    <div className="space-y-4">
                                        {Object.keys(
                                            selection_analysis?.cluster_analysis,
                                        )
                                            ?.filter(
                                                (key) =>
                                                    selection_analysis
                                                        .cluster_analysis[key]
                                                        ?.length > 0,
                                            )
                                            .map((key) => (
                                                <div
                                                    key={key}
                                                    className="space-y-4 rounded-md border border-slate-600/50 p-4 text-slate-900
                                                        dark:text-slate-200"
                                                >
                                                    <div>{key}</div>
                                                    <div className="space-y-4 pl-4 text-sm">
                                                        {selection_analysis.cluster_analysis[
                                                            key
                                                        ].map(
                                                            (
                                                                cluster,
                                                                clusterNumber,
                                                            ) => (
                                                                <div
                                                                    key={
                                                                        clusterNumber
                                                                    }
                                                                    className="space-y-1"
                                                                >
                                                                    <div className="pb-2">{`Cluster ${clusterNumber + 1}`}</div>
                                                                    {Object.keys(
                                                                        cluster,
                                                                    )?.map(
                                                                        (
                                                                            promptOrOptionId,
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    promptOrOptionId
                                                                                }
                                                                                className="flex justify-between space-x-4 pl-4 text-xs"
                                                                            >
                                                                                <div>
                                                                                    {selection_analysis
                                                                                        ?.analysis_by_prompt?.[
                                                                                        promptOrOptionId
                                                                                    ]
                                                                                        ?.prompt_content ||
                                                                                        selection_analysis?.round_prompt_option_data?.find(
                                                                                            (
                                                                                                roundPromptOption,
                                                                                            ) =>
                                                                                                roundPromptOption.option_id ==
                                                                                                promptOrOptionId,
                                                                                        )
                                                                                            ?.option_content}
                                                                                </div>
                                                                                <div className="flex space-x-2">
                                                                                    {Object.keys(
                                                                                        cluster[
                                                                                            promptOrOptionId
                                                                                        ],
                                                                                    )?.map(
                                                                                        (
                                                                                            stat,
                                                                                        ) => (
                                                                                            <div
                                                                                                key={
                                                                                                    stat
                                                                                                }
                                                                                            >{`${stat}: ${Math.round(100 * (cluster[promptOrOptionId][stat] ?? 0)) / 100}`}</div>
                                                                                        ),
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ),
                                                                    )}
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </>
                            </TabContentWrapper>
                        </>
                    ) : (
                        <div className="py-6 text-slate-600 dark:text-slate-400">
                            No submission data
                        </div>
                    )}
                </Tabs.Root>
            </div>
        </div>
    );
};

const SELECT_MENU_LABELS: {
    [index in keyof FilterState]: string;
} = {
    types: "Question Types",
    scopes: "Question Scopes",
    rounds: "Rounds",
    cohorts: "Cohorts",
};

function getPromptTypesFromRounds(
    rounds: Round[],
): (keyof typeof PromptType)[] {
    const promptTypeKeys: (keyof typeof PromptType)[] = [];

    rounds.forEach((round) => {
        round.prompts.forEach((prompt) => {
            const matchingKey = Object.keys(PromptType).find(
                (key) =>
                    PromptType[key as keyof typeof PromptType] ===
                    prompt.prompt_type,
            ) as keyof typeof PromptType | undefined;

            if (matchingKey && !promptTypeKeys.includes(matchingKey)) {
                promptTypeKeys.push(matchingKey);
            }
        });
    });

    return Object.keys(PromptType).filter((key) =>
        promptTypeKeys.includes(key as keyof typeof PromptType),
    ) as (keyof typeof PromptType)[];
}

type Props = SapienPageProps & { simulation: SimulationShape };

export default function DataStudioQuestions({ simulation }: Props) {
    const [filters, setFilters] = useState<FilterState>({
        types: [],
        scopes: [],
        rounds: [],
        cohorts: [],
    });

    const rounds: Round[] = useMemo(() => {
        return (
            simulation?.rounds
                ?.map(
                    (round) =>
                        ({
                            ...round,
                            prompts: (
                                round as Round & {
                                    promptsWithoutOptions: Prompt[];
                                }
                            ).promptsWithoutOptions,
                        }) as Round,
                )
                .filter((round) => round.prompts?.length > 0) ?? []
        );
    }, [simulation]);

    const promptTypes: (keyof typeof PromptType)[] = useMemo(() => {
        return getPromptTypesFromRounds(rounds);
    }, [rounds]);

    const cohorts: Cohort[] = useMemo(() => {
        return (
            simulation?.cohorts?.filter(
                (cohort) => cohort.selections_count > 0,
            ) ?? []
        );
    }, [simulation]);

    const itemsObject = useMemo<{
        [index in keyof FilterState]: {
            [index: string]: string;
        };
    }>(() => {
        return {
            types: promptTypes?.reduce(
                (map, key) => ({ ...map, [PromptType[key]]: key }),
                {},
            ),
            scopes: Object.keys(PromptScope).reduce(
                (map, key) => ({ ...map, [PromptScope[key]]: key }),
                {},
            ),
            rounds:
                rounds?.reduce(
                    (map, round) => ({
                        ...map,
                        [round.id]: `${round.title} (questions: ${round.prompts?.length})`,
                    }),
                    {},
                ) ?? {},
            cohorts:
                cohorts?.reduce(
                    (map, cohort) => ({
                        ...map,
                        [cohort.id]: `${cohort.title} (teams: ${cohort.teams_count}, users: ${cohort.team_user_rounds_count})`,
                    }),
                    {},
                ) ?? {},
        };
    }, [rounds, promptTypes, cohorts]);

    const handleFilterChange = useCallback(
        (
            category: keyof FilterState,
            items: (string | PromptType | PromptScope)[],
        ) => {
            setFilters((prev) => {
                const newItems = Object.keys(itemsObject[category]).filter(
                    (item) => items.includes(item),
                );
                const updated = {
                    ...prev,
                    [category]: newItems,
                };
                return updated;
            });
        },
        [itemsObject],
    );

    const clearFilters = useCallback(() => {
        const cleared = {
            types: [],
            scopes: [],
            rounds: [],
            cohorts: [],
        };
        setFilters(cleared);
    }, []);

    return (
        <DataStudioWrapper
            simulationTitle={simulation?.title}
            simulationSlug={simulation?.slug}
        >
            <div className="flex w-full flex-col">
                <div className="mx-auto w-full max-w-7xl p-6">
                    <div className="space-y-4">
                        <div
                            className="flex flex-col justify-between space-x-0 space-y-2 sm:flex-row sm:items-center
                                sm:space-x-2 sm:space-y-0"
                        >
                            <h1 className="text-lg font-bold text-slate-700 dark:text-slate-300">
                                {"Question Data"}
                            </h1>
                            <div className="flex items-center space-x-2 text-slate-600 dark:text-slate-400">
                                <div
                                    className="flex rounded-md border border-slate-200 hover:border-slate-300
                                        dark:border-slate-800 dark:hover:border-slate-700"
                                >
                                    <button
                                        onClick={clearFilters}
                                        className="rounded-md bg-slate-50/50 px-2 py-1.5 text-xs hover:bg-slate-100/25
                                            dark:bg-slate-800/20 dark:hover:bg-slate-800/25"
                                    >
                                        <div className="font-light tracking-wide">
                                            Clear All Filters
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full flex-col space-y-2">
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3">
                                {!!itemsObject &&
                                    Object.keys(SELECT_MENU_LABELS)
                                        .filter((label) => label !== "scopes")
                                        .map((key) => (
                                            <div
                                                key={key}
                                                className="flex flex-col space-y-2"
                                            >
                                                <div className="text-slate-600 dark:text-slate-400">
                                                    {SELECT_MENU_LABELS[key]}
                                                </div>
                                                <FilterMultiSelect
                                                    items={Object.keys(
                                                        itemsObject[key],
                                                    )}
                                                    itemDisplayLabels={Object.values(
                                                        itemsObject[key],
                                                    )}
                                                    selectedItems={filters[key]}
                                                    handleSetItems={(
                                                        items: string[],
                                                    ) =>
                                                        handleFilterChange(
                                                            key as keyof FilterState,
                                                            items,
                                                        )
                                                    }
                                                    isDisabled={false}
                                                />
                                                <div className="text-sm text-orange-600 dark:text-orange-400">
                                                    {filters[key].length > 0 ? (
                                                        <div>{`${filters[
                                                            key
                                                        ].map(
                                                            (item) =>
                                                                ` ${itemsObject[key][item]}`,
                                                        )}`}</div>
                                                    ) : (
                                                        <div className="text-sm text-slate-500 dark:text-slate-500">{`No ${key} selected`}</div>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                            </div>
                        </div>
                        <div>
                            <DataStudioQuestionsDetail
                                simulationId={simulation?.id}
                                filters={filters}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </DataStudioWrapper>
    );
}

import React from "react";
import { FormattedChartValue } from "./SharedTypes";
import styled from "styled-components";

type Props = {
    formattedChartValues: { [index: string]: FormattedChartValue[] };
    filteredIndices: number[];
    setFilteredSeriesIndices: React.Dispatch<React.SetStateAction<number[]>>;
    setHoveredIndex: (index: number) => void;
    hoveredIndex: number;
    color: string;
    hoverBackgroundColor?: string;
};

const LegendButton = styled.div<{ hoverBackgroundColor: string }>`
    &:hover {
        background-color: ${(props) => props.hoverBackgroundColor};
    }
`;

export default function Legend({
    formattedChartValues,
    setFilteredSeriesIndices,
    setHoveredIndex,
    filteredIndices,
    color = "black",
    hoverBackgroundColor = "rgb(243 244 246)",
}: Props) {
    return (
        <div className="flex flex-wrap gap-1 p-4">
            {Object.keys(formattedChartValues).map((key, i) => {
                const values = formattedChartValues[key];
                if (!values?.length) return null;

                const series = values[0];
                return (
                    <LegendButton
                        key={key}
                        className="flex text-black gap-1 items-center max-w-xl justify:center cursor:pointer rounded-md p-2"
                        role="button"
                        onClick={() => {
                            setFilteredSeriesIndices((filteredIndices) => {
                                if (filteredIndices.includes(i)) {
                                    return filteredIndices.filter(
                                        (index) => index !== i
                                    );
                                } else {
                                    return [...filteredIndices, i];
                                }
                            });
                        }}
                        onMouseEnter={() => {
                            setHoveredIndex(i);
                        }}
                        onMouseLeave={() => {
                            setHoveredIndex(-1);
                        }}
                        hoverBackgroundColor={hoverBackgroundColor}
                    >
                        <div
                            className="w-2 h-2 rounded-full"
                            style={{
                                background:
                                    series?.theme?.fill ||
                                    series?.theme?.stroke,
                            }}
                        ></div>
                        <p className={`text-sm`} style={{ color }}>
                            {series.label}
                        </p>
                    </LegendButton>
                );
            })}
        </div>
    );
}

import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { DataStudioProps } from "./types";
import { useDataStudio } from "./useDataStudio";
import { ClusterAnalysisDisplay } from "./ClusterAnalysisDisplay";
import { RegressionDisplay } from "./RegressionDisplay";
import { ForecastDisplay } from "./ForecastDisplay";
import { OverviewDisplay } from "./OverviewDisplay";
import { ClaudeChat } from "./ClaudeChat";
import { SubsectionWrapper } from "./SharedComponents";

const TabContentWrapper = ({
    tabKey,
    children,
}: {
    tabKey: string;
    children: React.ReactNode;
}) => {
    return (
        <Tabs.Content key={tabKey} value={tabKey} style={{}}>
            <div className={"mx-auto my-0 w-full min-w-80 max-w-7xl"}>
                <div
                    className="flex w-full flex-col space-y-8 divide-y-[1px] divide-slate-500 px-6 py-8
                        2xl:px-0 dark:divide-slate-500"
                >
                    <div className="text-lg font-bold text-slate-700 dark:text-slate-300">
                        {tabKey}
                    </div>
                    {children}
                </div>
            </div>
        </Tabs.Content>
    );
};

const DataStudioFilesDetailComponent = ({
    tabKeys,
    data,
    timeHorizons,
    variables,
    dataFile,
}: { tabKeys: string[] } & Partial<DataStudioProps>) => {
    const { regressionProps, clusterAnalysisProps, forecastProps } =
        useDataStudio(
            dataFile?.file_metadata?.default_parameters || {
                x: [],
                y: [],
                z: [],
                forecast_length: 2,
            },
        );

    return (
        <>
            <TabContentWrapper tabKey={tabKeys[0]}>
                <OverviewDisplay
                    data={data}
                    timeHorizons={timeHorizons}
                    variables={variables}
                />
            </TabContentWrapper>
            <TabContentWrapper tabKey={tabKeys[1]}>
                <RegressionDisplay variables={variables} {...regressionProps} />
            </TabContentWrapper>
            <TabContentWrapper tabKey={tabKeys[2]}>
                <ClusterAnalysisDisplay
                    variables={variables}
                    {...clusterAnalysisProps}
                />
            </TabContentWrapper>
            <TabContentWrapper tabKey={tabKeys[3]}>
                {!!timeHorizons && timeHorizons?.length > 0 ? (
                    <ForecastDisplay
                        variables={variables}
                        timeHorizons={timeHorizons}
                        {...forecastProps}
                    />
                ) : (
                    <div className="pt-2 text-slate-500">
                        No time series data to analyze
                    </div>
                )}
            </TabContentWrapper>
            <TabContentWrapper tabKey={tabKeys[4]}>
                <SubsectionWrapper title="">
                    <ClaudeChat />
                </SubsectionWrapper>
            </TabContentWrapper>
        </>
    );
};

export const DataStudioFilesDetail = React.memo(DataStudioFilesDetailComponent);

import React, { useEffect } from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { SapienPageProps } from "@/inertia-utils/types";
import { DataStudioWrapper } from "./DataStudioWrapper";
import { SimulationShape } from "@/models";
import { FileSpreadsheet, BarChart2, MessageSquare } from "lucide-react";
import { ClaudeChat } from "./ClaudeChat";
import { useSelectedSimulation } from "@/hooks";

type Props = SapienPageProps & {
    simulation: SimulationShape & { is_course: boolean };
};

export default function DataStudioIndex({ simulation }: Props) {
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();

    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
    }, [simulation]);

    return (
        <DataStudioWrapper
            simulationTitle={simulation?.title}
            simulationSlug={simulation?.slug}
        >
            <div className="flex w-full flex-col justify-center">
                <div className="mx-auto w-full max-w-7xl p-6">
                    <div className="space-y-6">
                        <div className="space-y-6 rounded-lg bg-slate-50 p-6 dark:bg-slate-800/25">
                            <div className="flex flex-col space-y-1.5">
                                <div className="font-normal leading-none tracking-wide text-slate-900 dark:text-slate-200">
                                    New Analysis
                                </div>
                                <div className="text-sm text-slate-500 dark:text-slate-400">
                                    Choose a data source to begin
                                </div>
                            </div>
                            <div className="">
                                <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 md:grid-cols-3">
                                    <InertiaLink
                                        className="flex items-center"
                                        href={sapienRoute(
                                            "data-studio.sets.index",
                                            {
                                                simulationSlug:
                                                    simulation?.slug,
                                            },
                                        )}
                                    >
                                        <button
                                            className="flex h-full w-full flex-col items-center justify-between rounded-lg border p-4
                                                transition-colors hover:bg-slate-100 dark:border-slate-800
                                                dark:hover:bg-slate-800"
                                        >
                                            <FileSpreadsheet className="mb-2 h-8 w-8 text-slate-600 dark:text-slate-400" />
                                            <h3 className="font-semibold text-slate-900 dark:text-slate-50">
                                                Data Files
                                            </h3>
                                            <p className="mt-1 text-center text-sm text-slate-500 dark:text-slate-400">
                                                Work with uploaded datasets
                                            </p>
                                        </button>
                                    </InertiaLink>
                                    <InertiaLink
                                        className="flex items-center"
                                        href={sapienRoute(
                                            "data-studio.questions.index",
                                            {
                                                simulationSlug:
                                                    simulation?.slug,
                                            },
                                        )}
                                    >
                                        <button
                                            className="flex h-full w-full flex-col items-center justify-between rounded-lg border p-4
                                                transition-colors hover:bg-slate-100 dark:border-slate-800
                                                dark:hover:bg-slate-800"
                                        >
                                            <MessageSquare className="mb-2 h-8 w-8 text-slate-600 dark:text-slate-400" />
                                            <h3 className="font-semibold text-slate-900 dark:text-slate-50">
                                                Question Data
                                            </h3>
                                            <p className="mt-1 text-center text-sm text-slate-500 dark:text-slate-400">
                                                Explore participant responses
                                            </p>
                                        </button>
                                    </InertiaLink>
                                    <InertiaLink
                                        className="flex items-center"
                                        href={sapienRoute(
                                            "data-studio.variables.index",
                                            {
                                                simulationSlug:
                                                    simulation?.slug,
                                            },
                                        )}
                                    >
                                        <button
                                            className="flex h-full w-full flex-col items-center justify-between rounded-lg border p-4
                                                transition-colors hover:bg-slate-100 dark:border-slate-800
                                                dark:hover:bg-slate-800"
                                        >
                                            <BarChart2 className="mb-2 h-8 w-8 text-slate-600 dark:text-slate-400" />
                                            <h3 className="font-semibold text-slate-900 dark:text-slate-50">
                                                Model Variables
                                            </h3>
                                            <p className="mt-1 text-center text-sm text-slate-500 dark:text-slate-400">
                                                Analyze simulation variables
                                            </p>
                                        </button>
                                    </InertiaLink>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-6 rounded-lg bg-slate-50 p-6 dark:bg-slate-800/25">
                            <div className="flex flex-col space-y-6">
                                <div className="font-normal leading-none tracking-wide text-slate-900 dark:text-slate-200">
                                    Explore with AI
                                </div>
                                <ClaudeChat />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                            <div className="space-y-6 rounded-lg bg-slate-50 p-6 dark:bg-slate-800/25">
                                <div className="flex flex-col space-y-1.5">
                                    <div className="font-normal leading-none tracking-wide text-slate-900 dark:text-slate-200">
                                        Templates
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 rounded-lg bg-slate-50 p-6 dark:bg-slate-800/25">
                                <div className="flex flex-col space-y-1.5">
                                    <div className="font-normal leading-none tracking-wide text-slate-900 dark:text-slate-200">
                                        Recent Activity
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DataStudioWrapper>
    );
}

import React, { useEffect, useMemo } from "react";
import { z } from "zod";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {
    useFormContext,
    Controller,
    useWatch,
    FieldErrors,
    FieldError,
} from "react-hook-form";
import { QuestionContentBlockRankingComponent } from "./QuestionContentBlockRankingComponent";
import { QuestionContentBlockErrorDisplay } from "./QuestionContentBlockErrorDisplay";
import {
    FormLabel,
    FormInput,
    FormSelect,
    FormOption,
    FormTextArea,
    FormInputRadioCheckbox,
    FormInputRange,
    FormInputToggle,
    FormToggleLabel,
    FormToggleDiv,
    FormToggleLeftLabel,
    FormToggleRightLabel,
    FormRadioOrCheckboxGroup,
} from "./QuestionComponents";
import { QuestionContentBlockFormFieldTheme } from "./types";
import { ColorScheme, Prompt, PromptType, SelectionShape } from "@/models";
import { QuestionContentBlockDragDropComponent } from "./drag-and-drop/QuestionContentBlockDragDropComponent";
import { QuestionContentBlockTimeline } from "./drag-and-drop/QuestionContentBlockTimeline";

type QuestionContentBlockFormFieldProps = {
    promptId: string;
    prompt: Prompt;
    palette?: ColorScheme;
    theme?: QuestionContentBlockFormFieldTheme;
    optionRenderMap: { [index: string]: JSX.Element | JSX.Element[] };
    isInDesignContext: boolean;
};

function getSliderLeftPct(min: number, max: number, position: number) {
    return ((position - min) / (max - min)) * 100;
}
type CustomError = {
    sum: { type: string };
    dependency: { type: string; error: any; valid: boolean };
    message: string;
};
const RenderFormField = ({
    promptId,
    prompt,
    palette,
    theme,
    optionRenderMap,
    isInDesignContext,
}: QuestionContentBlockFormFieldProps) => {
    const { register, control, formState, setValue, trigger } =
        useFormContext();

    const { errors } = formState;
    const customErrors = errors as FieldErrors<{
        [index: string]: CustomError;
    }>;

    const error = useMemo(() => {
        const { errors } = formState;

        if (!!errors && !!errors[promptId] && "message" in errors[promptId]) {
            return errors[promptId] as FieldError;
        }
    }, [customErrors, promptId, formState]);

    const numericErrors = useMemo(() => {
        if (
            typeof customErrors[promptId] === "object" &&
            typeof customErrors[promptId]?.sum === "object" &&
            (customErrors[promptId]?.sum?.type === z.ZodIssueCode.too_big ||
                customErrors[promptId]?.sum?.type === z.ZodIssueCode.too_small)
        ) {
            return customErrors[promptId].sum;
        }
    }, [formState, promptId, customErrors]);

    const dependencyValidationErrors = useMemo(() => {
        if (
            !!errors &&
            !!errors[promptId] &&
            typeof customErrors[promptId] === "object" &&
            customErrors[promptId]?.dependency &&
            typeof customErrors[promptId]?.dependency === "object" &&
            customErrors[promptId]?.dependency?.error
        ) {
            return customErrors[promptId].dependency.error;
        }

        return {};
    }, [formState, promptId, customErrors]);

    const dependencyValidationValid = useMemo(() => {
        if (
            !!customErrors &&
            !!customErrors[promptId] &&
            typeof customErrors[promptId] === "object" &&
            customErrors[promptId]?.dependency &&
            typeof customErrors[promptId]?.dependency === "object" &&
            customErrors[promptId]?.dependency?.valid
        ) {
            return customErrors[promptId].dependency.valid;
        }

        return {};
    }, [customErrors, promptId]);

    // unselect options that are disabled due to dependency validation errors
    useEffect(() => {
        const keysToUncheck = Object.keys(dependencyValidationErrors);
        if (keysToUncheck.length) {
            const newValue = Array.isArray(watchedValue)
                ? watchedValue?.filter(
                      (value) => !keysToUncheck.includes(value),
                  )
                : "";

            setValue(promptId, newValue, {
                shouldTouch: true,
                shouldDirty: true,
            });
        }
    }, [dependencyValidationErrors, formState]);

    const registerReturn = register(promptId);

    const watchedValue = useWatch({ control, name: promptId });

    switch (prompt.prompt_type) {
        case PromptType["Short Text"]:
            return (
                <>
                    <FormInput
                        type="text"
                        name={promptId}
                        {...registerReturn}
                    />
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Numerical Input"]:
            return (
                <>
                    <FormInput
                        type="number"
                        name={promptId}
                        {...registerReturn}
                        // min={
                        //     typeof prompt.min === "number"
                        //         ? prompt.min
                        //         : undefined
                        // }
                        // max={prompt.max || undefined}
                        // step={
                        //     prompt.increment !== undefined &&
                        //     prompt.increment !== 0
                        //         ? prompt.increment
                        //         : "any"
                        // }
                    />
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Dropdown List"]:
            return (
                <>
                    <FormSelect name={promptId} {...registerReturn}>
                        <FormOption key={""} value={""} disabled />
                        {!!prompt.options &&
                            prompt.options.map((option) => (
                                <FormOption
                                    key={option.id}
                                    value={option.content}
                                    data-option-id={option.id}
                                    data-content-block-id={
                                        option.content_block_id
                                    }
                                >
                                    {option.content}
                                </FormOption>
                            ))}
                    </FormSelect>
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Long Text"]:
            return (
                <>
                    <FormTextArea name={promptId} {...registerReturn} />
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Multiple Choice"]:
            return (
                <>
                    <FormRadioOrCheckboxGroup
                        spaced={theme?.spaced || "not spaced"}
                        palette={palette}
                        bordered={theme?.bordered || "not bordered"}
                        collapsed={theme?.collapsed}
                        isInDesignContext={isInDesignContext}
                    >
                        <>
                            {!!prompt.options &&
                                prompt.options.map((option) => (
                                    <FormLabel
                                        key={option.id}
                                        isSelected={option.id === watchedValue}
                                        palette={palette}
                                        highLightStyle={
                                            theme?.highLightStyle || "none"
                                        }
                                        highlightColor={theme?.highlightColor}
                                        data-content-block-id={
                                            option.content_block_id
                                        }
                                    >
                                        <div className="flex w-full flex-col items-start justify-center space-y-2">
                                            <span className="flex w-full flex-row items-center">
                                                <FormInputRadioCheckbox
                                                    data-option-id={option.id}
                                                    type="radio"
                                                    name={promptId}
                                                    value={option.id}
                                                    {...registerReturn}
                                                    disabled={
                                                        !!dependencyValidationErrors?.[
                                                            option.id
                                                        ]
                                                    }
                                                    checked={
                                                        option.id ===
                                                        watchedValue
                                                    }
                                                    checkMarkColor={
                                                        theme?.checkMarkColor
                                                    }
                                                />
                                                <span className="mt-1">
                                                    {option.content}
                                                </span>
                                            </span>
                                            {(!!dependencyValidationErrors?.[
                                                option.id
                                            ] ||
                                                !!dependencyValidationValid[
                                                    option.id
                                                ]) && (
                                                <div className="text-xs text-blue-600">
                                                    <InformationCircleIcon className="mr-1 inline-block h-6 w-6" />
                                                    {
                                                        dependencyValidationErrors?.[
                                                            option.id
                                                        ]?.message
                                                    }
                                                    {
                                                        dependencyValidationValid?.[
                                                            option.id
                                                        ]?.message
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </FormLabel>
                                ))}
                        </>
                    </FormRadioOrCheckboxGroup>
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Multiple Select"]:
            return (
                <>
                    <FormRadioOrCheckboxGroup
                        spaced={theme?.spaced || "spaced"}
                        palette={palette}
                        bordered={theme?.bordered || "not bordered"}
                        isInDesignContext={isInDesignContext}
                    >
                        <>
                            {!!prompt.options &&
                                prompt.options.map((option) => (
                                    <FormLabel
                                        key={option.id}
                                        isSelected={
                                            option.id === watchedValue ||
                                            (Array.isArray(watchedValue) &&
                                                watchedValue.includes(
                                                    option.id,
                                                ))
                                        }
                                        palette={palette}
                                        highLightStyle={
                                            theme?.highLightStyle || "none"
                                        }
                                        highlightColor={theme?.highlightColor}
                                        data-content-block-id={
                                            option.content_block_id
                                        }
                                    >
                                        <div className="flex w-full flex-col items-start justify-center space-y-2">
                                            <span className="flex w-full flex-row items-center">
                                                <FormInputRadioCheckbox
                                                    type="checkbox"
                                                    name={promptId}
                                                    value={option.id}
                                                    {...registerReturn}
                                                    data-option-id={option.id}
                                                    disabled={
                                                        !!dependencyValidationErrors?.[
                                                            option.id
                                                        ]
                                                    }
                                                    checkMarkColor={
                                                        theme?.checkMarkColor
                                                    }
                                                />
                                                <span className="mt-1">
                                                    {option.content}
                                                </span>
                                            </span>
                                            {(!!dependencyValidationErrors?.[
                                                option.id
                                            ] ||
                                                !!dependencyValidationValid[
                                                    option.id
                                                ]) && (
                                                <div className="text-xs text-blue-600">
                                                    <InformationCircleIcon className="mr-1 inline-block h-6 w-6" />
                                                    {
                                                        dependencyValidationErrors?.[
                                                            option.id
                                                        ]?.message
                                                    }
                                                    {
                                                        dependencyValidationValid?.[
                                                            option.id
                                                        ]?.message
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </FormLabel>
                                ))}
                        </>
                    </FormRadioOrCheckboxGroup>
                    {numericErrors && (
                        <div className="mt-1">
                            <span
                                className={`text-lg font-normal tracking-wide ${
                                    numericErrors.type ===
                                    z.ZodIssueCode.too_big
                                        ? "text-blue-600"
                                        : "text-red-700"
                                }`}
                            >
                                {numericErrors.message}
                            </span>
                        </div>
                    )}
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Numerical Slider"]:
            return (
                <>
                    {theme?.sliderValueType === "tooltip" && (
                        <>
                            <div className="relative px-[7px]">
                                {/** pad the left and right by 7 pixels to account for width of slider thumb */}
                                <div className="relative h-4">
                                    <div
                                        className="absolute -bottom-[4px] left-0 z-40 mb-2 h-2 max-w-sm -translate-x-1/2 opacity-100"
                                        style={{
                                            marginLeft: `${getSliderLeftPct(
                                                prompt.min || 0,
                                                prompt.max || 100,
                                                Number(watchedValue) ||
                                                    prompt.min ||
                                                    0,
                                            )}%`,
                                        }}
                                    >
                                        <div className="relative shadow-md">
                                            <div
                                                className="-mt-4 truncate rounded px-4 py-1 text-xs text-white"
                                                style={{
                                                    backgroundColor:
                                                        theme?.highlightColor ||
                                                        "#0075ff",
                                                }}
                                            >
                                                {watchedValue ||
                                                    prompt.min ||
                                                    0}
                                            </div>
                                            <svg
                                                className="top-100 absolute left-0 h-2 w-full"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 255 255"
                                                xmlSpace="preserve"
                                                style={{
                                                    color:
                                                        theme?.highlightColor ||
                                                        "#0075ff",
                                                }}
                                            >
                                                <polygon
                                                    className="fill-current"
                                                    points="0,0 127.5,127.5 255,0"
                                                ></polygon>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FormInputRange
                                hasMargin={true}
                                className="m-0"
                                id={`slider-${promptId}`}
                                type="range"
                                name={promptId}
                                {...registerReturn}
                                min={prompt.min || 0}
                                max={prompt.max || 100}
                                step={!!prompt.increment ? prompt.increment : 1}
                                color={theme?.highlightColor || "#0075ff"}
                            />
                        </>
                    )}

                    {(theme?.sliderValueType === "label_on_left" ||
                        theme?.sliderValueType === "label_on_right") && (
                        <>
                            <div
                                className={`flex items-center gap-1 ${
                                    theme?.sliderValueType === "label_on_left"
                                        ? "flex-row-reverse"
                                        : "flex-row"
                                }`}
                            >
                                <FormInputRange
                                    id={`slider-${promptId}`}
                                    type="range"
                                    name={promptId}
                                    {...registerReturn}
                                    min={prompt.min || 0}
                                    max={prompt.max || 100}
                                    step={
                                        !!prompt.increment
                                            ? prompt.increment
                                            : 1
                                    }
                                />
                                <label
                                    htmlFor={`slider-${promptId}`}
                                    className="flex max-w-sm items-center p-2 text-lg font-bold"
                                >
                                    {watchedValue}
                                </label>
                            </div>
                        </>
                    )}
                    {(!theme?.sliderValueType ||
                        theme?.sliderValueType === "none") && (
                        <FormInputRange
                            type="range"
                            name={promptId}
                            {...registerReturn}
                            min={prompt.min || 0}
                            max={prompt.max || 100}
                            step={!!prompt.increment ? prompt.increment : 1}
                        />
                    )}
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Rank Order"]:
            return (
                <>
                    {((!!setValue && !!watchedValue?.length) ||
                        !!prompt.options) && (
                        <Controller
                            name={promptId || ""}
                            control={control}
                            rules={{ required: false }}
                            render={(props) => (
                                <QuestionContentBlockRankingComponent
                                    {...props}
                                    controlledFormFieldData={watchedValue}
                                    updateControlledFormFieldData={(
                                        newData: SelectionShape[],
                                    ) => setValue(promptId, newData)}
                                    options={prompt.options}
                                />
                            )}
                        />
                    )}
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Toggle Switch"]:
            return (
                <>
                    <div className="flex flex-row items-center">
                        <FormToggleLeftLabel>
                            {!!prompt.options &&
                                prompt.options.length > 0 &&
                                prompt.options[0].content}
                        </FormToggleLeftLabel>
                        <FormToggleDiv>
                            <FormInputToggle
                                type="checkbox"
                                id="toggle"
                                name={promptId}
                                {...registerReturn}
                                data-option-id={prompt.options[0].id}
                            />
                            <FormToggleLabel htmlFor="toggle" />
                        </FormToggleDiv>
                        <FormToggleRightLabel>
                            {!!prompt.options &&
                                prompt.options.length > 1 &&
                                prompt.options[1].content}
                        </FormToggleRightLabel>
                    </div>
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType["Drag and Drop"]:
            return (
                <>
                    {((!!setValue && !!watchedValue?.length) ||
                        !!prompt.options) && (
                        <Controller
                            name={promptId || ""}
                            control={control}
                            rules={{ required: false }}
                            render={(props) => {
                                return (
                                    <QuestionContentBlockDragDropComponent
                                        {...props}
                                        controlledFormFieldData={watchedValue}
                                        updateControlledFormFieldData={(
                                            newData: SelectionShape[],
                                        ) => {
                                            setValue(promptId, newData);
                                            trigger(promptId);
                                        }}
                                        prompt={prompt}
                                        optionRenderMap={optionRenderMap}
                                        isInDesignContext={isInDesignContext}
                                    />
                                );
                            }}
                        />
                    )}
                    {numericErrors && (
                        <div className="mt-1">
                            <span
                                className={`text-lg font-normal tracking-wide ${
                                    numericErrors.type ===
                                    z.ZodIssueCode.too_big
                                        ? "text-blue-600"
                                        : "text-red-700"
                                }`}
                            >
                                {numericErrors.message}
                            </span>
                        </div>
                    )}
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        case PromptType.Timeline:
            return (
                <>
                    {((!!setValue && !!watchedValue?.length) ||
                        !!prompt.options) && (
                        <Controller
                            name={promptId || ""}
                            control={control}
                            rules={{ required: false }}
                            render={(props) => (
                                <QuestionContentBlockTimeline
                                    {...props}
                                    controlledFormFieldData={watchedValue}
                                    updateControlledFormFieldData={(
                                        newData: SelectionShape[],
                                    ) => setValue(promptId, newData)}
                                    prompt={prompt}
                                    optionRenderMap={optionRenderMap}
                                    isInDesignContext={isInDesignContext}
                                />
                            )}
                        />
                    )}
                    <QuestionContentBlockErrorDisplay error={error} />
                </>
            );
        default:
            return null;
    }
};

export const QuestionContentBlockFormField = (
    props: QuestionContentBlockFormFieldProps & { isSelected?: boolean },
) => {
    const { isSelected } = props;
    return isSelected ? (
        <>
            <RenderFormField {...props} />
        </>
    ) : (
        <RenderFormField {...props} />
    );
};

import React from "react";
import { Switch } from "@headlessui/react";
import {
    ChartType,
    ChartWizardSubmissionData,
} from "./ChartWizardReducer";
import { InterpolationPropType } from "victory";
import { reduceOpacity } from "@/modules/shared/charts/chart-util";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}
type Props = {
    setSubmissionData: (theme: Partial<ChartWizardSubmissionData>) => void;
    chartType: ChartType;
    submissionData: Partial<ChartWizardSubmissionData>;
};
const interpolations = [
    "basis",
    "bundle",
    "cardinal",
    "catmullRom",
    "linear",
    "monotoneX",
    "monotoneY",
    "natural",
    "step",
    "stepAfter",
    "stepBefore",
];

const MaximumorMinimumValueForm = ({
    value,
    onChange,
    valueName = "maximum"
}: {
    value: number;
    onChange: (value: number) => void;
    valueName?: "maximum" | "minimum";
}) => {
    return (
        <div className="flex flex-col space-y-4 w-full  px-4">
            <label className="capitalize">{valueName} Value</label>
            <input
                className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                type="number"
                value={value}
                onChange={(event) => {
                    onChange(Number(event.target.value));
                }}
            />
        </div>
    );
};

const AbbreviateAxisForm = ({
    axis,
    value,
    onChange,
}: {
    value: boolean;
    onChange: (value: boolean) => void;
    axis: "x" | "y";
}) => {
    return (
        <div className="flex flex-col space-y-4 w-ful mt-2 px-4">
            <Switch.Group
                as="div"
                className="flex items-center justify-start space-x-2"
            >
                <span className="flex  flex-col">
                    <Switch.Label
                        as="span"
                        className="text-sm font-medium text-white"
                        passive
                    >
                        Abbreviate {axis.toUpperCase()} Axis
                    </Switch.Label>
                </span>
                <Switch
                    checked={value}
                    onChange={onChange}
                    className={classNames(
                        !!value ? "bg-indigo-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    )}
                >
                    <span
                        aria-hidden="true"
                        className={classNames(
                            !!value ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                    />
                </Switch>
            </Switch.Group>
        </div>
    );
};

function AdvancedOptionsForm({
    chartType,
    setSubmissionData,
    submissionData,
}: Props) {
    switch (chartType) {
        case "line":
        case "area":
            return (
                <>
                    <AbbreviateAxisForm
                        axis={"y"}
                        value={submissionData.theme.abbreviateYAxis}
                        onChange={(abbreviateYAxis) => {
                            setSubmissionData({
                                ...submissionData,
                                theme: {
                                    ...submissionData.theme,
                                    abbreviateYAxis,
                                },
                                series: submissionData?.series?.map(
                                    (series) => ({
                                        ...series,
                                        theme: {
                                            ...series.theme,
                                            abbreviateYAxis,
                                        },
                                    })
                                ),
                            });
                        }}
                    />
                    <div className="flex flex-col space-y-4 w-full  px-4">
                        <label>Interpolotion</label>
                        <select
                            className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            value={submissionData?.theme?.interpolation}
                            onChange={(event) => {
                                const interpolation = event.target
                                    .value as InterpolationPropType;
                                setSubmissionData({
                                    ...submissionData,
                                    theme: {
                                        ...submissionData?.theme,
                                        interpolation,
                                    },
                                    series: submissionData?.series?.map(
                                        (series) => ({
                                            ...series,
                                            theme: {
                                                ...series.theme,
                                                interpolation,
                                            },
                                            chart_data: series?.chart_data?.map(
                                                (datum) => ({
                                                    ...datum,
                                                    theme: {
                                                        ...datum.theme,
                                                        interpolation,
                                                    },
                                                })
                                            ),
                                        })
                                    ),
                                });
                            }}
                        >
                            {interpolations.map((interpolation) => (
                                <option
                                    className="bg-[#29303e]"
                                    key={interpolation}
                                    value={interpolation}
                                >
                                    {interpolation}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-col space-y-4 w-full  px-4">
                        <label>Change Chart Type</label>
                        <select
                            className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            value={chartType}
                            onChange={(event) => {
                                setSubmissionData({
                                    ...submissionData,
                                    chartType: event.target.value as ChartType,
                                    series: submissionData?.series?.map(
                                        (series) => {
                                            const theme = { ...series.theme };
                                            if (event.target.value === "area") {
                                                theme.fill = reduceOpacity(
                                                    theme.stroke,
                                                    0.5
                                                );
                                            }
                                            return {
                                                ...series,
                                                chartType: event.target
                                                    .value as ChartType,
                                                theme,
                                                chart_data:
                                                    series?.chart_data?.map(
                                                        (datum) => ({
                                                            ...datum,
                                                            theme,
                                                            chartType: event
                                                                .target
                                                                .value as ChartType,
                                                        })
                                                    ),
                                            };
                                        }
                                    ),
                                });
                            }}
                        >
                            <option value="area" className="bg-[#29303e]">
                                Area
                            </option>
                            <option value="line" className="bg-[#29303e]">
                                Line
                            </option>
                        </select>
                    </div>
                    <MaximumorMinimumValueForm
                        valueName="maximum"
                        value={submissionData.theme.maximumValue}
                        onChange={(maximumValue) => {
                            setSubmissionData({
                                ...submissionData,
                                theme: {
                                    ...submissionData.theme,
                                    maximumValue,
                                },
                            });
                        }}
                    />
                    <MaximumorMinimumValueForm
                        valueName="minimum"
                        value={submissionData.theme.minimumValue }
                        onChange={(minimumValue) => {
                            setSubmissionData({
                                ...submissionData,
                                theme: {
                                    ...submissionData.theme,
                                    minimumValue,
                                },
                            });
                        }}
                    />
                </>
            );
        case "bar":
            return (
                <>
                    {" "}
                    <div className="flex flex-col space-y-4 w-full  px-4">
                        <label>Corner Radius</label>
                        <input
                            className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            type="number"
                            value={
                                submissionData?.series[0]?.theme?.cornerRadius
                            }
                            onChange={(event) => {
                                setSubmissionData({
                                    ...submissionData,
                                    series: submissionData?.series?.map(
                                        (series) => {
                                            const theme = {
                                                ...series.theme,
                                                cornerRadius: Number(
                                                    event.target.value
                                                ),
                                            };

                                            return {
                                                ...series,
                                                theme,
                                                chart_data:
                                                    series?.chart_data?.map(
                                                        (datum) => ({
                                                            ...datum,
                                                            theme,
                                                        })
                                                    ),
                                            };
                                        }
                                    ),
                                });
                            }}
                        />
                    </div>
                    <div className="flex flex-col space-y-4 w-full  px-4">
                        <label>Bar Width</label>
                        <input
                            className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            type="number"
                            value={submissionData?.series[0]?.theme?.barWidth}
                            onChange={(event) => {
                                setSubmissionData({
                                    ...submissionData,
                                    series: submissionData?.series?.map(
                                        (series) => {
                                            const theme = {
                                                ...series.theme,
                                                barWidth: Number(
                                                    event.target.value
                                                ),
                                            };

                                            return {
                                                ...series,
                                                theme,
                                                chart_data:
                                                    series?.chart_data?.map(
                                                        (datum) => ({
                                                            ...datum,
                                                            theme,
                                                        })
                                                    ),
                                            };
                                        }
                                    ),
                                });
                            }}
                        />
                    </div>
                    <MaximumorMinimumValueForm
                        valueName="maximum"
                        value={submissionData.theme.maximumValue}
                        onChange={(maximumValue) => {
                            setSubmissionData({
                                ...submissionData,
                                theme: {
                                    ...submissionData.theme,
                                    maximumValue,
                                },
                            });
                        }}
                    />
                    <MaximumorMinimumValueForm
                        valueName="minimum"
                        value={submissionData.theme.minimumValue }
                        onChange={(minimumValue) => {
                            setSubmissionData({
                                ...submissionData,
                                theme: {
                                    ...submissionData.theme,
                                    minimumValue,
                                },
                            });
                        }}
                    />
                </>
            );
        case "pie":
        case "doughnut":
            return (
                <>
                    <div className="flex flex-col space-y-4 w-full  px-4">
                        <label>Change Chart Type</label>
                        <select
                            className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            value={chartType}
                            onChange={(event) => {
                                setSubmissionData({
                                    ...submissionData,
                                    chartType: event.target.value as ChartType,
                                    series: submissionData?.series?.map(
                                        (series) => {
                                            const theme = { ...series.theme };

                                            return {
                                                ...series,
                                                chartType: event.target
                                                    .value as ChartType,
                                                theme,
                                                chart_data:
                                                    series?.chart_data?.map(
                                                        (datum) => ({
                                                            ...datum,
                                                            theme,
                                                            chartType: event
                                                                .target
                                                                .value as ChartType,
                                                        })
                                                    ),
                                            };
                                        }
                                    ),
                                });
                            }}
                        >
                            <option value="pie" className="bg-[#29303e]">
                                Pie
                            </option>
                            <option value="doughnut" className="bg-[#29303e]">
                                Doughnut
                            </option>
                        </select>
                    </div>
                    {chartType === "doughnut" && (
                        <div className="flex flex-col space-y-4 w-full  px-4">
                            <label>Inner Radius</label>
                            <input
                                className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                                type="number"
                                value={
                                    submissionData?.series[0]?.theme
                                        ?.innerRadius
                                }
                                onChange={(event) => {
                                    setSubmissionData({
                                        ...submissionData,
                                        series: submissionData?.series?.map(
                                            (series) => {
                                                const theme = {
                                                    ...series.theme,
                                                    innerRadius: Number(
                                                        event.target.value
                                                    ),
                                                };

                                                return {
                                                    ...series,
                                                    theme,
                                                    chart_data:
                                                        series?.chart_data?.map(
                                                            (datum) => ({
                                                                ...datum,
                                                                theme,
                                                            })
                                                        ),
                                                };
                                            }
                                        ),
                                    });
                                }}
                            />
                        </div>
                    )}
                    <div className="flex flex-col space-y-4 w-full  px-4">
                        <label>Corner Radius</label>
                        <input
                            className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            type="number"
                            value={
                                submissionData?.series[0]?.theme?.cornerRadius
                            }
                            onChange={(event) => {
                                setSubmissionData({
                                    ...submissionData,
                                    series: submissionData?.series?.map(
                                        (series) => {
                                            const theme = {
                                                ...series.theme,
                                                cornerRadius: Number(
                                                    event.target.value
                                                ),
                                            };

                                            return {
                                                ...series,
                                                theme,
                                                chart_data:
                                                    series?.chart_data?.map(
                                                        (datum) => ({
                                                            ...datum,
                                                            theme,
                                                        })
                                                    ),
                                            };
                                        }
                                    ),
                                });
                            }}
                        />
                    </div>
                    <div className="flex flex-col  w-full  px-4">
                        <label>Padding between slices</label>
                        <input
                            className={`w-full border max-w-3xl block p-2 rounded-md border-gray-300 sm:text-sm bg-white/10 focus:outline-[#1d4ed8] focus:outline-offset-0  focus:text-white text-gray-200`}
                            type="number"
                            value={submissionData?.series[0]?.theme?.padAngle}
                            onChange={(event) => {
                                setSubmissionData({
                                    ...submissionData,
                                    series: submissionData?.series?.map(
                                        (series) => {
                                            const theme = {
                                                ...series.theme,
                                                padAngle: Number(
                                                    event.target.value
                                                ),
                                            };

                                            return {
                                                ...series,
                                                theme,
                                                chart_data:
                                                    series?.chart_data?.map(
                                                        (datum) => ({
                                                            ...datum,
                                                            theme,
                                                        })
                                                    ),
                                            };
                                        }
                                    ),
                                });
                            }}
                        />
                    </div>
                </>
            );
    }
}

export default function AdvancedOptions({
    setSubmissionData,
    chartType,
    submissionData,
}: Props) {
    return (
        <div className="flex flex-col items-start space-y-4 w-full p-6 ">
            <div className="flex flex-col justify-between items-start w-full space-y-4 p-4 border rounded-md border-[#374151] bg-black/50 hover:bg-black/10 transition-all">
                <h3>Advanced Options</h3>
                <AdvancedOptionsForm
                    chartType={chartType}
                    setSubmissionData={setSubmissionData}
                    submissionData={submissionData}
                />
            </div>
        </div>
    );
}

import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export interface CustomStyleOptions {
    // List of allowed CSS classes that can be applied
    allowedClasses: string[];
    // List of allowed style properties that can be modified
    allowedStyles: string[];
    // Default classes to apply when the extension is enabled
    defaultClasses?: string[];
    // Default styles to apply when the extension is enabled
    defaultStyles?: Record<string, string>;
}

declare module "@tiptap/core" {
    interface Commands<ReturnType> {
        customStyle: {
            /**
             * Set custom classes
             */
            setCustomClasses: (classes: string[]) => ReturnType;
            /**
             * Set custom styles
             */
            setCustomStyles: (styles: Record<string, string>) => ReturnType;
            /**
             * Remove custom styling
             */
            removeCustomStyling: () => ReturnType;
        };
    }
}

export const CustomStyle = Extension.create<CustomStyleOptions>({
    name: "customStyle",

    addOptions() {
        return {
            allowedStyles: [
                "color",
                "backgroundColor",
                "fontSize",
                "--from-color",
                "--to-color",
                "--via-color",
            ],
            allowedClasses: [
                "text-transparent",
                "bg-clip-text",
                "bg-gradient-to-r",
                "bg-gradient-to-l",
                "bg-gradient-to-t",
                "bg-gradient-to-b",
                "bg-gradient-to-tr",
                "bg-gradient-to-tl",
                "bg-gradient-to-br",
                "bg-gradient-to-bl",
                "to-[var(--to-color)]",
                "from-[var(--from-color)]",
                "via-[var(--via-color)]"
            ],
            defaultClasses: [],
            defaultStyles: {},
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: ["textStyle"],
                attributes: {
                    customClasses: {
                        default: this.options.defaultClasses,
                        parseHTML: (element) => {
                            return Array.from(element.classList);
                        },
                        renderHTML: (attributes) => {
                            if (!attributes.customClasses?.length) {
                                return {};
                            }

                            return {
                                class: attributes.customClasses.join(" "),
                            };
                        },
                    },
                    customStyles: {
                        default: this.options.defaultStyles,
                        parseHTML: (element) => {
                            const styles: Record<string, string> = {};
                            this.options.allowedStyles.forEach((style) => {
                                const value = element.style[style as any];
                                if (value) {
                                    styles[style] = value;
                                }
                            });
                            return styles;
                        },
                        renderHTML: (attributes) => {
                            if (!attributes.customStyles) {
                                return {};
                            }

                            return {
                                style: Object.entries(attributes.customStyles)
                                    .map(([key, value]) => `${key}: ${value}`)
                                    .join("; "),
                            };
                        },
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            setCustomClasses:
                (classes: string[]) =>
                ({ chain }) => {
                    const filteredClasses = classes.filter((className) =>
                        this.options.allowedClasses.includes(className),
                    );
                    return chain()
                        .setMark("textStyle", {
                            customClasses: filteredClasses,
                        })
                        .run();
                },

            setCustomStyles:
                (styles: Record<string, string>) =>
                ({ chain }) => {
                    const filteredStyles = Object.entries(styles).reduce(
                        (acc, [key, value]) => {
                            if (this.options.allowedStyles.includes(key)) {
                                acc[key] = value;
                            }
                            return acc;
                        },
                        {} as Record<string, string>,
                    );

                    return chain()
                        .setMark("textStyle", { customStyles: filteredStyles })
                        .run();
                },

            removeCustomStyling:
                () =>
                ({ chain }) => {
                    return chain()
                        .setMark("textStyle", {
                            customClasses: [],
                            customStyles: {},
                        })
                        .run();
                },
        };
    },
});

import React, { ReactNode, useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
    DynamicValidationRule,
    ModelVariableScope,
    SelectionShape,
    SumRule,
} from "@/models";
import { useFormatVariableValue } from "@/hooks";
import { useIsSubmitting } from "@/Pages/Participant/SimulationDisplay/redux-state/hooks";

/**
 * FormContentBlockDefaultValue is the initial data
 * for a given prompt based on prompt type (and thus form field type)
 * and any selections, if they exist.
 *
 * string:
 * MULTIPLE_CHOICE, DROPDOWN_LIST, TOGGLE_SWITCH, SHORT_TEXT, LONG_TEXT
 *
 * number:
 * NUMERICAL_INPUT, NUMERICAL_SLIDER
 *
 * string[]:
 * MULTIPLE_SELECT
 *
 * SelectionShape[]:
 * RANK_ORDER, DRAG_AND_DROP
 */
export type FormContentBlockDefaultValue =
    | string
    | number
    | string[]
    | SelectionShape[]
    | null;

export type FormContentBlockDefaultValuesObject = {
    [promptId: string]: FormContentBlockDefaultValue;
};

// export type FormContentBlockFieldPropsObject = {
//     [promptId: string]: QuestionContentBlockFormFieldDisplayProps;
// };

export type FormValidationSchema = z.ZodObject<
    any,
    "passthrough",
    z.ZodTypeAny,
    {
        [x: string]: any;
    },
    {
        [x: string]: any;
    }
>;

function findVariableValue(validationRule: SumRule) {
    const { variableValues } = validationRule;
    const possibleVariableValues = variableValues.filter((value) => {
        return (
            validationRule.model_variable_time_horizon_id === null ||
            validationRule.model_variable_time_horizon_id ===
                value.time_horizon_id
        );
    });

    return (
        possibleVariableValues.find((value) => {
            if (value.scope === ModelVariableScope.Team) return !!value.team_id;
            if (value.scope === ModelVariableScope.User) return !!value.user_id;
        }) || possibleVariableValues[0]
    );
}

interface Props {
    defaultValues: FormContentBlockDefaultValuesObject;
    onSubmit?: (
        formObject: FormContentBlockDefaultValuesObject,
    ) => Promise<void>;
    children?: ReactNode;
    validationRules: DynamicValidationRule[];
    isSubmitting: boolean;
}

// export const ConnectForm = ({ children }) => {
//     const methods = useFormContext();

//     return children({ ...methods });
// };

function valuesToFlatArray(values: {
    [index: string]: string | number | string[] | number[];
}) {
    return Object.keys(values).reduce((carry, key) => {
        if (Array.isArray(values[key])) {
            return [...carry, ...(values[key] as string[])];
        }
        return [...carry, values[key]];
    }, []);
}

export const DesignLayerFormContentBlockProvider = (props: Props) => {
    const { defaultValues, children, validationRules } = props;
    const formatVariableValue = useFormatVariableValue();

    const { setInvalid, setValid } = useIsSubmitting();
    type FormObject = typeof defaultValues;

    //build first and THEN refine, so that the value passed to refine is the whole form state, one hopes
    const validationSchema = useMemo(() => {
        const validation = z
            .object(
                validationRules.reduce((carry, rule) => {
                    const parentOptions = rule.options.filter(
                        (option) => option.relation_type === "parent",
                    );

                    if (parentOptions.length) {
                        return {
                            ...carry,
                            [parentOptions[0].prompt_id]: z.any(),
                        };
                    }
                    return carry;
                }, {}),
            )
            .partial()
            .superRefine((value, ctx) => {
                const flatValues = valuesToFlatArray(value);

                //do dendency rules
                validationRules
                    .filter((rule) => rule.rule_type === "dependency")
                    .forEach((rule) => {
                        const unselectedParentOptions = rule.options.filter(
                            (option) =>
                                option.relation_type === "parent" &&
                                !flatValues.includes(option.id),
                        );
                        const childOption = rule.options.find(
                            (option) => option.relation_type === "child",
                        );
                        if (!childOption) return;
                        if (unselectedParentOptions?.length) {
                            // console.log(childOption.prompt_id, issue)

                            //until we can figure out how to get custom messages to work,
                            //we'll just use the label as a holder for all the info we need

                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message: rule.label,
                                params: {
                                    prompt_id: childOption.prompt_id,
                                    child_option_id: childOption.id,
                                    type: "dependency",
                                },
                                path: [
                                    childOption.prompt_id,
                                    "dependency",
                                    "error",
                                    childOption.id,
                                ],
                                fatal: false,
                            });

                            // return z.NEVER;
                        } else {
                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message: rule.label,
                                params: {
                                    prompt_id: childOption.prompt_id,
                                    child_option_id: childOption.id,
                                    type: "dependency",
                                },
                                path: [
                                    childOption.prompt_id,
                                    "dependency",
                                    "valid",
                                    childOption.id,
                                ],
                                fatal: false,
                            });
                        }
                    });

                //do numeric rules
                validationRules
                    .filter((rule) => rule.rule_type === "sum")
                    .forEach((rule) => {
                        const typedRule = rule as SumRule;
                        const { options } = typedRule;
                        //for now, we fetch all possible variable values
                        //includings base values, and accross all time horizons.
                        const variableValue = findVariableValue(typedRule);
                        if (!variableValue) return;

                        const initialValue = variableValue.numerical_value;

                        //TODO: this only works for choice types.
                        const sumOfSelectedValues = options
                            .filter((option) => {
                                return (
                                    flatValues.includes(option.id) ||
                                    flatValues.find(
                                        (optionLike) =>
                                            optionLike?.option_id ===
                                                option.id &&
                                            optionLike.is_selected,
                                    )
                                );
                            })
                            .reduce((carry, option) => {
                                return carry + Number(option.numerical_value);
                            }, 0);

                        let message: string;
                        const remainingAmount =
                            initialValue - sumOfSelectedValues;
                        const formattedValue = formatVariableValue(
                            variableValue.unit,
                            remainingAmount,
                            true,
                        );
                        if (typedRule.rule_position === "before") {
                            message = `${formattedValue} ${rule.label}`;
                        } else {
                            message = `${rule.label} ${formattedValue} `;
                        }

                        if (remainingAmount < 0) {
                            ctx.addIssue({
                                code: z.ZodIssueCode.too_small,
                                message,
                                minimum: 0,
                                inclusive: true,
                                type: "number",
                                path: [rule.options[0].prompt_id, "sum"],
                            });
                        } else {
                            ctx.addIssue({
                                code: z.ZodIssueCode.too_big,
                                message,
                                maximum: initialValue,
                                inclusive: true,
                                type: "number",
                                path: [rule.options[0].prompt_id, "sum"],
                            });
                        }
                    });
            });

        //todo: figure out how to get custom error maps to work.
        //currently, the error map is being called, but transofmrations to errors don't show up
        //in the form.
        // z.setErrorMap((issue, ctx) => {
        //     if (issue.code === z.ZodIssueCode.custom) {
        //         switch (issue.params.type) {
        //             case "dependency":
        //                 //todo: figure out of there is some other way to pass extra data to the error message
        //                 return { ...issue, message: "updated" };
        //         }
        //     }
        //     console.log("issue is: down here");
        //     return { message: ctx.defaultError };
        // });

        return validation;
    }, []);

    const methods = useForm<FormObject>({
        defaultValues,
        resolver: zodResolver(validationSchema),

        mode: "all",
    });

    const {
        handleSubmit,
        // control
        trigger,
        formState,
    } = methods;

    //trigger all the fields that have validation rules on mount
    //to force validation messages and disabled states
    useEffect(() => {
        validationRules.forEach((rule) => {
            const option =
                rule.rule_type === "dependency"
                    ? rule.options.find(
                          (option) => option.relation_type === "child",
                      )
                    : rule.options[0];
            if (!option) return;
            trigger(option.prompt_id);
        });
    }, [defaultValues, validationRules]);

    // const watchedFields = useWatch({ control });

    useEffect(() => {
        if (defaultValues !== undefined) {
            // console.log("form defaultValues: ", defaultValues);
            // reset(defaultValues);
        }
    }, [defaultValues]);

    useEffect(() => {
        const { errors } = formState;
        const hasErrors =
            Object.keys(errors).filter((promptId) => {
                //if the error is a depdendency error, we can ignore it
                //because we know that the dependent option is not selected
                if (typeof errors[promptId] === "object") {
                    const error = errors[promptId] as any;

                    if (error.sum) {
                        //we use the ZodIssueCode.too_small to signify that the budget is less than 0
                        if (error.sum.type === z.ZodIssueCode.too_small)
                            return true;
                    }
                    if (error.dependency) {
                        return false;
                    }
                }
            })?.length > 0;
        if (hasErrors) {
            setInvalid();
        } else {
            setValid();
        }
    }, [formState]);

    if (!methods) return null;
    return (
        <FormProvider {...methods}>
            <form
                data-testid="designer-form-provider"
                data-submitting={formState.isSubmitting}
                onSubmit={handleSubmit(
                    () => {},
                    (errors) => {
                        //if we can confirm that all errors are due to missing dependencies
                        //and no dependent options are selected, we can submit the form
                        const errorsThatShouldPreventSubmissions = Object.keys(
                            errors,
                        ).filter((promptId) => {
                            //if the error is a depdendency error, we can ignore it
                            //because we know that the dependent option is not selected
                            if (typeof errors[promptId] === "object") {
                                const error = errors[promptId] as any;

                                if (error.sum) {
                                    //we use the ZodIssueCode.too_small to signify that the budget is less than 0
                                    if (
                                        error.sum.type ===
                                        z.ZodIssueCode.too_small
                                    )
                                        return true;
                                }
                                if (error.dependency) {
                                    return false;
                                }
                            }
                        });

                        if (!errorsThatShouldPreventSubmissions.length) {
                            return;
                        }

                        //otherwise, we need to show the errors
                        console.log("FORM INVALID");
                    },
                )}
                className="relative"
            >
                {children}
            </form>
        </FormProvider>
    );
};

export const FormContentBlockProvider = (props: Props) => {
    const { defaultValues, onSubmit, children, validationRules } = props;
    const formatVariableValue = useFormatVariableValue();

    const { setInvalid, setValid } = useIsSubmitting();
    type FormObject = typeof defaultValues;

    //build first and THEN refine, so that the value passed to refine is the whole form state, one hopes
    const validationSchema = useMemo(() => {
        const validation = z
            .object(
                validationRules.reduce((carry, rule) => {
                    const parentOptions = rule.options.filter(
                        (option) => option.relation_type === "parent",
                    );

                    if (parentOptions.length) {
                        return {
                            ...carry,
                            [parentOptions[0].prompt_id]: z.any(),
                        };
                    }
                    return carry;
                }, {}),
            )
            .partial()
            .superRefine((value, ctx) => {
                const flatValues = valuesToFlatArray(value);

                //do dendency rules
                validationRules
                    .filter((rule) => rule.rule_type === "dependency")
                    .forEach((rule) => {
                        const unselectedParentOptions = rule.options.filter(
                            (option) =>
                                option.relation_type === "parent" &&
                                !flatValues.includes(option.id),
                        );
                        const childOption = rule.options.find(
                            (option) => option.relation_type === "child",
                        );
                        if (!childOption) return;
                        if (unselectedParentOptions?.length) {
                            // console.log(childOption.prompt_id, issue)

                            //until we can figure out how to get custom messages to work,
                            //we'll just use the label as a holder for all the info we need

                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message: rule.label,
                                params: {
                                    prompt_id: childOption.prompt_id,
                                    child_option_id: childOption.id,
                                    type: "dependency",
                                },
                                path: [
                                    childOption.prompt_id,
                                    "dependency",
                                    "error",
                                    childOption.id,
                                ],
                                fatal: false,
                            });

                            // return z.NEVER;
                        } else {
                            ctx.addIssue({
                                code: z.ZodIssueCode.custom,
                                message: rule.label,
                                params: {
                                    prompt_id: childOption.prompt_id,
                                    child_option_id: childOption.id,
                                    type: "dependency",
                                },
                                path: [
                                    childOption.prompt_id,
                                    "dependency",
                                    "valid",
                                    childOption.id,
                                ],
                                fatal: false,
                            });
                        }
                    });

                //do numeric rules
                validationRules
                    .filter((rule) => rule.rule_type === "sum")
                    .forEach((rule) => {
                        const typedRule = rule as SumRule;
                        const { options } = typedRule;
                        //for now, we fetch all possible variable values
                        //includings base values, and accross all time horizons.
                        const variableValue = findVariableValue(typedRule);
                        if (!variableValue) return;

                        const initialValue = variableValue.numerical_value;

                        //TODO: this only works for choice types.
                        const sumOfSelectedValues = options
                            .filter((option) => {
                                return (
                                    flatValues.includes(option.id) ||
                                    flatValues.find(
                                        (optionLike) =>
                                            optionLike?.option_id ===
                                                option.id &&
                                            optionLike.is_selected,
                                    )
                                );
                            })
                            .reduce((carry, option) => {
                                return carry + Number(option.numerical_value);
                            }, 0);

                        let message: string;
                        const remainingAmount =
                            initialValue - sumOfSelectedValues;
                        const formattedValue = formatVariableValue(
                            variableValue.unit,
                            remainingAmount,
                            true,
                        );
                        if (typedRule.rule_position === "before") {
                            message = `${formattedValue} ${rule.label}`;
                        } else {
                            message = `${rule.label} ${formattedValue} `;
                        }

                        if (remainingAmount < 0) {
                            ctx.addIssue({
                                code: z.ZodIssueCode.too_small,
                                message,
                                minimum: 0,
                                inclusive: true,
                                type: "number",
                                path: [rule.options[0].prompt_id, "sum"],
                            });
                        } else {
                            ctx.addIssue({
                                code: z.ZodIssueCode.too_big,
                                message,
                                maximum: initialValue,
                                inclusive: true,
                                type: "number",
                                path: [rule.options[0].prompt_id, "sum"],
                            });
                        }
                    });
            });

        //todo: figure out how to get custom error maps to work.
        //currently, the error map is being called, but transofmrations to errors don't show up
        //in the form.
        // z.setErrorMap((issue, ctx) => {
        //     if (issue.code === z.ZodIssueCode.custom) {
        //         switch (issue.params.type) {
        //             case "dependency":
        //                 //todo: figure out of there is some other way to pass extra data to the error message
        //                 return { ...issue, message: "updated" };
        //         }
        //     }
        //     console.log("issue is: down here");
        //     return { message: ctx.defaultError };
        // });

        return validation;
    }, []);

    const methods = useForm<FormObject>({
        defaultValues,
        resolver: zodResolver(validationSchema),

        mode: "all",
    });

    const {
        handleSubmit,
        reset,
        getValues,
        // control
        trigger,
        formState,
    } = methods;

    //trigger all the fields that have validation rules on mount
    //to force validation messages and disabled states
    useEffect(() => {
        validationRules.forEach((rule) => {
            const option =
                rule.rule_type === "dependency"
                    ? rule.options.find(
                          (option) => option.relation_type === "child",
                      )
                    : rule.options[0];
            if (!option) return;
            trigger(option.prompt_id);
        });
    }, [defaultValues, validationRules]);

    // const watchedFields = useWatch({ control });

    useEffect(() => {
        if (defaultValues !== undefined) {
            // console.log("form defaultValues: ", defaultValues);
            reset(defaultValues);
        }
    }, [defaultValues]);

    // watchedFields can be used to set controlled form field data in the store if needed
    // useEffect(() => {
    //     if (watchedFields !== undefined) {
    //         // console.log("form watchedFields: ", watchedFields, errors);
    //     }
    // }, [watchedFields]);

    useEffect(() => {
        const { errors } = formState;

        if (errors !== undefined && Object.keys(errors).length) {
            // console.log("FORM ERRORS: ", errors);
        }
    }, [formState]);

    useEffect(() => {
        const { errors } = formState;
        const hasErrors =
            Object.keys(errors).filter((promptId) => {
                //if the error is a depdendency error, we can ignore it
                //because we know that the dependent option is not selected
                if (typeof errors[promptId] === "object") {
                    const error = errors[promptId] as any;

                    if (error.sum) {
                        //we use the ZodIssueCode.too_small to signify that the budget is less than 0
                        if (error.sum.type === z.ZodIssueCode.too_small)
                            return true;
                    }
                    if (error.dependency) {
                        return false;
                    }
                }
            })?.length > 0;
        if (hasErrors) {
            setInvalid();
        } else {
            setValid();
        }
    }, [formState]);

    if (!methods) return null;
    return (
        <FormProvider {...methods}>
            <form
                data-testid="participant-form-provider"
                data-submitting={formState.isSubmitting}
                onSubmit={handleSubmit(
                    () => {
                        //why do we have to call getValues() here?
                        if ("function" === typeof onSubmit)
                            return onSubmit(getValues());
                    },
                    (errors) => {
                        //if we can confirm that all errors are due to missing dependencies
                        //and no dependent options are selected, we can submit the form
                        const errorsThatShouldPreventSubmissions = Object.keys(
                            errors,
                        ).filter((promptId) => {
                            //if the error is a depdendency error, we can ignore it
                            //because we know that the dependent option is not selected
                            if (typeof errors[promptId] === "object") {
                                const error = errors[promptId] as any;

                                if (error.sum) {
                                    //we use the ZodIssueCode.too_small to signify that the budget is less than 0
                                    if (
                                        error.sum.type ===
                                        z.ZodIssueCode.too_small
                                    )
                                        return true;
                                }
                                if (error.dependency) {
                                    return false;
                                }
                            }
                        });

                        if (!errorsThatShouldPreventSubmissions.length) {
                            onSubmit(getValues());
                            return;
                        }

                        //otherwise, we need to show the errors
                        console.log("FORM INVALID");
                    },
                )}
                className="relative"
            >
                {children}
            </form>
        </FormProvider>
    );
};

import React, { useMemo } from "react";
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryGroup,
    VictoryLabelProps,
    VictoryStack,
} from "victory";
import { StyledUniversalDiv, defaultChartContainerTheme } from "@/styles";
import { FormattedChartValue, SharedChartProps } from "./SharedTypes";
import { abbrNum, getDomain, getSteppedColors } from "./chart-util";

// export type AreaOrLineSeriesTheme = {
//     interpolation?: InterpolationPropType;
//     stroke?: string;
//     strokeWidth?: number;
//     // labelProps can be used to styled the VictoryLabel
//     labelProps?: {
//         x: number;
//         y: number;
//     };
// } & Partial<ThemeObject>;

// export type ChartAxisTheme = {
//     tickValues?: number[];
//     dependentAxis?: boolean;
//     tickPrefix?: string;
//     tickSuffix?: string;
// } & Partial<ThemeObject>;

type PieOrDonutChartProps = SharedChartProps & {
    onClick?: (clickedId: string) => void;
    theme: typeof defaultChartContainerTheme;
    hideLegend?: boolean;
};

function BarLabel(props: VictoryLabelProps) {
    const { x, y, text } = props;
    return (
        <g fontSize={10}>
            <foreignObject
                x={x}
                y={y - 25}
                width={100}
                height={100}
                style={{ pointerEvents: "none" }}
            >
                <div className="flex flex-wrap text-xs">
                    {text as string}: {abbrNum(y, 0)}
                </div>
            </foreignObject>
        </g>
    );
}

function findNestedWithThemeProp(
    arr: FormattedChartValue[][],
    themeProp: string,
): FormattedChartValue | undefined {
    for (const subArr of arr) {
        const found = subArr?.find((obj) => !!obj?.theme?.[themeProp]);
        if (found) {
            return found;
        }
    }
}

export function ColumnChart({
    theme,
    formattedChartValues,
    hideLegend,
}: PieOrDonutChartProps) {
    const yDomain = useMemo(() => {
        let values = getDomain(formattedChartValues, "y");
        if (
            theme.hasOwnProperty("minimumValue") &&
            theme.minimumValue !== null
        ) {
            values[0] = theme.minimumValue;
        }
        if (theme.maximumValue) {
            values[1] = theme.maximumValue;
        }
        return values;
    }, [formattedChartValues, theme]);

    const height = useMemo(() => {
        if (theme?.height) {
            return Number(theme?.height);
        }
        if (yDomain?.length === 2) {
            const minHeight = Math.min(
                Math.abs(yDomain[1] - yDomain[0]) * 6,
                300,
            );
            return Math.max(minHeight, 200);
        }
        return 300;
    }, [yDomain, theme]);

    const isTimeSeries = useMemo(() => {
        return (
            Object.values(formattedChartValues)?.some((series) => {
                return series?.length > 1;
            }) &&
            !Object.values(formattedChartValues)?.every((series) => {
                return !!series.every((bar) => !!bar.label);
            })
        );
    }, [formattedChartValues]);

    const barValues: FormattedChartValue[][] = useMemo(() => {
        if (isTimeSeries) {
            const values = Object.values(formattedChartValues);
            if (!values?.length) return [];
            const times = values[0]?.map((v) => v.x);

            return times.reduce((acc, time) => {
                values.forEach((series, index) => {
                    const value = series.find((v) => v.x === time);
                    if (value) {
                        if (!acc[index]) {
                            acc[index] = [];
                        }
                        acc[index].push(value);
                    }
                });

                return acc;
            }, []);
        }

        return Object.keys(formattedChartValues).reduce((acc, key) => {
            const series = formattedChartValues[key];
            if (!series?.length) return acc;
            return [
                ...acc,
                series.map((value) => {
                    return { ...value, x: value.label };
                }),
            ];
        }, []);
    }, [formattedChartValues, isTimeSeries]);

    const colorScale = useMemo(() => {
        return barValues.map((values) => {
            const v = values[0];
            const color = v.theme?.fill || "#000";
            return getSteppedColors(color, values?.length || 1);
        });
    }, [barValues]);

    const cornerRadius = useMemo(() => {
        const valueWithRadius = findNestedWithThemeProp(
            Object.values(formattedChartValues),
            "cornerRadius",
        );
        return valueWithRadius?.theme?.cornerRadius || 3;
    }, [barValues, formattedChartValues]);

    const barWidth = useMemo(() => {
        const valueWithRadius = findNestedWithThemeProp(
            Object.values(formattedChartValues),
            "barWidth",
        );
        return valueWithRadius?.theme?.barWidth || 0;
    }, [barValues, formattedChartValues]);

    //band aid for border theming; need to refactor
    let borderTheme = {};
    if (theme.borderColor !== "transparent")
        borderTheme = ["borderWidth", "borderColor", "borderRadius"]
            .filter((themeProp) => theme.hasOwnProperty(themeProp))
            .reduce((acc, themeProp) => {
                return { ...acc, [themeProp]: theme[themeProp] };
            }, {});

    return (
        <StyledUniversalDiv
            {...borderTheme}
            className="flex flex-col rounded-md border px-2 lg:items-center"
        >
            <VictoryChart domainPadding={{ x: 75 }} height={height}>
                <VictoryAxis
                    dependentAxis
                    tickFormat={(tick) => abbrNum(tick)}
                    domain={yDomain}
                    style={{
                        axis: { stroke: theme?.axisColor || "rgba(0,0,0,0.6)" },
                        tickLabels: {
                            fontFamily: "Open Sans",
                            fontSize: 9,
                            fill: theme.color || "black",
                        },
                        grid: {
                            stroke: theme?.gridColor || "rgba(0,0,0,0.2)",
                            opacity: 0.5,
                        },
                    }}
                />
                <VictoryAxis
                    style={{
                        tickLabels: {
                            fontFamily: "Open Sans",
                            fontSize: 10,
                        },
                        axis: { stroke: theme?.axisColor || "rgba(0,0,0,0.6)" },

                        // grid: {
                        //     stroke: "rgba(0,0,0,0.1)",
                        //     strokeDasharray: "15",
                        // },
                    }}
                    label={""}
                    tickFormat={(tick) => {
                        // if(isTimeSeries){
                        //     if()
                        // }
                        return isTimeSeries && !isNaN(tick)
                            ? `Horizon ${tick}`
                            : null;
                    }}
                />
                {isTimeSeries ? (
                    <VictoryGroup>
                        {barValues.map((values, index) => {
                            const scale = colorScale[index];
                            return (
                                <VictoryStack colorScale={scale} key={index}>
                                    {values.map((value, i) => (
                                        <VictoryBar
                                            barRatio={2}
                                            data={[value]}
                                            key={value.label}
                                            labels={({ datum }) => {
                                                return `${datum.label}: ${datum.y}`;
                                            }}
                                            labelComponent={
                                                i === values?.length - 1 ? (
                                                    <BarLabel />
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            style={{
                                                data: {
                                                    fill:
                                                        values[0]?.theme
                                                            ?.fill || "black",
                                                },
                                            }}
                                        />
                                    ))}
                                </VictoryStack>
                            );
                        })}
                    </VictoryGroup>
                ) : (
                    barValues.map((values, i) => {
                        const color = colorScale[i];
                        return (
                            <VictoryBar
                                labels={({ datum }) => {
                                    return `${datum} ${datum} ${datum}`;
                                }}
                                key={i}
                                barRatio={2}
                                barWidth={barWidth || 200 / values.length / 2}
                                cornerRadius={cornerRadius}
                                data={values}
                                style={{
                                    data: {
                                        fill: (bar) => {
                                            return (
                                                bar?.datum?.theme?.fill ||
                                                color[0]
                                            );
                                        },
                                    },
                                    labels: {
                                        fontFamily: "Open Sans",
                                        fontSize: 9,
                                        fill: theme.color || "black",
                                        angle: 45,
                                        textAnchor: "end",
                                    },
                                }}
                            />
                        );
                    })
                )}
            </VictoryChart>
        </StyledUniversalDiv>
    );
}

import React from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import { sapienRoute } from "@/inertia-utils/hooks";
import { TopNavLinkDiv } from "@/components";
import LogoImage from "@/components/LogoImage";
import { LaravelRoutes } from "@/ziggy-shims";
import { ModelSuiteLinkGroup } from "./ModelSuiteLinkGroup";
import { ChevronRightIcon } from "@heroicons/react/24/solid";

export type ModelSuiteContainerProps = {
    simulationTitle: string;
    simulationSlug: string;
    logoRoute: keyof LaravelRoutes;
    subtitle: string;
    childrenNavCenter?: React.ReactNode;
};

const ModelSuiteTopNavComponent = ({
    simulationTitle,
    simulationSlug,
    logoRoute,
    subtitle,
    childrenNavCenter,
    currentRoute,
    showModelSuiteLinkGroup,
    showSimWithSubtitle,
    colorModeButton,
    userNavDropdown,
}: ModelSuiteContainerProps & {
    currentRoute: keyof LaravelRoutes;
    showModelSuiteLinkGroup: boolean;
    showSimWithSubtitle: boolean;
    colorModeButton: React.ReactNode;
    userNavDropdown: React.ReactNode;
}) => {
    return (
        <div className="model-suite-top-nav flex h-11">
            <div className="model-suite-top-logo flex h-11 w-11 min-w-11 justify-center bg-slate-950">
                <TopNavLinkDiv style={{ padding: 0 }}>
                    <InertiaLink href={sapienRoute(logoRoute)}>
                        <LogoImage size={"22"} opacity={"0.9"} />
                    </InertiaLink>
                </TopNavLinkDiv>
            </div>
            <div
                className="model-suite-top-center flex h-11 w-[calc(100%-44px)] min-w-[calc(100%-44px)]
                    items-center justify-between bg-slate-950 text-white transition-all"
            >
                <div className="model-suite-left hidden flex-row flex-wrap items-center sm:flex">
                    <div
                        className={`hidden items-center ${!!childrenNavCenter ? "lg:flex" : "md:flex"}`}
                    >
                        <TopNavLinkDiv
                            style={{
                                padding: 0,
                            }}
                            className=""
                        >
                            <InertiaLink
                                href={
                                    !!showSimWithSubtitle
                                        ? !!simulationSlug &&
                                          sapienRoute(
                                              "simulation.projects.show",
                                              {
                                                  project: simulationSlug,
                                              },
                                          )
                                        : sapienRoute("dashboard")
                                }
                                data-testid={`return_to_sim`}
                            >
                                <div className="px-1 text-sm font-bold text-white md:px-3">
                                    {`${!!showSimWithSubtitle ? simulationTitle : "Dashboard"}`}
                                </div>
                            </InertiaLink>
                        </TopNavLinkDiv>
                        <div className="text-white opacity-60">
                            <ChevronRightIcon className="h-3 w-3" />
                        </div>
                    </div>
                    <div className={"hidden items-center sm:flex"}>
                        <div className="px-1 text-sm font-light md:px-3">
                            {subtitle}
                        </div>
                    </div>
                </div>
                {!!childrenNavCenter ? (
                    <div className="model-suite-center flex">
                        {childrenNavCenter}
                    </div>
                ) : (
                    <span></span>
                )}
                <div
                    className="model-suite-right flex h-full items-center bg-slate-950 text-white
                        transition-all"
                >
                    {!!showModelSuiteLinkGroup && (
                        <div className="mr-2 flex h-full items-center">
                            <ModelSuiteLinkGroup
                                simulationSlug={simulationSlug}
                                currentRoute={currentRoute}
                            />
                        </div>
                    )}
                    {colorModeButton}
                    {userNavDropdown}
                </div>
            </div>
        </div>
    );
};

export const ModelSuiteTopNav = React.memo(ModelSuiteTopNavComponent);

import { ContentBlockShape } from "@/models";
import React, { useCallback } from "react";
import { useUpdateStyles } from "../../content-block-management/useSaveContentBlock";
import { debounce } from "@/hooks";
import { useContentBlockState } from "../../useContentBlockState";
import PaddingSlider from "./PaddingSlider";

type Props = { contentBlock: ContentBlockShape };


export function DefaultSpacingMenu({ contentBlock }: Props) {
    const { mutateAsync } = useUpdateStyles();
    const { setContentBlock } = useContentBlockState();

    const update = useCallback(
        debounce((updatedBlock: ContentBlockShape) => {
            mutateAsync(updatedBlock);
        }, 100),
        [contentBlock],
    );
    const handleChange = useCallback((newBlock: ContentBlockShape) => {
        setContentBlock(newBlock);
        update(newBlock);
    }, []);

    return (
        <div className="flex flex-col">
            <div className="flex flex-col gap-4">
                <PaddingSlider contentBlock={contentBlock} handleChange={handleChange} />
            </div>
        </div>
    );
}
